@import "../../styles/variables";
@import "../../styles/mixins";

.calculators {
  margin-bottom: 90px;

  @include break-max($breakpoint-xs) {
    margin-bottom: 0;
    padding-bottom: 70px;
  }

}

.calculators__mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.calculators .installment-link {
  color: $color-brand-3;
  margin-right: 10px;
  margin-top: 5px;
  text-decoration: underline;
}

.calculators__navs {
  @include unlist();
  display: flex;
  margin: 0;
}

.calculators__navs-item {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  padding: 20px 40px;
  font-size: 18px;
  line-height: 22px;
  color: $color-brand-3;
  text-decoration: none;
  cursor: pointer;

  &.active {
    background: $color-brand-opaque-8;
    color: $color-brand-1;

    @include break-max($breakpoint-xs) {
      align-items: center;
      border-radius: 5px;
      display: flex;
      font-weight: bold;
      font-size: 18px;
      justify-content: center;
      height: 50px;
      line-height: 18px;
      margin: 0 10px 18px;
      padding: 0;
      width: calc(100% - 20px);
    }
  }
}

.calculators__tab {
  display: none;

  &.active {
    display: block;
  }
}

.calculators__container {
  background: $color-brand-opaque-8;
  padding: 30px 40px;
  border-radius: 0 5px 5px 5px;

  @include break-max($break-xs) {
    border-radius: 0;
    padding: 20px 10px;
  }
}

.calculator {

}


.calculator__grid {
  @include grid();
  justify-content: space-between;
}

.calculator__form {
  @include grid();
  @include grid-col();
  @include grid-size(8);
  @include break-max($breakpoint-sm) {
    @include grid-size(12, $grid-gutter-sm);
  }

  @include break-max($breakpoint-xs) {
    margin: 0;
    width: 100%;
  }
}

.calculator__result {
  padding-top: 15px;
  @include grid-col();
  @include grid-size(4);

  @include break-max($breakpoint-sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  @include break-max($breakpoint-sm) {
    @include grid-size(12, $grid-gutter-sm);
  }

  &_no-padding {
    padding-top: 0;
  }
}


.calculator__result-info {
  font-size: 14px;
  line-height: 21px;
  color: $color-brand-3;
  width: 190px;
}

.calculator__result-value {
  font-size: 48px;
  line-height: 55px;
  font-weight: bold;
  white-space: nowrap;

  @include break-max($breakpoint-sm) {
    font-size: 40px;
    line-height: 1.25;
    margin: 0 30px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 35px;
    line-height: 55px;
    margin-right: 20px;
  }
}

.calculator__result-btn {
  margin-top: 20px;

  @include break-max($breakpoint-sm) {
    margin-top: 0;
  }
}

.calculator__item {
  flex: 0 0 calc(50% - 30px);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  flex-grow: 1;

  @include break-max($break-xs) {
    flex-basis: 100%;
  }

  &:nth-of-type(2n + 1) {
    margin-right: 30px;
  }

}

.calculator__item label {
  font-size: 14px;
  line-height: 21px;
  color: $color-brand-3;
  margin-bottom: 5px;

  @include break-max($breakpoint-xsm) {
    white-space: nowrap;
  }
}

.calculator__item-wrapper {
  background: $color-white;
  border-radius: 4px;
  overflow: hidden;
  height: 50px;
}

.calculator__item-wrapper input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.calculator input {
  font-size: 14px;
  line-height: 17px;
  padding: 0 10px;
}

.calculator .slider__input {
  padding: 0;
}

.calculator__sign {
  color: $color-brand-3;
}

.calculator__result-flex {
  display: flex;
  align-items: flex-end;

  @include break-max($breakpoint-sm) {
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.calculators_cashback  {

  .calculators__container {
    padding: 30px 32px;
  }

}

.calculator_cashback  {

  .calculator__grid {
    margin: 0;
  }
  .calculator__form {
    width: 640px;
    max-width: 100%;
    margin: 0;

    @include break-max($breakpoint-md) {
      width: 530px;
    }

    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .calculator__item {
    @include break-max($breakpoint-sm) {
      margin-bottom: 20px;
    }
  }

  .calculator__result {
    margin: 0;
    width: fit-content;
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .calculator__result-value {
    font-size: 35px;
    line-height: 40px;
    @include break-max($breakpoint-sm) {
      margin: 0;
      margin-top: 5px;
    }
  }

  .calculator__result-btn {
    margin-top: 0;
    margin-left: 35px;

    @include break-max($breakpoint-md) {
      margin-left: 15px;
    }
    
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      margin-top: 15px;
      width: 100%;
    }
  }

  .calculator__result-btn .button {
    @include break-max($breakpoint-md) {
      display: flex;
      padding: 17px 0;
      justify-content: center;
      width: 180px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .calculator__item:nth-of-type(2n + 1) {
    @include break-max($breakpoint-md) {
      margin-right: 20px;
    }
  }

  .calculator__grid {
    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
  }

  .calculator__result-block {
    @include break-max($breakpoint-sm) {
      flex-basis: 50%;
    }
  }

  .calculator__result-info {
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
  }

  .calculator__sign {
    @include break-max($breakpoint-sm) {
      margin-top: 25px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }
  }
}
