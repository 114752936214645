@import "../../styles/variables";
@import "../../styles/mixins";

.sell {
  padding-bottom: 60px;

  .botBar {
    margin-left: -30px;
    margin-right: -30px;
  }

  .container {
    max-width: 930px;
  }

  .input__label .info {
    width: 26px;
    height: 26px;
    margin-left: 5px;
  }

  .modal-form__files {
    padding-bottom: 10px;
  }

  .modal-form__gist .sell__subtitle {
    margin-top: 20px;
  }

  .textarea__control {
    min-height: 170px;
  }
}

.sell__required {
  padding-left: 3px;
}

.sell__title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-hover {
    margin-top: 15px;
  }

}

.sell__subtitle {
  margin-top: 55px;
  margin-bottom: 25px;
  text-align: center;
}

.sell__button-with-info {
  display: flex;
  justify-content: center;
  align-items: center;

  .info {
    border: 1px solid $color-white;
    margin-left: 15px;
  }

  .info .icon {
    fill: $color-white;
  }

  .info__popup {
    color: $color-brand-1;
    width: 450px;
  }
}

.sell__switcher {
  margin-top: 40px;

  .switcher__btn {
    @include break-max($breakpoint-xs) {
      width: auto;
    }
  }
}

  @include break-max($breakpoint-sm) {
    .sell {
      // .modal-form__row {
      //   flex-direction: column;
      // }
      //
      // .modal-form__item + .modal-form__item {
      //   margin-left: 0;
      // }

      .container {
        margin: 0 40px;
      }
    }
  }

  @include break-max($breakpoint-xs) {
    .sell {
      .container {
        margin: 0 10px;
        padding: 0;
      }

      .h1 {
        padding-top: 20px;
      }

      .sell__subtitle {
        margin-top: 30px;
      }

      .modal-form__gist.with-margin {
        border-radius: 0;
        padding: 12px 10px 24px;
      }

      .modal-form__item_sm {
        flex-basis: 0;
      }

      .checkbox__label {
        padding-left: 5px;
      }

      .modal-form__submit .button {
        width: 100%;
      }

      .botbar {
        margin-top: 25px;
      }
    }
  }
