.events-calendar {
	width: auto;
	background-color: transparent;
	border: none;
}

.events-calendar .react-calendar__tile {
	overflow: visible !important;
}

.events-calendar__events {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.events-calendar__event {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin: 5px 2px;
}

.events__calendar {
	overflow: hidden;
}

.events__calendar .tns-outer {
	position: relative;
	z-index: 2;
}

.events__calendar .tns-ovh {
	overflow: visible;
}

.events-calendar__day {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.events-calendar__event_past {
	background-color: rgb(143, 149, 172, 0.4);
}

.events-calendar__event_upcoming {
	background-color: #7684bc;
}

.events-calendar__event_active {
	background-color: #ff2d23;
}

.react-calendar__navigation__arrow {
	display: none;
}

.react-calendar__navigation__label {
	font-weight: bold;
	font-size: 25px;
	line-height: 30px;
	color: #2c2e3e;
}

.react-calendar__navigation button[disabled] {
	background-color: transparent;
}

.react-calendar__month-view__weekdays__weekday abbr {
	text-decoration: none;
	color: #8f95ac;
}

.react-calendar__tile {
	position: relative;
	width: 35px;
	min-height: 35px;
	border-radius: 5px;
	padding: 0.75em 5px 2px;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
}

.react-calendar__tile:enabled:hover .events-calendar__event,
.react-calendar__tile:enabled:focus .events-calendar__event {
	background-color: #fff;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background-color: #ff2d23;
	color: #fff;
}

.react-calendar__tile--active,
.react-calendar__tile--now {
	background: transparent;
	color: #2c2e3e;
}

.react-calendar__month-view__days {
	align-items: flex-start;
}

.calendar__month {
	padding: 40px 100px;
	background-color: transparent;
}

@media screen and (max-width: 1600px) {
	.calendar__month {
		padding: 40px 40px;
		background-color: transparent;
	}
}

.calendar__month:hover {
	background-color: rgba(143, 149, 172, 0.08);
}

.calendar__month.active {
	background-color: rgba(143, 149, 172, 0.08);
}

.event-card {
	width: 290px;
	padding: 10px;
}

.event-card:nth-child(even) {
	background-color: rgba(196, 196, 196, 0.1);
	border-radius: 5px;
}

.event-card__head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.event-card__name {
	color: #2c2e3e;
	margin-bottom: 5px;
}

.event-card__speaker {
	color: #8f95ac;
	margin-top: 10px;
}

.popover__block_align_center {
	top: 50%;
	transform: translateY(-50%);
	left: calc(100% + 28px);
}

.popover__block_align_center:after {
	top: 50%;
	transform: translateY(-50%);
	border-bottom: 14px solid transparent;
	border-right: 14px solid #fff;
	border-left: none;
	border-top: 14px solid transparent;
}

.popover__block_align_left.popover__block_align_center {
	left: auto;
	right: calc(100% + 20px);
}

.popover__block_align_left.popover__block_align_center:after {
	left: calc(100% - 5px);
	border-bottom: 14px solid transparent;
	border-right: none;
	border-left: 14px solid #fff;
	border-top: 14px solid transparent;
}

.events-calendar__popover {
	top: -10px;
	box-shadow: 0px 2px 40px rgba(44, 46, 62, 0.1);
}
