.switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;

  &__btn {
    padding: 16px 20px;
    border-radius: 5px;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: all $transition-default;
    
    @include break-max($breakpoint-sm) {
      padding: 8px 10px;
    }

    & + & {
      margin-left: 15px;
    }

    &:hover {
      background-color: $color-brand-opaque-10;
    }

    &.active {
      background-color: $color-brand-3;
      color: $color-white;
    }
  }
}