.pan-popup {
  background: $color-brand-2-o-20;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.pan-popup-content-wrapper {
  bottom: 0;
  height: 50%;
  position: absolute;
  width: 100%;

  &::after {
    background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 20%, $color-white 70%);
    content: '';
    height: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 120;
  }
}

.pan-popup-content {
  background: $color-white;
  border-radius: 5px 5px 0 0;
  max-height: 50%;
  padding: 35px 85px 20px 10px;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 110;

  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  &.is-active {
    transform: translateX(0);
  }
}

.pan-popup-close-btn {
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 16px;
  height: 32px;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  top: 15px;
  width: 32px;
  z-index: 111;

  .icon {
    stroke: $color-brand-3-hover;
    height: 10px;
    margin-right: -1px;
    width: 10px;
  }
}

.info .pan-popup-trigger {
  display: flex;
}
