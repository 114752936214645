.popover {
  $c: &;
  position: relative;

  &.is-showing {
    z-index: 999;

    #{$c}__block {
      display: block;
    }
  }

  &__block {
    position: absolute;
    top: 50%;
    right: calc(100% + 45px);
    display: none;
    box-shadow: 0px 2px 20px rgba(44, 46, 62, 0.1);
    cursor: default;
    text-align: left;

    &:after {
      transform: rotate(-45deg);
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: calc(100% - 10px);
      border-bottom: 14px solid $color-white;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
    }

    &_align {

      &_right {
        right: inherit;
        left: calc( 100% + 45px );

        &:after {
          left: -10px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__inner {
    padding: 20px;
    background-color: $color-white;

    &.no-padding {
      padding: 0;
    }
  }

  &__btn {
    transition: background-color $transition-default;
  }
}
