@import "../../styles/variables";
@import "../../styles/mixins";

.poll-title {
  display: flex;
  justify-content: center;
}

.poll-timer {
  display: inline-flex;
  position: relative;
  align-items: center;
  left: 35px;
  margin-top: 8px;
  color: $color-brand-3;
  font-weight: 400;

  svg {
    margin-right: 10px;
  }
}

.poll {
  max-width: 1000px;
  padding: 0 15px;
  margin: 40px auto 0;

  .radio__box + .radio__label {
    padding-left: 14px;
  }

  .checkbox-group__item {
    align-items: flex-start;
    padding: 0;
    &:hover {
      background: none;
    }
  }
}

.poll__item {
  display: flex;
  align-items: center;
  padding: 42px 25px;
  border-radius: 5px;

  &:nth-child(odd) {
    background: $color-brand-opaque-8;
  }

  &_flex-start {
    align-items: flex-start;
  }
}

.poll__q {
  width: 232px;
  flex-shrink: 0;
  margin-right: 60px;
  font-weight: bold;

  @include break-max($breakpoint-sm) {
    margin-right: 10px;
  }
}

.poll__a {
  flex-grow: 1;
}

.poll__submit {
  display: flex;
  margin: 0 auto;
  &_big-mb {
    margin-bottom: 100px;
  }
}

.poll__row {
  display: flex;
  width: 100%;

  & > .poll__elem,
  .poll__col {
    margin-right: 50px;
    @include break-max($breakpoint-sm) {
      margin-right: 15px;
    }
    &_big-mr {
      margin-right: 170px;
      @include break-max($breakpoint-sm) {
        margin-right: 15px;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  & + & {
    margin-top: 25px;
  }
}

.poll__col {
  & > .poll__elem {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

}

.poll__input {
  width: 345px;
  max-width: 100%;
}

.poll__half {
  width: 50%;
}

.poll__elem {
  display: flex;
}

.poll__box {
  display: flex;
  width: 161px;
  justify-content: space-between;
}

.poll__social {
  margin-left: 15px;
}
