@import "../../styles/variables";
@import "../../styles/mixins";

.fixations {

  .modal-form__files {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .file-up {
    justify-content: space-between;
  }

  .file-up__inner {
    height: 30px;
  }

  &__container {
    max-width: 730px;
    display: flex;
    gap: 20px;
    position: relative;
  }

  &__settings-block {
    padding: 30px;
    border-radius: 20px;
    background-color: $gray-light;
    width: 410px;
    box-sizing: border-box;
    margin: 10px 0 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .hp__form-item input {
      box-shadow: none;
    }
  }
  &__block-dash {
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-dark;
  }

  .input_type_form .input__control {
    box-shadow: none;
  }
  .input_type_form .input__error{
    box-shadow: inset 0 0 0 1px #ff2d23;
  }
  .drop__error .form__drop-btn{
    box-shadow: inset 0 0 0 1px #ff2d23;
    border-radius: 6px;
  }

  .form__item{
    margin-bottom: 0;
  }
  .hp__form-item + .hp__form-item {
    margin-top: 0;
  }
  .textarea_type_form .textarea__control {
    box-shadow: none;
  }

  &__checkboxs {
    .checkbox__label {
      color: $gray-dark-2;
    }
  }
  .hp__form-label {
    display: flex;
    justify-content: space-between;
    .hp__form-label-rules {
      color: $blue;
      cursor: pointer;
    }
  }

  .new-alert {
    margin-bottom: 0;
  }

}
.crm-table__sf-controlsmodal.crm-table__sf-controlsmodal_fixation {
   .modal__inner {
    width: 450px;
  }
}

.select-filter-fix{
  margin-left: 0;
  width: 100%!important;
}
.fixation-dp-fix{
  .react-datepicker-popper{
    z-index: 99;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
}

.flexible-tag__container {
  display: flex;
  min-width: 0;
  font-size: 13px;
  padding: 5px 10px;
  background-color: #ECEDF0;
  border-radius: 20px;
  color: #2C2E3E;
  margin-right: 9px;

  .flexible-tag__title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .flexible-tag__button {
    margin-left: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.rules-text {
  min-width: fit-content;
  color: $blue;
  cursor: pointer;
}

.custom-select_fixations {
  .custom-select__search {
    position: absolute;
    background-color: white;
    width: 100%;
  }
  .custom-select__options {
    margin-top: 48px;
  }
}

.custom-select_sf-filter-fixations {
  .input__control {
    height: 32px;
    padding: 0px 16px;
  }
  .form__drop-content {
    padding: 5px 22px 24px 16px;
    min-width: auto;
  }

  .form__drop-name {
    span {
      background-color: #ff2d23!important;
    }
  }
}

.advantages-list {

  &__item {
    font-size: 17px;
    line-height: 20px;
    margin-top: 20px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
      left: -16px;
      top: 8px;
      position: absolute;
    }
  }
}

.sf__sticky.sf__sticky_fixations {
  @include break-max($breakpoint-sm) {
    display: block;
  }
}
