table.dataTable {
  & thead {
    background-color: $color-brand-2;
    color: $color-white;
    border-radius: 4px;
    & tr {
      padding: 0;
    }
    & th {
      border: none;
      padding: 18px 10px;
      font-weight: normal;
      text-align: left;

      // @include break-min($breakpoint-sm) {
      //   &:nth-child(2) {
      //     border-radius: 4px 0 0 4px;
      //   }

      //   &:last-child {
      //     border-radius: 0 4px 4px 0;
      //   }
      // }

    }

    & > th.control {
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
    }
  }

  &.dtr-column > tbody > tr.parent td.control:before {
    content: "—";
    font-size: 13px;
    font-weight: bold;
  }

  & tbody {
    & tr {
      cursor: pointer;

      &:hover {
        background: $color-brand-opaque-8!important;
      }

    }
    td {
      padding: 18px 10px;
      border-top: 1px solid $color-brand-opaque-30;
      border-bottom: none;

      &.control {
        width: 30px;

        &:before {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 25px;
          width: 25px;
          margin: auto;
          background-color: $color-brand-opaque-10 !important;
          border: none;
          box-shadow: none;
          font-size: 20px;
          line-height: 22px;
          font-weight: 300;
          font-family: "Formular", sans-serif;
          color: $color-brand-1;
        }
      }
    }

    & > tr.child {
      ul.dtr-details {
        display: flex;
        padding: 0 6.5px 0;

        li {
          padding: 0.5em 0;
          border: none;
          margin-left: 30px;

          &:first-child {
            padding: 0.5em 0;
            margin-left: 0;
          }

          .dtr-title {
            display: block;
          }
        }
      }
    }
  }
}

table.dataTable.display tbody tr.odd {
  background-color: #fff;

  &:hover {
    background-color: whitesmoke;
  }
}
table.dataTable.display tbody td {
  // border-top: none;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.dt {

  &_mod {

    &_no-p {

      &_h {
        padding-left: 0!important;
        padding-right: 0!important;
      }
    }
  }

  &__more-control {
    margin-top: 15px;
    cursor: pointer;
    color: $color-brand-3;
    transition: color $transition-default;
    display: flex;
    align-items: flex-start;


    &:hover {
      color: $color-red;
    }

    & span {
      display: block;
    }

    & .icon {
      flex-shrink: 0;
      margin-top: 3px;
    }
  }
}

.control_more {
  & > .form__item {
    width: 28px;
    background-color: transparent;
    height: auto;
    margin: 0;
    z-index: 1;

    .dropdown {
      height: 28px;

      &__block {
        top: -17px;
        right: -17px;
        left: auto;
        z-index: -1;
        cursor: default;
      }
    }

    .form__drop.is-showing {
      .form__drop-btn {
        background-color: rgba($color-icon, 0.3);
        border-radius: 50%;
      }
    }

    .form__drop-name {
      display: none;
    }

    .form__drop-content {
      padding: 24px 17px;
      min-width: 220px;

      hr {
        border-top: none;
        border-color: $color-brand-opaque-30;
        margin-right: 40px;
      }
    }

    .form__drop-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-brand-opaque-10;
      box-shadow: none;
      padding: 0;
      border-radius: 50%;
      width: 28px;
      height: 28px;

      &:hover {
        background-color: $color-brand-opaque-30;
      }

      .icon.icon_elipsis {
        width: 15px;
        height: 15px;
        stroke: none;
      }
    }

    .icon__elipsis {
      transform: none;
    }
  }
}

table thead, .custom-table__head {
  & .sorting {
    background-image: none;
    cursor: pointer;

    &-th {
      display: flex;
      align-items: center;
    }

    &-arrows {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='20' viewBox='0 0 8 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 0L0.000719547 7.36842H7.36914L3.68493 0Z' fill='%238F95AC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 20L0.000719547 12.6316H7.36914L3.68493 20Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      width: 8px;
      height: 20px;
      margin-left: 10px;
    }

    &_asc {
      background-image: none;

      .sorting-arrows {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 8L0.000719547 0.631579H7.36914L3.68493 8Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
        background-position: center bottom;
      }
    }

    &_desc {
      background-image: none;

      .sorting-arrows {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 0L0.000719547 7.36842H7.36914L3.68493 0Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

.dt__more {
  &-control {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-radius: 4px;
    color: $color-brand-1;
    cursor: pointer;
    transition: background 0$transition-default;

    &:hover {
      background-color: $color-brand-opaque-10;
    }
    &:first-of-type {
      margin-top: 18px;
    }
    &:nth-of-type(n + 2) {
      margin-top: 10px;
    }

    .icon {
      width: 19px;
      height: 19px;
      transform: none;
      margin: auto 10px auto 0;
      transform: none !important;
    }
  }
}

.table__show-more {
  text-align: center;
  padding: 18px 10px;

  &_btn {
    display: inline-block;
    color: $color-brand-3;
    cursor: pointer;
    transition: color $transition-default;

    .icon {
      margin-left: 5px;
      width: 10px;
      stroke: $color-brand-3;
      transition: stroke $transition-default;
    }

    &:hover {
      color: $color-brand-2;

      .icon {
        stroke: $color-brand-2;
      }
    }
  }
}

img.table-image-scalable {
  &:hover {
    @include break-max($breakpoint-xs) {
      transform: scale(4);
      transform-origin: center;
    }
  }
}
