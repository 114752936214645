.manager {
  $c: &;
  display: flex;

  &-content {
    @include break-max($break-xs) {
      margin-top: 10px;
    }
  }

  &-avatar {
    display: flex;
    flex-basis: 0;
    margin-right: 36px;
    &_wrapper {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      overflow: hidden;

      & img {
        max-width: 100%;
        max-width: 100%;
      }
    }
  }
  &-title {
    font-weight: bold;
    font-size: 25px;
    line-height: 28px;
    margin-top: 22px;
  }

  &-post {
    font-size: 14px;
    line-height: 21px;
    color: $color-brand-3;
    margin-top: 4px;
  }

  &-contacts {
    margin-top: 16px;
    &_list {
      @include unlist();
      display: flex;
      flex-wrap: wrap;
    }
    &_item {
      margin-right: 30px;
      margin-bottom: 10px;
      &:last-of-type {
        margin-right: 0;
      }

      @include break-max($break-xs) {
        flex-basis: 50%;
        margin: 0;
        padding-right: 5px;
      }
    }
  }

  &-action {
    margin-top: 20px;

    & button {
      font-weight: bold;
    }
  }

  &-socials {
    margin-top: 10px;

    &__list {
      display: flex;
    }

    &__item {
      margin-right: 10px;
    }
  }

  &.medium {
    #{$c}-avatar {

      &_wrapper {
        width: 90px;
        height: 90px;
      }
    }
  }

  &.small {
    #{$c}-avatar {
      margin-right: 10px;

      &_wrapper {
        width: 50px;
        height: 50px;

        @include break-max($break-xs) {
          width: 75px;
          height: 75px;
        }
      }
    }

    #{$c}-title {
      font-size: 14px;
      line-height: 21px;
      margin-top: 10px;
    }

    #{$c}-contacts {
      margin-top: 12px;

      &_item {
        margin-bottom: 3px;
      }
    }
  }
}
