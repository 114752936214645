.client__value {
  word-break: break-word;
}
.custom-select-high{
  width: 195px!important;
}

.pan-table.for-clients .custom-select__selected .label-item{
  padding-left: 0;
}
.option-small-padding{
  padding: 8px 8px;
}

.clients__download {
  cursor: pointer;
}

.clients__crm-data {
  position: relative;
}

.clients__crm-data .mini-filter .form {
  background-color: transparent;
  padding: 0;
}

.clients__crm-data .mini-filter .form .form__drop-btn {
  background-color: #f6f7f8;
}

.clients__crm-data .mini-filter {
  margin-bottom: 0;
}

.clients__crm-data .clients__filter-col_search .input_type_search .input__control {
  background-color: #f6f7f8;
}

.clients__crm-data .mini-filter .form  .clients__filter-col {
  margin-right: 20px;
}
.clients__crm-data .mini-filter .form  .clients__filter-col:last-child {
  margin-right: 0px;
}

.clients__crm-data .flats__row-table table {
  padding-left: 0;
  padding-right: 0;
}

.clients__crm-data .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 5px 0 0 5px;
}
.clients__crm-data .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0 5px 5px 0;
}

.clients__crm-data .ant-table-container table > tbody > tr td:first-child {
  border-radius: 5px 0 0 5px;
}
.clients__crm-data .ant-table-container table > tbody > tr td:last-child {
  border-radius: 0 5px 5px 0;
}

.clients__crm-data .mini-filter .form {
  margin-left: 0;
  margin-right: 0;
  width: calc(100% - 600px);
}

.clients__crm-data .clients__filter-col_search {
  flex: 1 1 270px;
}

@media screen and (max-width: 1599px) {
  .clients__crm-data .mini-filter .form {
    width: calc(100% - 350px);
  }
}

@media screen and (max-width: 1279px) {
  .clients__crm-data .mini-filter .form {
    width: calc(100% - 250px);
  }
  .clients__crm-data .clients__filter-col_search {
    flex: 1 1 210px;
  }
}

.button_addClientFromCrm {
  position: absolute;
  top: 0;
  right: 0;
}

.table__controls.table__controls_fromCrm {
  justify-content: flex-start;
  margin-bottom: 15px;
  margin-top: 30px;
}

.table__controls.table__controls_fromCrm .button_view_control:first-child {
  margin-left: 0;
  padding-left: 0;
}
.table__controls.table__controls_fromCrm .button_view_control {
  height: auto;
  color: #8f95ac;
}

.table__controls.table__controls_fromCrm .button_view_control:hover {
  box-shadow: none;
  color: #ff2d23;
}

.mini-filter__grid.mini-filter__grid_fromCrmPresentation {
  justify-content: flex-start;
}

.mini-filter__grid.mini-filter__grid_fromCrmPresentation .clients__filter-col {
  min-width: 170px;
  flex: 0;
}
