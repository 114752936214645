@import "../../../styles/variables";
@import "../../../styles/mixins";

.lid {
  position: relative;
  z-index: 2;
  border-radius: 8px;
  padding-left: 98px;
  background: $gray-light;

  @include break-max($breakpoint-sm) {
    padding-left: 80px;
    overflow: hidden;
  }
  @include break-max($breakpoint-xsm) {
    padding-left: 60px;
  }
  @include break-max($breakpoint-xs) {
    padding: 0 25px;
  }
}

.lid__box {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 308px;


  @include break-max($breakpoint-xs) {
    flex-direction: column;
    text-align: center;
  }
}

.lid__btn {
  width: 180px;
  max-width: 100%;
  min-width: fit-content;
  margin-top: 0;
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.lid__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  min-height: 308px;
  max-width: 100%;
  padding: 55px 0 45px;
  @include break-max($breakpoint-sm) {
    width: 320px;
    z-index: 1;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.lid__title {
  display: block;
  margin-bottom: 20px;
}

.lid__aside {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-sm) {
    position: absolute;
    right: -80px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.lid__descr {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 25px;
  color: $color-brand-3;
  @include break-max($breakpoint-xs) {
    margin-bottom: 140px;
  }

}

.lid__icon {
  height: 100%;

  svg {
    width: 542px;
    height: 100%;
  }

  @include break-max($breakpoint-md) {

  }
  @include break-max($breakpoint-sm) {

  }
  @include break-max($breakpoint-xsm) {

  }
  @include break-max($breakpoint-xs) {
    height: 225px;
  }
}
