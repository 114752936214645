@import "../../styles/variables";
@import "../../styles/mixins";

.sor_rieltor {
  .sor__h1 {
    font-size: 55px;
    line-height: 55px;
    @include break-max($breakpoint-md) {
      font-size: 48px;
      line-height: 50px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 32px;
      line-height: 35px;
    }
    @include break-max($breakpoint-xs) {
      font-size: 28px;
      line-height: 28px;
    }
  }
  .sor__sign {
    max-width: 490px;
    @include break-max($breakpoint-sm) {
      max-width: 100%;
      width: 300px;
      margin-bottom: 45px;
      @include break-max($breakpoint-xs) {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  .sor__faq-section {
    background: $green-light;
  }
  
  .sor__faq {
    margin-top: 0;
    padding-top: 45px;
    padding-bottom: 45px;
    @include break-max($breakpoint-sm) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    @include break-max($breakpoint-sm) {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .sor__sup {
    font-size: 12px;
    line-height: 18px;
    color: $green-light;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    @include break-max($breakpoint-xs) {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .sor__faq-title, .sor__faq-descr {
    color: $color-white;
  }

  .sor__faq-title {
    margin-bottom: 15px;
    @include break-max($breakpoint-xs) {
      margin-bottom: 5px;
    }
  }

  .sor__faq-descr {
    font-size: 20px;
    line-height: 24px;
    @include break-max($breakpoint-md) {
      font-size: 18px;
    }

    span {
      display: block;
      font-weight: normal;
      margin-top: 3px;
    }
  }
}

.preview .rie {
  margin-top: 85px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }

  .input__control {
    background-color: $color-white;
  }

}

.rie {

  color: $color-brand-1;

  .button_view_default {
    background: $green-light;

    &:hover, &:focus {
      background: $green-light-hover;
    }

    &:active {
      background: $green-light-active;
    }

  }

  .button_view_outline-with-icon-invert {
    color: $green-light;
    box-shadow: inset 0 0 0 1px $green-light;
    &:hover {
      background-color: $green-light;
      color: $color-white;
    }
    &:active {
      background: $green-light-active;
    }
  }
  
  .sor__box {
    padding-top: 85px;
    @include break-max($breakpoint-md) {
      padding-top: 35px;
    }
    @include break-max($breakpoint-sm) {

    }
    @include break-max($breakpoint-xs) {
      padding-top: 25px;
    }
  }

  .sor__h1 {
    margin-bottom: 25px;
  }


  .rie__top-row {
    display: flex;
    justify-content: space-between;
    width: 490px;
    max-width: 100%;
    margin-bottom: 45px;
    @include break-max($breakpoint-sm) {
      width: 100%;
    }
    @include break-max($breakpoint-xs) {
      flex-wrap: wrap;
      order: 1;
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  .rie__top-list {
    display: flex;
    flex-wrap: wrap;
  }

  .rie__top-item {
    display: flex;
    position: relative;
    width: 190px;
    height: 35px;
    margin-bottom: 20px;

    @include break-max($breakpoint-sm) {
      width: 220px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-top: -15px;
      @include break-max($breakpoint-xs) {
        margin-bottom: 20px;
      }
    }

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      flex-shrink: 0;
      border-radius: 50%;
      background: $green-light;
      margin-top: 6px;
      margin-right: 12px;
    }
  }

  .sor__promo-pic {
    width: 680px;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: -200px;
    @include break-max($breakpoint-md) {
      right: -50px;
    }
    @include break-max($breakpoint-sm) {
      overflow: inherit;
      right: 0;
    }
    @include break-max($breakpoint-xs) {
      position: relative;
    }
  }

  .rie__promo-svg-1 {
    position: absolute;
    left: -80px;
    top: 220px;
    width: 230px;
    @include break-max($breakpoint-md) {
      left: 40px;
      top: 170px;
    }
    @include break-max($breakpoint-sm) {
      top: 90px;
      width: 150px;
      left: auto;
      right: 250px;
    }
    @include break-max($breakpoint-xs) {
      top: 60px;
      right: 230px;
    }
  }

  .rie__promo-svg-2 {
    position: absolute;
    left: 140px;
    top: 470px;
    width: 200px;
    @include break-max($breakpoint-md) {
      left: 200px;
      top: 370px;
      width: 230px;
    }
    @include break-max($breakpoint-sm) {
      width: 150px;
      top: 210px;
      left: auto;
      right: 150px;
    }
    @include break-max($breakpoint-xs) {
      top: 190px;
    }
  }

  .sor__faq-item:first-of-type {
    white-space: nowrap;
    width: 410px;
    @include break-max($breakpoint-sm) {
      white-space: unset;
      width: 200px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__faq-item {
    @include break-max($breakpoint-sm) {
      width: 200px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .rie__prog {
    padding-top: 90px;
    display: flex;
    @include break-max($breakpoint-md) {
      padding-top: 80px;
    }
    @include break-max($breakpoint-sm) {
      padding-top: 40px;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .rie__prog-list {
    width: 650px;
    max-width: 100%;
    @include break-max($breakpoint-md) {
      width: 580px;
    }
    @include break-max($breakpoint-sm) {
      width: 415px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-bottom: 25px;
    }


    li {
      display: flex;
      align-items: center;
      height: 60px;
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      border-bottom: 1px solid $color-brand-opaque-70;

      @include break-max($breakpoint-sm) {
        font-size: 16px;
        line-height: 20px;
        height: 55px;
      }

      &:last-of-type {
        border: none;
      }
      svg {
        margin-right: 25px;
        @include break-max($breakpoint-xs) {
          margin-right: 15px;
          align-self: flex-start;
          margin-top: 15px;
        }
      }
    }
  }

  .rie__prog-aside {
    width: 470px;
    max-width: 100%;
    margin-left: auto;
    background: $color-brand-opaque-8;
    border-radius: 20px;
    padding: 50px 80px 60px;

    @include break-max($breakpoint-md) {
      width: 450px;
      padding: 40px 70px 50px;
    }

    @include break-max($breakpoint-sm) {
      width: 265px;
      padding: 20px 35px 30px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      text-align: center;
    }
  }

  .rie__prog-img {
    position: relative;
    left: -55px;
    margin-bottom: 45px;
    @include break-max($breakpoint-sm) {
      left: 0;
      margin-bottom: 25px;
    }
  }
  .rie__prog-title {
    font-weight: 900;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 25px;
    @include break-max($breakpoint-sm) {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .rie__prog-text {
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    @include break-max($breakpoint-sm) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .rie__about-info {
    font-weight: 900;
    font-size: 26px;
    line-height: 35px;

    margin-top: -50px;

    @include break-max($breakpoint-sm) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  .rie__about-img-left {
    position: relative;
    left: -90px;
    @include break-max($breakpoint-xs) {
      left: 0;
    }
  }

  .rie__about-img-right {
    @include break-max($breakpoint-xs) {
      display: block;
      margin: 0 auto;
    }
  }

  .rie__about-left {
    width: 610px;
    max-width: 100%;
    @include break-max($breakpoint-md) {
      width: 580px;
    }
    @include break-max($breakpoint-sm) {
      width: 415px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .rie__about-right {
    width: 470px;
    max-width: 100%;
    margin-left: auto;


    margin-top: 105px;

    
    @include break-max($breakpoint-md) {
      width: 450px;
    }

    @include break-max($breakpoint-sm) {
      width: 265px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-top: 45px;
    }
  }

  .rie__about-text {
    margin-top: 70px;
    font-size: 21px;
    line-height: 30px;
    @include break-max($breakpoint-sm) {
      font-size: 18px;
      line-height: 26px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 35px;
    }
  }

  .rie__title {
    font-weight: 900;
    font-size: 55px;
    line-height: 55px;
    @include break-max($breakpoint-md) {
      font-size: 48px;
      line-height: 50px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 32px;
      line-height: 35px;
    }
    @include break-max($breakpoint-xs) {
      font-size: 28px;
      line-height: 28px;
    }
  }
  
  .rie__section-title {
    padding-bottom: 60px;

    @include break-max($breakpoint-xs) {
      padding-bottom: 30px;
    }
  }

  .rie__for-row {
    display: flex;
    justify-content: space-between;
    &:first-of-type {
      margin-bottom: 65px;
      @include break-max($breakpoint-sm) {
        margin-bottom: 0;
      }
    }
    
    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .rie__for-col {
    
  }

  .rie__for-item {
    width: 48%;
    display: flex;
    align-items: flex-start;

    @include break-max($breakpoint-sm) {
      width: 100%;
      margin-bottom: 50px;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .rie__for-row:last-of-type .rie__for-item:last-of-type {
    @include break-max($breakpoint-sm) {
      margin-bottom: 0;
    }
  }

  .rie__for-img {
    width: 180px;
    margin-right: 30px;
    
    @include break-max($breakpoint-xs) {
      width: 130px;
      margin-bottom: 15px;
      // margin-left: auto;
      // margin-right: auto;
    }
  }

  .rie__for-box {
    
  }

  .rie__for-title {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
    }
  }

  .rie__for-text {
    font-size: 21px;
    line-height: 30px;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rie__section {
    padding-top: 75px;
    padding-bottom: 100px;
    //border-bottom: 1px solid $color-brand-opaque-20;
    @include break-max($breakpoint-sm) {
      padding-bottom: 75px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 45px;
      padding-bottom: 45px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .rie__about {
    display: flex;
    padding-top: 0;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      text-align: center;
    }
  }

  .rie__learn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .rie__learn-item {
    width: 325px;
    max-width: 100%;
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      margin-bottom: 70px;
    }

    @include break-max($breakpoint-xs) {
      &:nth-child(n) {
        margin-bottom: 35px;
      }
    }
  }

  .rie__learn-title {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 50px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    @include break-max($breakpoint-sm) {
      height: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rie__learn-text {
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rie__how-container {
    display: flex;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .rie__how-img {

  }

  .rie__how-aside {
    position: relative;
    left: -90px;
    display: flex;
    width: 570px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    @include break-max($breakpoint-sm) {
      left: -30px;
      width: 360px;
    }
    @include break-max($breakpoint-xs) {
      left: 0;
    }
  }

  .rie__how-btn {
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .rie__how-list {
    padding-top: 80px;
    width: 595px;
    max-width: 100%;
    margin-left: auto;
    @include break-max($breakpoint-sm) {
      padding-top: 80px;
      width: 330px;
      padding-top: 50px;
    }
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      width: 100%;
    }
  }

  .rie__how-item {
    margin-bottom: 50px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .rie__how-title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: $color-brand-3;
    b {
      color: $color-brand-1;
    }

  }

  .rie__how-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-bottom: 20px;
    padding: 0 18px;
    border-radius: 4px;
    background: $green-light;
    color: $color-white;
    line-height: 1;
    font-weight: bold;
  }
  
  .rie__how-text {
    font-size: 21px;
    line-height: 30px;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rie__banner {
    color: $color-white;
    background: $green-light;
  }
  
  .rie__banner-container {
    display: flex;
    align-items: center;
    height: 240px;
  }

  .rie__banner-content {
    display: flex;
    align-items: flex-start;
    @include break-max($breakpoint-sm) {
      padding: 0 50px;
    }
    @include break-max($breakpoint-xs) {
      padding: 0 25px;
      flex-direction: column;
    }
  }
  
  .rie__banner-percent {
    font-weight: 900;
    font-size: 55px;
    line-height: 55px;
    @include break-max($breakpoint-sm) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  
  .rie__banner-box {
    margin-left: 95px;
    width: 595px;
    max-width: 100%;
    @include break-max($breakpoint-sm) {
      width: 400px;
      margin-left: 45px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-left: 0;
    }
  }
  
  .rie__banner-title {
    font-weight: 900;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 25px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 15px;
      font-size: 21px;
      line-height: 26px;
    }
  }

  .rie__banner-text {
    font-size: 21px;
    line-height: 30px;
    color: $color-white;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rie__banner-img {
    position: absolute;
    right: 5%;
    top: -50px;
    @include break-max($breakpoint-sm) {
      width: 50%;
    }
  }

  .rie__download, .sor__form .link {
    color: $green-light;
    transition: all 0.3s;
    &:hover {
      color: $green-light-hover;
    }
    &:active {
      color: $green-light-active;
    }
  }

  .sor__promo-img-lg {
    @include break-max($breakpoint-md) {
      max-width: 565px;
    }
    @include break-max($breakpoint-sm) {
      max-width: 330px;
    }
    @include break-max($breakpoint-xs) {
      position: relative;
      top: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .sor__promo-btns {
    @include break-max($breakpoint-sm) {
      flex-direction: row;
      justify-content: center;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .sor__promo-btn:first-of-type {
    margin-right: 15px;
    @include break-max($breakpoint-xs) {
      height: 50px;
    }
  }

  .sor__promo {
    @include break-max($breakpoint-sm) {
      min-height: 650px;
    }
    @include break-max($breakpoint-xs) {
      .wrapper__center {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .wrapper__center {
    @include break-max($breakpoint-md) {
      margin: 0;
    }
  }

  .sor__footer {
    @include break-max($breakpoint-md) {
      padding-top: 30px;
    }
  }
  
}
