.about__text {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    font-size: 17px;
    line-height: 22px;
    text-align: justify;

    b {
      margin-left: 15px;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.about__features {
  width: 100%;
  margin-top: 50px;
  padding: 20px 50px;
  background: $color-1;
  color: $color-white;
  border-radius: 4px;
}

.about__features-list {
  display: flex;
  flex-wrap: wrap;
  list-style: disc;

  background: $color-1;
  color: $color-white;
}

.about__feature {
  flex-basis: 28%;
  flex-grow: 0;
  margin: 5px 30px 5px 0;

  @include break-max($breakpoint-xs) {
    flex-basis: 100%;
  }
}
