.ot__section {
  .admin__h1 {
    margin-right: 0;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.ot__add-obj {
  color: $color-white;
  @include break-max($breakpoint-xs) {
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
  }
}


.ot__btns {
  @include break-max($breakpoint-sm) {
    order: 1;
    width: 100%;
    margin-top: 20px;
  }
  

}

.ot__switcher {

  @include break-max($breakpoint-xs) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid rgba($color-brand-3, 0.2);
    min-height: 30px;
  }

  .switcher__btn {
    width: 200px;
    @include break-max($breakpoint-md) {
      width: 160px;
    }
    @include break-max($breakpoint-sm) {
      flex-grow: 1;
      padding: 15px;
    }
    @include break-max($breakpoint-xs) {
      &:nth-child(n) {
        padding: 10px 0;
        width: auto;
        height: auto;
        font-size: 12px;
        margin-right: 0;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 0;
        border: none;
        border-bottom: 3px solid transparent;
        flex-shrink: 0;
        background: none;
      }

      &.active {
        color: $color-brand-2;
        border-bottom: 3px solid $color-red;
      }
    }
  }
}



.ot__filter {
  width: 100%;
  .mini-filter {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .mini-filter .form {
    width: 100%;
  }
  .mini-filter__button {
    display: inline-block;
  }
  
  @include break-max($breakpoint-xs) {
    display: none;
  }
}
