.c-modal {
  textarea {
    resize:  vertical;
  }

  .modal__shadow {
    opacity: 0.6;
  }

  .c-modal__close {
    position: absolute;
    top: 25px;
    right: 25px;
    left: auto;
    transform: translate(0);
  }

  .c-modal__upload-text {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }

  .c-modal__icon-btn {
    align-items: center;
    transition: all 0.3s;
    &:hover {
      color: $color-red;
    }
    &:active {
      color: $color-brand-3;
    }

    &_with-hover {
      .c-modal__icon {
        &:hover {
          background: $color-brand-opaque-30;
        }
        &:active {
          background: $color-brand-opaque-20;
        }
      }
    }

    &_map {
      .c-modal__icon {
        background: $color-white;
        &:hover {
          background: $color-brand-opaque-30;
        }
        &:active {
          background: $color-brand-opaque-20;
        }
      }
    }
  }

  .c-modal__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: $color-brand-opaque-10;
    transition: all 0.3s;
    &_delete {
      margin-right: 12px;
    }
    &_sm {
      width: 28px;
      height: 28px;
    }
    &_square {
      width: 50px;
      height: 50px;
      border-radius: 0;
    }
    &_eye {
      fill: $color-brand-opaque-40;
    }
    .icon {
      width: 15px;
      height: 20px;
    }
    .icon_cross {
      width: 11px;
      height: 8px;
    }
    .icon_plus {
      width: 8px;
      height: 8px;
    }
  }

  .modal__inner {
    padding: 55px 95px;
    @include break-max($breakpoint-xs) {
      padding-left: 65px;
      padding-right: 65px;
    }
  }

  &_small .modal__inner {
    max-width: 570px;
  }

  &_lid .c-modal__box {
    max-width: 470px;
    margin: 0 auto;
  }

  .c-modal__header {
    margin-bottom: 45px;
    font-weight: bold;
    &_center {
      text-align: center;
      margin-bottom: 35px;
    }
  }

  .upload {
    display: flex;
    justify-content: space-between;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .upload {

    input[type=range] {
      overflow: hidden;
      -webkit-appearance: none;
      width: 135px;
      margin-left: auto;
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: $color-brand-opaque-20;
    }

    input[type=range]::-webkit-slider-thumb {
      border: 4px solid #fff;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $color-red;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: $color-red;
    }


    input[type=range]::-moz-range-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: $color-brand-opaque-20;
    }

    input[type="range"]::-moz-range-progress {
      background-color: $color-red;
      height: 1px;
      cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
      border: 4px solid #fff;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $color-red;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }

    input[type=range]::-ms-track {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: $color-red;
    }


    input[type=range]::-ms-fill-lower {
      background-color: $color-red;
      height: 1px;
      cursor: pointer;
    }

    input[type=range]::-ms-fill-upper {
      width: 100%;
      height: 1px;
      cursor: pointer;
      background: $color-brand-opaque-20;
    }
    //
    input[type=range]::-ms-thumb {
      border: 4px solid #fff;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $color-red;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -10px;
    }
    //
    // input[type=range]:focus::-ms-fill-lower {
    //   background: #3071a9;
    // }
    //
    // input[type=range]:focus::-ms-fill-upper {
    //   background: #367ebd;
    // }
    .dropzone {
      margin-top: 35px;
    }
    .dropzone svg {
      width: 16px;
    }

  }

  .upload__result {
    margin-top: 8px;

    @include break-max($breakpoint-xs) {
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 10px 0;
    }
  }

  .upload__photo {
    width: 220px;
    height: 220px;
    border-radius: 5px;

    // background: url('../../assets/images/constructor/photo-upload.svg') no-repeat center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .upload__info {
    display: flex;
    flex-direction: column;

    width: 250px;
  }

  .upload__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 250px;
    height: 50px;
    margin-top: 45px;

    border: 1px solid $color-brand-opaque-30;
    box-sizing: border-box;
    border-radius: 4px;

    font-weight: bold;


    &:hover {
      box-shadow: $shadow;
    }
    &:active {
      box-shadow: inset $shadow;
    }
  }

  .upload__descr {
    margin-top: 15px;
    font-size: 13px;
    color: $color-brand-3;
  }

  .checkbox__label {
    display: flex;
    align-items: center;
  }

  .c-modal__socials {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      width: 180px;
      margin-right: 80px;
      margin-bottom: 20px;
      &:nth-child(even) {
        margin-right: 0;
      }
    }

    .checkbox {
      width: 100%;
      justify-content: space-between;
    }

    .c-modal__social-label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $color-brand-opaque-50;
      cursor: pointer;
      margin-left: 15px;

      &_ig {
        background-image: $color-ig;
      }
      &_vk {
        background: $color-vk;
      }
      &_fb {
        background: $color-fb;
      }
      &_tg {
        background: $color-tg;
      }
      &_wa {
        background: $color-wa;
      }
      &_viber {
        background: $color-viber;
      }

      .icon {
        fill: $color-white;
        width: 23px;
        height: 23px;

      }
    }

    @include break-max($breakpoint-xs) {
      width: 180px;
      li {
        margin-right: 0;
      }
      .checkbox {
        width: 100%;
        justify-content: flex-start;
      }
      .checkbox__label {
        flex-grow: 1;
      }
      .c-modal__social-label {
        margin-left: auto;
      }
    }
  }

  .c-modal__label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: $color-brand-3;
  }

  .c-modal__input-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .c-modal__input,
  .c-modal__textarea {
    font-family: inherit;
    font-size: 14px;
    padding: 16.5px 15px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
  }

  .c-modal__submit {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }


  &_preview {
    .modal__inner {
      padding: 0;
    }

    .c-modal__icon-btn {
      z-index: 2;
    }
  }

  .c-preview {
    height: 367px;
    background: #DD453B;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    padding: 55px 25px 50px 35px;

    @include break-max($breakpoint-xs) {
      padding: 25px 15px;
      height: 250px;
    }
  }

  .c-preview__content {
    color: $color-white;
  }

  .c-preview__info {
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 100%;
  }

  .c-preview__sign {
    font-weight: 300;
    line-height: 30px;
    margin-top: 10px;

    @include break-max($breakpoint-xs) {
      font-size: 14px;
    }
  }

  .c-preview__image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 10px;
    z-index: 2;
  }

  .c-preview__dots {
    position: absolute;
    top: 30px;
    right: -30px;
    z-index: 0;
    height: 167px;
    width: 167px;

    @include break-max($breakpoint-xs) {
      top: 0;
      right: -38px;
      width: 30%;
    }
  }

  .c-preview__triangle {
    position: absolute;
    top: -13px;
    right: -110px;
    z-index: 1;
  }

  .c-preview__note {
    background: $color-white;
    padding: 16px 15px 20px 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .c-preview__label {
    margin-bottom: 15px;
  }

  .c-preview__text {
    font-size: 30px;
    line-height: 35px;
    padding-right: 50px;

    @include break-max($breakpoint-xs) {
      font-size: 21px;
      line-height: 24px;
      padding-right: 130px;
    }
  }

    &_lid {
      .c-modal__header_center {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }


}

.c-modal__box {

    .big-text {
      margin-bottom: 25px;
      color: $color-brand-3;
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      @include break-max($breakpoint-xs) {
        margin-bottom: 15px;
      }
    }
}

.c-modal__input-block.modal-form__row {
  @include break-max($breakpoint-xs) {
    margin-top: 18px;
  }
}

.c-banner {
  height: 175px;

  @include break-max($breakpoint-xs) {
    height: 215px;
  }
}

.c-banner__content {
  background: #301120;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 99px;
}

.c-banner__text {
  color: white;
  font-size: 9px;
  line-height: 30px;
  margin: 0px 0 0 77px;
  display: flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;

  @include break-max($breakpoint-xs) {
    margin-left: 10px;
    line-height: 15px;
  }
}

.c-banner__img {
  position: absolute;
  top: -60px;
  path {
    fill: #DD453B;
  }

  svg {
    width: 190px;
  }
}

.c-banner__circle {
  align-items: center;
  justify-content: center;
  border-radius: 50em;
  display: flex;
  height: 30px;
  width: 30px;

  @include break-max($breakpoint-xs) {
    height: 20px;
    width: 20px;
  }
}

.c-banner__circle.ig {
  background: linear-gradient(42.3deg, #FFD569 -11.65%, #F35633 29.09%, #C62992 65.58%, #585AD1 105.11%);

  svg.icon {
    height: 14px;
    width: 14px;

    @include break-max($breakpoint-xs) {
      height: 10px;
      width: 10px;
    }
  }
}

.c-banner__circle.vk {
  background: #4D7198;

  svg.icon {
    height: 8px;
    width: 14px;

    @include break-max($breakpoint-xs) {
      width: 10px;
    }
  }
}

.c-banner__circle.wa {
  background: #25D366;

  svg.icon {
    height: 16px;
    width: 16px;

    @include break-max($breakpoint-xs) {
      width: 10px;
    }
  }
}

.constructor__socials_banner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
