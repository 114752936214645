@import "../../styles/variables";
@import "../../styles/mixins";

.Toastify__toast-container:has(.autopan-viewing-customToast) {
  width: 380px;
}
.preview__header_bg + .section .sor.sor_auto,
.preview__header_bg + .section .sor.sor_cashback {
  margin-top: 0;
}

.sor.sor_auto {

  .custom-select.is-showing .custom-select__selected {
    background-color: $color-white;
    color: $color-brand-2;
  }

  .input__control, .select {
    color: $color-brand-2;
  }

  .sor__review-more {
    display: inline-flex;
    align-items: center;
    margin-top: 15px;
    color: $color-brand-3;

    cursor: pointer;
    font-size: 14px;
    line-height: 24px;

    svg {
      margin-left: 10px;
      width: 10px;
      stroke: $color-brand-3;
    }
  }

  .sor__form-row {
    display: flex;

    @include break-max($breakpoint-sm) {
      width: 100%;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

    & + & {
      margin-top: 20px;
    }
  }

  .sor__prog-ul li::before {
    @include break-max($breakpoint-xs) {
      margin-right: 15px;
    }
  }

  .sor__sup {
    @include break-max($breakpoint-xs) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      text-align: center;
      padding-top: 15px;
    }
  }

  .sor__box {
    @include break-max($breakpoint-xs) {
      display: flex;
      flex-direction: column;
      padding-top: 60px;
    }
  }

  .sor__submit {
    @include break-max($breakpoint-sm) {
      position: static;
      transform: none;
      margin-left: 15px;
    }

    @include break-max($breakpoint-xs) {
      margin-top: 15px;
      margin-left: 0;
    }
  }

  .sor__footer {
    @include break-max($breakpoint-sm) {
      padding-bottom: 40px;
    }
  }

  .sor__form-box {
    @include break-max($breakpoint-sm) {
      padding-top: 0;
    }
    @include break-max($breakpoint-xs) {
      align-items: center;
    }
  }

  .sor__form-footer {
    margin-top: 15px;
  }

  .sor__prog-t {
    padding-bottom: 0;
    border: none;
  }

  .sor__footer-title {
    width: 100%;
    padding-bottom: 0;
  }

  .sor__form-item {
    width: 470px;
    @include break-max($breakpoint-md) {
      width: 385px;
    }

    @include break-max($breakpoint-sm) {
      flex-grow: 1;
      width: 232px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }

    input {
      width: 470px;
      @include break-max($breakpoint-md) {
        width: 385px;
      }

      @include break-max($breakpoint-xs) {
        width: 100%;
      }
    }
  }

  .sor__promo {
    min-height: 675px;
  }

  .sor__content {
    width: 675px;
    @include break-max($breakpoint-md) {
      width: 500px;
    }

    @include break-max($breakpoint-sm) {
      width: 330px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__h1 {
    width: 100%;
    padding-top: 50px;
    @include break-max($breakpoint-xs) {
      padding-top: 0;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }

  .sor__h2 {
    font-weight: 900;

    @include break-max($breakpoint-sm) {
      padding-bottom: 30px;
    }
  }

  .sor__promo-pic {
    width: fit-content;
    top: 15px;
    right: -200px;

    @include break-max($breakpoint-md) {
      width: 550px;
      right: -100px;
    }

    @include break-max($breakpoint-sm) {
      width: 410px;
      right: 0;
      top: 100px;
    }

    @include break-max($breakpoint-xs) {
      width: 320px;
      position: static;
      transform: none;
      order: -1;
    }
  }

  .sor__promo-btn {
    width: 260px;

    &.button_view_outline-with-icon-invert {
      width: 160px;
      @include break-max($breakpoint-xs) {
        width: 100%;
        height: 44px;
      }
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      height: 44px;
    }
  }

}

.sor__ifnow-content {
  display: flex;

  @include break-max($breakpoint-sm) {
    justify-content: space-between;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.sor__ifnow-item {
  &:first-of-type {
    width: 360px;

    @include break-max($breakpoint-sm) {
      width: 50%;
      padding-right: 25px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    }
  }

  &:last-of-type {
    margin-left: 265px;
    width: 385px;

    @include break-max($breakpoint-sm) {
      width: 50%;
      padding-left: 25px;
      margin-left: 0;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

}

.sor__ifnow-list {
  font-weight: bold;

  li::before {
    width: 5.5px;
    height: 5.5px;
    margin-top: 6px;
  }
}

.sor__ifnow-label {
  width: 106px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  background-color: $color-red;
  border-radius: 100px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.sor__dark-label {
  background-color: $color-brand-2;
  color: $color-white;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;

  @include break-max($breakpoint-md) {
    height: 150px;
  }

  @include break-max($breakpoint-sm) {
    height: 120px;
    text-align: center;
  }

  @include break-max($breakpoint-xs) {
    padding: 0 15px;
  }
}

.sor__grid {

  .swiper-wrapper {
    align-items: center;
  }

  &:not(.swiper-pan) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 386px);
    grid-template-rows: repeat(2, 200px);
    row-gap: 20px;

    @include break-max($breakpoint-md) {
      grid-template-columns: repeat(3, 318px);
    }

    @include break-max($breakpoint-sm) {
      grid-template-columns: repeat(3, 232px);
    }

    @include break-max($breakpoint-xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }


  .sor__about-item {
    width: 100%;
    padding-right: 0;
    background-color: $color-brand-opaque-8;
    border-radius: 20px;
    padding: 28px 40px;

    @include break-max($breakpoint-md) {
      padding: 20px;
    }

    @include break-max($breakpoint-xs) {
      min-height: 150px;
    }

    &:last-of-type {
      grid-column: 2 / -1;
    }
  }

  .sor__about-header {
    margin-bottom: 15px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 10px;
    }
  }

  .sor__about-counter {
    color: $color-brand-3;
  }

  .sor__about-text {
    margin-top: 12px;
    font-size: 18px;
    line-height: 24px;
    @include break-max($breakpoint-md) {
      font-size: 17px;
      line-height: 22px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

}

.sor__grid_steps {

  &:not(.swiper-pan) {
    grid-template-columns: repeat(4, 283px);
    grid-template-rows: 260px 292px;
    row-gap: 30px;

    @include break-max($breakpoint-md) {
      grid-template-columns: repeat(4, 233px);
      row-gap: 20px;
    }

    @include break-max($breakpoint-sm) {
      grid-template-columns: repeat(3, 233px);
      gap: 15px;
      grid-template-rows: 240px;
    }
  }

  .sor__about-item {
    padding: 40px;
    position: relative;
    @include break-max($breakpoint-md) {
      padding: 20px 35px 20px 25px;
    }
    @include break-max($breakpoint-xs) {
      min-height: 0;
    }

    &::after {
      content: '';
      display: flex;
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%);
      height: 45px;
      width: 45px;
      background: url('../../assets/images/auto/red.svg') no-repeat;
      z-index: 1;
    }

    &:last-of-type {
      grid-column: unset;
      &::after {
        display: none;
      }
    }

    &:nth-child(3n+3) {
      @include break-max($breakpoint-sm) {
        &::after {
          display: none;
        }
      }

    }

    &:nth-child(n) {
      @include break-max($breakpoint-xs) {
        &::after {
          display: none;
        }
      }

    }
  }

  .sor__about-counter {
    color: $color-red;
  }


}

.sor__about-inner {
  display: flex;
  justify-content: space-between;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  li {
    width: fit-content;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.sor__promo-btn.sor__promo-btn_center {
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.sor__h2_760 {
  max-width: 760px;
}

.sor__h2_845 {
  max-width: 845px;
}

.sor.sor_cashback:not(.booking) .sor__section.sor__steps {
  padding-top: 0;
}

.sor__section_dark {
  background-color: $color-brand-2;
  color: $color-white;
}

.sor__author-content {
  padding-top: 30px;
  width: 720px;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    width: fit-content;
  }

  @include break-max($breakpoint-xs) {
    .sor__author-title, .caps {
      display: block;
      text-align: center;
    }
  }
}

.sor.sor_cashback .sor__author-title {
  padding-bottom: 15px;
}

.sor__author-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.sor__author-list {
  width: 340px;
  max-width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @include break-max($breakpoint-md) {
    width: 280px;
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 15px;
    line-height: 20px;
  }

  li::before {
    width: 5.5px;
    height: 5.5px;
    margin-top: 9px;
    margin-right: 15px;
  }
}

.sor__author-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.sor__author-sign {
  width: 285px;
  margin-top: -10px;
}

.sor__author-box {
  display: flex;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    align-items: center;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column-reverse;
  }
}

.sor__h2 span {
  color: $color-red;
}

.sor__reviews {

}

.sor__review {
  width: 386px;
  height: 405px;
  border-radius: 20px;
  padding: 40px;
  background-color: $color-brand-opaque-8;

  @include break-max($breakpoint-md) {
    width: 318px;
    padding: 30px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    height: fit-content;
  }


  .sor__about-text {
    font-size: 18px;
    line-height: 24px;
  }
}

.sor.sor_auto .sor__form_autopan {
  background-color: $color-brand-2;
  border-radius: 20px;
  padding: 58px 100px;

  @include break-max($breakpoint-sm) {
    padding: 58px;
  }

  @include break-max($breakpoint-xs) {
    padding: 35px 26px;
  }

  .sor__form-box {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;

  }


  .sor__form-item {
    margin-right: 22px;
    @include break-max($breakpoint-sm) {
      flex-grow: 1;
    }
    &:last-of-type {
      margin-right: 0;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__form-item_half {
    width: 220px;

    @include break-max($breakpoint-md) {
      width: 180px;
    }

    @include break-max($breakpoint-sm) {
      width: 170px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }

    input {
      width: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .sor__form-item_location {
    @include break-max($breakpoint-sm) {
      width: 232px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__form-row:nth-child(2) .sor__form-item {
    @include break-max($breakpoint-sm) {
      width: 40%;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .sor__submit {
    display: flex;
    margin: 40px auto 0;

  }
}

.sor__section_gray {
  background-color: $color-brand-opaque-8;
}

.sor__prog-list_autopan {

  padding-bottom: 70px;

  @include break-max($breakpoint-xs) {
    padding-bottom: 35px;
  }

  .sor__prog-header {
    padding-top: 34px;
    padding-bottom: 34px;

    @include break-max($breakpoint-xs) {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .h4 {
      padding-right: 60px;
    }

  }

  .sor__prog-text {
    font-size: 18px;
    line-height: 24px;
    padding-top: 30px;
    padding-bottom: 50px;
    border-top: 1px solid $color-brand-opaque-20;

    p:not(:last-child) {
      margin-bottom: 15px;
    }

    li:not(:last-of-type) {
      margin-bottom: 5px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 20px;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }

  .sor__prog-item {
    padding-left: 53px;
    padding-right: 40px;
    padding-top: 0;
    border-radius: 20px;
    border-bottom: none;

    &:nth-child(odd) {
      background-color: $color-brand-opaque-8;
    }

    @include break-max($breakpoint-xs) {
      padding: 15px 25px;
    }
  }

  .sor__accordion-btn {
    right: 0;
    background-color: $color-brand-2;
  }


}

// calendar
.sor.sor_auto {
  .react-datepicker__header {
    background-color: $color-white;
  }

  .react-datepicker__triangle::before {
    display: none;
  }

  .datepicker-autopan {
    cursor: pointer;
  }

  .react-datepicker__current-month {
    color: $color-red;
    text-transform: uppercase;
  }

  .react-datepicker__day-name {
    color: rgba($color-brand-2, 0.7);
    text-transform: uppercase;
    width: 35px;
    height: 33px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .react-datepicker {
    display: flex;
    border-radius: 4px;
    border: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-md) {
      flex-direction: column;
    }
  }

  .react-datepicker__month-container {
    padding: 15px 30px;
    @include break-max($breakpoint-md) {
      padding: 15px;
    }
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day {
    width: 35px;
    height: 33px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    &:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month) {
      color: $green;
      background-color: rgba($green, 0.1);
    }

    &:hover:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month) {
      background-color: rgba($green, 0.2);
      color: $green;
    }

    &--selected,
    &--keyboard-selected,
    &--selected:hover,
    &--keyboard-selected:hover {
      color: $color-white;
      background-color: $green;
    }

    &--disabled,
    &--disabled:hover {
      color: $color-brand-opaque-40;
      background-color: $color-white;
    }


  }

  .react-datepicker__day--outside-month {
    opacity: 0;
    visibility: hidden;
  }

  .react-datepicker__header--time--only {
    display: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation--next {
    top: 25px;
    border-left-color: $color-red;
  }

  .react-datepicker__navigation--previous {
    top: 25px;
    border-right-color: $color-red;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__navigation {
    display: none;
  }

}
