$c-green: #2CBA2E;
$c-blue: #62B8E7;
$c-yellow: #FFD336;
$c-red: #DD453B;
$c-gray: #A9A9B8;

$c-asphalt: #5E617F;
$c-purple: #9377B7;
$c-burgundy: #781B1B;
$c-nightsky: #4545C2;
$c-turquoise: #5EB7A7;



$c-black-1: #183024;
$c-black-2: #173343;
$c-black-3: #2C2E3E;
$c-black-4: #301120;
$c-black-5: #3F3F57;

$c-black-6: #1F223E; // asphalt
$c-black-7: #584274; // purple
$c-black-8: #280101; //burgundy
$c-black-9: #14143A; // nightsky
$c-black-10: #102E28; // turquoise

$c-darkmode: #151622;

$c-green_dark: #24513A;
$c-blue_dark: #54A0CA;
$c-yellow_dark: #EEC021;
$c-red_dark: #B62E25;
$c-gray_dark: #9090A6;

$c-asphalt_dark: darken($c-asphalt, 20%);
$c-purple_dark: darken($c-purple, 20%);
$c-burgundy_dark: darken($c-burgundy, 20%);
// $c-burgundy_dark: #212330;


$c-nightsky_dark: darken($c-nightsky, 20%);
$c-turquoise_dark: darken($c-turquoise, 20%);

$c-gray_m: #F6F6F8;