

.fav-attach {
  opacity: 0;
  visibility: hidden;
  transition: all $transition-default;

  .popover__block {
    right: calc(100% + 60px);
    
    &_align {
      &_right {
        right: inherit;
        left: calc(100% + 20px);
      }
    }
  }

  & &__btns {
    display: flex;
    align-items: center;
  }

  & &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 14px;
    color: $color-brand-3;
    background-color: $color-brand-opaque-10;
    cursor: pointer;

    &:hover {
      background-color: $color-brand-opaque-30;
    }

    & .icon_user {
      fill: $color-brand-3;
      stroke: none;
      font-size: 12px;
      fill-opacity: 0.5;
    }

    & .icon_plus {
      font-size: 8px;
      fill: none;
      stroke: $color-brand-3;
      margin-right: 3px;
      fill-opacity: 0.5;
    }
  }
}