:root {
    --radio-width: 18px;
    --radio-height: 18px;
    --radio-animation-duration: 0.2s;
    --color-primary: #696D82;
    --color-input: #{$color-brand-2};
  }

  .radio {
    $c: &;
    display: flex;
    align-items: flex-start;

    &_view_invert {
      #{$c}__box {
        box-shadow: inset 0 0 0 1px $color-brand-opaque-50;
        background-color: transparent;
      }

      #{$c}__label {
        color: $color-brand-3;
      }
    }

    &_view_red {
      #{$c}__control:checked + #{$c}__input {
        background-color: $color-red;
      }

      #{$c}__control:checked + #{$c}__marker {
        background-color: $color-red;
      }

      #{$c}__marker:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
      }
    }

    &_disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    &.is-excluded {
      #{$c}__box {
        background-color: $color-red;
        box-shadow: inset 0 0 0 1px $color-red;;
      }

      #{$c}__marker:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgNC43NjVsLjg1NS0uOCA0LjE5IDIuMjcyTDEyLjI2NyAwIDEzIC42NjUgNS4zNiAxMCAwIDQuNzY1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
      }
    }

    &_type {

      &_btn {

        & #{$c}__box {
          box-shadow: none;
          width: auto;
          height: auto;
        }

        & #{$c}__control {
          margin: 0;
          padding: 0;
          opacity: 0;
          height: 0;
          width: 0;
          pointer-events: none;

          &:checked + .radio__label:after {
            content: '';
            height: calc(100% + 12px);
            position: absolute;
            top: -6px;
            left: -6px;
            width: calc(100% + 12px);
            background-color: $color-brand-opaque-10;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .radio__box {
    position: relative;
    outline: none;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: var(--radio-width);
    height: var(--radio-height);
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-30;
    transition: box-shadow $transition-default;
    background-color: #fff;
  }

  .radio__control, .radio__input {
    position: absolute;
    left: 0;
    top: 0;
  }

  .radio__tooltip {
    position: absolute;
    top: 7px;
    padding: 20px 24px;
    border-radius: 4px;
    box-shadow: $shadow;
    display: none;
    background-color: $color-white;
    white-space: normal;
    width: 280px;
    pointer-events: none;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: calc(100% - 14px);
      border-bottom: 14px solid $color-white;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
    }

    &_align {

      &_right {
        right: inherit;
        left: calc(100% + 18px);

        &:before {
          right: calc(100% - 14px);
          transform: rotate(-180deg);
        }
      }

      &_left {
        left: inherit;
        right: calc( 100% + 40px );

        &:before {
          left: calc(100% - 14px);
          transform: rotate(180deg);
        }
      }
    }
  }

  .radio__control {
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    &:checked + .radio__input + .radio__marker {
      opacity: 1;

      &:after {
        transform: scale(1);
      }
    }
  }

  .radio__input {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: transparent;
    user-select: none;
    // background-color: var(--color-input);
    border-radius: inherit;
    z-index: 1;
    cursor: pointer;
    transition: background var(--radio-animation-duration);
  }

  .radio__marker {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
    pointer-events: none;
    border-radius: inherit;
    opacity: 0;
    transition: opacity var(--radio-animation-duration);

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      position: absolute;
      transform: scale(0);
      background-color: $color-brand-3;
      transition: transform .3s cubic-bezier(0,0,0.12,1);
    }
  }

  .radio__label {
    color: $color-brand-2;
    font-size: 14px;
    line-height: 17px;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      position: relative;
      z-index: 20;

      .radio__tooltip {
        display: block;
        z-index: 30;
      }
    }
  }

  .radio__label + .radio__box {
    margin-left: 6px;
  }

  .radio__box + .radio__label {
    padding-left: 6px;
  }
