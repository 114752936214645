@import "../../styles/variables";
@import "../../styles/mixins";

.chart__pie {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 50%;
}

.chart__pie-label {
  font-size: 21px;
  line-height: 30px;
  font-weight: 300;
}

.chart__tooltip {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  padding: 22px 15px;
  background: $color-white;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.18);

  .chart__tooltip-date {
    white-space: nowrap;
  }

  .chart__tooltip-date,
  .chart__tooltip-detail {
    font-size: 13px;
    line-height: 16px;
  }

  &_lg {
    .chart__tooltip-date,
    .chart__tooltip-detail {
      font-size: 14px;
      line-height: 18px;
    }

    .chart__info-value {
      font-size: 16px;
      line-height: 20px;
    }
  }


  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: -10px;
  //   right: 0;

  //   width: 0;
  //   height: 0;
  //   border-top: 20px solid $color-white;
  //   border-left: 20px solid transparent;
  // }

  // &_left::after {
  //   right: auto;
  //   left: 0;
  //   border-top: 20px solid $color-white;
  //   border-right: 20px solid transparent;
  //   border-left: none;
  // }

}

.chart__dates {
  display: flex;
  margin-top: 5px;
}

.chart__date {
  display: inline-flex;
  flex-direction: column;
  padding: 5px 10px;
  margin-right: 35px;

  border-radius: 5px;
  background: none;

  font-family: inherit;
  font-size: 14px;
  line-height: 18px;

  cursor: pointer;

  &_active {
    background: $color-brand-opaque-10;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.chart__date-year {
  font-size: 13px;
  line-height: 16px;
  color: $color-brand-3;
}

.chart__block {
  display: flex;
  flex-direction: column;
}

.chart__info-container {
  display: flex;
  flex-wrap: wrap;

  .chart__info-block {
    flex-basis: 40%;
  }

  @include break-max($breakpoint-xsm) {
    flex-direction: column;
    .chart__info-block {
      flex-basis: 100%;
    }
  }
}

.chart__title {
  padding-bottom: 60px;

  @include break-max($breakpoint-xs) {
    margin: 0 10px;
    padding-bottom: 30px;
  }
}

.chart__box {
  position: relative;
  display: flex;
  max-width: 665px;
  flex-grow: 1;
  margin-right: 15px;

  &_width_full {
    min-width: 100%;
    margin-right: 0;
    margin-top: 15px;
  }

  @include break-max($breakpoint-md) {
    max-width: 100%;
    margin-right: 0;
  }
}

.chart__y {
  display: flex;
  flex-direction: column;
  margin-right: 25px;

  span {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.chart__chart {
  position: relative;
  padding-top: 10px;
}

.chart__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  @include break-max($breakpoint-xs) {
    margin-top: 30px;
  }

  &_blur {
    position: relative;
    padding-bottom: 20px;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      background: rgba($color-white, 0.8);
      filter: blur(4px);
    }
  }

  &_bordered {
    padding: 22px;
    border: 1px solid $color-brand-opaque-10;
    border-radius: 4px;
  }
}

.chart__info-block {
  border-top: 1px solid $color-brand-opaque-30;
  padding-top: 30px;
  &:first-of-type {
    padding-top: 0;
    border: none;
  }

  &:nth-child(odd) {
    margin-right: 15%;
    @include break-max($breakpoint-xsm) {
      margin-right: 0;
    }
  }
  &_border_none {
    border: none;
  }
  &_padding_sm {
    padding-top: 20px;
  }
}

.chart__info-row {
  display: flex;

  &_align-center {
    align-items: center;
  }
  &_paddings {
    padding: 0 15px;
  }

  &_wrap_sm {
    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
      row-gap: 15px;

      .chart__info-col {
        flex-basis: 50%;
      }
    }
  }
}


.chart__info-col {
  flex-grow: 1;
  .flat__info-item {
    margin-bottom: 5px;
  }
  .chart__info-item:first-child {
    margin-right: 10px;
    width: auto;
  }
}

.chart__info-item.flat__info-item {
  @include break-max($breakpoint-md) {
    margin-right: 0;
  }
}

.chart__info-item:first-child {
  width: 120px;
  margin-right: 60px;
  @include break-max($breakpoint-md) {
    width: 90px;
    margin-right: 6px;
  }
}

.chart__info-item:last-child {
  flex-grow: 1;
}

.chart__info-value {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  white-space: nowrap;
  &_sm {
    font-size: 14px;
    line-height: 21px;
    height: 27px;
  }
}

.chart__percent {
  display: inline-flex;
  padding: 3.5px 11px;
  margin-left: 10px;
  border-radius: 5px;
  @include break-max($breakpoint-md) {
    margin-left: 3px;
  }
  &_plus {
    color: $green;
    background: rgba($green, 0.1);
  }
  &_minus {
    color: $color-red;
    background: rgba($color-red, 0.1);
  }
  &_lg {
    padding: 5.5px 11px;
    font-weight: bold;
  }
  &_pos_rel {
    position: relative;
    top: 5px;
  }
}

.chart__diff {
  position: relative;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  svg {
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
  }

  &_plus svg {
    transform: rotate(180deg);
    fill: $green;
  }

  &_minus svg {
    fill: $color-red;
  }
}

.chart__placeholder {
  padding: 30px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 5px;

  text-align: center;

  p {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  svg {
    width: 100%;
  }
}

.pan-popup-content .chart__tooltip {
  @include break-max($breakpoint-xs) {
    box-shadow: none;
    position: static;

    &::after {
      display: none;
    }
  }
}

.chart__list {
  display: flex;
  padding: 15px 24px;
  margin-top: 15px;
  border: 1px solid $color-brand-opaque-10;
  border-radius: 4px;
  flex-wrap: wrap;
  row-gap: 8px;
  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
    row-gap: 5px;
  }
  div {
    margin-left: 0;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.chart__subtitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.chart__filter {
  background: $color-brand-opaque-10;
  border-radius: 4px;
  padding: 25px 22px;

  @include break-max($breakpoint-sm) {
    padding-left: 0;
    padding-right: 0;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 -25px;
  }

  .form__item {
    margin-bottom: 0;
    @include break-max($breakpoint-xs) {
      margin-bottom: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
      @include break-max($breakpoint-xs) {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }

  .form__reset {
    background: transparent;
    display: inline-flex;
    align-items: baseline;
    width: auto;
    margin-left: 20px;
    @include break-max($breakpoint-xs) {
      margin-left: 5px;
    }
  }
}

.chart__header, .complex__section-about .chart__header {
  margin-bottom: 20px;
}

.chart__subtitle {
  margin-bottom: 15px;
}

.chart__current-value {
  font-weight: bold;
  padding-right: 5px;
}

.PREP-VALUE {
  margin-left: auto;
}
