@import "../../../styles/variables";
@import "../../../styles/mixins";
.apartments-action-row {
  background: rgba(255, 204, 0, 0.2);
}

.action-row-tooltip {
  position: absolute;
  padding: 10px;
  width: 300px;
  background: white;
  z-index: 4;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid;
    border-left: 10px solid transparent;
    border-top-color: white;
  }
}
.table2 {
  padding: 16.5px 0;
  @keyframes fadein {
      from {
        opacity: 0;
      }
  
      to {
        opacity: 1;
      }
  }
  .hidden {
    display: none;
  }
  
  @include break-max($breakpoint-xs) {
    padding: 0;
  }

  &.assignations-flats__tables {
    padding-top: 0;
    @include break-max($breakpoint-xs) {
      padding-bottom: 0 !important;
    }
  }

  .status-assignment {
    max-width: 100%;
    @include break-max($breakpoint-md) {
      font-size: 12px;
    }
  }

    
  .flats__row-concat {
    @include break-max($breakpoint-sm) {
      color: $color-brand-3;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-left: 24px;
    }

  }

  .flats__row {
    background: $color-brand-opaque-8;
  }

  .flats__row-rooms {
    font-size: 16px;
    line-height: 20px;
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 18px;
      flex-grow: 1;
    }
  }

  .flats__row {
    padding: 3px 8px;

    font-size: 14px;
    line-height: 16px;

    @include break-max($breakpoint-sm) {
      padding: 13px 15px;
    }
  }

  .flats__row-action .button_view_round {
    background: none;
    width: 20px;
  }
  
  .flats__row-action .button_view_round .icon {
    fill: $color-brand-2;
    stroke: $color-brand-2;
    @include break-max($breakpoint-xs) {
      width: 14px;
    }
  }

  .flats__row.is-active {
    margin-bottom: 5px;
  }

  .flats__row-table table {
    padding: 0;
  }

  @include break-min($break-md) {
    .flats__row-col {
      white-space: nowrap;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 20px;
  
      &.flats__row-rooms {
        flex-basis: 100px;
      }
  
      &.flats__row-count {
        flex-basis: 110px;
      }
  
      &.flats__row-square {
        flex-basis: 190px;
      }
  
      &.flats__row-price {
        flex-basis: 310px;
      }
  
      &.flats__row-action {
        margin-right: 12px;
      }
  
    }
  }
 

  .ant-table-thead {
    height: 45px;
  }

  .ant-table-thead > tr > th, .ant-table tfoot > tr > th {
    padding: 0 5px;
    height: 45px;
    border-right: 1px solid rgba($color-white, 0.2);
    
    &:last-of-type {
      border-right: none;
    }

    @include break-max($breakpoint-md) {
      padding: 0 2px;
    }

    @include break-max($breakpoint-sm) {
      padding: 0 5px;
    }

    @include break-max($breakpoint-xs) {
      background: none;
      color: $color-brand-1;
      border-right: none;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      border-bottom: 1px solid $color-brand-opaque-20;
      &::before {
        display: none;
      }
    }
  }

  .ant-table-tbody > tr > td, .ant-table tfoot > tr > td {
    padding: 8px 10px;
    max-width: 130px;
  }

  .ant-table-column-sorters {
    padding: 0 5px;
  }

  .complex__appartments-row, .assignations-flats__row {
    margin-bottom: 5px;
  }
  .ant-table-column-title {
    margin-right: 5px;
  }

  .table-img {
    display: flex;
    justify-content: center;
    animation: fadein 1s;
    img {
      max-height: 35px;
      object-fit: contain;
    }

    @include break-max($breakpoint-md) {
      padding-left: 0;
    }

    .table-img__preview {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-20%, -50%);
      z-index: 2;
      display: none;
      width: 250px;
      height: 250px;
      padding: 10px;
      border: 1px solid $color-brand-opaque-8;
      border-radius: 4px;
      background: $color-white;
      img {
        width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &:hover .table-img__preview {
      display: flex;
    }
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }
}

.table2-with-tooltip {
  position: relative;
  &__content {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    white-space: nowrap;
  }

  &:hover .table2-with-tooltip__content {
    display: block;
    background: $color-white;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
    padding: 5px;
  }
}

.table2-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;

  .status-anchors {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;

      svg {
        fill: $color-brand-3;
        transition: all 0.3s;
      }

      &:hover svg {
        fill: $color-brand-1;
      }
    }

    a:last-of-type {
     margin-left: 20px; 
    }
  }
}

.complex__view-item .view__btn {
  display: flex;
  border-radius: 4px;
  border: 1px solid $color-brand-opaque-20;
  width: 182px;
  height: 50px;
  justify-content: center;
  align-items: center;
  .view__icon {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
  
  svg {
    margin-right: 12px;
  }
}

.complex__view-item:not(.active) .view__btn:hover {
  box-shadow: inset 0 0 0 1px rgba(143, 149, 172, 0.3);
}


.complex__view-item.active .view__btn {
  background: $color-brand-opaque-20;
}

.complex__appartments-navigation .omplex__view_height-auto {
  height: auto;
  margin-bottom: 0;

  .view__icon {
    width: 19px;
    height: 20px;
  }

  .view__cards .view__icon {
    fill: none;
    stroke: $color-brand-3;
  }

  .complex__view-item.active .view__icon {
    stroke: $color-brand-2;
  }

  .view__chess .view__btn {
    background-color: $color-red;
    color: $color-white;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: $color-red-hover;
    }

    &:active {
      background-color: $color-red-active;
    }

    .view__icon {
      width: 22px;
      height: 16px;
    }
  }
}

.complex__title-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;


  @include break-max($breakpoint-xs) {
    justify-content: space-between;
    padding: 5px 15px 15px;
    border-bottom: 1px solid $color-brand-opaque-20;
  }


  .h4 {
    margin-right: 45px;
    @include break-max($breakpoint-sm) {
      font-size: 16px;
      line-height: 20px;
    }
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  span {
    color: $color-brand-3;

    &:first-child {
      margin-right: 35px;
      @include break-max($breakpoint-xs) {
        margin-right: 0;
      }
    }

  }

  
  .complex__title-text {
    display: flex;
    @include break-max($breakpoint-md) {
      
    }
    @include break-max($breakpoint-sm) {
      
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
      text-align: right;
    }
  }


}


.complex-results-wrapper_table2 {
  @include break-max($breakpoint-xs) {
    background: none;
    padding-bottom: 0;

    .section__complex-results {
      margin-top: 0;
    }
  }
}

.assignations-flats_table2 {
  .assignations-flats__header .button:hover, .assignations-flats__header .button:focus, .assignations-flats__header .button:active {
    color: inherit;
  }
  .view__icon_map {
    stroke: none;
  }

  .assignations-flats__btns {
    display: flex;
    .complex__view-item {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  .view__map .view__btn {
    background-color: $color-red;
    color: $color-white;
    transition: background-color 0.15s ease-in-out;
    svg {
      fill: $color-white;
    }

    &:hover {
      background-color: $color-red-hover;
      color: $color-white;
    }

    &:active {
      background-color: $color-red-active;
      color: $color-white;
    }

    &:focus {
      background-color: $color-red-active;
      color: $color-white;
    }

    .view__icon {
      width: 22px;
      height: 22px;
    }

    @include break-max($breakpoint-xs) {
      height: 44px;
      font-weight: bold;
    }
  }
}

.filter__header-with-m {
  display: flex;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  .filter__header {
    align-items: center;
    @include break-max($breakpoint-xs) {
      margin-top: 0;
    }
  }

  .complex__manager {
    display: flex;
    position: relative;
    width: auto;
    margin: 0;
    flex-direction: row;
    align-items: center;
    margin-left: 50px;
    border-left: 1px solid $color-brand-opaque-20;
    padding-left: 30px;
    @include break-max($breakpoint-sm) {
      margin-top: 25px;
      margin-left: 0;
      border-left: none;
      padding-left: 0;
      margin-bottom: 10px;
    }
    @include break-max($breakpoint-xs) {
      padding: 8px 20px;
      border: 1px solid $color-brand-opaque-20;
      border-radius: 4px;
      margin: 0 10px;
    }
  }

  .complex__manager-avatar {
    flex-basis: 60px;
    margin-right: 15px;
  }

  .complex__manager-titles .info {
    @include break-max($breakpoint-xs) {
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      .icon {
        font-size: 16px;
      }
    }
  }

  .complex__manager-avatar_wrapper {
    width: 60px;
    height: 60px;
  }
  
  .complex__manager-title {
    font-size: 14px;
    line-height: 18px;
    margin-top: 0;
    @include break-max($breakpoint-xs) {
      font-size: 18px;
      line-height: 22px;
      padding-right: 25px;
    }
  }

  .complex__manager-contacts {
    margin-top: 0;
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 35px;
    @include break-max($breakpoint-sm) {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .complex__manager-contacts_item {
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }

    @include break-max($breakpoint-sm) {
      margin-right: 0;
      flex-basis: 50%;
    }
  }

  .complex__manager-contacts_item_email {
    @include break-max($breakpoint-sm) {
      order: 1;
    }
  }

  .complex__manager-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include break-max($breakpoint-sm) {
      min-width: 175px;
    }
  }

  .complex__manager-post {
    line-height: 18px;
  }

  .complex__manager-contacts_list {
    flex-direction: row;
    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
    }
  }

  .complex__manager-content {
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  .complex__manager-action {
    margin-top: 0;
  }
}

.table-view {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    fill: $color-brand-3;
  }
}

// Вынести отдельно когда-нибудь

.ot-table {
  width: 100%;
  border-bottom: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-xs) {
    border-bottom: none;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    @include break-max($breakpoint-xs) {
      padding-left: 10px;
    }
  }

  .flats__row-table .ant-table-cell {
    @include break-max($breakpoint-xs) {
      white-space: normal;
    }
  }

  .lk-presentations__name {
    @include break-max($breakpoint-xs) {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .lk-presentations__clients-attach_new {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
  .pan-table {
    width: 100%;

    .hidden {
      display: none;
    }

    .type-col {
      width: 100px;
    }
  }

  .ot__col-with-img {
    display: flex;
    align-items: center;

    img {
      width: 65px;
      height: 65px;
      object-fit: cover;
      margin-right: 25px;
      border-radius: 3px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .custom-select {
    min-width: 125px;
  }

  .custom-select__selected {
    padding: 10px 10px;
  }

  .custom-select__option {
    padding: 7px 10px;
    margin: 10px;
  }

&_bordered-header {
  .ant-table-thead {
    height: 45px;
  }
  .ant-table-thead > tr > th, .ant-table tfoot > tr > th {
    height: 45px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    &:first-of-type {
      border-right: none;
    }
  }
  th:not(:first-of-type):not(:last-of-type) {
    border-right: 1px solid rgba($color-white, 0.2);
  }

  .ant-table-tbody > tr > td {
    padding-left: 15px;
  }
  
  .ant-table-thead > tr > th:first-of-type,
  .ant-table-tbody > tr > td:first-of-type {

    padding-right: 5px;
  }
}

}

.ot-change-status {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px;
  position: sticky;
  bottom: 0;
  background: $color-white;

  .ot-change-status__item {
    display: flex;
    align-items: center;
    max-width: 100%;
    @include break-max($breakpoint-xs) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
  }

  b {
    margin-right: 20px;
    @include break-max($breakpoint-xs) {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 5px;
    }

  }

  button {
    width: 200px;
    font-size: 14px;
    justify-content: center;
    @include break-max($breakpoint-xs) {
      margin-top: 5px;
      width: 100%;
    }
  }

  .custom-select {
    width: 400px;
    max-width: 100%;
    margin-right: 20px;

    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-right: 0;
    }
    .custom-select__selected {
      border: 1px solid $color-brand-opaque-20;
      border-radius: 4px;
      height: 50px;
      background: $color-white;

      &:hover {
        background: $color-white;
      }
    }

    .custom-select__option {
      padding: 12px 16px;
    }

    .custom-select__selected,
    .custom-select__option {
      span {
        display: inline-flex;
        padding: 4px 8px;
        border-radius: 4px;
        // background: red;
      }

      &.green span,
      &.hex-2CBA2E span {
        background: $green-20;
        color: $green;
      }
    
      &.red span,
      &.hex-FF2D23 span {
        background: $color-red-opaque-20;
        color: $color-red;
      }
    
      &.blue span,
      &.hex-8790E5 span {
        background: $blue-20;
        color: $blue;
      }
    
      &.hex-67B2F8 span {
        background: rgba(#67B2F8, 0.2);
        color: #67B2F8;
      }

      &.orange span,
      &.hex-FFA100 span {
        background: $orange-20;
        color: $orange;
      }
    
      &.hex-8F95AC span {
        background: $color-brand-opaque-20;
        color: $color-brand-2;
      }
    }
  }
}

.ot-change-status__item + .ot-change-status__item {
  margin-right: auto;
  margin-left: 30px;
  @include break-max($breakpoint-sm) {
    margin-left: 25px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}