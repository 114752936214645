@import "../../styles/variables";
@import "../../styles/mixins";

.calc__manager-photo {
  flex-shrink: 0;
}

.complex__manager-avatar_wrapper img {
  object-fit: contain;
}

.show__map-mobile {
  display: flex;
  margin-left: 40px;
}

.show__map-mobile .icon_map-pin {
  height: 17px;
  margin-right: 10px;
}

.complex__info-main.mobile .complex__address {
  margin-bottom: 16px;
}

.filter__manager-photo {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(143, 149, 172, 0.1);
  margin-right: 15px;
  overflow: hidden;
}
.status-assignment{
  background: #8790E5;
  color: white;
  padding: 5px 10px;
  display: flex;
  max-width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
}

.thumb_active {
  border-color: white;
}

.card-tile .status-circle {
  position: absolute;
  top: 20px;
  right: 20px;

  .status-circle__tooltip {
    top: 2px;
    left: 20px;
  }
}

.card-tile {
  position: relative;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 10px;
  min-height: 407px;
  background-color: $color-white;

  &:hover {
    box-shadow: $shadow;
  }
}

.complex__appartments-card_tile {
  width: calc(100% / 12 * 3 - 30px);
  @include break-max($breakpoint-md) {
    width: calc(100% / 12 * 4 - 22px);
  }
  @include break-max($breakpoint-sm) {
    width: calc(100% / 12 * 6 - 16px);
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.card-tile__image {
  padding-top: 55px;
  padding-bottom: 50px;
  max-height: 265px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $color-brand-opaque-20;
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: 159px;
    padding: 0 15px;
  }
}

.card-tile__body {
  padding: 0 18px;
}

.card-tile__label {
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
  padding: 4px 8px;
  border: 1px solid $color-brand-opaque-30;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;

  &_assign {
    background: $blue;
    color: #fff;
  }
}

.card-tile__body {
  padding: 12px 18px 20px;
}

.card-tile__row {
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 15px;
  }
}

.card-tile__row {
  flex: 1 1;
}

.card-tile__value-small, .card-tile__key {
  display: block;
  font-size: 14px;
  color: $color-brand-3;
}

.card-tile__value-small {
  margin-bottom: 12px;
}

.card-tile__value-lg {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

.card-tile__value {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

.card-tile__key {
  margin-bottom: 3px;
}

.card-tile_img-full {
  .card-tile__image {
    padding: 0;
  }

  .card-tile__image img {
    width: 100%;
    height: 265px;
    object-fit: cover;
    padding: 0;
  }

  .status-circle {
    outline: 2px solid $color-white;
  }
}

.table-compilation-icon {
  position: relative;
  height: 28px;

  &__hint {
    position: absolute;
    left: -200px;
    background: white;
    z-index: 123;
    top: -8px;
    height: 44px;
    width: 190px;
    align-items: center;
    transition-duration: .3s;
    box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
    border: 1px solid #ECEDF0;
    border-radius: 20px;
    box-sizing: border-box;
    justify-content: center;
    display: none;

    &::after {
      content: '';
      border: 7px solid transparent;
      border-left: 7px solid white;
      position: absolute;
      right: -12px;
      box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
    }
  }

  &:hover {
    .table-compilation-icon__hint {
      display: flex;
    }
  }
}

.table-compilation-icon_share {
  display: inline-block;
  transition-duration: .3s;
  &:hover {
    svg {
      transition-duration: .3s;
      path {
        stroke: #CF2D23;
      }
      line {
        stroke: #CF2D23;
      }
    }
  }
}

.complex-custom-view {

  &__video-slider {
    height: 100%;

    .swiper-pan {
      height: 100%;

      .swiper-container {
        height: 100%;
      }
    }
  }

  &__main-container {
    height: 490px;
    overflow: hidden;

    .swiper-thumbs.swiper-container-thumbs {
      .swiper-wrapper {
        transform: none!important;
      }
    }

    .swiper-container {
      // height: 100%;
    }

    .swiper-thumbs-block {
      position: relative;

      button.sf-slider-btn {
        position: absolute;
        z-index: 1;
        bottom: 0px;
        left: 25px;
      }

      width: 100%;
      min-width: 100%;
      text-align: center;
      justify-content: center;
      margin-top: -30px;

      .swiper-thumbs {
        .swiper-slide {
          border-radius: 50%;
          overflow: hidden;
          width: 8px;
          height: 8px;
          background-color: white;
          opacity: 0.5;
          cursor: pointer;

        }
        .swiper-slide.swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }

    .sf__section {
      margin-bottom: 0;
      .h3-l.sf__h3 {
        display: none;
      }
    }

    .sf__furnish.sf__section {
      .tabs {
        position: relative;

        .tab-bar.tab-bar_flex {
          position: absolute;
          z-index: 2;
          right: 20px;
          top: 20px;

          .h3.tab-title {
            display: none;
          }

          .tab-list {
            border-bottom: none;
            gap: 10px;

            button.tab-btn {
              min-width: auto;
              padding: 6px 10px;
              border-radius: 50px;
              background-color: #F6F7F8;
              color: #777A89;
              font-weight: normal;
              transition-duration: .3s;
            }

            button.tab-btn:hover {
              background-color: #e9eaec;
            }

            button.tab-btn.tab-btn_active:after {
              display: none;
            }

            button.tab-btn.tab-btn_active {
              background-color: #DFE1E9;
              cursor: default;
            }

          }
        }
      }
    }

  }

  .complex-custom-view__main-container_genplan .sf-slide.sf-slide_main img {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    object-fit: contain;
  }

  &__genplan-img {
    height: 100%;
    //object-fit: cover;
    width: 100%;
    object-position: top;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    object-fit: contain;
  }

  &__video-frame {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }


}

.complex-view-list {
  //display: flex;
  //gap: 10px;
  margin-top: 20px;

  &__item {
    //width: 155px;
    height: 100px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(0,0,0,0.3);
      z-index: 1;
      transition-duration: .3s;
    }

    &:hover:before {
      opacity: 0;
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    span {
      z-index: 2;
      color: white;
      padding-bottom: 7px;
      border-bottom: 2px solid transparent;
    }

  }

  &__item.active {
    span {
      border-bottom: 3px solid #EF372E;
    }
  }

  .swiper-container {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 50%;
    cursor: pointer;
    background: $color-white;
    transition: all 0.3s;

    &:hover:not(.swiper-button-disabled) {
      background: $gray-light;
    }

    &:active:not(.swiper-button-disabled) {
      background: $gray-light;
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 1px solid $color-brand-2;
      border-right: 1px solid $color-brand-2;
      transform: rotate(-135deg);
      margin-left: 2px;
    }

    svg {
      width: 12px;
      height: 10px;
    }

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .swiper-button-next {
    left: auto;
    right: 0px;
    &::before {
      transform: rotate(45deg);
      margin-left: 0;
      margin-right: 2px;
    }


  }
}

//.complex-view-list.complex-view-list_suburban {
//  .complex-view-list__item {
//    width: 100%;
//  }
//}

.sf__elem-btn.record-not-available {
  color: #95979F;
  cursor: default;
  position: relative;

  svg {
    path {
      stroke: #95979F;
    }
  }

  span {
    position: absolute;
    color: white;
    padding: 5px 23px 6px 23px;
    border-radius: 20px;
    background-color: #EF372E;
    margin-top: 65px;
    margin-left: 40px;
    opacity: 0;
    transition-duration: .3s;

    &::after {
      content: '';
      position: absolute;
      left: 55px;
      border: 7px solid transparent;
      border-bottom: 10px solid #EF372E;
      top: -15px;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
}

span.complex__title-text_handed {
  background-color: #EF372E;
  color: white;
  padding: 3px 15px;
  border-radius: 50px;
}


.actions-slider {
  margin-top: 60px;
  margin-bottom: 40px;
  width: calc(100% - 200px);
  margin-left: auto;
  margin-right: auto;

  .tns-controls {
    margin-top: 57px;
    position: absolute;
    width: 100%;
  }



  &__top-title {
    margin-left: -100px;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: #8f95ac;
      font-weight: 400;
      cursor: pointer;
      margin-right: -100px;
      transition-duration: .3s;

      &:hover {
        color: #797e93;
      }

    }
  }


  &__slide-inside {
    border-radius: 20px;
    background-color: #f6f7f8;
    display: flex;
    cursor: pointer;
    height: 115px;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    transition-duration: .3s;

    &:hover {
      background-color: #ECEDF0;
    }

  }
  &__icon {
    margin-right: 20px;
  }

  .h4-l.sf__promo-title {
    margin-bottom: 5px;
  }

  .tns-outer {
    position: relative;
  }

  .tns-controls {
    button {
      font-size: 0;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: -60px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border: 1px solid rgba(143, 149, 172, 0.2);
      border-radius: 50%;
      cursor: pointer;
      background: #fff;
      transition-duration: .3s;

      &:hover {
        background: #f1f1f1;
      }

      &:after {
        content : '';
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjA4ODIgMTUuMDkzNkwxNC4wODg2IDEyLjAzODZMMTEuMDg4MiA4Ljk4MzU3IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150%;

        transform: rotate(180deg);
        transform-origin: center;
      }
    }

    button:disabled {
      background-color: rgba(0,0,0,0.02);
    }

    button[data-controls="next"] {
      right: -60px;
      left: auto;

      &:after {
        content : '';
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjA4ODIgMTUuMDkzNkwxNC4wODg2IDEyLjAzODZMMTEuMDg4MiA4Ljk4MzU3IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150%;
        transform: rotate(360deg);
        transform-origin: center;
      }
      }
    }
  }









