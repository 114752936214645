@import '../../styles/variables';
@import '../../styles/mixins';

.case-info {
  display: flex;
  flex-direction: column;

  &__block {
    margin-top: 35px;
  }

  &__block-title {
    margin-bottom: 15px;
  }

  .new-alert {
    margin-bottom: 0;
  }

  .sf__info-value {
    max-width: 100%;
  }

  .sf__info {
    margin-bottom: 0;
  }
}

.custom-table {
  color: $color-brand-2;

  &__first-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include h3();
  }

  &__first-cell {
    display: flex;
    justify-content: space-between;
  }

  &__fst-lable {
    color: $gray-light-2;
  }
}
.custom-table thead th {
  background-color: $color-white;
  height: 42px;
  color: $color-brand-2;
  padding-top: 0;
  padding-bottom: 5px;
}
.custom-table thead th {
  border-right: 1px solid $gray-light;
  border-bottom: 1px solid $gray-light;
}
.custom-table thead th:first-child {
  text-align: right;
  padding-right: 54px;
  position: relative;
  padding-left: 0;
}



.custom-table thead th:last-child {
  border-right: 0;
  padding-left: 67px;
}

.custom-table tbody td {
  border-right: 1px solid $gray-light;
  height: 40px;
  padding: 0;
}
.custom-table tbody td:first-child {
  padding-right: 54px;
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
}
.custom-table tbody td:last-child {
  padding-left: 67px;
  border-right: 0;
  border-radius: 0 5px 5px 0;
}


.custom-table_cases {
  margin-top: 35px;


  .ant-table-tbody > tr:hover {
    background-color: $gray-light;
    cursor: default;

    &:hover {
      background-color: $color-white;
    }
  }

  .ant-table-tbody > tr.ant-table-second-color {
    background-color: $gray-light;

    &:hover {
      background-color: $gray-light;
    }
  }
}


.other-cases {
  display: flex;
  flex-wrap: wrap;

  & > .other-cases__item {
    margin: 0 20px 20px 0;
    width: calc(50% - 10px);
    padding: 35px;
    border-radius: 20px;
    border: 1px solid $gray-dark;
    display: flex;
    align-items: center;
    padding-right: 70px;
    max-height: 130px;
    cursor: pointer;
    transition-duration: .3s;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  & > .other-cases__item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }

  & > .other-cases__item:hover {
    background-color: $gray-light;
    border: 1px solid $gray-light;
  }

  &__img {
    margin-right: 25px;
    width: 58px;
    max-width: 58px;
    object-fit: contain;
    max-height: 80px;
  }


}
.case-info__block-title_link {
  display: flex;
  justify-content: space-between;

  .case-info__cases-link {
    font-size: 14px;
    color: $gray-dark-2;
    font-weight: 400;
    transition-duration: .3s;

    &:hover {
      color: $color-red-bottom-border;
    }
  }
}


.sf__detail-col--common .sf__info {
  margin-bottom: 20px;
}

.info-list {
  margin-bottom: 25px;

  &__item {
    padding-left: 35px;
    padding-bottom: 25px;
    margin-top: 25px;
    border-bottom: 1px solid $gray-dark;
    display: flex;

    @include break-max($breakpoint-sm) {
      padding-left: 5px;
    }
  }

  &__content {

  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  &__icon {
    margin-right: 10px;
  }

  &__text {
    color: $gray-light-2;
  }

  @include break-max($breakpoint-xsm) {
    display: flex;
    &__item {
      width: 50%;
      padding-right: 10%;
      padding-left: 0;
    }
  }

}

.case {

  .new-alert {
    margin-bottom: 0;
    margin-top: 35px;
  }
}































