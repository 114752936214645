.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  font-size: 16px;
  line-height: 18px;
  @include break-max($breakpoint-md) {
    font-size: 14px;
    line-height: 17px;
  }
}

.header__wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  @include break-max($breakpoint-md) {
    height: 50px;
  }
}

.header__logo {
  margin-right: 25px;
  height: 100%;
  object-fit: contain;
  @include break-max($breakpoint-xsm) {
    margin-right: 15px;
    width: 52px;
  }
}

.header__logo-link {
  height: 100%;
}

.header__title {
  max-width: 185px;
  margin-right: 30px;
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include break-max($breakpoint-md) {
    max-width: 85px;
    margin-right: 15px;
    font-size: 14px;
    line-height: 17px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 150px;
  }

  @include break-max($breakpoint-xsm) {
    max-width: 85px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 160px;
  }
}

.header__nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;

  @include break-max($breakpoint-sm) {
    display: none;
    position: absolute;
    background: $color-3;
    top: 100%;
    width: 100%;
    left: 0;
    padding: 36px 25px;
    font-weight: 700;
    justify-content: flex-start;
    flex-grow: 0;

    &_opened {
      display: flex;
      @include break-max($breakpoint-xs) {
        background: $color-white;
        left: auto;
        right: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: fixed;
        padding: 0;
        top: 0;
        bottom: 0;

        .constructor__socials_promo {
          &:after {
            display: none;
          }
        }

        .constructor__social-btn {
          font-weight: 300;
          margin: 0 0 6px !important;
          width: 100%;

          a {
            align-items: center;
            display: flex;
            font-size: 15px;
            line-height: 20px;
            justify-content: center;
          }

          svg.icon {
            margin-right: 12px;
            position: static;
          }
        }

        .header__nav-title {
          align-items: center;
          border-bottom: 1px solid $color-brand-opaque-20;
          color: $color-brand-2;
          display: flex;
          font-weight: normal;
          font-size: 15px;
          flex-shrink: 0;
          height: 50px;
          width: 100%;

          .icon {
            transform: rotate(-90deg);
            margin-right: 20px;
            margin-left: 12px;
          }
        }

        .header__nav-agent {
          border-radius: 4px;
          border: 1px solid $color-brand-opaque-20;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 9px;
          padding: 15px;
          width: 100%;
        }

        .header__nav-agent-name {
          color: $color-brand-2;
          font-size: 15px;
          font-weight: 700;
          line-height: 18px;
          margin-bottom: 3px;
          margin-top: 15px;
        }

        .header__nav-agent-role {
          color: $color-brand-3;
          font-weight: 300;
        }

        .header__nav-agent-avatar {
          border-radius: 50em;
          margin-right: 15px;
          object-fit: cover;
          height: 70px;
          width: 70px;
        }

        .header__nav-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 12px 10px 30px;
        }

        .header__nav-phone {
          border-top: 1px solid $color-brand-opaque-20;
          color: $color-brand-2;
          font-size: 15px;
          font-weight: 700;
          order: 2;
          padding-top: 30px;
          text-align: center;
          margin: 40px 0 30px;
        }
      }
    }
  }
}

.header__list {
  display: flex;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  li {
    margin-right: 35px;
    &:last-child {
      margin-right: 0;
    }

    @include break-max($breakpoint-md) {
      margin-right: 15px;
    }

    @include break-max($breakpoint-xs) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  a {
    transition: all 0.3s;
    &:hover {
      color: $color-1;
    }

  }

}

.header__list-dropdown .dropdown__btn {
  font-size: inherit;
  color: inherit;
}

.dropdown.is-showing .icon {
  transform: rotate(180deg);
}

.dropdown__block {
  left: -20px;
  transform: none;
}

.header__top-menu-drop {
  padding: 20px;
}

.header__top-drop-list {
  li {
    margin-right: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header__list-title {
  color: $color-white;

  .icon {
    stroke: #fff;
    fill: transparent;
    font-size: 12px;
    margin-left: 5px;
    transform: rotate(0);
    transition: transform 0.15s ease-in-out;
  }
}

.header__contacts {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
}

.header__phone {
  margin-right: 30px;

  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    color: $color-1;
  }

  @include break-max($breakpoint-md) {
    font-size: 14px;
    line-height: 17px;
  }
  @include break-max($breakpoint-xsm) {
    margin-right: 15px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }

  &_mob {
    display: none;
    @include break-max($breakpoint-xs) {
      display: flex;
      margin-bottom: 15px;
    }
  }
}

.header__callback-btn {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px;

  // background: $color-1;
  color: $color-white;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    background: darken($color-1, 10%);
  }

  &:active {
    background: darken($color-1, 20%);
  }

  @include break-max($breakpoint-md) {
    font-size: 14px;
    line-height: 17px;
  }

  @include break-max($breakpoint-xsm) {
    padding: 0 17px;
    margin-right: 15px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }

  &_mob {
    display: none;
    @include break-max($breakpoint-xs) {
      display: flex;
      height: auto;
      background: $color-1;
      font-size: 11px;
      letter-spacing: 0.3px;
      padding: 16px 37px;
      border-radius: 8px;
      margin: 8px 0 0 0;
      justify-content: center;
      text-transform: uppercase;
      width: 100%;
    }
  }


}

.header__socials {

  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  padding: 10px 5px 15px;

  border-radius: 8px 0 0 8px;

  li {
    margin-bottom: 10px;
    margin-right: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }

  &_mob {
    display: none;

    @include break-max($breakpoint-xs) {
      display: flex;
    }
  }

}

.header__social-sign {
  writing-mode: tb-rl;
  transform: rotate(180deg);
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
  align-items: center;
  margin-top: 15px;
  cursor: default;
}

.header__nav-toggler {
  display: none;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  background: $color-3;

  @include break-max($breakpoint-sm) {
    display: flex;
  }

  @include break-max($breakpoint-xs) {
    position: absolute;
    right: 0;
  }

  &_multi-link {
    @include break-max($breakpoint-sm) {
      display: none;
    }
    @include break-max($breakpoint-xs) {
      display: flex;
    }
  }

  button {
    width: 100%;
    height: 100%;
    position: relative;
    background: none;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translateX(-50%);
      width: 25px;
      height: 2px;
      background: $color-white;
      box-shadow: 0 6px $color-white,
                  0 12px $color-white;
    }
  }

  &_close {
    button {
      &::before {
        content: '';
        position: absolute;
        left: 13px;
        top: 50%;
        transform: rotate(-45deg);
        width: 25px;
        height: 2px;
        background: $color-white;
        border-radius: 4px;
      }
      &::after {
        box-shadow: none;
        left: 13px;
        top: 50%;
        transform: rotate(45deg);
        border-radius: 4px;
      }
    }
  }

}
