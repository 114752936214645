@import "../../styles/variables";
@import "../../styles/mixins";

.invite {
  font-size: 14px;
  line-height: 18px;

  background-color: $color-white;
  background-image: url('../../assets/images/invite/cloud-left.svg'), url('../../assets/images/invite/cloud-right.svg');
  background-repeat: no-repeat;
  background-position: top left, center right;

  @include break-max($breakpoint-sm) {
    background-size: 20%, 60%;
  }

}

.invite__section {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: center;

  @include break-max($breakpoint-xsm) {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}

.invite__block {
  width: 535px;
  max-width: 100%;
  padding: 0 20px;
}


.invite_friend .invite__block {
  width: 525px;
}

.invite__logo {
  margin-bottom: 90px;

  @include break-max($breakpoint-md) {
    margin-bottom: 30px;
  }

  @include break-max($breakpoint-xsm) {
    margin-left: auto;
    margin-right: auto;
  }

}

.invite__title {
  margin-bottom: 60px;
  @include break-max($breakpoint-md) {
    margin-bottom: 20px;
  }

}

.invite__highlight {
  margin-bottom: 15px;
}

.invite_friend .invite__highlight {
  margin-bottom: 25px;
}

.invite__text {
  margin-bottom: 35px;
  @include break-max($breakpoint-md) {
    margin-bottom: 25px;
  }

}


.invite_friend .invite__text {
  margin-bottom: 20px;
}

.invite__bottom-text {
  margin-bottom: 75px;
  padding: 10px 15px 10px 25px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 15px;

  @include break-max($breakpoint-md) {
    margin-bottom: 35px;
  }


}

.invite__img {
  width: 868px;
  max-width: 60%;
  @include break-max($breakpoint-xsm) {
    display: none;
  }
}

.invite__btn {
  display: inline-flex;
  @include break-max($breakpoint-xs) {
    padding: 22px 36px;
  }
}

.invite__link-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 25px;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  background: $color-brand-opaque-10;
  border-radius: 5px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  cursor: pointer;

  overflow: hidden;
  white-space: nowrap;

  @include break-max($breakpoint-sm) {
    font-size: 14px;
  }

  @include break-max($breakpoint-xsm) {
    font-size: 12px;
  }
}

.invite__link-icon {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  background: $color-red;
  border-radius: 5px;

  box-shadow: -21px 0 #f4f4f7;
}

.invite__sign {
  color: $color-brand-3;
  text-align: left;
}


.invite .popover__block_align_right {
  width: 200px;
  text-align: center;
  .popover__inner {
    width: 100%;
    padding: 20px;
  }
  @include break-max($breakpoint-xsm) {
    max-width: 100%;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    .popover__inner {
      padding: 20px 0;
      font-size: 18px;
    }
    .clip__tooltip-close {
      display: none;
    }
  }
}
