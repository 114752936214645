.ant-table {
    font-variant: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    color: #1e2030;
}

.ant-table-thead > tr > th {
    background-color: #2c2e3e;
    color: #fff;
    font-weight: normal;
}
.ant-table-thead th.ant-table-column-sort {
    background-color: #2c2e3e;
}

.ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #2c2e3e;
}

td.ant-table-column-sort {
    background: none;
}
.ant-table-thead > tr > th {
    white-space: nowrap;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
    padding: 18px 10px;
    /* border-top: 1px solid rgba(143, 149, 172, 0.3); */
    border-bottom: none;
}

.ant-table-column-sorters {
    padding: 18px 10px;
    user-select: none;
}

.clients__phone {
    text-decoration: none;
    color: #1e2030;
}

.form__item-table {
    width: 28px;
    background-color: transparent;
    height: auto;
    margin: 0;
    z-index: 1;
}

.form__item-table .dropdown {
    height: 28px;
}

.form__item-table .form__drop-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(41, 61, 143, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.form__item-table .form__drop-btn .icon.icon_elipsis {
    width: 15px;
    height: 15px;
    stroke: none;
}

.table__more {
    padding: 18px 10px;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #ffffff;
}
