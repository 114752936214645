.client {
  padding-bottom: 50px;

  &__header {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }

  &__grid {
    @include grid();
  }

  &__content {
    @include grid-col();
    @include grid-size(9);
  }

  &__aside {
    @include grid-col();
    @include grid-size(3);
    position: relative;
    box-shadow: 0 2px 20px 0 $color-brand-opaque-10;
  }

  &__ops {
    display: flex;
    align-items: center;
    margin-left: 15px;

    & > * + * {
      margin-left: 15px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__control {
    margin-right: 30px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__fav {
    cursor: pointer;

    & .icon {
      fill: $color-brand-3;
    }

    & span.is-fav {
      display: none;
    }

    & span.add-fav {
      display: inline-block;
    }

    &.is-fav {
      & .icon {
        fill: $color-red;
      }
      & span.is-fav {
        display: inline-block;
      }

      & span.add-fav {
        display: none;
      }
    }
  }

  &__section {
    background-color: $color-brand-opaque-8;
    border-radius: 8px;
    padding: 28px 20px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__nav {
    background-color: $color-brand-3;
    border-radius: 4px;
    padding: 22px 10px;
    position: sticky;
    top: 100px;
  }

  &__nav-list {
    @include unlist();
  }

  &__nav-item {
    cursor: pointer;
    padding: 10px 14px 10px 18px;
    border-radius: 4px;
    background-color: transparent;

    &:hover {
      background-color: $color-white-15;
    }
  }

  &__nav-link {
    color: $color-white;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;

    & .icon {
      font-size: 19px;
      margin-right: 20px;
      flex-shrink: 0;
    }
  }

  &__link-qty {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #dee2f3;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    color: $color-brand-3;
    margin-left: auto;
    flex-shrink: 0;
  }

  &__applications {
    &-object {
      color: $color-brand-3;
      transition: color $transition-default;
      margin-top: 5px;

      &:hover {
        color: $color-red;
      }
    }
  }

  &__section-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__header-controls {
    display: flex;
    align-items: center;
    margin-left: auto;

    & .icon {
      font-size: 20px;
    }

    & .button {
      margin-left: 30px;
    }
  }

  &__id {
    @include unlist();
    padding: 32px 26px 0;
  }

  &__id-item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 26px;
  }

  &__key {
    width: 40%;
    flex-shrink: 0;
    font-weight: 700;
  }

  &__value {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__feed {
    box-shadow: inset 0 0 0 1px $color-brand-opaque-10;
    border-radius: 4px;
    margin-bottom: 32px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    display: flex;
    align-items: flex-start;

    & > .icon {
      font-size: 19px;
      margin-right: 17px;
      flex-shrink: 0;
    }

    &-more {
      margin-top: 10px;
      color: $color-brand-3;
    }
  }

  &__files-ready,
  &__files-upload {
    background-color: $color-white;
    border-radius: 4px;
  }

  &__files-ready {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 10px;
    margin-bottom: 14px;
  }

  &__files-item {
    padding: 14px 8px;
    flex: 0 0 25%;
  }

  &__files-upload {
    padding: 26px 70px 26px 18px;
  }

  &__files-label {
    @include grid-size(3);
    font-weight: bold;
    font-size: 16px;
    line-height: 70px;

    @include break-max($breakpoint-md) {
      @include grid-size(4, $grid-gutter-md);
    }
  }

  &__files-input {
    @include  grid-size(9);

    @include break-max($breakpoint-md) {
      @include grid-size(8, $grid-gutter-md);
    }
  }

  &__files-submit {
    margin-left: 42px;
  }
}

.clients__table-link {
  @include break-max($break-xs) {
    font-size: 15px;
    flex-wrap: wrap;
    max-width: 80%;
    width: 80%;
    white-space: normal;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 12px;
  }
}

.clients__phone {
  @include break-max($break-xs) {
    font-size: 15px;
  }

  @include break-max($break-xxs - 1px) {
    font-size: 12px;
  }
}

.lk-presentation__clients .clients__phone {
  @include break-max($break-xs) {
    font-size: 15px;
  }
}

.lk-presentation__clients {
  .select_type_table .custom-select {
    @include break-max($break-xs) {
      min-width: 0;
      width: auto !important;
    }
  }

  @include break-max($break-xs) {
    .select_type_table .custom-select__selected {
      align-items: center;
      border-radius: 4px;
      height: auto;
      display: flex;
      padding: 0 10px;
    }
  }
}

.lk-presentation__clients.is-wrap {
  @include break-max($break-xs) {
    flex-wrap: wrap;
  }
}

.button_type_task {
  @include break-max($break-xs) {
    & > .icon {
      margin-right: 7px;
    }
  }
}

.clients__filter-addit.for-clients {
  @include break-max($break-xs) {
    align-items: flex-start;
    flex: 0;

    .checkbox {
      flex-direction: row-reverse;
    }

    .checkbox__box {
      margin-right: 8px;
    }
  }
}

.mini-filter.for-contacts,
.clients__filter-col_search {
  @include break-max($break-xs) {
    .input__control {
      border: 1px solid $color-brand-opaque-30;
      margin: 0 10px;
      width: calc(100% - 20px);
      margin-bottom: 20px;
    }

    .icon_search {
      left: 10px;
      top: 25px;
    }

    .index-search__filter-inc {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}

.mobile-filters.for-clients {
  @include break-max($break-xs) {
    .rdrMonth {
      padding: 0 10px;
      width: 100%;
    }
  }
}

.clients__back-btn {
  @include break-max($break-xs) {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    height: 44px;
    width: 100%;

    &.is-small {
      height: 30px;
      margin-right: 20px;
      padding: 0;
      width: 58px;

      .icon {
        margin: 0;
      }
    }
  }
}

.clients__new {
  @include break-max($break-xs) {
    margin: 0;
    width: 100%;

    .lk-form__btns {
      justify-content: space-between;

      & > a, & > button {
        margin: 0;
        width: calc(50% - 5px);
      }
    }

    .lk-form__submit {
      border-top: 1px solid $color-brand-opaque-20;
      margin: 0 10px;
      padding-top: 20px;

      .button {
        justify-content: center;
        padding: 0;
      }
    }
  }
}

.clients__form .lk-form__row,
.clients__form .lk-form__row._bg-gray {
  @include break-max($break-xs) {
    padding: 0 10px;

    .lk-form__fr {
      flex-basis: 100%;
      margin-right: 0;

      & > .lk-form__fr {
        padding-top: 9px;
      }

      & + .lk-form__fr {
        padding-top: 16px;
      }
    }
  }

  .lk-form__row-content {
    justify-content: space-between;
  }

  .lk-form__fr.is-half {
    flex-basis: calc(50% - 10px);
  }

  .input_width_small {
    width: 100%;
  }

  .lk-form__fr_full {
    padding-top: 0;
  }
}

.button_type_task-assigned {
  @include break-max($break-xs) {
    align-items: center;
    color: $color-brand-2;
    width: auto;

    .icon-block {
      margin-top: 0;
    }
  }
}

.mobile-filters.for-clients {
  @include break-max($break-xs) {
    .react-datepicker-wrapper {
      display: block;
    }

    .clients__filter-addit.for-clients {
      .checkbox {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }

    .button_view_default {
      width: 100%;
    }

    .button_view_outline {
      border-radius: 5px;
    }
  }
}

.task-info {
  display: flex;
  flex-direction: column;

  .task-info__item {
    border-top: 1px solid $color-brand-opaque-20;
    display: flex;
    min-height: 50px;
    padding-top: 15px;
  }

  .task-info__item.is-gray {
    background: $color-brand-opaque-8;
    border-top: none;
    margin: -23px -10px 0;
    padding: 15px 10px 0;

    & + .task-info__item {
      border-top: none;
    }
  }

  .task-info__title {
    color: $color-brand-3;
    font-size: 15px;
    line-height: 20px;
    width: 40%;
  }

  .task-info__content {
    align-items: center;
    color: $color-brand-2;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    margin-top: -15px;
  }

  .icon_alert-bell {
    stroke: $color-brand-3;
    width: 16px;
  }

  .task-info__icon {
    align-items: center;
    background: $color-brand-opaque-10;
    border-radius: 50em;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    height: 28px;
    width: 28px;
  }

  .clients__info-actions .button_type_edit-small  {
    height: 44px;
    font-size: 15px;
    justify-content: center;
    line-height: 20px;
    margin-top: 20px;
    width: calc(50% - 6px);
  }
}

.clients__fav-btn {
  @include break-max($break-xxs - 1px) {
    width: 20px;

    .icon {
      font-size: 12px;
    }
  }
}

.section.for-agents {
  @include break-max($break-xs) {
    padding-bottom: 35px;

    .lk-form__row-aside {
      margin: 6px 0px 12px;
      padding: 0;
      width: 100%;
    }

    .lk-form__row.is-gray,
    .lk-form__row-content.is-gray {
      background: $color-brand-opaque-8;
      margin: 0 -10px;
      padding: 14px 10px 23px;
      width: 100%;

      .custom-select__selected {
        background: $color-white;
        border: 1px solid $color-brand-opaque-20;
        border-radius: 4px;
        height: 50px;

        &::after {
          transform: rotate(-90deg);
        }
      }
    }

    .lk-form__row.is-gray {
      margin: 30px 0 0 0;
      padding: 0 10px 28px;
    }

    .lk-form__row-content {
      padding-bottom: 0;
    }

    .lk-form__row-title {
      margin: 22px 0 12px;
      padding: 0;
    }

    .lk-form__fr.lk-form__fr_auto.no-padding {
      padding-top: 0;
    }

    .lk-form__submit {
      border-top: none;
    }
  }
}

.index-search__filter-inc.no-margin {
  @include break-max($break-xs) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.mini-filter.for-contacts,
.clients__filter-col_search {
  @include break-max($break-xs) {
    .filter__filter .input__control {
      box-shadow: none;
      margin: 0;
      width: 100%;
    }

  }
}
