.list {
  &__item-label {
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
    color: $color-brand-3;
  }

  &__item-value {
    font-size: 16px;
    font-weight: bold;
  }
}