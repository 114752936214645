@import "../../styles/variables";
@import "../../styles/mixins";

.unsub {
  width: 650px;
  margin: 0 auto;
  max-width: 100%;
  @include break-max($breakpoint-sm) {
    padding: 50px 0;
  }


  .not-found-content {
    width: 100%;
    padding: 0 15px;
  }

  svg {
    max-width: 80%;
  }
}

.unsub__title {
  color: $color-brand-1;
  margin-top: 30px;
  margin-bottom: 15px;
  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }

}

.unsub__second-text {
  color: $color-brand-3;
  margin-bottom: 35px;
  font-weight: 300;
}

.preview__header_bg + .section .unsub {
  margin-top: 85px;
  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }
}
