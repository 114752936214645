@import "../../styles/variables";
@import "../../styles/mixins";
@import "./constructorVariables";


.lid-page h1 {
  overflow: hidden;
}

.constructor {
  $color-1: $c-green;
  $color-2: $c-black-1;
  $color-3: $c-green_dark;

  display: flex;
  flex-direction: column;
  height: 100%;

  @import "./common.scss";
  @import "./header.scss";
  @import "./form.scss";
  @import "./Advantages/advantages.scss";
  @import "./Calculator/calculator.scss";
  @import "./Consultation/consultation.scss";
  @import "./Promo/promo.scss";
  @import "./Property/property.scss";
  @import "./Services/services.scss";
  @import "./Reviews/reviews.scss";
  @import "./Banner/banner.scss";
  @import "./Background/background.scss";
  @import "./StartBlock/start-block.scss";
  @import "./Lid/lid.scss";
  @import "./Video/video.scss";
  @import "./block.scss";
  @import "./InnerPage/inner.scss";
  @import "./InnerPage/InnerForm/innerForm.scss";
  @import "./InnerPage/Breadcrumbs/breadcrumbs.scss";
  @import "./InnerPage/ReviewsPage/reviewsPage.scss";
  @import "./InnerPage/ContactsPage/contactsPage.scss";
  @import "./InnerPage/DropInfo/dropinfo.scss";
  @import "./InnerPage/Mortgage/mortgage.scss";
  @import "./InnerPage/LidPage/lidPage.scss";

  @import "./Card/card.scss";
  @import "./Card/obj-card.scss";
  @import "./Typical/typical.scss";
  @import "./About/about.scss";
  @import "./Infra/infra.scss";
  @import "./ConstructorBanks/ConstructorBanks.scss";
  @import "./footer.scss";

  @import "./Admin/admin.scss";
  @import "./Admin/MyObjects/add.scss";
  @import "./Admin/MyObjects/objects-table.scss";
  @import "./Admin/MyObjects/object.scss";

  &_color-green {
    $color-1: $c-green;
    $color-2: $c-black-1;
    $color-3: $c-green_dark;
    @import "./themes.scss";
  }

  &_color-blue {
    $color-1: $c-blue;
    $color-2: $c-black-2;
    $color-3: $c-blue_dark;
    @import "./themes.scss";
  }

  &_color-yellow {
    $color-1: $c-yellow;
    $color-2: $c-black-3;
    $color-3: $c-yellow_dark;
    @import "./themes.scss";
  }

  &_color-red {
    $color-1: $c-red;
    $color-2: $c-black-4;
    $color-3: $c-red_dark;
    @import "./themes.scss";
  }

  &_color-gray {
    $color-1: $c-gray;
    $color-2: $c-black-5;
    $color-3: $c-gray_dark;
    @import "./themes.scss";
  }

  &_color-asphalt {
    $color-1: $c-asphalt;
    $color-2: $c-black-6;
    $color-3: $c-asphalt_dark;
    @import "./themes.scss";
  }

  &_color-purple {
    $color-1: $c-purple;
    $color-2: $c-black-7;
    $color-3: $c-purple_dark;
    @import "./themes.scss";
  }

  &_color-burgundy {
    $color-1: $c-burgundy;
    $color-2: $c-black-8;
    $color-3: $c-burgundy_dark;
    @import "./themes.scss";
  }

  &_color-nightsky {
    $color-1: $c-nightsky;
    $color-2: $c-black-9;
    $color-3: $c-nightsky_dark;
    @import "./themes.scss";
  }
  &_color-turquoise {
    $color-1: $c-turquoise;
    $color-2: $c-black-10;
    $color-3: $c-turquoise_dark;
    @import "./themes.scss";
  }
}

@import "./modal.scss";
