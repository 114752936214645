@import "../../styles/variables";
@import "../../styles/mixins";

.new-events {

  &__filters {
    display: flex;
    justify-content: space-between;
  }
  &__filters-left {
    display: flex;
    gap: 20px;

    .section-search__inp {
      width: 400px;
    }

    .custom-select__selected {
      height: 100%;
      min-width: 190px;
    }
  }

  &__tabs {
    margin-top: 20px;

    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
  }

  .hp__event:before {
    display: none;
  }
  .hp__event {
    padding: 20px 30px;
  }
  .hp__event-name {
    padding-right: 120px;
    width: 100%;
  }
  .hp__event-header {
    align-items: center;
  }
  .hp__event-status {
    cursor: pointer;
  }
  .hp__events-day .hp__event-status.text {
    padding: 5px 15px;
    border-radius: 20px;
    &:before {
      display: none;
    }
  }




  .hp__events-day .hp__event_webinar .hp__event-status,
  .hp__events-day .hp__event-status_webinar {
    color: $green;
    border: 1px solid $green;
  }

  .hp__events-day .hp__event_learning .hp__event-status,
  .hp__events-day .hp__event-status_learning {
    color: $yellow;
    border: 1px solid $yellow;
  }

  .hp__events-day .hp__event_excursion .hp__event-status,
  .hp__events-day .hp__event-status_excursion {
    color: $blue;
    border: 1px solid $blue;
  }

  .hp__events-day_top {
    border: 1px solid red;
  }

  .hp__events-day .hp__events-label {
    font-weight: normal;
    height: 30px;
  }

  .hp__calendar-legend {
    margin-bottom: 20px;
  }


  .hp__events-day .hp__event-status.hp__event-status_ether {
    background-color: $green;
    color: $color-white;
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      background-color: $color-white;
    }
  }

  .hp__calendar-title {
    display: inline-block;
  }


  &__search-result {
    position: absolute;
    margin-top: 51px;
    width: 600px;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
    background-color: $color-white;
    z-index: 1;

    li {

      a {
        display: flex;
        justify-content: space-between;
        padding: 6px 12px;
        border-radius: 5px;
        align-items: center;
        div {
          display: flex;
          width: calc(100% - 100px);
          align-items: center;

          svg {
            flex-shrink: 0;
            margin-right: 10px;
          }

          p {
            color: $color-brand-2;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 40px;
          }
        }
        span {
          color: $gray-light-2;
        }



        &:hover {
          background-color: $gray-light-3;
        }
      }
    }
  }

  &__videolist {
    margin-top: 20px;
  }
  &__videolist-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9eaee;
  }

  &__videolist-link {
    a {
      display: flex;
      align-items: center;
      color: #8F95AC;
      transition-duration: .3s;

      &:hover {
        color: #80869a;
      }
    }
  }

  &__vebinars {
    display: flex;
    gap: 20px;
  }
  &__vebinar-item {
    border: 1px solid #DFE1E9;
    border-radius: 20px;
    margin-top: 20px;
    width: calc(50% - 10px);
  }
  &__vebinar-img {
    position: relative;
    height: 125px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 20px;
      position: relative;
      margin-top: -1px;
    }
  }
  &__vebinar-time {
    position: absolute;
    background-color: #F6F7F8;
    color: #777A89;
    padding: 2px 6px;
    border-radius: 20px;
    right: 10px;
    bottom: 10px;
    font-size: 11px;
    z-index: 1;
  }
  &__vebinar-info {
    padding: 7px 15px 15px 15px;
  }

  &__vebinar-date {
    font-size: 13px;
    color: #8F95AC;
    margin-bottom: 5px;
  }

  &__vebinar-title {
    font-weight: 700;
    color: #2C2E3E;
    font-size: 13px;
  }

}

.custom-calendar-select {
  display: inline-block;
  width: auto;
  min-width: auto;

  .custom-select__selected {
    background-color: transparent;
    padding: 0;
    padding-left: 5px;
    color: #777A89;
    font-size: 16px;

    &::after {
      margin-left: 5px;
    }
  }

}
.custom-select.custom-calendar-select.is-showing .custom-select__options {
  width: auto;
  min-width: 80px;
  box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
  border-radius: 10px;
  padding-bottom: 3px;
  padding-top: 3px;

  .custom-select__option {
    color: #8F95AC;
    font-size: 14px;
    font-weight: normal;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .custom-select__option.is-active {
    color: #FF2D23;
  }
}
.custom-select.is-showing.custom-calendar-select .custom-select__selected {
  background-color: transparent;
}

.speakers-form {
  .new-alert__title {
    margin-top: 3px;
  }
}
.hp__calendar-title {
  .custom-select {
    box-shadow: none;
  }
}

.hp__events-label_active {
  animation-name: fade-in-out;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  span {
    animation-name: fade-in-out-text;
    animation-duration: 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }
}

@keyframes fade-in-out {
  0% {
    background: #8790E5;
    color: white;
  }
  50% {
    background: #8790E5;
    color: white;
  }
  100% {
  }
}
@keyframes fade-in-out-text {
  0% {
    color: white;
  }
  50% {
    color: white;
  }
  100% {
  }
}


.hp__calendar.hp__calendar_events .react-calendar__month-view__days {
  display: flex!important;
  gap: 0px!important;
}

.hp__calendar.hp__calendar_events .react-calendar__month-view__weekdays {
  display: flex!important;
  gap: 0px!important;
}

.hp__calendar.hp__calendar_events .react-calendar__month-view__days .react-calendar__tile {
  overflow: visible !important;
  border: 3px solid white;
  box-sizing: border-box;
  height: 50px;
  border-radius: 10px;
  font-family: "Formular", sans-serif;
}
.events-page {
  height: calc(100vh - 180px);
  overflow: auto;
}

.sf__detail-col.sf__detail-col_events-list {
  height: 100%;
  overflow: auto;
}
.sf__detail-col.sf__detail-col_events-list::-webkit-scrollbar {
  width: 2px;
  margin-right: -5px;
}
.sf__detail-col.sf__detail-col_events-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-right: -5px;
}
.sf__detail-col.sf__detail-col_events-list::-webkit-scrollbar-thumb {
  background-color: #FF2D23FF;
  border-radius: 10px;
  margin-right: -5px;
}

.hp__calendar.hp__calendar_events .react-calendar__year-view .react-calendar__tile {
  border: 3px solid white;
  border-radius: 15px;
  height: 55px;
}

.hp__calendar.hp__calendar_events .react-calendar__year-view .react-calendar__tile:disabled {
  background: linear-gradient(178.9deg, rgba(243, 244, 252, 0.58) 30.12%, #F4F4F7 99.06%);
}

.hp__calendar.hp__calendar_events .react-calendar__year-view .react-calendar__tile {
  background-color: #f4fff4;
  font-family: "Formular", sans-serif;
}
.hp__calendar.hp__calendar_events .react-calendar__year-view .react-calendar__tile:enabled:hover {
  background-image: none;
}


.hp__calendar.hp__calendar_events .react-calendar__year-view .react-calendar__tile.react-calendar__tile--hasActive {
  background-color: #DFF5E0;
  color: #2cba2e;
}

.hp__calendar.hp__calendar_events .react-calendar__navigation__label {
  font-size: 16px;
  padding-left: 0;
  padding-bottom: 20px;
  line-height: normal;
  font-family: "Formular", sans-serif;
  white-space: nowrap;
}
.hp__calendar.hp__calendar_events .react-calendar__navigation__label:hover {
  cursor: default;
}
.hp__calendar.hp__calendar_events .react-calendar__navigation__label span {
  color: #777a89;

  background: linear-gradient(178.9deg, rgba(243, 244, 252, 0.58) 30.12%, #F4F4F7 99.06%);
  cursor: pointer;
  padding: 8px 28px 8px 12px;
  border-radius: 10px;
}


.hp__calendar.hp__calendar_events .react-calendar__navigation__label span:after {
  content: '';
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjUxMTUgNy45MTk5MkwxMi40NTY1IDQuOTE5NDdMOS40MDE1NCA3LjkxOTkyIiBzdHJva2U9IiM3NzdhODkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNOS40MDE1NSAxNS45MTk0TDEyLjQ1NjYgMTguOTE5OUwxNS41MTE2IDE1LjkxOTQiIHN0cm9rZT0iIzc3N2E4OSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
  position: absolute;
  margin-top: -3px;
}


.hp__calendar.hp__calendar_events .react-calendar__navigation__label:before {
  content: 'Календарь мероприятий на '
}
.hp__calendar.hp__calendar_events .react-calendar__navigation__label:hover {
  background: transparent;
}
.hp__calendar.hp__calendar_events .react-calendar__navigation__label:focus {
  background: transparent;
}

.sf__sticky.sf__sticky_calendar-events {
  top: -7px;
}
