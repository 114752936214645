.fixate-client {
  margin: 0 auto;
  max-width: 660px;
  width: 100%;

  @include break-max($breakpoint-xs) {
    margin: 0 10px;
    width: calc(100% - 20px);

    .modal-form__submit {
      width: 100%;
    }
  }
}

.fixate {
  h1 {
    padding-top: 55px;
  }


  .new-client,
  .sell__switcher {
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  div.switcher__btn {
    align-items: center;
    height: 50px;
    display: flex;

    @include break-max($breakpoint-xs) {
      width: calc(50% - 5px);
    }
  }

  .modal-form__row {
    padding-left: 25px;
    padding-right: 25px;

    @include break-max($breakpoint-xs) {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  .add-client {
    margin-top: 15px;
  }

  .add-files {
    background: $color-brand-opaque-8;
    border-radius: 5px;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 0;
    padding: 40px 55px;

    @include break-max($breakpoint-xs) {
      border-radius: 0;
      margin-right: 0;
      margin-left: -10px;
      padding: 30px 10px;
      width: calc(100% + 20px);
    }
  }

  .input_type_upload-btn {
    width: 100%;
  }

  .modal-form__files {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }

  .file-up {
    flex-basis: calc(50% - 15px);
  }

  .file-uploader { //TO DO вырезать file-up но для этого везде надо поправить файлы
    flex-basis: calc(50% - 15px);
  }
  .modal-form__row + .modal-form__row {
    margin-top: 25px;
  }

  .modal-form__new-client {
    margin-bottom: 5px;
    padding-bottom: 50px;
  }

  .modal-form__row.for-client {
    margin-bottom: 40px;
  }

  .fixation-badges-container {
    margin-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;

    @include break-max($breakpoint-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.sell .container {
    position: relative;
    margin: 0 auto;
  }

  // &.sell {
  //   padding-bottom: 170px;
  // }
}

.fixate.redesignFixate {
  .fixate-client {
    max-width: 100%;
  }
  .modal-form__row {
    padding-left: 0;
    padding-right: 0;
  }
  .add-files {
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-form__gist {
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
  .sell__subtitle.h4 {
    text-align: left;
  }
}

.fixating-pluses {
  left: -230px;
  position: absolute;
  transition: all 0.5s ease-out;
  top: 165px;
  width: 225px;

  .show-all,
  .icon-wrap {
    display: none;
  }

  @include break-max($breakpoint-md) {
    left: -110px;
    width: 195px;
  }

  @include break-max($breakpoint-sm) {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;
    height: 210px;
    margin-left: -33px;
    margin-top: 50px;
    left: auto;
    top: auto;
    position: relative;
    padding: 30px 60px 70px;
    width: 726px;

    .fixating-pluses-title {
      margin-bottom: 20px;
      text-align: center;
    }

    .icon-wrap {
      align-items: center;
      background: rgb(244, 244, 247);
      border-radius: 50em;
      justify-content: center;
      display: flex;
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 25px;
      width: 25px;

      .icon {
        fill: $color-brand-3;
      }
    }

    .show-all {
      bottom: 32px;
      color: $color-brand-3;
      cursor: pointer;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .icon_arrow_up {
      align-self: center;
      fill: $color-brand-3;
      margin-left: 8px;
      transform: rotate(180deg);
      width: 12px;
    }

    &.show-pluses {
      height: auto;

      .icon_arrow_up {
        transform: rotate(0);
      }
    }
  }

  @include break-max($breakpoint-xs) {
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: 30px 20px 40px;
    width: 100%;
  }
}

.fixating-list {
  color: $color-brand-3;
  font-size: 14px;
  line-height: 21px;
  list-style-type: disc !important;

  @include break-max($breakpoint-xs) {
    padding-left: 20px;
  }

  li {
    margin-bottom: 30px;
  }
}

.mobile-filters__content .fixating-list {
  margin: 0 auto;
  width: 85%;
}

.fixating-pluses-title {
  color: $color-brand-2;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 40px;
}
