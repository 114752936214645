/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  60% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  60% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  60% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
}
/* /right bounce */

.assignations {
  &-attention {
    position: relative;

    &__btns {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 80px;
      right: 0;
      z-index: 10;


      @include break-max($breakpoint-sm) {
        display: none;
      }
    }

    &__btn {
      margin-bottom: 10px;

      .button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: $color-red;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 33px;
      height: 33px;

      margin-right: 15px;

      background: $color-red;
      border-radius: 50%;

      -webkit-animation: bounceRight 2s infinite ease-in-out;
      animation: bounceRight 2s infinite ease-in-out;

      .icon {
        width: 15px;
        margin-right: 0;
        stroke: $color-white;
      }

      &.no-animation {
        animation: none;
        margin-right: 8px;
      }
    }

    &_static {
      display: none;
      @include break-max($breakpoint-sm) {
        display: block;
        margin-top: 15px;
      }
      .assignations-attention__btns {
        display: none;
        position: static;
        flex-direction: row;
        justify-content: center;
        @include break-max($breakpoint-sm) {
          display: flex;
        }
      }
      .assignations-attention__btn {
        margin-bottom: 0;
        &:first-of-type {
          margin-right: 15px;
        }
      }
    }
  }

  &-top {
    padding-top: 25px;
    padding-bottom: 0px;

    &__title {
      text-align: center;
      margin-bottom: 50px;
    }

    &__slider {

    }
  }

  &-flats {
    position: relative;
    padding-top: 50px;

    @include break-max($break-xs) {
      background: $color-white;
      padding-top: 25px;

      & .map {
        top: 0;
      }

      & .public__map-button {
        left: 10px;
      }

      & .section__grid-col {
        margin: 0 0 25px;
      }

      & .flat-card__col {
        box-shadow: 0px 4px 20px $color-brand-opaque-10;
        border-radius: 5px;
      }

      & .flats__card,
      & .flats__card-image {
        padding: 0;
      }

      & .flats__card-image {
        display: flex;
        border-radius: 5px;
      }

      & .flats__card-info {
        padding: 16px 12px 21px 14px;
      }

      & .flats__card-price {
        font-size: 21px;
        line-height: 24px;
      }

      & .flats__card-features {
        padding-top: 5px;
      }

      & .card__company {
        margin-top: 15px;
      }

      & .flats__card-feature_title {
        order: 1;
      }

      & .flats__card-feature_value {
        margin-top: 5px;
        order: 2;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 10px;

      .button {
        box-shadow: none;
        &:hover, &:focus, &:active {
          background: none;
          color: $color-red;
          svg {
            fill: $color-red;
          }
        }
      }

      @include break-max($break-xs) {
        margin-bottom: 0;
      }
    }

    &__title {
      @include break-max($breakpoint-xs) {
        margin-bottom: 15px;
        padding: 0 10px;
      }

      &_flex {
        display: flex;
        align-items: baseline;
        @include break-max($breakpoint-xsm) {
          flex-direction: column;
        }
      }
    }

    &__tables {
      padding-bottom: 60px;
    }

    &__public-assignment {
      @include break-max($break-xs) {
        background: $color-brand-opaque-8;
        padding-top: 20px;

        & .flats__row  {
          border-radius: 0;
        }
      }
    }

    &__row {
      margin-bottom: 30px;

      @include break-max($breakpoint-md) {
        margin-left: -12px;
        margin-right: -12px;
      }
      @include break-max($breakpoint-sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .flats__row-table {

      & table {
        @include break-max($breakpoint-md) {
          padding: 0 12px 20px;
        }
        @include break-max($breakpoint-sm) {
          padding-left: 0;
          padding-right: 0;
        }


        // & th, td {
        //   padding-left: 6px;
        //   padding-right: 6px;
        // }
      }
    }

    &__table {

      &-id {
        padding-left: 15px !important;
      }

      & > tbody > tr.child ul.dtr-details span.dtr-title {
        min-width: auto;
        white-space: nowrap;
      }

      & .dtr-data {

        .surface {
          width: auto;
          height: auto;

          &__wrap {
            white-space: normal;
            line-height: 17px;
          }
        }
      }
    }

    &__reglament {
      display: flex;
      align-items: center;
      margin-left: 25px;

      @include break-max($breakpoint-xsm) {
        margin-left: 0;
        margin-top: 10px;
      }

      .link {
        color: $color-brand-3;
        margin-left: 8px;
      }
    }
  }
}

#filter-results {
  position: absolute;
  opacity: 0;
  z-index: -1;
  top: -150px;
}

.surface {
  position: relative;
  max-width: 60px;
  min-width: 100%;
  height: 25px;

  &__wrap {
    width: 100%;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      position: absolute;
      top: 0;
      left: -10px;
      width: auto;
      overflow: visible;
      background-color: $color-white;
      box-shadow: 0 0 15px rgba(44, 46, 62, 0.1);
      border-radius: 4px;
      z-index: 10;
      padding: 0 10px;
    }
  }

  // @include break-max($breakpoint-sm) {
  //   width: auto;
  //   height: auto;
  //   max-width: initial;
  //   min-width: initial;

  //   &__wrap {
  //     white-space: normal;
  //     line-height: 17px;
  //   }
  // }
}

#assignations-slider div.slider__item:hover img {
  @include break-max($break-xs) {
    transform: none;
  }
}


.assignations-flats__public-assignment.assignations-flats__tables {
  @include break-max($breakpoint-xs) {
    padding: 10px;
    padding-bottom: 55px;
  }
}

.assignations-flats_public {
  padding-top: 0;
  @include break-max($breakpoint-xs) {
    padding-bottom: 40px;
  }
}