.order-history {

  &__body {
    width: 100%;
    background-color: $color-brand-2;
    border-radius: 5px;
    padding: 60px 125px;
    color: $color-white;

    @include break-max($breakpoint-sm) {
      margin-left: -20px;
      margin-right: -20px;
      padding: 35px 20px;
      box-sizing: content-box;
      border-radius: 0;
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &_mobile {
    position: relative;

    @include break-min($breakpoint-sm) {
      display: none;
    }

    .order-history__step {
      &:last-child {
        width: 219px;
        flex-grow: 0;
      }
    }
  }

  &_pc {
    @include break-max($breakpoint-sm) {
      display: none;
    }

    .order-history__advanced {

      &-hint {
        width: 455px;
        right: 121px;
        top: -45px;

        &__bracket {
          height: 20px;
          margin-left: 7px;
        }

        @include break-max($breakpoint-md) {
          width: 348px;
        }

        @include break-max(1330px) {
          width: 342px;
        }
      }
    }
  }

  &__advanced {

    &-hint {
      position: absolute;
      width: calc(100% - 195px);
      top: -35px;

      &__title {
        font-size: 14px;
        line-height: 18px;
        color: $color-brand-opaque-50;
        text-align: center;
      }

      &__bracket {
        position: relative;
        width: calc(100% - 14px);
        height: 18px;
        margin-left: 3px;
        margin-top: 10px;
        border: 1px solid $color-brand-opaque-50;
        border-bottom: none;
        border-radius: 15px 15px 0 0;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -5px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-color: $color-brand-2;
          width: 10px;
          height: 10px;
          border-top: 1px solid $color-brand-opaque-50;
          border-right: 1px solid $color-brand-opaque-50;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__steps {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.second {
      margin-top: 50px;
    }

    &-line {
      position: absolute;
      height: 1px;
      top: 51px;
      background-color: $color-brand-3;
      width: calc(100% - 133px);

      @include break-max($breakpoint-sm) {
        width: calc(100% - 108px);

        &.advanced {
          width: calc(100% - 198px);
        }
      }

      &_bracket {
        position: absolute;
        top: 51px;
        right: 0;
        width: 198px;
        height: 193px;
        border: 1px solid $color-brand-3;
        border-left: none;
        border-radius: 0 54px 54px 0;

        &.active {
          border-color: $color-red;
        }

        @include break-min($breakpoint-sm) {
          display: none;
        }
      }
    }
  }

  &__step + &__step {
    margin-left: 40px;
  }

  &__step {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 140px;
    flex-grow: 1;
    padding-top: 75px;

    &.short, &:last-child {
      width: 140px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include break-max($breakpoint-sm) {
      &:last-child {
        flex-grow: 1;
      }
      &.short {
        width: 115px;
        flex-grow: 0;
      }
    }

    &-date {
      position: absolute;
      top: 10px;
      left: 0;

      @include break-max($breakpoint-sm) {
        top: 15px;
      }
    }

    &-point {
      position: absolute;
      top: 38px;
      left: -7px;
      width: 14px;
      height: 14px;
      border: 7px solid $color-brand-2;
      border-radius: 50%;
      background-color: $color-brand-3;
      box-sizing: content-box;

      &.active {
        background-color: $color-red;
      }

      .icon_flag {
        position: absolute;
        left: 50%;
        top: -32px;
        transform: translate(calc(-100% + 1px));

        @include break-max($breakpoint-sm) {
          top: -25px;
        }
      }
    }

    &-name {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }

    &-comments {
      display: flex;
      align-items: center;
      width: 90px;
      padding: 7px 14px;
      background-color: $color-brand-opaque-30;
      border-radius: 15px;
      font-size: 12px;
      cursor: pointer;
      transition: background-color $transition-default;

      &:hover,
      &.active {
        background-color: $color-red;
      }

      &.active {
        .icon_arrow-bottom {
          transform: rotate(180deg);
        }
      }

      .icon_comment {
        margin-right: 8px;
      }

      .icon_arrow-bottom {
        margin-left: auto;
        transition: transform $transition-default;
      }
    }
  }
}
