@import '../../../styles/variables';
@import '../../../styles/mixins';

.tabs-container {
  max-width: 728px;
}

.contact-card {
  border: 1px solid #DFE1E9;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;

  &__img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  &--apt-modal {
    padding: 0;
    margin-bottom: 20px;
    border: none;
  }
}

.contacts-card-user {
  margin-left: 20px;
  width: calc(100% - 90px);

  &__name {
    margin: 0;
    padding: 0;
    @include h6();
  }

  &__post {
    margin-top: 6px;
    font-size: 14px;
    line-height: 18px;
    color: $gray-dark-2;
  }
}

.contacts-card-user-info {
  margin-top: 12px;
  display: flex;
  align-items: center;

  &__phone {
    font-size: 14px;
    line-height: 18px;
    color: $color-brand-2;

    a {
      color: $color-brand-2;
    }
  }

  svg {
    margin-left: 20px;

    path {
      transition-duration: .3s;

      &:hover {
        fill: #ECEDF0;
        transition-duration: .3s;
      }
    }
  }
}
