@import "../../styles/variables";
@import "../../styles/mixins";

.payments-schedule {
  max-width: 728px;
  margin: 40px auto;
}

.payments-schedule-info {
  background-color: $gray-light;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  justify-content: space-between;

  &__label {
    margin-bottom: 6px;
  }

  &__value {
    color: $gray-dark-2;
  }
}

.payments-schedule-table {

  &__header {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    font-size: 16px;
    font-weight: bold;
  }

  &__header-dwn {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }
}

.payments-schedule-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #f5f5f5;
}


.payments-schedule-table .year-row td:first-child {
  font-weight: bold;
}

.payments-schedule-table tr.sum-row td:first-child {
  font-weight: bold;
}









