@import "../../../styles/variables";
@import "../../../styles/mixins";

.collapse {

}

.collapse__title {
  align-items: center;
  border-top: 1px solid $color-brand-opaque-40;
  display: flex;
  justify-content: space-between;

  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  height: 60px;
  padding: 0px 10px;

  &-text {
    align-items: center;
    display: flex;
  }

  &.is-gray {
    background: $color-brand-opaque-10;
    border-radius: 4px;
  }
}

.collapse__sign {
  font-size: 14px;
}

.collapse .icon_plus {
  width: 10px;
}

.collapse__content {
  padding: 27px 0 31px;
}

.collapse__minus {
  border-top: 1px solid $color-brand-2;
  height: 1px;
  width: 8px;
}

.icon_arrow_bottom {
  margin-left: 5px;
  stroke: $color-brand-3;
  width: 10px;

  &.is-open {
    transform: rotate(180deg);
    transition: 0.3 all ease;
  }
}

.collapse.is-small {
  .collapse__title-text {
    color: $color-brand-3;
    font-weight: normal;
  }

  .collapse__title {
    height: 28px;
  }
}

.collapse_with-chevron {
  border-top: none;
  cursor: pointer;
  .collapse__title {
    border: none;
    align-items: baseline;
    height: auto;
    padding-right: 0;
  }

}

.collapse__chevron {
  display: flex;

  svg {
    stroke: $color-brand-1;
    fill: transparent;
    font-size: 10px;
    margin-left: 5px;
    transform: rotate(-90deg);
    transition: transform 0.15s ease-in-out;
  }

  &_opened svg {
    transform: rotate(0);
  }
}

