@import "../../styles/variables";
@import "../../styles/mixins";

.preview .policy {
  margin-top: 85px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }

}

.policy {
  padding-top: 80px;
  padding-bottom: 100px;
  color: #1e2030;
  background: #fff;
  @include break-max($breakpoint-md) {
    padding-top: 55px;
    padding-bottom: 60px;
  }
  @include break-max($breakpoint-sm) {

    padding-top: 50px;
    padding-bottom: 115px;

  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    padding-top: 25px;
  }

  .promo {
    width: 970px;
    max-width: 100%;
    margin-bottom: 105px;

    @include break-max($breakpoint-md) {
      width: 860px;
    }
    @include break-max($breakpoint-sm) {
      width: 670px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .promo__title {
    margin-bottom: 35px;
    @include break-max($breakpoint-md) {
      margin-bottom: 30px;
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
    }
  }

  .promo__date {
    margin-bottom: 30px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;

    @include break-max($breakpoint-md) {
      margin-bottom: 25px;
    }
    @include break-max($breakpoint-sm) {

    }
    @include break-max($breakpoint-xs) {
      margin-bottom: 10px;
    }
  }

  .promo__text {
    font-size: 18px;
    line-height: 26px;
  }

  .block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 85px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include break-max($breakpoint-md) {
      margin-bottom: 60px;
    }
    @include break-max($breakpoint-sm) {
      flex-direction: column;
      align-items: center;
    }

    img {
      margin-left: auto;
      @include break-max($breakpoint-sm) {
        margin-right: auto;
      }
      @include break-max($breakpoint-xs) {
        max-width: 50%;
      }
    }

  }

  .block__box {
    width: 845px;
    max-width: 100%;
    padding: 50px 65px;
    margin-left: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

    @include break-max($breakpoint-md) {
      width: 755px;
      padding: 40px 45px;
    }
    @include break-max($breakpoint-sm) {
      margin-right: auto;
    }
    @include break-max($breakpoint-xs) {
      box-shadow: none;
      padding: 0;
    }
  }

  .block__title {
    margin-bottom: 25px;

    @include break-max($breakpoint-xs) {
      margin-top: 20px;
      margin-bottom: 14px;
    }
  }

  .block__container {
    position: relative;
    padding-top: 5px;
    padding-left: 65px;

    p {
      margin-bottom: 20px;
    }

    &:last-of-type p:last-of-type {
      margin-bottom: 0;
    }

    ul {
      list-style: none;
    }

    li {
      margin-bottom: 20px;
    }
  }

  .block__counter {
    position: absolute;
    top: 0;
    left: 0;

    display: inline-flex;
    padding: 0 14px;
    line-height: 30px;
    border-radius: 100px;
    background: $color-brand-opaque-20;
  }

  .update-date {
    margin-top: 35px;
  }

}
