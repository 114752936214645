.advantages {

}

.advantages__list {
  display: block;
  // display: flex;
  // justify-content: center;
}

.advantages__item {
  margin-right: 55px;
  &:last-child {
    margin-right: 0;
  }

  @include break-max($breakpoint-md) {
    margin-right: 25px;
  }
}

.constructor__slider .tns-carousel .advantages__item.tns-item{
  // display: flex;
  // flex-grow: 1;
  display: block;
}


// временно

  @include break-max($breakpoint-sm) {
    .advantages__item:nth-child(3) {
      margin-right: 0;
    }
    .advantages__item:nth-child(n+4) {
      display: none;
    }
}

@include break-max($breakpoint-xs) {
  .advantages__item:nth-child(1) {
    margin-right: 0;
  }
  .advantages__item:nth-child(n+2) {
    display: none;
  }
}
