.custom-table {
  $c: &;
  .nowrap {
    white-space: nowrap;
  }

  &__details {
    color: $color-brand-3;
  }

  &__head {
    background-color: $color-brand-2;
    border-radius: 4px;

    .custom-table__tr {
      background-color: transparent;
      padding: 5px 10px;

      @include break-max($break-xs) {
        padding: 0;
      }
    }

    &.thead_fixed {
      position: fixed;
      top: 79px;
      left: 0;
      width: 100%;
      z-index: 10;
      border-radius: 0;
      background-color: $color-white;
      box-shadow: 0px 4px 20px $color-brand-opaque-10;

      .custom-table__tr {
        @include wrapper;
        padding-left: calc(#{$fields} + 10px);
        padding-right: calc(#{$fields} + 10px);

        @include break-max($breakpoint-md) {
          padding-left: calc(#{$fields-md} + 10px);
          padding-right: calc(#{$fields-md} + 10px);
        }

        // Отступ контейнера для фиксированной панели справа
        @include break-max(1330px) {
          padding-left: calc(40px + 10px);
          padding-right: calc(56px + 10px);
        }

        @include break-max($breakpoint-sm) {
          padding-left: calc(#{$fields-sm} + 10px);
          padding-right: calc(#{$fields-sm} + 10px);
        }
      }

      .custom-table__th {
        color: $color-brand-2;
      }
    }
  }

  &.striped {
    #{$c}__item {
      &:nth-child(even) {
        .custom-table__tr {
          background-color: $color-brand-opaque-8;
        }
      }
    }
  }

  &__item {
    padding: 0 10px;

    @include break-max($break-xs) {
      padding: 0;
    }
  }

  &__tr {
    display: flex;
    padding: 5px 0;
    border-radius: 4px;

    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
    }

    @include break-max($break-xs) {
      flex-wrap: nowrap;
    }

    &.is-active {
      .button_view_round-control {
        transform: rotate(180deg);
      }
    }

    &:first-child .custom-table__td {
      @include break-max($break-xs) {
        padding-left: 0px;

        &.custom-table__name {
          padding-left: 21px;
        }
      }
    }

    &-expanding {
      align-items: center;
      padding: 10px 5px;

      &-expanded {
        height: auto;
      }
    }
  }

  &__card {
    background-color: $color-white;
    border-radius: 4px;
    transition: all $transition-default;
    margin-bottom: 20px;
    padding-bottom: 20px;
    box-shadow: 0 2px 20px $color-brand-opaque-12;

    #{$c}__td {
      align-items: flex-start;
    }
  }

  &__th {
    display: flex;
    align-items: center;
    color: $color-white;
    padding: 12.5px 10px;
  }

  &__td {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 10px;

    @include break-max($break-xs) {
      padding-top: 0;
      padding-bottom: 10px;
      padding-right: 0;

      &:first-child {
        padding-left: 50px;
      }
    }

    &:first-child {
      font-weight: bold;

      &.custom-table__details {
        font-weight: 400;
      }
    }

    & .control {
      margin-left: auto;
      transition: all $transition-default;

      & > .button_view_round-control {
        .icon {
          font-size: 10px;
          stroke: $color-brand-2;
        }
      }
    }
  }

  &__dropdown {
    display: none;

    @include break-min($breakpoint-sm) {
      display: none !important;
    }

    &-body {
      display: flex;
      flex-basis: 100%;
      padding: 40px 0;
      border-bottom: 1px solid $color-brand-opaque-20;
      margin-bottom: 15px;
    }
  }

  &__info {
    flex: 1 0 40%;
    padding: 0 70px 0 20px;
    border-right: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-sm) {
      padding-right: 20px;
    }

    @include break-max($break-xs) {
      border-right: none;
    }

    .text {
      font-size: 14px;
      line-height: 21px;
      margin: 15px 0;

      @include break-max($break-xs) {
        height: 230px;
        overflow: hidden;

        &.text-is-visible {
          height: auto;
          overflow: visible;
        }
      }
    }
  }

  &__show-text {
    color: $color-brand-3;

    & .icon {
      cursor: pointer;
      margin-left: 5px;
      stroke: $color-brand-3;
      width: 10px;

      &.icon-reverse {
        transform: rotate(180deg);
        transition: 0.2s;
      }
    }
  }

  &__requires {
    flex: 1 0 40%;
    padding-left: 50px;
    padding-right: 90px;

    @include break-max($breakpoint-sm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include break-max($break-xs) {
      border-top: 1px solid $color-brand-opaque-20;
      margin-top: 20px;
      padding-left: 0;
      padding-right: 30px;
    }

    @include break-max($break-xs) {
      padding-left: 50px;
      padding-right: 30px;
    }

    &-row {
      display: flex;
      margin-top: 30px;

      @include break-max($break-xs) {
        margin-top: 15px;
      }

      @include break-max($break-xxs) {
        & .list__item-value {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }

    &-col {
      flex: 1 1 100%;
    }
  }
}
