@import "../../styles/variables";
@import "../../styles/mixins";

.add-partner {
  .h1 {
    padding: 64px 0;

    @include break-max($breakpoint-xs) {
      padding: 32px 0;
    }
  }

  .preview__footer-policy:hover {
    color: inherit;
    opacity: 0.8;
  }

  .checkbox {
    color: $color-brand-3;
    flex-basis: 100%;
    font-size: 13px;
    line-height: 16px;
  }

  .checkbox__box {
    background: $color-white;
  }

  .preview__footer-policy {
    text-decoration: underline;
  }

  .button_view_default {
    border-radius: 5px;
    height: 60px;

    @include break-max($breakpoint-xs) {
      height: 50px;
    }
  }
}

.add-partner-form {
  background: $color-brand-opaque-8;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  padding: 64px 130px;

  @include break-max($breakpoint-sm) {
    padding: 32px 65px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    padding: 24px 30px;
  }
}

.add-partner-form__item {
  flex-basis: calc(50% - 15px);
  margin-bottom: 24px;

  &:nth-child(2n) {
    margin-left: 30px;

    @include break-max($breakpoint-xs) {
      margin-left: 0;
    }
  }

  .custom-select__selected {
    background: $color-white;
    border: 1px solid $color-brand-opaque-20;
    height: 50px;
  }

  &.full-width {
    flex-basis: 100%;
  }
}

.add-partner-form__submit {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 40px;
}

.add-partner-form__label {
  align-items: center;
  display: flex;
  color: $color-brand-3;
  line-height: 25px;
  margin-bottom: 4px;
  white-space: nowrap;
}
