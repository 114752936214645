@import "../../styles/variables";
@import "../../styles/mixins";

.preview .future.sale-teacher {
  margin-top: 85px;
  color: $color-brand-1;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }
}

.future.sale-teacher {
  .big-text {
    @include break-max($breakpoint-xsm) {
      font-size: 17px;
      line-height: 22px;
    }
  }
  .wrapper__center_start {
    @include break-max($breakpoint-md) {
     width: 998px;
    }

    @include break-max($breakpoint-xs) {
      width: 325px;
    }
  }
  .promo {
    padding-top: 0;
    position: relative;
    z-index: 1;
  }

   .promo__box {
     width: 690px;
     @include break-max($breakpoint-md) {
      width: 445px;
     }
     @include break-max($breakpoint-xsm) {
      width: 100%;
     }
   }

   .promo__box-title {
     width: 370px;
     max-width: 100%;
     margin-bottom: 25px;
     @include break-max($breakpoint-xsm) {
      width: 515px;
      margin-left: auto;
      margin-right: auto;
     }
   }

   .promo__box-text {
     width: 475px;
     max-width: 100%;
     @include break-max($breakpoint-md) {
      width: 315px;
      margin-bottom: 35px;
     }
     @include break-max($breakpoint-xsm) {
      width: 515px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 395px;
     }

     @include break-max($breakpoint-xs) {
      margin-bottom: 205px;
     }
   }

  .promo__container {
    height: calc(100vh - 134px);
    @include break-max($breakpoint-md) {
      align-items: flex-start;
      padding-top: 50px;
      padding-bottom: 60px;
      height: auto;
      max-height: none;
    }
    @include break-max($breakpoint-xs) {
     padding-bottom: 45px;
    }
  }

  .promo__advantages {
    display: flex;
    justify-content: space-between;
    margin-bottom: 58px;
    @include break-max($breakpoint-md) {
     justify-content: flex-start;
     flex-wrap: wrap;
     gap: 9px;
     margin-bottom: 25px;
    }
    @include break-max($breakpoint-xsm) {
      justify-content: space-between;
      gap: 0;
      max-width: 730px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .promo__advantages-item {
    width: 218px;
    padding-top: 25px;
    padding-left: 55px;
    padding-right: 15px;
    padding-bottom: 21px;
    border: 1px solid $color-brand-opaque-60;
    border-radius: 4px;

    background: rgba($color-brand-1, 0.6) url('../../assets/images/saleTeacher/pin.svg') no-repeat 25px 25px;
    &:nth-child(2) {
      padding-left: 65px;
      background-image: url('../../assets/images/saleTeacher/clock.svg');
    }
    &:nth-child(3) {
      width: 232px;
      padding-left: 65px;
      background-image: url('../../assets/images/saleTeacher/man.svg');
    }

    &:nth-child(n) {
      @include break-max($breakpoint-md) {
        width: 218px;
        height: 96px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-position-x: 15px;
      }

      @include break-max($breakpoint-xsm) {
        align-items: flex-start;
        padding-left: 55px;
      }

      @include break-max($breakpoint-xs) {
       width: 100%;
       margin-bottom: 10px;
       height: 50px;
       padding-left: 80px;
       background-position-x: 45px;
       background-position-y: 8px;
      }
    }

    &:last-of-type {
      @include break-max($breakpoint-xs) {
        margin-bottom: 0;
        height: 64px;
      }
    }
  }

  .promo__image {
    right: 4%;
    @include break-max($breakpoint-md) {
      max-width: 100vh;
    }
    @include break-max($breakpoint-xsm) {
     width: 518px;
     max-width: 100%;
     right: 0;
     top: 190px;
    }

    @include break-max($breakpoint-xs) {
      width: 245px;
      top: 210px;
      z-index: 3;
    }
  }

  .promo__advantages-title {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    @include break-max($breakpoint-md) {
      font-size: 16px;
      line-height: 20px;
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 2px;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .promo__advantages-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: $color-brand-3;
    @include break-max($breakpoint-xsm) {
      text-align: left;
    }
    @include break-max($breakpoint-xs) {
      font-size: 11px;
      line-height: 16px;
    }
  }

  .promo__btn {
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .future__h2 {
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 58px;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 76px;
      height: 4px;
      background: $color-red;
    }

    @include break-max($breakpoint-xs) {
      width: 280px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 45px;
    }
  }

  .about__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 45px;
    @include break-max($breakpoint-md) {
     gap: 34px;
    }
    @include break-max($breakpoint-xsm) {
     gap: 0;
    }
  }

  .about__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    max-width: 100%;
    min-height: 295px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid $color-brand-opaque-20;
    padding: 40px 68px 0;
    background: $color-white;

    &_pad_55 {
      padding: 40px 55px 0;
    }

    &_pad_45 {
      padding: 40px 45px 0;
    }

    &_pad_35 {
      padding: 40px 35px 0;
    }

    @include break-max($breakpoint-md) {
      width: 310px;
      min-height: 260px;
      padding: 40px 25px 0;
    }

    &_last {
      padding: 20px 15px 0;
    }

    @include break-max($breakpoint-xsm) {
      margin-bottom: 34px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .about__image {
    margin-bottom: 20px;
    &_mb_5 {
      margin-bottom: 5px;
    }
  }

  .reviews__item {
    display: flex;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }

  }

  .reviews__author {
    width: 125px;
    flex-shrink: 0;
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .reviews__author-name {
    margin-bottom: 10px;
  }

  .reviews__author-agency {
    color: $color-brand-3;
  }

  .reviews__text {
    margin-left: 70px;
    font-size: 18px;
    line-height: 24px;

    position: relative;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 15px;

    @include break-max($breakpoint-xsm) {
      margin-left: 40px;
      font-size: 17px;
      line-height: 22px;
    }

    p {
      position: relative;
      z-index: 3;
      padding: 30px 70px;
      background: $color-white;
      border-radius: 15px;
    }

    &::before, &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;

    }
    &::before {
      border-width: 0 62px 62px 0;
      border-color: transparent $color-white transparent transparent;
      top: 41px;
      left: -16px;
      z-index: 2;
    }
    &::after {
      border-width: 0 64px 64px 0;
      border-color: transparent $color-brand-opaque-20 transparent transparent;
      top: 40px;
      left: -18px;
    }

    @include break-max($breakpoint-xs) {
      margin-left: 0;

      p {
        padding: 30px;
      }

      &::before {
        top: -4px;
        left: 3px;
        transform: rotate(45deg);
      }

      &::after {
        top: -8px;
        left: 0;
        transform: rotate(45deg);
      }
    }
  }

  .application {
    background: $color-brand-2;
    color: $color-white;
    border-bottom: 1px solid $color-brand-opaque-40;
  }

  .application__form {
    width: 470px;
    max-width: 100%;
    margin: 0 auto;
  }

  .application__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;

    @include break-max($breakpoint-xs) {
      font-size: 17px;
      line-height: 22px;
    }
  }

  .application__submit {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 35px;

    @include break-max($breakpoint-xs) {
      margin-top: 8px;
      margin-bottom: 25px;
    }

    .button {
      @include break-max($breakpoint-xs) {
        width: 100%;
      }
    }
  }

  .input__control {
    color: $color-brand-3;
    background: $color-brand-2;
    box-shadow: none;
    border: 1px solid $color-brand-opaque-40;
  }

  .future__h2_mb_sm {
    margin-bottom: 38px;
  }

  .about {
    position: relative;
    padding-top: 20px;
    &::before {
      content: '';
      position: absolute;
      top: -52%;
      width: 100%;
      height: 693px;
      background: url('../../assets/images/saleTeacher/bg1.svg') no-repeat;
      background-size: cover;
      @include break-max($breakpoint-xsm) {
        top: -27%;
      }
    }
  }

  .about_second {
    z-index: 2;
    &::before {
      background-image: url('../../assets/images/saleTeacher/bg2.svg');
      top: -5%;
      height: 1640px;
    }
  }

  .reviews {
    padding-bottom: 80px;
    position: relative;
    background: $color-white;
    z-index: 3;
    @include break-max($breakpoint-xs) {
      padding-bottom: 55px;
    }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: -52%;
    //   width: 100%;
    //   height: 1230px;
    //   background: url('../../assets/images/saleTeacher/bg3.svg') no-repeat;
    //   background-size: cover;
    // }
  }

}
