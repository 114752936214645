.banks {

  .section__banks {
    margin-top: 0;
    width: 100%;
  }

  .wrapper {
    max-width: 1220px;
    padding: 0;
  }

  .banks__slider-value {
    color: $color-1;
  }

  .complex__slider-info {
    bottom: -70px;
  }
}
