.lk-order {
  padding: 30px 0 135px;

  @include break-max($breakpoint-md) {
    padding: 30px 0 105px;
  }

  @include break-max($breakpoint-sm) {
    padding: 30px 0 60px;
  }

  &__header {
    margin-bottom: 50px;
  }

  &__label {
    font-size: 14px;
    line-height: 21px;
    color: $color-brand-3;
    margin-bottom: 20px;

    @include break-max($breakpoint-sm) {
      margin-top: 5px;
    }
  }

  &__details {
    margin-bottom: 55px;

    &-row + &-row {
      margin-top: 60px;

      @include break-max($breakpoint-md) {
        margin-top: 40px;
      }

      @include break-max($breakpoint-sm) {
        margin-top: 20px;
      }
    }

    &-col {
      @include grid-size(2);

      @include break-max($breakpoint-md) {
        @include grid-size(2, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(3, $grid-gutter-sm);
      }

      &:first-child {
        display: flex;
        @include grid-size(4);

        @include break-max($breakpoint-sm) {
          @include grid-size(3.5, $grid-gutter-sm);
        }
      }

      &__icon {
        margin-right: 35px;

        @include break-max($breakpoint-sm) {
          margin-right: 20px;
        }
      }
    }

    &-prices {
      @include grid-size(4);

      @include break-max($breakpoint-md) {
        @include grid-size(4, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(2.5, $grid-gutter-sm);
      }

      &__item {
        flex-grow: 1;

        @include break-max($breakpoint-sm) {
          width: 100%;
        }
      }

      &_light.h3 {
        font-weight: 300;
      }
    }

    &-form {
      @include grid-size(6);

      @include break-max($breakpoint-md) {
        @include grid-size(6, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(5.5, $grid-gutter-sm);
      }
    }

    &-client_contacts {
      .lk-order__contacts-link {
        margin-right: 30px;
      }
    }

    &-docs {
      margin-top: 30px;

      &__item {
        display: flex;

        &-icon {
          margin-right: 10px;
        }
        &-name {
          font-weight: bold;
        }
        &-delete {
          cursor: pointer;
          transition: color $transition-default;

          &:hover {
            color: $color-red;
          }
        }
      }
    }
  }

  &__footer {
    &-body {
      padding: 80px 125px 0;

      @include break-max($breakpoint-sm) {
        padding: 35px 0 0;
        box-sizing: content-box;
        border-radius: 0;
      }
    }
  }

  &__agent {
    &-title {
      margin-bottom: 20px;
    }

    &-card {
      display: flex;
      padding: 40px 45px;
      border: 1px solid $color-brand-opaque-20;
      border-radius: 5px;

      &__contacts {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 60px;

        @include break-max($breakpoint-sm) {
          padding: 0;
          flex-grow: 0;
          margin-left: auto;

          .lk-order__contacts-link + .lk-order__contacts-link {
            margin-left: 30px;
          }
        }
      }
    }
  }

  &__managers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;

    @include break-max($breakpoint-md) {
      margin-top: 50px;
    }
  }

  &__manager {
    width: calc(50% - 10px);

    &:nth-child(n + 3) {
      margin-top: 40px;
    }

    &:nth-child(even) {
      margin-left: 20px;
    }

    &-title {
      margin-bottom: 25px;
    }

    &-card {
      display: flex;
      padding: 27px 32px;
      border: 1px solid $color-brand-opaque-20;
      border-radius: 5px;

      &__photo {
        margin-right: 30px;
      }

      @include break-max($breakpoint-sm) {
        padding: 20px;

        &__photo {
          margin-right: 20px;
        }
      }
    }

    &-contacts {

      .lk-order__contacts-link {
        margin-right: 60px;

        @include break-max($breakpoint-md) {
          margin-right: 20px;
        }
        @include break-max($breakpoint-sm) {
          margin-right: 20px;
        }
      }
    }
  }

  &__contacts-link {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: $color-brand-2;
    text-decoration: none;
    transition: color $transition-default;

    &:hover {
      color: $color-red;
    }
  }
}

.requests-complex {
  @include break-max($break-xs) {
    white-space: normal;
  }

  @include break-max($break-xxs - 1px) {
    max-width: 100px;
  }
}

.requests-manager {
  @include break-max($break-xs) {
    white-space: normal;
  }
}
