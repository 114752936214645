@import '../../styles/variables';
@import '../../styles/mixins';

.cookies-up {
  position: fixed;
  z-index: 123;
  padding: 25px 0;
  width: 100%;
  bottom: -100px;
  background-color: #e2f0f7;
  transition: bottom 0.5s ease;

  &__content {
    display: flex;
    justify-content: space-between;
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__text {
    padding-right: 20px;
  }

  &__text-top {
    display: flex;
    margin-bottom: 8px;

    svg {
      flex-shrink: 0;
      margin-right: 10px;
    }

    p {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__text-bottom {
    margin-left: 34px;

    span {
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
.cookies-up.visible {
  bottom: 0;
}

.cookies-up.hidden {
  bottom: -150px;

  @include break-max($breakpoint-xs) {
    bottom: -270px;
  }
}

.cookies-up__content_mobile {
  flex-direction: column;

  button.btn_primary {
    margin-top: 10px;
    width: auto;
    max-width: 220px;
    margin-left: 35px;
  }
}
