@import "../../styles/variables";
@import "../../styles/mixins";


.access-denied {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 4%;
  margin-bottom: 4%;
  padding-bottom: 90px;
  background: url('../../assets/images/access-denied/man.svg') no-repeat bottom right;
  @include break-max($breakpoint-md) {
    background-size: 330px;
  }
  @include break-max($breakpoint-sm) {
    background: none;
    flex-direction: column;
  }
  @include break-max($breakpoint-xs) {
    padding-bottom: 40px;
  }

  .wrapper__center {
    position: relative;
  }
}

.access-denied__box {
  position: relative;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: $color-white;

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.access-denied__h2 {
  width: 90%;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  @include break-max($breakpoint-sm) {
    width: 100%;
    margin-bottom: 25px;
  }

}

.access-denied__text {
  color: $color-brand-3;
  margin-bottom: 45px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 5px;
  }
}

.access-denied__list {
  display: flex;
  margin-bottom: 45px;
  border-radius: 20px;
  border: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    border-radius: 0;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border: 0;
    text-align: center;
  }
}

.access-denied__item {
  padding: 15px 0;
  flex: 1 1;
}

.access-denied__item p {
  position: relative;
  padding: 45px 38px 35px;
  border-right: 1px solid $color-brand-opaque-20;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: left;

  &::before {
    position: absolute;
    top: 17px;
    left: 38px;
    content: '';
    width: 38px;
    height: 4px;
    background: $color-red;
  }

  @include break-max($breakpoint-sm) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 35px;
    padding-bottom: 0;
    border-right: 0;
    text-align: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
      &::before {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
  }
}

.access-denied__item:last-child p {
  border: 0;
  @include break-max($breakpoint-xs) {
    border-bottom: 1px solid $color-brand-opaque-20;
    padding-bottom: 35px;
  }
}

.access-denied__btn {
  display: inline-flex;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.access-denied__text-info {
  margin-bottom: 55px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  @include break-max($breakpoint-xs) {
    width: 90%;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
  }
  span {
    color: $color-red;
  }
}

.access-denied__dark-block {
  position: relative;
  padding: 50px 120px;
  background: $color-brand-1;
  color: $color-white;
  border-radius: 15px;

  .big-text {
    margin-bottom: 35px;
    font-weight: 300;
    text-align: center;
    @include break-max($breakpoint-sm) {
      margin-bottom: 25px;
    }
  }

  @include break-max($breakpoint-sm) {
    padding: 50px 40px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    padding: 30px;
  }

}

.access-denied__book {
  position: absolute;
  bottom: -70px;
  right: -150px;
  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.access-denied__absolute-container  {
  position: relative;
}

.access-denied__absolute-btn {
  display: flex;
  align-items: center;
  text-align: center;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  @include break-max($breakpoint-sm) {
    position: static;
    margin: 0 auto 15px;
    width: fit-content;
  }

  @include break-max($breakpoint-xs) {
    width: 90%;
  }
}
