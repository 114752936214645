@import "../../styles/variables";
@import "../../styles/mixins";

.preview .school.lawyers {
  margin-top: 85px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }
}



.school.lawyers {

  .block__footer p.h3 {
    //font-size: 23px;
  }

  color: $color-brand-1;

  .mobile-filters__title {
    cursor: pointer;
  }

  .whom {
    padding-bottom: 100px;
  }

  .wrapper__center_start {
    @include break-max($breakpoint-md) {
      width: 1010px;
    }
  }

  .promo {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 158px);
    &::after {
      display: none;
    }

    @include break-max($breakpoint-sm) {
      min-height: 540px;
    }

    @include break-max($breakpoint-xs) {
      padding-bottom: 80px;
      min-height: calc(100vh - 50px);
    }
  }

  .promo__container {
    padding-bottom: 10px;
    @include break-max($breakpoint-sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .promo__box {
    width: 470px;
    @include break-max($breakpoint-xs) {
      align-items: center;
    }
  }

  .promo__btn {
    width: 220px;
    justify-content: center;
    margin-top: 40px;

    @include break-max($breakpoint-xs) {
      position: absolute;
      margin-top: 0;
      bottom: -70px;
      width: calc(100% - 30px);
    }
  }

  .promo__image {
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
    }
  }

  .promo__text {
    @include break-max($breakpoint-xs) {
      text-align: center;
    }
  }

  .block {
    width: 355px;
    min-height: 430px;
    padding: 35px 35px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 30px;
    box-shadow: none;

    margin-right: 65px;
    margin-bottom: 65px;

    &:last-of-type:not(.block_others) {
      margin-right: 0;
    }

    @include break-max($breakpoint-md) {
      width: 300px;
      min-height: 380px;
      padding: 35px;
      margin-right: 45px;
      margin-bottom: 45px;
    }

    @include break-max($breakpoint-sm) {
      //width: 230px;
      min-height: 300px;
      padding: 25px;
      margin-right: 25px;
      margin-bottom: 25px;

      .block__text {
        display: none;
      }
    }

    @include break-max($breakpoint-xs) {
      width: 300px;
      min-height: 290px;
      margin-right: 0;

      .block__title {
        text-align: center;
        width: 100%;
        font-size: 18px;
        line-height: 22px;
      }

      &:last-of-type:not(.block_others) {
        margin-bottom: 25px;
      }



    }
  }
  .block.block_bg_blue {
    border: 1px solid transparent;
    @include break-max($breakpoint-xs) {
      background-color: transparent;
      border: 1px solid $color-brand-opaque-20;
    }
  }

  .block__footer {
    position: relative;
    width: 100%;
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid $color-brand-opaque-20;
  }

  .block__btn {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .block__overbtns {
    display: flex;
    align-items: center;
    position: absolute;
    top: 24px;
    right: 0;
    gap: 10px;

    .block__showmore {
      position: relative;
    }

    @include break-max($breakpoint-md) {
      top: 21px;
    }
  }
  .block__paybtn {
    width: 60px;
    background-color: transparent;

    svg {
      flex-shrink: 0;
      margin-right: 0;
    }

    &:hover {
      background-color: #ECEDF0;
    }
  }

  .block__showmore {
    display: none;
    position: absolute;
    right: 0;
    top: 40%;

    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $color-brand-opaque-20;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    color: $color-brand-3;
    font-size: 21px;
    line-height: 1;
    padding-bottom: 3px;

    @include break-max($breakpoint-xs) {
      display: inline-flex;
    }
  }

  .block__descr {
    margin-bottom: 2px;
  }

  .block__text {
    margin-bottom: 20px;
  }

  .whom__list {
    flex-wrap: wrap;
    justify-content: center;

    @include break-max($breakpoint-md) {
    //  gap: 45px;
    }

    @include break-max($breakpoint-sm) {
    //  gap: 25px;
    }

  }

  .whom__row {
    display: flex;
    justify-content: center;
    width: 100%;


    @include break-max($breakpoint-xs) {
      flex-direction: column;
      align-items: center;
    }
    //
    // &:last-of-type {
    //   &::after {
    //     content: '';
    //     width: 355px;
    //     min-height: 430px;
    //     @include break-max($breakpoint-md) {
    //       width: 300px;
    //       min-height: 380px;
    //     }
    //     @include break-max($breakpoint-sm) {
    //       width: 220px;
    //       min-height: 300px;
    //     }
    //     @include break-max($breakpoint-xs) {
    //       display: none;
    //     }
    //   }
    // }

  }

  .block_2x {
    position: relative;
    width: 780px;
    padding-top: 65px;
    padding-left: 240px;

    @include break-max($breakpoint-md) {
      width: 650px;
      padding-left: 170px;
      padding-right: 35px;
    }

    @include break-max($breakpoint-sm) {
      width: 625px;
      min-height: 300px;
      padding-left: 145px;
      padding-right: 35px;
    }

    @include break-max($breakpoint-xs) {
      width: 280px;
      min-height: 290px;
      padding: 25px;
    }

    img {
      position: absolute;
      left: 70px;
      top: 40px;

      @include break-max($breakpoint-md) {
        left: 35px;
      }

      @include break-max($breakpoint-sm) {
        left: 15px;
      }

      @include break-max($breakpoint-xs) {
        position: static;
      }
    }
  }

  .block_bg_blue {
    background: rgba($blue, 0.1);
    &:not(.block_2x) {
      //background: $color-white;
    }
  }

  .block_others {
    background: $color-brand-1;
    color: $color-white;
    text-align: center;
    align-items: center;
    padding-top: 110px;
    padding-left: 65px;
    padding-right: 65px;

    @include break-max($breakpoint-sm) {
      padding-top: 80px;
      padding-left: 22px;
      padding-right: 22px;

      .button {
        height: 50px;
        align-items: center;
      }
    }

    .block__footer {
      border: none;
      margin-top: 25px;
      padding-top: 0;
    }

    .block__text {
      padding-top: 25px;

      @include break-max($breakpoint-sm) {
        display: block;
        padding-top: 15px;
      }
    }

    margin-right: 0;
  }
  .block__text-list li {
    position: relative;
    padding-left: 15px;
  }
  .block__text-list li::before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 1px;
    margin-left: -15px;
    position: absolute;
  }
  .block__img {
    margin-bottom: 25px;
  }
}

.lawyers__bar {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;

  background: $color-white;
  box-shadow: 0px 2px 80px rgba(143, 149, 172, 0.15);
  z-index: 3;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

}

.lawyers__bar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    justify-content: center;
    height: 170px;
  }

  .button {
    width: 220px;
    height: 50px;
    justify-content: center;
    align-items: center;

    @include break-max($breakpoint-xs) {
      width: 100%;
      height: 44px;
    }
  }

  p {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.lawyers__bar-div {
  display: flex;
  align-items: center;
}

.lawyers__phone {
  margin-left: 50px;
  color: inherit;
  @include break-max($breakpoint-sm) {
    margin-left: 15px;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 0;
    margin-bottom: 15px;
    font-size: 16px;
  }
}

.lawyers__info {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: $color-white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);

  &.opened {
    transform: translateX(0);
  }
}

.lawyers__info-block {
  padding-top: 30px;
  padding-bottom: 35px;
  border-bottom: 1px solid $color-brand-opaque-20;

  .h4, .text {
    font-size: 15px;
  }

  .h4 {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;
  }

  .text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lawyers__info-bottom {
  padding-top: 20px;
  text-align: center;
  color: $color-brand-3;
  font-size: 14px;
  b {
    padding-left: 10px;
    color: $color-brand-1;
    font-size: 18px;
  }
}

.lawyers-modal {

  .checkbox__box + .checkbox__label {
    padding-right: 15px;
  }

  .modal-form__gist {
    @include break-max($breakpoint-xs) {
      background: $color-white;
      padding: 0;
    }
  }

  .modal-form__submit .button {
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .mobile-filters__content {
    padding-bottom: 0;
  }

  .modal__title {
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .modal__inner {
    width: 970px;
    @include break-max($breakpoint-sm) {
      width: 720px;
      padding: 25px 40px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      padding: 25px;
      margin: 0;
    }
  }

  .modal__row {
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }
  .modal-form__row {
    position: relative;
  }

  .modal-form__row:nth-of-type(2) {
    @include break-max($breakpoint-xs) {
      margin-top: 470px;
    }
  }

  .h5 {
    font-size: 14px;
    line-height: 20px;
    color: $color-brand-3;
    text-transform: none;
    padding-bottom: 15px;
    font-weight: normal;
  }

  .textarea__control {
    min-height: 120px;
  }

  div.modal-form__gist .checkbox {
    @include break-max($breakpoint-xs) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }


}

.lawyers-modal__services {
  width: 220px;
  margin-right: 60px;

  @include break-max($breakpoint-xs) {
    position: absolute;
    top: 200px;
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .checkbox_view_start-checkbox {
    align-items: flex-start;

    .checkbox__box {
      margin-top: 4px;
      width: 18px;
      height: 18px;


      @include break-max($breakpoint-xs) {
        margin-right: 15px;
        margin-left: auto;
      }
    }
    .checkbox__marker {
      background: $color-red;
    }
    .checkbox__marker::after {
      top: 4px;
      left: 3px;
      width: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10'%3E%3Cpath d='M0 4.765l.855-.8 4.19 2.272L12.267 0 13 .665 5.36 10 0 4.765z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
  }


}

.mobile-filters .lawyers__agreement {
  order: 0;
  margin-right: 15px;
}

.lawyers-modal__checkbox {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.lawyers__bar-links {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    width: 100%;
  }
  a {
    margin-right: 40px;
    white-space: nowrap;
    margin-left: 20px;
    @include break-max($breakpoint-xs) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 10px;
      border-radius: 4px;
      border: 1px solid $color-red;
      order: 1;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.newpayment + footer .preview__footer {
  margin-top: 0;
}


.new-layers {
  //border: 1px solid red;

  .new-layers__sec1 {
    margin-top: 40px;

    .promo__title {
      font-size: 80px;
      color: white;
    }
    .promo__text {
      font-size: 21px;
      color: white;
    }
  }

  .new-layers__sec1-content {
    background-image: url("../../assets/images/ur-usl/ur-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 125px;
    padding-left: 125px;
    padding-right: 125px;
  }

  .btn-sec1 {
    width: 222px;
    background-color: #2C2E3E;

    &:hover {
      background-color: #1b1d2d;
    }
  }
  .new-layers__sec1-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .new-layers__action {
    display: flex;
    gap: 40px;
    margin-top: 40px;
  }
  .new-layers__action-info {
    background-color: #E3F0F7;
    border-radius: 30px;
    height: 270px;
    padding: 0px 125px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 90px);

    h3 {
      font-size: 36px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
    }
  }

  .new-layers__action-img {
    width: 500px;
    min-width: 500px;
    border-radius: 30px;
    height: 270px;
    background-image: url("../../assets/images/ur-usl/houseicon.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .whom.second__section {
    margin-top: 20px;
    border-radius: 50px;
    padding-left: 125px;
    padding-right: 125px;
    background-color: #E3F0F7;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .block {
      margin: 0;
      width: auto;
      min-height: 300px;
      width: 50%;
    }
    .whom__row {
      gap: 40px;
    }
    .whom__list {
      gap: 40px;
    }
  }
  .whom.school__section {
    margin-top: 60px;
    //height: 1150px;
    background-image: url("../../assets/images/ur-usl/2bg.png");
    background-repeat: no-repeat;
    //background-position: center;
    border-radius: 50px;
    padding-top: 60px;
    padding-left: 125px;
    padding-right: 125px;

    .block {
      margin: 0;
      min-height: 300px;
      width: 50%;
    }
    .whom__row {
      gap: 40px;
    }
    .whom__list {
      gap: 40px;
    }
  }

  .new-layers__additional-link {
    font-size: 18px;
    margin-top: -10px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .modal-form__gist {
    margin: 60px 0;
    background-image: url("../../assets/images/ur-usl/fbg.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 580px;
    border-radius: 50px;
    padding: 80px 189px;
    margin-top: 60px;

    .input_type_form .input__label {
      color: white;
    }
    .textarea_type_form .textarea__label {
      color: white;
    }
    .checkbox__label {
      color: white;
    }

    .modal-form__item {
      flex: none;
      width: 360px;
    }
  }

  //.modal__title {
  //  color: white;
  //}

  .modal-form__gist.footermod {
    margin: auto;
    background-color: transparent;
  }

  .modal-form.wrapper.modalwrap {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 1599px) {
    .new-layers__sec1 .promo__title {
      font-size: 42px;
    }
    .new-layers__sec1 .promo__text {
      font-size: 18px;
    }
    .new-layers__action-info h3 {
      font-size: 30px;
    }
    .new-layers__sec1-content {
      padding-left: 80px;
      padding-right: 80px;
    }
    .new-layers__action-info {
      padding: 0 80px;
    }
    .whom.school__section {
      padding-left: 80px;
      padding-right: 80px;
    }
    .new-layers__additional-link {
      font-size: 15px;
    }
    .whom.second__section {
      padding-left: 80px;
      padding-right: 80px;
    }
    .modal-form__gist.footermod {
      padding-left: 100px;
      padding-right: 100px;
    }
    .new-layers__sec1-content {
      height: 550px;
    }

    .whom.school__section {
      background-size: cover;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  @media screen and (max-width: 1279px) {
    .new-layers__sec1-content {
      padding-left: 65px;
      padding-right: 65px;
    }
    .new-layers__action-info {
      padding: 0 65px;
    }
    .whom.school__section {
      padding-left: 65px;
      padding-right: 65px;
    }
    .new-layers__additional-link {
      font-size: 15px;
    }
    .whom.second__section {
      padding-left: 65px;
      padding-right: 65px;
    }
    .modal-form__gist.footermod {
      padding-left: 85px;
      padding-right: 85px;
    }

    .new-layers__action-img {
      width: 400px;
      min-width: 400px;
    }

  }

  @media screen and (max-width: 1023px) {
    .modal-form.wrapper.modalwrap {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    br {
      display: none;
    }
    .new-layers__sec1-content {
      height: 430px;
      padding-bottom: 60px;
    }
    .new-layers__action-img {
      width: 300px;
      min-width: 300px;
      height: 200px;
      background-size: cover;
    }
    .new-layers__action-info {
      padding-right: 30px;
      height: 200px;

      p {
        font-size: 14px;
      }
      h3 {
        font-size: 23px;
      }
    }
    //.header__top-rightblock {
    //  display: none;
    //}
    .new-layers__sec1 {
      margin-top: 20px;
    }
    .new-layers__action {
      gap: 20px;
      margin-top: 20px;
    }
    .whom.school__section {
      margin-top: 20px;
    }
    .school.lawyers .whom {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 767px) {

      margin-left: 10px;
      margin-right: 10px;

    .new-layers__action-info {
      width: 100%;
      border-radius: 20px;
    }
    .whom.school__section .whom__list {
      gap: 0;
    }
    .whom.school__section .whom__row {
      gap: 20px;
    }
    .whom.second__section {
      gap: 0;
    }
    .whom.second__section .whom__list {
      gap: 0;
    }
    .whom.second__section .whom__row {
      gap: 20px;
    }
    .whom.school__section {
      background-size: cover;
      background-position: center;
      border-radius: 20px;
    }
    .whom.school__section .block {
      width: 100%;
    }
    .block .block__title {
      text-align: left!important;
    }
    .whom.school__section h2.h2 {
      padding-bottom: 20px!important;
    }
    .block__showmore {
      display: none!important;
    }
    .whom.school__section {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .new-layers__action-img {
      display: none;
    }
    .btn.h6.btn_primary_gray.btn-sec1 {
      display: none;
    }
    .new-layers__sec1-content {
      height: 240px;
      justify-content: center;
      padding-bottom: 0;
      padding-left: 25px;
      padding-right: 25px;
      background-size: cover;
    }
    .new-layers__sec1 .promo__title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .new-layers__sec1 .promo__text {
      text-align: left;
      font-size: 14px;
    }

    .new-layers__sec1-content {
      padding-left: 25px;
      padding-right: 25px;
      border-radius: 20px;
    }
    .new-layers__action-info {
      padding: 0 25px;
    }
    .whom.school__section {
      padding-left: 25px;
      padding-right: 25px;
    }

    .whom.second__section {
      padding-left: 25px;
      padding-right: 25px;
      border-radius: 20px;
      padding-top: 25px;
      padding-bottom: 25px;

      .block {
        width: 100%;
      }
    }
    .modal-form__gist.footermod {
      padding-left: 25px;
      padding-right: 25px;
      border-radius: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .modal-form__gist .modal-form__item {
      width: 100%;
    }

    .button.button_view_default.btn-sec1 {
      width: 100%!important;
    }

  }


}
@media screen and (max-width: 1279px) {
  .school.lawyers .block .block__text {
    display: block;
  }
}
.new-layers.school.lawyers .block__paybtn {
  width: 70px;
  background-color: #EF372E;
    svg {
      path {
        fill: white;
      }
    }
}
.newlayers-list {
  li {
    list-style-type: none;
    margin-bottom: 10px;
    font-size: 18px;
    margin-left: 10px;
    &::before {
      content: '•';
      color: red;
      margin-left: -20px;
      margin-right: 10px;
    }
  }
  margin-top: 10px;
}
