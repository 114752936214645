@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Formular-Light.woff2") format('woff2'), url("../../fonts/Formular-Light.woff") format('woff');
}
@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/Formular.woff2") format('woff2'), url("../../fonts/Formular.woff") format('woff');
}
@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Formular-Medium.woff2") format('woff2'), url("../../fonts/Formular-Medium.woff") format('woff');
}
@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Formular-Bold.woff2") format('woff2'), url("../../fonts/Formular-Bold.woff") format('woff');
}
@font-face {
  font-family: 'Formular';
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/Formular-Black.woff2") format('woff2'), url("../../fonts/Formular-Black.woff") format('woff');
}
