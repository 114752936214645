.motivation {
  padding-top: 45px;

  @include break-max($break-xs) {
    padding-top: 18px;

    .custom-table__tr {
      flex-wrap: wrap;
      margin-top: 0;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include break-max($break-xs) {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 10px;
    }
  }

  .button-mobile {
    @include break-max($break-xs) {
      margin: 0 10px;
      width: calc(100% - 20px);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-bottom: 10px;
  }

  &__info {
    margin-right: 20px;

    @include break-max($break-xs) {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }

  & .mini-filter {

    & .form {
      position: relative;
    }

    &__grid {
      @include break-max($breakpoint-sm) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    &__col {

      @include break-max($breakpoint-sm) {
        flex-basis: calc( 25% - 9px );
        max-width: calc( 25% - 9px );
        flex-grow: 0;

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        &:nth-child(n + 5) {
          margin-top: 15px;
        }
      }
    }


    &__advance {
      margin-top: 20px;
    }

    &__mobile {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: 0 auto;
      width: 150px;
      height: 25px;
    }
  }

  &__table {
    padding-bottom: 25px;

    @include break-max($break-xs) {
      margin: 0 10px;

      .custom-table__card {
        box-shadow: none;
        margin-bottom: 0;
      }
    }

    .manager {
      &-contacts {
        &_item {
          margin-right: 0;
        }
      }
    }

    &-control {
      padding-right: 20px;

      & .button {
        position: relative;

        &:before {
          content: '+';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 25px;
          height: 25px;
          font-size: 20px;
          line-height: 22px;
          font-weight: 300;
          font-family: "Formular", sans-serif;
        }
      }


      &.is-active {

        & .button:before {
          content: "—";
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    &-dropdown {
      display: flex;
      margin-top: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid $color-brand-opaque-30;
    }

    &_builder {
      flex: 1 0 270px;
      max-width: 270px;

      @include break-max($breakpoint-md) {
        flex-basis: 180px;
        max-width: 180px;
      }

      @include break-max($breakpoint-sm) {
        max-width: inherit;
        flex-basis: 155px;
      }
    }

    &_objects {
      flex: 1 0 480px;
      display: flex;

      @include break-max($breakpoint-md) {
        flex-basis: 450px;
      }

      @include break-max($breakpoint-sm) {
        flex-basis: 460px;
      }

      @include break-max($break-xs) {
        flex-basis: 100%;
      }
    }

    &_object {
      flex: 1 0 230px;

      @include break-max($breakpoint-sm) {
        flex-basis: 250px;
      }

      @include break-max($break-xxs - 1px) {
        flex-basis: 65%;
        padding-right: 5px;
      }

      @include break-max($break-xs) {
        flex-basis: 57%;
        padding-right: 5px;
      }
    }

    &_fee {
      flex: 1 0 250px;
      max-width: 250px;
      display: flex;
      align-items: center;

      @include break-max($breakpoint-md) {
        flex-basis: 220px;
        max-width: 220px;
      }

      @include break-max($break-xs) {
        flex-basis: 43%;
      }

      @include break-max($break-xxs - 1px) {
        flex-basis: 35%;
      }
    }

    &_conditions {
      flex: 1 0 215px;
      max-width: 215px;

      @include break-max($breakpoint-md) {
        flex-basis: 195px;
        max-width: 195px;
      }

      @include break-max($breakpoint-sm) {
        flex-basis: 480px;
        max-width: 480px;
        padding-left: 68px;
      }
    }

    &_cashback {
      flex: 1 0 140px;
      max-width: 140px;

      @include break-max($breakpoint-md) {
        // flex-basis: 215px;
        // max-width: 215px;
      }

      @include break-max($breakpoint-sm) {
        // flex-basis: 480px;
        // max-width: 480px;
        // padding-left: 68px;
      }

      .label-text {
        padding-left: 4px;
      }
    }

    &_manager {
      flex: 1 0 220px;
      max-width: 220px;
    }

    &-conditions {
      align-items: flex-start;

      .label__text {
        padding-top: 4px;
      }
    }
  }


  &__builder {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 20px;
    line-height: 21px;

    &-name {
      flex-grow: 1;
      padding-top: 10px;
    }

    &-logo {
      flex-shrink: 0;
      max-width: 55px;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__objects {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 10px;

    @include break-max($break-xs) {
      padding-bottom: 5px;
    }

    &-header {
      display: flex;

      &.is-mobile {
        color: $color-brand-3;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      border-top: 1px solid $color-brand-opaque-30;
      padding: 15px 10px 0;
      margin: 0 -10px;

      @include break-max($break-xs) {
        border-top: none;
        border-bottom: 1px solid $color-brand-opaque-30;
        padding-bottom: 25px;
        padding-top: 0;
      }
    }

    &-item {
      display: flex;
      width: 100%;

      @include break-max($break-xs) {
        align-items: center;
        margin-bottom: 10px;
      }

      @include break-max($break-xxs - 1px) {
        margin-bottom: 8px;
      }
    }
  }

  &__object, &__fee {
    height: 100%;

    &-header {
      padding: 5px 0 35px;
      display: flex;
      align-items: center;
    }

    &-list {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      flex-grow: 1;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      min-height: 30px;
      height: 100%;
      padding: 5px 0;
    }
  }

  &__object {
    line-height: 28px;

    &-amount {
      display: inline-block;
      margin-left: 10px;
    }

    &-item {
      color: $purple;
      line-height: 17px;
      padding-right: 20px;

      @include break-max($break-xs) {
        color: $blue;
        padding: 0;
      }
    }
    &-usual{
      color: black;
    }
  }

  &__fee {
    &-item {
      @include break-max($break-xs) {
        padding: 0;
      }
      .label-item {
        white-space: nowrap;
        margin-right: 10px;

        @include break-max($break-xs) {
          margin-right: 0;
        }
      }
    }
  }

  &__conditions, &__manager {
    display: flex;
    flex-direction: column;

    &-th {

    }
  }

  &__conditions {
    padding-right: 40px;
  }

}

.motivation__hidden + .motivation__hidden {
  margin-top: 25px;
}

.motivation__hidden-header {
  display: flex;
}

.motivation__hidden-body {
  display: flex;
}

.motivation__hidden-block {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 0 25px;
}

.motivation__hidden-th {
  flex: 1 1;
  padding: 0 25px;
  margin-bottom: 5px;
  font-weight: bold;
}

.motivation__download {
  @include break-max($break-xs) {
    margin: 0px 0 16px;
  }
}

.mobile-filters.for-motivation {
  @include break-max($break-xs) {
    .mini-filter .form {
      background: none;
      margin: 0;
      padding: 0;
    }

    .mini-filter__grid {
      align-items: flex-start;
      flex-direction: column;
    }

    .form__drop-btn {
      border: 1px solid $color-brand-opaque-20;
    }

    .mini-filter__col {
      flex-basis: 100%;
      margin-left: 0;
      width: 100%;
    }

    .mini-filter__advance {
      margin-top: 15px;
    }

    .form__drop-content {
      min-width: 0;
    }
  }
}

div.motivation__builder {
  @include break-max($break-xs) {
    align-items: center;
    background: $color-brand-opaque-8;
    border-radius: 4px;
    flex-direction: row-reverse;
    min-height: 55px;
    padding: 9px 16px;

    .motivation__builder-logo {
      height: auto;
      max-width: 50px;
      margin-right: 30px;
      margin-top: 0;

      & img {
        height: auto;
      }
    }

    .motivation__builder-name {
      padding: 0;
    }
  }
}

.custom-table__tr:first-child .custom-table__td {
  @include break-max($break-xs) {
    padding: 0;
  }
}

.custom-table__tr {
  @include break-max($break-xs) {
    padding: 0;
    margin-top: 16px;
  }
}

.label_purple {
  @include break-max($break-xs) {
    background: rgba($blue, 0.2);
    color: $blue;
  }
}

.motivation__fee-text {
  @include break-max($break-xxs - 1px) {
    width: 100px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.motivation__details {
  display: flex;
  margin: 0 -10px;
  padding: 15px 0;

  .pan-popup-trigger {
    display: flex;
  }

  .ant-collapse {
    display: flex;
    width: 100%;
  }

  .ant-collapse-header {
    align-items: center;
    border-radius: 4px;
    color: $color-brand-3;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    height: 28px;
    font-size: 15px;
    line-height: 20px;
    outline: none;
    padding: 0 18px;

    @include break-max($break-xxs - 1px) {
      font-size: 14px;
      line-height: 18px;
      padding: 0 10px;
    }

    .ant-collapse-arrow svg {
      margin-left: 6px;
      transform: rotate(90deg) scaleY(1.3);
      transition: 0.3s all;
      width: 10px;
    }
  }

  .ant-collapse-content {
    transition: none !important;
  }

  .ant-collapse-content.ant-collapse-content-inactive {
    display: none;
  }

  .ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-header {
      background: $color-brand-opaque-10;
    }

    .ant-collapse-arrow svg {
      transform: rotate(-90deg) scaleY(1.3) !important;
      transition: 0.3s all;
    }
  }

  .ant-collapse-content-box {
    display: flex;
    margin-top: 15px;
    text-align: left;

    .label__text {
      margin-left: 15px;
    }
  }

  .ant-collapse-item {
    // transition: all 0.3s;
    text-align: center;
    width: 50%;

    .ant-collapse-content {
      margin-left: -100%;
    }

    &:first-child {
      .ant-collapse-content {
        margin-right: -100%;
        margin-left: 0;
      }
    }
  }
}

div.motivation__object-header, div.motivation__fee-header {
  @include break-max($break-xs) {
    padding: 5px 0 15px;
  }
}

.motivation__manager {
  @include break-max($break-xs) {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
    flex-grow: 1;
    margin-top: 10px;
    padding: 22px 22px 18px 22px;

    .manager.small .manager-avatar {
      flex-basis: 0;
      margin-right: 20px;
    }

    .manager-title,
    .manager-content {
      margin-top: 0;
      text-align: left;
    }

    .manager-content {
      flex-grow: 1;
      width: 50px;
    }

    .manager.small .manager-title {
      margin-top: 0;
    }

    .manager-contacts_item {
      flex-basis: 100%;
    }
  }
}

div.motivation__object-header.motivation__table_object {
  @include break-max($break-xs) {
    padding-top: 5px;
  }
}


.motivation__for-trade-in {
  .motivation__builder-logo {
    max-width: 95px;
  }
  .motivation__table {
    padding-bottom: 25px;

    @include break-max($break-xs) {
      margin: 0 10px;

      .custom-table__card {
        box-shadow: none;
        margin-bottom: 0;
      }
    }

    .manager {
      &-contacts {
        &_item {
          margin-right: 0;
        }
      }
    }

    &-control {
      padding-right: 20px;

      & .button {
        position: relative;

        &:before {
          content: '+';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 25px;
          height: 25px;
          font-size: 20px;
          line-height: 22px;
          font-weight: 300;
          font-family: "Formular", sans-serif;
        }
      }


      &.is-active {

        & .button:before {
          content: "—";
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    &-dropdown {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid $color-brand-opaque-30;
    }

    &_builder {
      flex: 1 0 165px;
      max-width: 165px;

      @include break-max($breakpoint-md) {
        max-width: 160px;
        flex-basis: 160px;
      }

      @include break-max($breakpoint-sm) {
        max-width: 310px;
        flex-basis: 310px;
      }
    }

    &_objects {
      flex: 1 0 270px;
      display: flex;

      @include break-max($breakpoint-md) {
        flex-basis: 240px;
      }

      @include break-max($breakpoint-sm) {
        flex-basis: 260px;
      }

      @include break-max($break-xs) {
        flex-basis: 100%;
      }
    }

    &_object {
      flex: 1 0 135px;

      @include break-max($breakpoint-md) {
        flex-basis: 115px;
      }

      @include break-max($break-xxs - 1px) {
        flex-basis: 65%;
        padding-right: 5px;
      }

      @include break-max($break-xs) {
        flex-basis: 57%;
        padding-right: 5px;
      }
    }

    &_fee {
      flex: 1 0 135px;
      max-width: 135px;
      display: flex;
      align-items: center;

      @include break-max($breakpoint-md) {
        flex-basis: 105px;
        max-width: 105px;
      }

      @include break-max($break-xs) {
        flex-basis: 43%;
      }

      @include break-max($break-xxs - 1px) {
        flex-basis: 35%;
      }
    }

    &_terms {
      flex: 1 0 170px;
      @include break-max($breakpoint-md) {
        flex-basis: 135px;
      }
    }

    &_signs {
      flex: 1 0 190px;
      @include break-max($breakpoint-md) {
        flex-basis: 135px;
      }
    }

    &_purchase {
      flex: 1 0 190px;
      @include break-max($breakpoint-md) {
        flex-basis: 125px;
      }
    }

    &_conditions {
      flex: 1 0 140px;
      max-width: 140px;

      @include break-max($breakpoint-md) {
        flex-basis: 125px;
        max-width: 125px;
      }

      @include break-max($breakpoint-sm) {

        padding-left: 68px;
      }
    }

    &_manager {
      flex: 1 0 155px;
      max-width: 155px;
      @include break-max($breakpoint-md) {
        flex-basis: 125px;
        max-width: 125px;
      }
    }

    &_award {
      flex: 1 0 180px;
      @include break-max($breakpoint-md) {
        flex-basis: 125px;
        max-width: 125px;
      }
    }

    &-conditions {
      align-items: flex-start;

      .label__text {
        padding-top: 4px;
      }
    }
  }
}