@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/imports/mixins";

.calc__manager-photo {
  flex-shrink: 0;
}

.complex__manager-avatar_wrapper img {
  object-fit: contain;
}

.show__map-mobile {
  display: flex;
  margin-left: 40px;
}

.show__map-mobile .icon_map-pin {
  height: 17px;
  margin-right: 10px;
}

.complex__info-main.mobile .complex__address {
  margin-bottom: 16px;
}

.filter__manager-photo {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(143, 149, 172, 0.1);
  margin-right: 15px;
  overflow: hidden;
}
.status-assignment{
  background: #8790E5;
  color: white;
  padding: 5px 10px;
  display: flex;
  max-width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
}
.complex__additional-row.suburbans{
  .complex__additional-item{
    width: 25%;
    flex-grow: 1;
  }
}

.complex__additional.for-suburban {
  .complex__info-item-desc {
    margin-top: 5px;
  }
}

.section.for-suburbans {
  .complex__appartments-card {
    @include break-max($breakpoint-sm) {
      @include grid-size(12, $grid-gutter-sm);
    }

    @include break-max($breakpoint-xs) {
      margin-bottom: 0px;
      margin-top: 20px;
      width: 100%;
    }
  }

  .flats__row-table table {
    padding-left: 0;
    padding-right: 0;
  }

  .complex__appartments-title {
    font-size: 21px;
    font-weight: bold;
    line-height: 25px;
  }

  .complex__appartments {
    padding: 12px 0 0 0;
  }

  .complex__appartments-navigation {
    margin-top: 15px;
  }

  .complex__appartments-cards {
    margin-bottom: 110px;
  }

  .table-image-scalable {
    background: $color-white;
  }

  .ant-table-column-sorters {
    @include break-max($breakpoint-xsm) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .table-image-scalable {
    @include break-max($breakpoint-xsm) {
      max-width: 60px;
      max-height: 25px;
    }
  }
}

.flat__suburban-address {
  margin-top: 10px;
}

.section__calc.for-suburban {
  margin-top: 60px;
}

.complex__filter.for-suburban {
  .input.input_type_default[for="square-1"],
  .input.input_type_default[for="square-2"] {
    margin-bottom: 20px;
  }
}

.flat__col-images.for-suburban {
  .flat__images,
  .flat__image {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .complex__slider-thumbnails_item img {
    background: $color-white;
  }

  .complex__slider-preview {
    bottom: -50px;

    @include break-max($breakpoint-md) {
      bottom: -90px;
    }
  }

  .flat__manager {
    margin-top: 100px;
  }
}

.section__another.for-suburbans {
  margin-top: 0;
}

.flat__col-info.for-suburban {
  @include break-max($breakpoint-sm) {
    margin-top: 100px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 120px;
  }
}

.section.flat.for-suburbans {
  @include break-max($breakpoint-xs) {
    margin-top: 0;

    .flat__grid {
      position: relative;
    }

    .flat__header {
      padding-top: 0;
      margin-top: 18px;
    }

    .flat__complex.for-suburbans {
      margin-top: 0;
    }

    .button_type_settings {
      margin-right: 11px;
    }

    .button_type_settings + .button_type_settings {
      margin-right: 0;
    }

    .flat__col-title {
      flex-basis: 65%;
      justify-content: center;
      min-height: 44px;
    }

    .flat__suburban-address {
      margin: 18px 10px 24px;
      min-height: 40px;
      width: 80%;
    }

    .show__map {
      position: absolute;
      right: 10px;
      bottom: -62px;
    }
  }
}

.complex__additional-row.suburbans .complex__additional-item {
  @include break-max($breakpoint-xs) {
    width: 50%;
  }
}

.flat__images.gallery.is-empty {
  justify-content: center;
}



.about-redesign {
  padding: 30px 70px 30px 30px;
  border-radius: 20px;
  background-color: #F6F7F8;

  &__top {
    display: flex;
    gap: 30px;
  }

  &__top-icon {
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #FF2D23;
    flex-shrink: 0;

  }

  &__top-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__top-title {
    font-size: 18px;
    font-weight: 700;
  }

  &__bold-text {
    font-weight: 700;
  }

  &__available-list {
    margin-bottom: 10px;

    li {
      position: relative;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #FF2D23;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }

  &__top-more {
    color: #777A89;
    border-bottom: 1px dashed;
    cursor: pointer;
    display: inline;
    width: max-content;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &__overlist {
    margin-top: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__list-item {
    flex-basis: calc(50% - 20px);
    box-sizing: border-box;
    display: flex;
    gap: 10px;
  }

  &__list-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
    //background-color: #FF2D23;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .app-popover-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__list-text {

  }

  &__listtext-sm {
    font-size: 12px;
  }

  &__listtext-bd {
    font-weight: bold;
  }

  &__complectation-price {
    font-size: 18px;
    background-color: white;
    font-weight: 700;
    padding: 10px 15px;
    border-radius: 10px;
    margin-top: 20px;
  }

  &__complectation-list {
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      position: relative;
      display: inline-block;
      margin-right: 20px;

      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #EF372E;
        margin-right: 10px;
        margin-top: -2px;
      }
    }
  }

}

.communications-redesign {
  padding: 30px 70px 30px 30px;
  border-radius: 20px;
  background-color: #F6F7F8;

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__list {
    margin-top: 20px;

    .about-redesign__list-item {
      flex-basis: calc(33.33% - 20px);
      align-items: center;
    }
  }

}

.communications-redesign__list.communications-redesign__list_white {
  .about-redesign__list-icon {
    background-color: white;
  }
}

.suburban-alreadylook {

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__card {
    border-radius: 20px;
    background-color: #F6F7F8;
    position: relative;
  }

  &__image {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin: 20px 20px 15px 20px;
    font-size: 20px;
    font-weight: 700;
  }

  &__list {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      display: flex;
      justify-content: space-between;
      width: 100%;

      div:first-child {
        color: #777A89;
      }

      div:last-child {
        font-weight: 500;
      }
    }
  }
}


.ComplectationPopup {
  //display: block;

  .ant-popover-inner-content {
    max-width: 677px;
    background-color: white;
    border: 1px solid #DFE1E9;
    border-radius: 20px;
    padding: 30px 30px 30px 30px;
  }
}

.complectation-popup {
  display: flex;
  gap: 15px;

  &__left {
    width: 175px;
    height: 125px;
  }

  &__image {
    width: 175px;
    height: 125px;
    border-radius: 20px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__list-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      position: relative;

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        background-color: #EF372E;
        border-radius: 50%;
        margin-top: -3px;
        margin-right: 10px;
      }
    }
  }

}










