.panel {
  $c: &;
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);

  z-index: 100;

  &__block {
    width: 40px;
    border-radius: 8px 0 0 8px;
    background-color: $color-brand-2;
    margin-top: 8px;

    &_bg_red {
      background-color: $color-red;
    }


    &:first-of-type {
      margin-top: 0;
    }
  }

  &__list {
    @include unlist();
    padding: 8px 0;

    &_rating {
      padding: 4px 0;
    }

    &_inverted {
      background-color: #fff;
      border-radius: 0 0 0 8px;
      box-shadow: $shadow;

      & .icon {
        fill: #8F95AC;
      }
    }
  }

  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    position: relative;


    &:first-of-type {
      margin-top: 0;
    }

    &:hover {

      .panel__tooltip {
        display: flex;
      }
    }

  }

  &__tooltip {
    display: none;
    position: absolute;
    right: calc(100% + 19px);
    top: -5px;
    height: 59px;
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: $shadow;
    align-items: center;
    padding: 0 22px;
    white-space: nowrap;
    font-size: 14px;
    color: $color-brand-2;

    &:after {
      content: '';
      background-size: 100%;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMyAwTDAgMTBWMGgxM3oiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: top left;
      width: 13px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: -9px;
    }

    &_manager {
      width: 280px;
      padding: 0;
      white-space: normal;
      height: auto;
    }

    &-value, &-add {
      color: $color-red;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 19px;
    text-align: center;
    position: relative;

    &_absolute {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &_rating {
      font-size: 25px;
    }

    &_not_link {
      cursor: pointer;
      svg {
        stroke: $color-white;
      }
      &:hover {
        svg {
          stroke: $color-red;
        }
      }
    }

    & .icon {
      stroke: $color-white;
      line-height: 40px;
      transition: stroke $transition-default;
    }

    &:hover {

      & > .icon {
        stroke: $color-red;
      }
    }
  }

  &__val {
    width: 18px;
    height: 18px;
    position: absolute;
    font-size: 12px;
    color: $color-white;
    text-align: center;
    z-index: 1;
    top: -8px;
    left: 50%;
    display: none;

    &.is-visible {
      display: block;

      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-color: $color-red;
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(5) translate(-50%, -50%);
        animation: blast .3s forwards cubic-bezier(0,0,0.12,1);
        opacity: 1;
        transform-origin: left top;
        pointer-events: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      background-color: $color-red;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .icon_arrow-right {
      transform: rotate(90deg);
    }

    &.plus {

      &:before {
        background-color: $green;
      }

      &::after {
        background-color: $green;
      }

      .icon_arrow-right {
        transform: rotate(-90deg);
      }
    }

  }

  &__social {
    display: none;
    position: absolute;
    width: 100%;

    &.is-active {
      display: block;
    }

    & #{$c}__link {

      & .icon {
        fill: $color-brand-3;
        transition: fill $transition-default;
      }
    }

    & #{$c}__link:hover {
        & .icon {
          stroke: none;
          fill: $color-brand-1;
        }
      }
  }


  &__manager {
    display: flex;
    position: relative;
    padding: 18px 18px;
    cursor: default;

    &-avatar {
      width: 60px;
      height: 60px;
      margin-right: 18px;
      border-radius: 50%;
    }

    &-block {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    &-name {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
    }

    &-position {
      font-size: 14px;
      line-height: 18px;
      color: $color-brand-3;
    }

    &-contacts {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      li {
        margin-bottom: 3px;
      }
    }

  }
}

@keyframes blast {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 1;
  }

  100% {
    transform: scale(5) translate(-50%, -50%);
    opacity: 0;
  }
}

.mobile-panel {

  &__item {
    margin-bottom: 24px;

    @include break-max($break-xs) {
      margin-bottom: 15px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $color-brand-3;
    display: inline-flex;
    align-items: center;
    position: relative;

    & .icon {
      font-size: 19px;
      stroke: $color-white;
      margin-right: 19px;
      @include break-max($break-xs) {
        stroke: none;
      }

      &_object {
        stroke: none;
      }
    }

  }

  &__val {
    width: 18px;
    height: 18px;
    position: absolute;
    font-size: 12px;
    color: $color-white;
    text-align: center;
    z-index: 1;
    top: -6px;
    left: 12px;
    display: none;

    &.is-visible {
      display: block;
    }

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      background-color: $color-red;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
