.chess {
  display: flex;
  // height: 100vh;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  &__filter {
    width: 250px;
    padding: 20px;
    background-color: $color-bg-light;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    flex-shrink: 0;

    @include break-max($breakpoint-sm) {
      width: 205px;
      padding: 20px 10px;

      .button_view_default {
        padding: 22px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 25px;
    }

    &-wrapper {
      // overflow: hidden;
      margin-right: -20px;
      padding-right: 20px;
    }

    &-list {
      height: 100%;
      margin-right: -10px;
      padding-right: 10px;

      @include break-max($breakpoint-sm) {
        margin-right: -5px;
        padding-right: 5px;
      }
    }

    &-item {
      margin-bottom: 12px;

      & .form__item {
        margin: 0;
      }

      & .form__drop-content {
        min-width: initial;
      }
    }

    &-more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 25px;
      color: $color-brand-3;

      & .icon {
        stroke: $color-brand-3;
        font-size: 10px;
        margin-left: 10px;
      }
    }

    &-submit {
      display: flex;
      justify-content: center;
      margin-top: 32px;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 100vh;

    @include break-max($break-xs) {
      max-height: none;
    }

    &-nav {
      border-bottom: 1px solid $color-bg-light;

      .complex__appartments-navigation .complex__move-nav {
        max-width: initial;
        justify-content: flex-end;
        flex-basis: auto;
      }
    }

    &-item {
      overflow: hidden;
      display: none;
      flex-direction: column;

      &.active {
        display: flex;
      }
    }
  }

  &__nav {
    &-sections {
      display: flex;
      padding: 0 23px;
      box-shadow: 0px 2px 15px $color-brand-opaque-10;
      z-index: 10;

      @include break-max($break-xs) {
        border-bottom: 1px solid $color-brand-opaque-20;
        box-shadow: none;
        height: 40%;
        min-height: 40px;
        max-height: 40px;
        overflow: auto;
        padding: 0 0 0 10px;
      }
    }

    &-section {
      padding: 16px 0 12px;
      margin-right: 40px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active, &:hover {
        border-color: $color-red;
      }

      @include break-max($break-xs) {
        align-items: center;
        display: flex;
        padding: 0;
        white-space: nowrap;
      }
    }
  }

  &__sections {
    display: flex;
  }

  &__section {
    padding: 0 12px;

    &:first-child {
      padding-left: 0;

      .chess__board {
        &-section {
          margin-left: -60px;
          padding-left: 60px;
        }
      }
    }

    &:last-child {
      padding-right: 60px;
    }

    & + & {
      border-left: 1px solid $color-brand-opaque-10;

      .chess__board {
        &-section {
          border-left: 1px solid $color-brand-opaque-10;
          margin-left: -13px;
        }
      }
    }
  }

  &__board {
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    cursor: move;
    user-select: none;

    @include break-max($break-xs) {
      height: 100%;
      overflow: scroll;
      padding-left: 30px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-section {
      position: sticky;
      top: 0;
      background-color: $color-white;
      z-index: 5;
      padding: 0 12px;
      margin: 0 -12px;

      &_label {
        display: inline-block;
        position: sticky;
        left: 60px;
        padding: 18px 0;
        color: $color-brand-3;
      }
    }

    &-main {
      display: flex;
    }

    &-aside {
      position: sticky;
      left: 0;
      flex-shrink: 0;
      width: 60px;
      height: 100%;
      padding-top: 53px;
      z-index: 4;

      &--content {
        overflow: hidden;
      }

      @include break-max($break-xs) {
        height: auto;
        padding-top: 0;
        position: absolute;
        width: 30px;
      }
    }

    &-num {
      width: 100%;
      height: 102px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-white;

      @include break-max($break-xs) {
        height: 87px;
      }
    }

    &-row {
      display: flex;
    }

    &-cell {
      margin: 1px;
      width: 165px;
      height: 100px;

      @include break-max($break-xs) {
        height: 83px;
        margin: 2px;
        width: 155px;
      }
    }
  }
}

.chessboard-flat {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 165px;
  height: 100px;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;

  @include break-max($break-xs) {
    height: 83px;
    width: 155px;
  }

  &__head {
    padding-top: 7px;
    display: flex;
    justify-content: space-between;

    @include break-max($break-xs) {
      padding-top: 5px;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    @include break-max($break-xs) {
      margin-top: 4px;
    }
  }

  &__furnish {
    height: 25px;
    padding-right: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sqr {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    padding: 6px 6px;

    @include break-max($break-xs) {
      align-items: center;
      display: flex;
      padding: 0 3px;
      height: 25px;

      span {
        display: flex;
      }
    }
  }

  &.sale {
    background-color: $green-20;

    .chessboard-flat {
      &__status {
        color: $green;
      }
      &__sqr {
        background-color: $green-20;
      }
    }
  }

  &.sold {
    background-color: $color-brand-opaque-10;

    .chessboard-flat {
      &__status {
        color: $color-brand-3;
      }
      &__sqr {
        background-color: $color-brand-opaque-10;
      }
    }
  }

  &.reserved {
    background-color: $color-red-10;

    .chessboard-flat {
      &__status {
        color: $color-red;
      }
      &__sqr {
        background-color: $color-red-10;
      }
    }
  }

  &.assignment {
    background-color: #8790e533;

    .chessboard-flat {
      &__status {
        color: #8790E5;
      }
      &__sqr {
        background-color: #8790e526;
      }
    }
  }
  &.filtered-out {
    opacity: .3;
  }
}

.chess__tabs-nav {
  @include break-max($break-xs) {
    border-bottom: none;
    position: relative;

    .complex__appartments-navigation {
      margin-bottom: 0;
    }

    .complex__appartments-navigation .complex__move-nav .tabs__navs-item.prev {
      top: 25px;
      left: 10px;
    }

    .complex__appartments-navigation .complex__move-nav .tabs__navs-item.next {
      top: 25px;
      right: 10px;
    }
  }
}

.section.chess {
  @include break-max($break-xs) {
    height: 100%;
  }
}

.button-mobile.for-chess {
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}

.chess__filter.mobile-filters {
  @include break-max($breakpoint-xs) {
    padding: 0;
    width: 100%;

    .mobile-filters__title {
      z-index: 11;
    }
  }
}

.chess__filter-list.index-search__filter-top,
.chess__filter-wrapper.mobile-filters__content {
  @include break-max($breakpoint-xs) {
    margin: 0;
  }
}

.chess__filter-list.index-search__filter-top {
  @include break-max($breakpoint-xs) {
    margin-top: 30px;
  }
}
