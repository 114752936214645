@import "../../../styles/variables";
@import "../../../styles/mixins";

.tab-bar {
  margin-bottom: 20px;
}

.tab-bar_flex {
  display: flex;
  justify-content: space-between;
  align-items: end;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}

.tab-bar_inline {
  width: max-content;
}

.tab-list {
  display: flex;
  border-bottom: 1px solid $gray-dark;

  @include break-max($breakpoint-xs) {
    overflow-x: auto;
    margin-top: 15px;
    width: 100%;
  }
}

.tab-btn {
  position: relative;
  padding: 15px;
  min-width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
  }

  @include break-max($breakpoint-xs) {
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 11px;
    min-width: auto;
    color: black;
  }

  &_active {

    &::after {
      background-color: $color-red;
    }
  }
}


.tab-bar_with-icons {
  
  svg {
    margin-right: 5px;
  }
  
  .tab-list {
    border: none;
    gap: 40px;
  }
  
  .tab-btn {
    min-width: auto;
    border: none;
    padding: 0;
    transition: all 0.3s;
    &::after {
      display: none;
    }
    &_active, &:hover {
      color: $color-red;
    }
  }
}

#tab-new-parking:before {
  content: "New";
  display: inline-block;
  padding: 1px 12px;
  border-radius: 20px;
  background-color: $deal-completed-bg-status;
  color: #2CBA2E;
  font-weight: 400;
  font-size: 14px;
  order: 1;
  margin-left: 10px;
}

.tab-count {
  margin-left: 5px;
  background-color: $gray-light;
  color: $gray-dark-2;
  font-weight: normal;
  padding: 5px 10px;
  border-radius: 20px;
}
