@import '../../../styles/variables';
@import '../../../styles/mixins';

.sf-card {
  display: inline-flex;
  flex-direction: column;
  background-color: $gray-light;
  border-radius: 20px;
}

.sf-card__header {
  position: relative;
}

.sf-card__pic {
  border-radius: 20px;
  width: 100%;
  height: 355px;
  object-fit: cover;

  @include break-max($breakpoint-md) {
    height: 300px;
  }

  @include break-max($breakpoint-sm) {
    height: 280px;
  }
}

.sf-card__label {
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 13px;
}

.sf-card__hashtag {
  position: absolute;
  top: 20px;
  left: 25px;
  background-color: $color-brand-opaque-70;
  color: $color-white;
}

.sf-card__date {
  position: absolute;
  bottom: 22px;
  left: 25px;
  background-color: $color-white;
  color: $color-brand-3;
}

.sf-card__label-list {
  position: absolute;
  bottom: 22px;
  left: 25px;
  .sf-card__label {
    display: inline-flex;
    background-color: $color-white;
    color: $color-brand-3;
  }

  .sf-card__label + .sf-card__label {
    margin-left: 10px;
  }

}

.sf-card__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 25px 25px;
  margin-top: 0;
}

.sf-card__title {
  margin-bottom: 5px;
}

.sf-card__text {
  margin-bottom: 5px;
}

.sf-card__descr-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-brand-opaque-20;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.sf-card__descr {
  color: $color-brand-3;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.sf-card__descr + .sf-card__descr {
  text-align: right;
}

.sf-card__table {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sf-card__table_custom {

  .sf-card__row {

    .sf-card__col {
      padding-right: 0;
    }
    .sf-card__col:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .sf-card__col:nth-child(2) {
      text-align: center;
    }
    .sf-card__col:last-child {
      text-align: right;
      white-space: nowrap;
    }
  }
}

.sf-card__counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  border-radius: 20px;
  background-color: $color-brand-opaque-10;
  font-size: 13px;
  color: $color-brand-3;
  margin-left: 8px;
}

.sf-card__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & + & {
    margin-top: 5px;
  }
}

.sf-card__col {
  padding-right: 5px;
  &:last-of-type {
    padding-right: 0;
  }
}

.sf-card__metro-time {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  padding: 3px 7px;
  border-radius: 20px;
  background-color: $color-brand-opaque-10;
  white-space: nowrap;

  svg {
    margin-right: 5px;
  }
}

.sf-card__metro-list {
  margin-top: auto;
}

.sf-card__metro-station {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.sf-card__metro-circle {
  display: inline-flex;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 1.5px;
  margin-right: 10px;
  flex-shrink: 0;
}

.sf-card__metro {
  display: flex;
  align-content: center;
  color: $color-brand-3;
  gap: 5px;
  flex-wrap: wrap;
  & + & {
    margin-top: 5px;
  }
}

.sf-card__metro-route {
  color: $color-brand-3;
}

.sf-card_flat {
  .sf-card__pic {
    background-color: $color-white;
    height: 268px;
    @include break-max($breakpoint-md) {
      height: 230px;
    }

    @include break-max($breakpoint-sm) {
      height: 180px;
    }

    @include break-max($breakpoint-xs) {
      height: 230px;
    }
  }

  .sf-card__hashtag {
    background-color: $gray-light;
    color: $gray-dark-2;
  }
}

.sf-card_flat.sf-card_flat-update {
  .sf-card__pic {
    @include break-max($breakpoint-md) {
      height: 275px;
      padding: 30px;
    }
  }
}

.sf-card__bottom-text {
  margin-top: 10px;
}

.sf-card__text + .sf-card__bottom-text {
  margin-top: 0;
}

.sf-card_short {
  width: 285px;
  max-width: 100%;

  // @include break-max($breakpoint-sm) {
  //   width: 266px;
  // }

  .sf-card__pic {
    height: 180px;
  }

  .sf-card__row {
    grid-template-columns: repeat(2, 1fr);
  }

  .sf-card__col:last-of-type {
    text-align: right;
  }
}

.sf-card__status.status-circle {
  position: absolute;
  top: 8px;
  right: 8px;
  outline: 2px solid $color-white;
  position: absolute;
  top: 20px;
  right: 20px;
}

.sf-card_bordered {
  cursor: pointer;
  .sf-card__pic {
    border: 2px solid $gray-light;
    object-fit: contain;
  }
}



.sf-card__btn-list {
  position: absolute;
  top: 18px;
  right: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;

  .sf-card__btn {
    border-radius: 20px;
    width: 40px;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition-duration: .3s;

    &:after {
      content: '';
      background-color: $color-bg-light;
      border-radius: 20px;
      position: absolute;
      white-space: nowrap;
      z-index: 1;
      left: 60px;
      padding: 20px 15px;
      opacity: 0;
      transition-duration: .3s;
    }

    &:before {
      border: 4px solid transparent;
      border-right: 7px solid $color-bg-light;
      content: "";
      position: absolute;
      left: 50px;
      opacity: 0;
      transition-duration: .3s;
    }

    &:hover:after {
      opacity: 1;
      transition-duration: .3s;
    }

    &:hover:before {
      opacity: 1;
      transition-duration: .3s;
    }
  }

  .sf-card__btn_heart {
    &:after {
      content: 'Добавить в избранное';
    }
  }

  .sf-card__btn_cube {
    &:after {
      content: 'Есть аэропанорама';
    }
  }

  .sf-card__btn_chrome {
    &:after {
      content: 'Доступен АвтоПАН';
    }
  }
}

.sf__cards-list .sf-card:nth-child(3n) .sf-card__btn-list .sf-card__btn:after{
  right: 60px;
  left: auto;

  @include break-max($breakpoint-xsm) {
    left: 60px;
    right: auto;
  }
}
.sf__cards-list .sf-card:nth-child(3n) .sf-card__btn-list .sf-card__btn:before{
  border: 4px solid transparent;
  border-left: 7px solid #f6f7f9;
  left: -20px;

  @include break-max($breakpoint-xsm) {
    left: 53px;
    border-right: 7px solid #f6f7f9;
    border-left: 0;
  }
}

.sf__cards-list .sf-card:nth-child(2n) .sf-card__btn-list .sf-card__btn:after{
  @include break-max($breakpoint-xsm) {
    right: 60px;
    left: auto;
  }
}
.sf__cards-list .sf-card:nth-child(2n) .sf-card__btn-list .sf-card__btn:before{
  @include break-max($breakpoint-xsm) {
    border: 4px solid transparent;
    border-left: 7px solid #f6f7f9;
    left: -20px;
  }
}

.sf__cards-list .sf-card .sf-card__btn-list .sf-card__btn:before {
  @include break-max($breakpoint-xs) {
    left: -20px!important;
    border: 4px solid transparent!important;
    border-left: 7px solid #f6f7f9!important;
  }
}

.sf__cards-list .sf-card .sf-card__btn-list .sf-card__btn:after {
  @include break-max($breakpoint-xs) {
    right: 60px!important;
    left: auto!important;
  }
}

.sf__map-block_dubai {

  .sf-card .sf-card__btn-list .sf-card__btn:after{
    right: 60px;
    left: auto;
  }
  .sf-card .sf-card__btn-list .sf-card__btn:before{
    border: 4px solid transparent;
    border-left: 7px solid #f6f7f9;
    left: -20px;
  }
}

.sf-card__header:hover {

  .sf-card__btn-list {
    opacity: 1;
    transition-duration: .3s;
  }
}

.sf-card__bottom-status {
  border-top: 1px solid $gray-dark;
  padding-top: 12px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: $gray-light-2;
  font-size: 13px;
  svg {
    margin-right: 10px;
    margin-top: 1px;
  }
  svg.red circle {
    stroke: $color-red-bottom-border;
  }
  svg.red line {
    stroke: $color-red-bottom-border;
  }
  svg.yellow circle {
    stroke: #FFA100;
  }
  svg.yellow line {
    stroke: #FFA100;
  }
  svg.green circle {
    stroke: #2CBA2E;
  }
  svg.green line {
    stroke: #2CBA2E;
  }

}

.sf-card__before-title {
  color: $gray-dark-2;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-dark;
  margin-bottom: 10px;
}

.sf-card__btn-list-bottom {
  bottom: 18px;
  top: unset;
}

























