.dropinfo {
  width: 100%;
  padding: 11.5px 13px;
  display: flex;
  align-items: center;
  background-color: $color-brand-opaque-8;
  border-radius: 4px;

  margin-bottom: 24px;
  transition: all 0.3s;
  cursor: pointer;

  .info {
    margin-right: 12px;
    flex-shrink: 0;
  }

  .h6 {
    display: block;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .collapse__chevron {
    margin-left: auto;
    margin-right: 5px;
    align-self: flex-start;
    margin-top: 14px;

    svg {
      transform: rotate(0);
    }
  }
}

.dropinfo__block {
  display: flex;
}

.dropinfo__hidden-text {
  display: none;
  margin-top: 12px;
  padding-bottom: 10px;
}


.dropinfo.open {
  
  .dropinfo__hidden-text {
    display: block;
  }

  .collapse__chevron svg {
    transform: rotate(180deg);
  }
}