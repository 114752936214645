@import "styles/main";
@import "components/Suburbans/index";
@import "components/Cards/pancard.scss";

.Toastify__toast {
  border-radius: 4px;
  padding: 16px;
  font-family: "Formular", sans-serif;
  min-height: 0;
}

.Toastify__toast--error {
  background-color: $color-red;
}

.Toastify__toast--default {
  color: #1e2030;
}

.info__popup-clients {
  width: 330px;
}

.clients__info-actions {
  display: flex;
  justify-content: space-between;
}

.clients__info-actions .button_type_edit-small {
  font-size: 12px;
}

.client__aside {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.client__feed {
  margin-bottom: 0;
  margin-top: 26px;
}

.client__id-item:last-child {
  margin-bottom: 0;
}

.client__data {
  -webkit-box-shadow: 0 2px 20px 0 rgba(143, 149, 172, 0.1);
  box-shadow: 0 2px 20px 0 rgba(143, 149, 172, 0.1);
  padding-bottom: 32px;
}

.checkbox_view_invert .checkbox__box.checkbox__box-error {
  -webkit-box-shadow: inset 0 0 0 1px #ff2d23;
  box-shadow: inset 0 0 0 1px #ff2d23;
}

.button div.task {
  justify-content: start;
}

@media screen and (max-width: 1599px) {
  .complex__actions .button__files {
    padding: 22px 28px;
  }
}

.complex__about-info {
  font-size: 25px;
  line-height: 32px;
  font-weight: 300;
  text-align: justify;
}

.cards__item {
  display: flex;
}

.show__map {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(143, 149, 172, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.show__map .icon_map-pin {
  margin: 0;
}

.map-card__image {
  height: 228px;

  @include break-max($break-xs) {
    height: auto;
    margin-right: 16px;
    min-width: 45%;
  }
}

.map-card__image img {
  height: 100%;
  object-fit: cover;

  @include break-max($break-xs) {
    height: 100px;
    width: 165px;
  }
}

.complex__news {
  display: block;
}

.complex__news .tns-carousel {
  display: flex;
}
.complex__blocks-slider .tns-carousel {
  display: flex;
}

.complex__blocks-slider .tns-carousel .cards__item.tns-item {
  display: flex;
}

.lk-form__logo-image {
  overflow: hidden;
}

.pan-table {
  margin-bottom: 25px;
}

.pan-table .ant-table {
  z-index: initial;
}

.ant-back-top.back-top {
  right: 10px;
  bottom: 40px;
  width: 30px;
  height: 30px;

  @include break-max($break-xs) {
    bottom: 70px;
  }
}

.ant-back-top.back-top:hover .round-top {
  opacity: 0.3;
}

@media screen and (max-width: 1599px) {
  .cards__item {
    display: block;
  }
}

.index-search__filter-main .form__row .form__item {
  margin-bottom: 12px;
}
.manager-contacts_item a {
  color: #1e2030;
}

.card__image img {
  height: 100%;
}

@media screen and (max-width: 1279px) {
  .contacts__grid {
    margin-left: -20px;
    margin-right: -20px;
  }

  .md-hidden {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .contacts__grid-col {
    max-width: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .sm-hidden {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .contacts__grid {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .contacts__grid-col {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.top__title span {
  white-space: nowrap;
}

.Toastify__toast--default {
  background: #7684bc;
  border-radius: 4px;
  color: #fff;
}

.Toastify__close-button--default {
  display: flex;
  align-self: center;
}

.Toastify__toast--success {
  background: $green;
  .icon_info {
    fill: $color-white;
  }
}

.Toastify__toast {
  display: flex;
}

.Toastify__close-button {
  align-self: flex-start;
  background: rgba($color-white, 0.7);
  border-radius: 50em;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}

.Toastify__close-button > svg {
  margin: 2px 0 0 1px;
  width: 15px;
}

.Toastify__wrap {
  align-items: center;
  display: flex;
  line-height: 20px;
}

.Toastify__icon-wrap {
  align-items: center;
  align-self: flex-start;
  border: 1px solid rgba($color-white, 0.5);
  border-radius: 50em;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}

.Toastify__error-icon {
  flex-shrink: 0;
  margin-right: 10px;
}

.Toastify__error-sign {
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% + 2px);
  left: 30px;
}

.Toastify__toast--info {
  background: $blue;

  .icon_info {
    fill: $color-white;
  }
}

.Toastify__toast--info .Toastify__close-button > svg {
  fill: $blue;
}

.Toastify__toast--success .Toastify__close-button > svg {
  fill: $green;
}

.Toastify__toast--error .Toastify__close-button > svg {
  fill: $color-red;
}

.Toastify__text {
  display: block;
}

.Toastify__list {
  display: flex;
  margin-top: 10px;

  li {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 35px;
    width: 95px;
  }
}

.Toastify__list-active {
  background: rgba($color-white, 0.9);
  border-radius: 100px;
  color: $blue;

  &:hover {
    background: rgba($color-white, 0.6);
  }
}

.Toastify__toast-body {
  margin: 0;
}

.profile__aside {
  position: relative;
}

.profile__manager {
  position: sticky;
  top: 140px;
}

.map__list .simplebar-track.simplebar-horizontal {
  display: none;
}
.card-heart-selected {
  height: 50px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(143, 149, 172, 0.2);
  box-shadow: inset 0 0 0 1px rgba(143, 149, 172, 0.2);
  background-color: #fff;
}

.floor__plan-svg {
  overflow: hidden;
  width: 100%;
  height: 500px;

  @include break-max($break-xs) {
    height: 300px;
  }
}

.floor__plan-svg .sect {
  fill: rgba(228, 7, 19, 0.3);
}

.floor__plan-svg image {
  width: 100%;
  /* height: 600px; */
  display: block;
}

.floor-plan .sect {
  fill: rgba(228, 7, 19, 0.3);
}

.flat__nav-link {
  background: none;
  cursor: pointer;
}

.flat__info-btns {
  justify-content: flex-start;

  @include break-max($break-xs) {
    width: 100%;
  }
}

.input .react-datepicker__day--keyboard-selected,
.input .react-datepicker__month-text--keyboard-selected,
.input .react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #ff2d23;
  color: #fff;
}

.input .react-datepicker {
  font-family: "Formular";
  border: none;

  -webkit-box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
}

.input .react-datepicker__header {
  text-align: center;
  background-color: #2c2e3e;
  border: none;
  color: #fff;
}

.input .react-datepicker__day-name,
.input .react-datepicker__time-name {
  color: #fff;
}

.input .react-datepicker__navigation--next {
  border-left-color: #ffffff;
}

.input .react-datepicker__navigation--previous {
  border-right-color: #ffffff;
}

.input .react-datepicker__current-month,
.input .react-datepicker-time__header,
.input .react-datepicker-year-header {
  color: #ffffff;
}

.input .react-datepicker__today-button {
  color: #ffffff;
  background-color: #2c2e3e;
  padding: 10px 0;
}

.input .react-datepicker-popper {
  -webkit-box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  border-radius: 4px;
}

.input
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle {
  border-bottom: none;
  border-top-color: #ffffff;
}

.input
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  border-top: none;
  border-bottom-color: #2c2e3e;
}

.input .react-datepicker__day--selected,
.input .react-datepicker__day--in-selecting-range,
.input .react-datepicker__day--in-range,
.input .react-datepicker__month-text--selected,
.input .react-datepicker__month-text--in-selecting-range,
.input .react-datepicker__month-text--in-range,
.input .react-datepicker__quarter-text--selected,
.input .react-datepicker__quarter-text--in-selecting-range,
.input .react-datepicker__quarter-text--in-range {
  background-color: #ff2d23;
}

.input
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #ff2d23;
}

.shadow {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.04), 0 10px 10px rgba(0, 0, 0, 0.08);
}
.complex__promo-image img {
  object-fit: cover;
}

.complex__manager-contacts_item a {
  color: #1e2030;
}

.complex__slider-magnifier {
  right: 20px;
  bottom: 34px;
  background-color: $gray-light;
}

.complex__address {
  @include break-max($break-xs) {
    align-items: flex-start;
    padding-top: 15px;
  }
}

.complex__address-value {
  font-size: 14px;
  line-height: 18px;
  color: #8f95ac;
}

.complex__map-wrap {
  width: 100%;
  height: 100%;
}

.complex__main {
  margin-top: 0;
}


.navigation__block .complex__navigation-item {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 16px;
}

.complex__navigation-item {
  cursor: pointer;
}

.complex-filter_wrapper {
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.complex-filter_wrapper > .filtered,
.complex-filter_wrapper > .filtered {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.margin-sect {
  margin-top: 80px;
}
.complex-filter_wrapper {
  flex-wrap: wrap;
}

.complex__blocks-slider .tns-ovh {
  overflow: visible !important;
}

.complex__blocks-slider .tns-item {
  visibility: hidden;
}
.complex__blocks-slider .tns-slide-active {
  visibility: visible !important;
}
.complex__blocks-slider .popover__block {
  transform: translate(10px, -100%);
}
.complex__blocks-slider .popover__block:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  content: "";
  display: block;
  position: absolute;
  top: calc(100% - 12px);
  left: -webkit-calc(100% - 10px);
  left: calc(100% - 10px);
  border-bottom: 14px solid #fff;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
}
.section__another {
  overflow: hidden;

  @include break-max($break-xs) {
    overflow: visible;
  }
}
.complex__tabs .tns-slider {
  display: flex;
}
.complex__tabs-item {
  outline: none;
  user-select: none;
}

#assignations-slider .flats__card-image img {
  transition: all 0.3s;
}
#assignations-slider .slider__item:hover img {
  transform: scale(1.1);
}
.ant-table-row {
  cursor: pointer;
}
.not-found-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 101px;
  text-align: center;
  padding-bottom: 112px;
  background: white;
  height: 100%;
}
.not-found-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}
.not-found-button {
  width: fit-content;
  text-align: center;
}
.not-found-second-text {
  font-size: 14px;
  line-height: 21px;
  margin: 23px 0 35px 0;
}
.not-found-image {
  width: 100%;
}

.fatal-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  background: $color-white;
  padding: 70px 0 60px;
  height: 100vh;

  @media (max-height: 500px) {
    padding: 30px 0 30px;
  }
}
.fatal-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  @include break-max($breakpoint-md) {
    width: 60%;
  }
  @include break-max($breakpoint-sm) {
    width: 80%;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}
.fatal-title {
  margin-bottom: 20px;
  @include break-max($breakpoint-xsm) {
    font-size: 24px;
    line-height: 26px;
  }
}
.fatal-button {
  width: fit-content;
  text-align: center;
}
.fatal-second-text {
  margin-bottom: 40px;
  padding: 0 15px;
  @include break-max($breakpoint-xsm) {
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 30px;
  }
}
.fatal-image {
  width: 65%;
  margin-bottom: 40px;

  @media (max-height: 500px) {
    width: 20%;
    margin-bottom: 20px;
  }
}


.big-info-container {
  white-space: pre-wrap;
}
.card__pill {
  max-width: 90%;
  white-space: pre-wrap;
  line-height: 20px;
  padding: 5px 12px;

  @include break-max($break-xs) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.filter-show-on-map {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.filter-show-on-map:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  color: #cf2d23;
}
.filter-show-on-map:hover .icon {
  fill: #cf2d23;
}

.complex__section-about .complex__about-info {
  font-size: 18px;
  @include break-max($break-xs) {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

}

.complex__slider-tab iframe {
  min-height: 550px;
}

.complex__section-about {
  .complex__about-feature {
    font-size: 18px;
    line-height: 24px;

    @include break-max($break-xs) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .complex__about-features {
    @include break-max($break-xs) {
      padding: 10px 30px;
    }
  }

  .complex__about-features_list {
    @include break-max($break-xs) {
      flex-direction: column;
    }
  }
}

.aero-icon {
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.aero-icon:hover::after {
  content: "Есть аэропанорама"; /* Выводим текст */
  position: absolute;
  left: 80%;
  top: 30%;
  z-index: 1;
  background: white;
  font-size: 11px;
  padding: 5px 10px;
  box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
  border-radius: 4px;
  white-space: nowrap;
}



.work-company__logo {
  height: 36px;
  width: auto;
  max-width: 66px;
}

.work-company__logo img {
  object-fit: contain;
  width: auto;
}

.header__logo.icon_logo {
  max-height: 65px;
}

.content {
  position: relative;
  z-index: 1;
}

.content_redesign-mobile {
  @include break-max($breakpoint-xs) {
    .sf__h1 {
      margin-top: 25px;
    }

    .filter__max.form_dubai-secondfilter {
      margin-top: 0;
      margin-bottom: 0;
    }

    .sf__cards-list_dubai-complex-list {
      margin-top: -5px;
    }

    .sf-slider .swiper-container:not(.swiper-thumbs) .sf-slide_main img {
      border-radius: 0;
    }

    .swiper-thumbs {
      position: absolute;
      margin-top: -50px;
      width: 100%;
    }
    .swiper-container-free-mode > .swiper-wrapper {
      display: flex;
      justify-content: center;
    }
    .swiper-thumbs .swiper-slide:last-child {
      margin-right: 0!important;
    }
    .swiper-thumbs .swiper-slide {
      width: 7px;
      border-radius: 50px;
      height: 7px;
      background-color: white;
      opacity: 0.6;
      img {
        display: none;
      }
    }
    .swiper-thumbs .swiper-slide.swiper-slide-thumb-active {
      opacity: 1;
    }
    .btn.swiper-zoom {
      display: none;
    }

    .swiper-thumbs-block {
      gap: 10px;
    }
    .sf-slider-btn {
      min-width: calc(50% - 8px);
      justify-content: center;
      span {
        color: black;
      }
    }
    //.sf__header {}
  }
}

.dropdown__block.left {
  left: auto;
  right: 0;
  border-radius: 4px 0 4px 4px;
}

.section__personal-header {
  z-index: 11;
}

.complex__about-feature {
  flex-basis: 28%;
  flex-grow: 0;
}

.clear-after {
  margin-right: 0;
}
.clear-after::after {
  content: "";
  display: none;
}

.smart-serach-result{
  position: absolute;
  top: 50px;
  z-index: 99999;
  background: white;
  box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
  border-radius: 4px;
  padding: 20px 11px 23px 13px;
  color: #2C2E3E;

  @include break-max($break-xs) {
    left: 0;
    min-width: 100%;
    width: calc(100% + 38px);
  }

  li {
    display: flex;
    align-items: center;
    line-height: 13px;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    padding-left: 11px;
    padding-right: 10px;
    padding: 10px 10px 10px 11px;
  }
  li:hover{
    background: rgba(143, 149, 172, 0.1);
  }
  li.selected{
    background: rgba(143, 149, 172, 0.1);
    .exclude{
      visibility: visible;
    }
  }
  .exclude{
    margin-left: auto;
    color: #8F95AC;
    align-items: center;
    display: flex;
    height: 100%;
    visibility: hidden;
  }
  .exclude:hover{
   color: #ff2d23;
  }

}

//.smart-serach-result_dubai {
.smart-serach-result_redesign {
  left: 0;
  right: -246px;
  @include break-max($wrapper) {
    right: -212px;
  }
  @include break-max($wrapper-md) {
    right: -38px;
  }
}

@media screen and (max-width: 1279px){
.form__item_2x {
    flex: 0 0 calc(100% / 4 * 3 - 12px);
    margin-bottom: 0!important;
  }
}


.public-assignments-wrapper{
  display: flex;
  flex-wrap: wrap;

  @include break-max($break-xs) {
    background: $color-white;
    justify-content: center;
    padding-top: 0;
  }
}
.full-width-list-item{
  width: 100% !important;
  background: transparent;
}
.forced-enable-z{
  z-index: 1!important;
}
.copy-fake-input{
  position :fixed;
  top: -100px;
  left: 0;
  width: 2em;
  height: 2em;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
}
.smart-filter{
  display: flex;
  height: 100%;
  cursor: text;
  line-height: 22px;
}
.smart-filter-item.exclude {
    color: #ff2d23
}
.contenteditable-placeholder {
  color: #828482;
  white-space: nowrap;
}
.manager-avatar {
  @include break-max($break-xs) {
    flex-basis: 30%;
    margin-right: 0;
  }
}

.manager-avatar_wrapper{
  display: flex;
  img{
    margin: auto
  }

  @include break-max($break-xs) {
    height: 100px;
    width: 100px;
  }
}
.smart-filter-result:hover{
  .exclude{
    visibility: visible;
}
}

.fixate-client {
  .modal-form__files {
    padding-bottom: 15px;
    border: none;
  }
}
.fixation-badges {
  margin-right: 10px;
  margin-top: 10px;
  &-container{
    padding-bottom: 12px;
    flex: 1 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  }
}
#realtyWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.banners__grid-col a {
  text-decoration: none;
}
.profile-drop__item a {
  text-decoration: none;
}
.input_type_form .input__error {
  -webkit-box-shadow: inset 0 0 0 1px #ff2d23;
  box-shadow: inset 0 0 0 1px #ff2d23;
}
.input__error-label {
  font-size: 14px;
  line-height: 17px;
  color: #ff2d23;
  display: block;
  margin-top: 5px;
}
.form__error-text {
  background: rgba(255, 45, 35, 0.1);
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #ff2d23;
  padding: 10px;
  margin-bottom: 10px;
}
.form__info-text {
  background: rgba(143, 149, 172, 0.15);
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #8f95ac;
  padding: 10px;
  margin-bottom: 10px;
}
.index-search__filter-tip {
  display: block;

  margin-top: 0;
  margin-bottom: 0;
}
.header__top-profile-avatar {
  overflow: hidden;
}
.mini-filter .form__drop-content {
  min-width: 350px;
}
.custom-select.is-showing {
  -webkit-box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  background-color: #fff;
}
.files__item-name {
  color: #1e2030;
}

.folder__name {
  color: #1e2030;
}

.contact__photo img {
  object-fit: cover;
}

.index-search__filter-inc.no-border {
  padding-left: 0;
  border: none;
}
.index-search__filter-inc.no-border:after {
  content: none;
}

.custom-select.is-showing .custom-select__options {
  display: block;
  z-index: 100;
  -webkit-box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
  box-shadow: 0 2px 10px rgba(143, 149, 172, 0.2);
}
.select_type_form .custom-select.is-showing .custom-select__selected {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-select.is-showing .custom-select__selected {
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  background-color: rgba(143, 149, 172, 0.2);
}
.form__drop-content.no-fix-height {
  height: auto;
}

.map-list__item:last-child {
  border-bottom: none;
}

.header {
  //position: relative;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020; // больше чем у header__bottom
  transition: all 0.3s ease;
}
.header.hide-second-header .header__bottom {
  opacity: 0;
  height: 0;
  padding: 0;
}

.header.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.section__map {
  height: 900px;

  @include break-max($break-xs) {
    height: 100%;
    position: fixed;
    overflow: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}

.preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  /* flex-flow: row; */
  top: 0;
  left: 0;
  /* text-align: center; */
  z-index: 10;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear 0s, visibility 0.2s linear 0.3s;
}

.preloader.loading {
  opacity: 1;
  visibility: visible;
  transition: none;
}

.section__personal-header {
  z-index: 8;
  position: relative;
}

.modal.pass-recover {
  z-index: 9;
}

.assignations-flats__row .ant-table-row {
  cursor: pointer;
}

.search-assignments-controls {
  display: flex;
  flex: 1;
  justify-content: center;
}

.search-assignments-controls button {
  margin: 0 10px;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #8f95ac;
  border-radius: 100%;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  margin: 0 2px;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.button > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flats__card_compact .flats__card-image {
  padding: 0 20px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1279px) {
  .flats__card-image {
    height: 240px;
  }
}
.table-image{
  max-width: 80px;
  max-height: 45px;
}
.table-image-scalable{
  transition: all .3s;
  max-width: 80px;
  max-height: 45px;
}
.table-image-scalable:hover{
  transform: scale(5);
  transform-origin: left;
}
.image-table-cell{
  z-index: 10;
}
.image-table-cell:hover{
  z-index: 99;
}

.lk-form__multi-row{
  padding-bottom: 24px;
}
.ant-table-second-color{
  background: $gray-light
}
.ant-table-actualize-color{
  background: #fff8eb
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset!important;
}
td.ant-table-cell-fix-left, td.ant-table-cell-fix-right{
  background: unset!important;
}
.ant-table-tbody > tr:hover{
  background: #e9eaee;
}
.bot-wrapper{
  width: 100%;
  min-height: calc(100vh - 116px);
  display: flex;
  justify-content: center;
}
.bot-content{
  display: flex;
  flex-direction: column;
  width: 70%;
}
.bot-content object{
  @media screen and (max-width: 1280px) {
    max-height: 70%;
    position: absolute;
    bottom: 0;
  }
}
.bot-title{
  font-family: Formular;
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  margin: 90px 0 42px;
  z-index: 2;

  @include break-max($breakpoint-xs) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
  }
}
.bot-title-red{
  background: #FF2D23;
  border-radius: 5px;
  color: white;
  padding: 0 5px;
}
.bot-button{
  position: absolute;
  bottom: 70px;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;

  @include break-max($breakpoint-xs) {
    bottom: 20px;
    width: calc(100% - 20px);
  }
}

.input_type_form .input__label {
  @include break-max($break-xs) {
    white-space: normal;
  }
}
.with-tooltip {
  .tooltip{
    position: absolute;
    display: none;
    padding: 5px;
    background-color: #fff;
    width: fit-content;
    color: #000;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
    margin-top: 10px;
    z-index: 9999;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
      width: calc(100% + 60px);
      height: calc(100% + 40px);
      z-index: -1;
    }
  }
  &:hover .tooltip{
    display: block;
  }
}
.with-tooltip-disabled {
  .tooltip {
    display: none;
  }
}

a[disabled] {
  pointer-events: none;
}
.skeletoned > * {
  color: transparent!important;
  background: #e3e0e0c4;
  cursor: unset;
}
.skeletoned > * > * {
  background: #e3e0e0c4;
  color: transparent!important;
  border-radius: 6px;
}
.skeletoned > * > * > * {
  color: transparent!important;
}

.pre-line {
  white-space: pre-line;
}