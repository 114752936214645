.section {
  transition: background-color $transition-default;

  &_view {

    &_top {
      @include break-max($break-xs) {
        position: absolute;
        top: 50px;
        width: 100%;
      }
    }

    &_white {
      background-color: #fff;
    }

    &_gray {
      background-color: $color-brand-opaque-8;
    }
  }

  &__grid {
    @include grid();

    &_column {
      flex-direction: column;
    }
  }

  &__grid-col {
    @include grid-col();

    @include break-max($break-xs) {
      margin: 0 10px;
    }
  }

  &__heading {
    margin-right: auto;

    @include break-max($breakpoint-sm) {
      margin: 10px 0;
    }

  }

  &__title {
    // font-size: 35px;
    // line-height: 55px;
  }

  &__cards {
    display: block;
  }

  &__map {
    display: none;
    padding-bottom: 100px;
    overflow: hidden;
    max-height: 900px;
  }
}

.mobile-section-dark {
  @include break-max($break-xs) {
    & .calc__grid {
      background: $color-brand-2;
      margin: -27px -10px 0;
      padding: 23px 5px 0;
    }

    & .calculator__result-value {
      color: $color-white;
    }

    & .calc__manager.calc__col,
    & .calc__form.calc__col.mortgage__calc {
      width: calc(100% - 10px);
    }

    & .calc__manager.calc__col {
      border-top: 1px solid $color-brand-opaque-40;
      color: $color-brand-3;
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    & .calc__manager-card {
      display: flex;
      margin-top: 20px;
    }

    & .lk-order__contacts-link,
    & .calc__manager-name {
      color: $color-white;
    }

    & .calc__manager-photo {
      width: 105px;
      height: 105px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 30px;
    }

    & .calculator .slider__input {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 95%;
    }

    & .calc__manager-contacts {
      margin-top: 15px;
    }
  }
}
