@import "../../styles/variables";
@import "../../styles/mixins";

.preview .sor {
  margin-top: 85px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }

  .input__control {
    background-color: $color-white;
  }

  .custom-select__options {
    background-color: $color-white;
  }
}

.sor {
  color: $color-brand-1;
  @include break-max($breakpoint-xs) {
    .wrapper__center {
      padding: 0 10px;
    }
  }
}

.sor__promo {
  min-height: 760px;
  background: $color-brand-opaque-8;

  @include break-max($breakpoint-md) {
    min-height: 625px;
  }

  @include break-max($breakpoint-sm) {
    min-height: 587px;
  }

  @include break-max($breakpoint-xs) {
    min-height: 947px;
  }

}

.sor__box {
  padding-top: 160px;
  position: relative;
  @include break-max($breakpoint-md) {
    padding-top: 110px;
  }
  @include break-max($breakpoint-sm) {
    padding-top: 60px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 35px;
  }
}

.sor__h1 {
  width: 600px;
  max-width: 100%;
  font-size: 70px;
  line-height: 70px;
  font-weight: 900;

  margin-bottom: 45px;

  @include break-max($breakpoint-md) {
    width: 414px;
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 35px;
  }
  @include break-max($breakpoint-sm) {
    width: 330px;
    font-size: 45px;
    line-height: 45px;
  }
  @include break-max($breakpoint-xs) {
    width: 90%;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}

.sor__sign {
  margin-bottom: 45px;
  @include break-max($breakpoint-md) {
    margin-bottom: 30px;
  }
  @include break-max($breakpoint-sm) {
    width: 255px;
  }
  @include break-max($breakpoint-xs) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.sor__promo-pic {
  width: 485px;
  max-width: 100%;
  position: absolute;
  top: 70px;
  right: -100px;
  @include break-max($breakpoint-md) {
    width: 415px;
    top: 45px;
  }
  @include break-max($breakpoint-sm) {
    width: 335px;
    top: 55px;
    right: 0;
    overflow: hidden;
  }
  @include break-max($breakpoint-xs) {
    position: relative;
    top: 0;

    width: 305px;
    margin-left: auto;
    margin-right: auto;
  }
}

.sor__promo-img-lg {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 2;

  @include break-max($breakpoint-md) {
    max-width: 338px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 269px;
    top: 33px;
    right: 33px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 240px;
  }

}

.sor__promo-img-sm {
  position: absolute;
  border: 10px solid $gray-light;
  border-radius: 50%;
  bottom: -40px;
  left: -55px;
  z-index: 2;
  @include break-max($breakpoint-md) {
    max-width: 183px;
    bottom: -30px;
    left: -25px;
  }
  @include break-max($breakpoint-sm) {
    max-width: 145px;
    bottom: -20px;
    left: -15px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 130px;
    left: 0;
    bottom: 0;
  }
}

.sor__promo-svg {

}

.sor__promo-btns {
  @include break-max($breakpoint-sm) {
    display: flex;
    flex-direction: column;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 50px;
  }
}

.sor__promo-btn {
  width: 220px;
  display: inline-flex;
  justify-content: center;

  @include break-max($breakpoint-sm) {
    width: 232px;
  }



  &:first-of-type {
    margin-right: 30px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 12px;
      margin-right: auto;
    }
  }


  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

}

.sor__faq {
  position: relative;

  margin-top: 95px;
  display: flex;
  justify-content: space-between;

  @include break-max($breakpoint-sm) {
    margin-top: 55px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 25px;
    flex-direction: column;
  }
}

.sor__faq-item {
  width: 345px;
  max-width: 100%;
  padding-right: 35px;

  @include break-max($breakpoint-md) {
    width: 284px;
  }

  @include break-max($breakpoint-sm) {
    width: 250px;
    padding-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.sor__faq-title {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-brand-3;
}

.sor__faq-descr {
  font-size: 16px;
  line-height: 20px;
  color: $color-brand-2;
  font-weight: bold;
}

.sor__about {
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
  @include break-max($breakpoint-sm) {
    padding-top: 35px;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.sor__about-item {
  width: 345px;
  max-width: 100%;
  padding-right: 35px;

  @include break-max($breakpoint-md) {
    width: 284px;
  }
  @include break-max($breakpoint-sm) {
    width: 250px;
    padding-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 35px;
    padding-right: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.sor__about-header {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-brand-3;
  margin-bottom: 18px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 8px;
  }
}

.sor__about-counter {
  padding-right: 5px;
  color: $color-red;
}

.sor__about-title {

}

.sor__about-text {

}

.sor__prog {
  position: relative;
  padding-top: 95px;
  @include break-max($breakpoint-sm) {
    padding-top: 50px;
  }
}

.sor__prog-t {
  padding-bottom: 35px;
  border-bottom: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-sm) {
    padding-bottom: 25px;
  }
}

.sor__prog-title {
  width: 430px;
  max-width: 100%;
  font-size: 35px;
  line-height: 40px;
  font-weight: 900;

  @include break-max($breakpoint-md) {
    width: 410px;
    font-size: 30px;
    line-height: 35px;
  }
  @include break-max($breakpoint-sm) {
    width: 336px;
    font-size: 25px;
    line-height: 30px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 20px;
    line-height: 22px;
  }


}

.sor__h2 {

}

.sor__prog-list {

}

.sor__prog-item {
  padding-top: 35px;
  border-bottom: 1px solid $color-brand-opaque-20;
  &:last-of-type {
    border-bottom: none;
  }

  .sor__h3 {
    @include break-max($breakpoint-xs) {
      padding-right: 65px;
    }
  }
}

.sor__prog-header {
  position: relative;
  padding-bottom: 45px;
  cursor: pointer;
  @include break-max($breakpoint-xs) {
    padding-bottom: 35px;
  }
}

.sor__prog-label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $color-brand-3;
  border-radius: 4px;
  text-transform: uppercase;
  color: $color-white;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  width: 120px;
  height: 28px;
  margin-bottom: 15px;
}

.sor__h3 {

}

.sor__prog-body {
  display: flex;
  align-items: flex-start;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s;
  &.opened {
    max-height: 3000px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.sor__prog-img {
  margin-right: 85px;
  border-radius: 4px;

  @include break-max($breakpoint-sm) {
    margin-right: 35px;
    max-height: 170px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    width: 100%;
    max-height: 203px;
    object-fit: cover;
  }
}

.sor__prog-ul {

  max-width: 100%;

  @include break-max($breakpoint-sm) {

  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }


  li {
    position: relative;
    display: flex;
    margin-bottom: 25px;

    @include break-max($breakpoint-sm) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background: $color-red;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      margin-right: 30px;
      margin-top: 10px;
    }
  }
}

.sor__prog-btn {
  width: 220px;
  margin-top: 30px;
  margin-bottom: 60px;
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 30px;
  }
}

.sor__footer {
  padding-top: 85px;
  padding-bottom: 75px;
  background: $color-brand-opaque-8;

  @include break-max($breakpoint-md) {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 125px;
  }

  .checkbox__box {
    background: $color-white;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
  }

  .custom-select__selected {
    background: $color-white;
    height: 50px;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
  }

  .custom-select.is-showing .custom-select__selected {
    background: none;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .form__item {
    margin-bottom: 0;
    margin-left: 0;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;

    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .dropdown__btn {
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
    &:hover {
      box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
    }
  }
}

.sor__footer-title {
  width: 720px;
  max-width: 100%;
  font-size: 35px;
  font-weight: 900;
  line-height: 40px;

  @include break-max($breakpoint-md) {
    font-size: 30px;
    line-height: 35px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 20px;
    line-height: 22px;
  }

}

.sor__form {
  position: relative;
}

.sor__form-box {
  padding-top: 25px;
  display: flex;
  align-items: flex-end;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: center;
  }
}

.sor__form-item {
  width: 220px;
  margin-right: 30px;
  @include break-max($breakpoint-sm) {
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    margin-right: 0;
    width: 100%;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  input {
    width: 220px;
    max-width: 100%;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  &:last-of-type {
    flex-grow: 1;
    input {
      width: 100%;
    }
  }
}

.sor__form-footer {
  margin-top: 10px;
}

.sor__submit {

  @include break-max($breakpoint-sm) {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.sor__accordion-btn {
  position: absolute;
  right: 0;
  top: 50%;
  width: 45px;
  height: 45px;

  transform: translateY(-50%);
  border-radius: 50%;
  background: $color-brand-opaque-10;

  transition: transform 0.6s;
  cursor: pointer;

  &:hover {
    background: $color-brand-opaque-20;
  }

  .icon {
    font-size: 14px;
    stroke: $color-brand-3;
    fill: transparent;
  }

  &.opened {
    transform: translateY(-50%) rotate(180deg);
  }
}
