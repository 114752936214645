.constructor__section + .banner {
  margin-top: -100px;
  @include break-max($breakpoint-md) {
    margin-top: -60px;
  }
  @include break-max($breakpoint-xsm) {
    margin-top: -50px;
  }

  &:not(.banner_property) {
    margin-bottom: 0;
  }

}

.banner {
  position: relative;
  background: $color-2;
  color: $color-white;
}

.banner__wrapper {
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 220px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    justify-content: center;
  }

  &_center {
    justify-content: center;
  }

}

.banner__title {
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 40px;
    text-align: center;
  }
}

.banner__btn {
  position: relative;
  z-index: 2;
  margin-top: 0;
}

.banner__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  path {
    fill: $color-1;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.banner__text {
  margin-top: 5px;
  font-size: 21px;
  line-height: 30px;

  @include break-max($breakpoint-sm) {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
  }
}


.banner_property {

  margin-top: 0 !important;

  .banner__wrapper {
    min-height: 230px;
  }

  .banner__content {
    position: relative;
    z-index: 2;
  }

  .banner__img {
    left: auto;
    right: 50px;
    @include break-max($breakpoint-md) {
      right: 0;
    }
  }

  .banner__title {
    @include break-max($breakpoint-xs) {
      margin-top: 0;
    }
  }

  .banner__text {
    padding-right: 25px;
  }

  .banner__btn {
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
    }
  }
}
