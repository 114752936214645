@import '../../../styles/variables';
@import '../../../styles/mixins';

.menu {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  width: 406px;
  background-color: $color-white;
  transition: all 0.3s;
  z-index: 9999;
  padding: 26px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: $color-brand-2;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);

  transform: translateX(200%);
  transition: all 0.3s;

  @include break-max($breakpoint-xs) {
    width: 100%;
  }


  &::after {
    content: '';
    width: 100%;
    height: 40px;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: rgba($color-white, 0.8);
    filter: blur(4px);
    z-index: 3;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    
    svg {
      cursor: pointer;

      path {
        transition-duration: .3s;
      }

      &:hover path {
        stroke: #FF2D23;
      }
    }
  }

  &__title-main {
    @include h5-L();
  }

  &__close-menu {
    display: inherit;
  }

}

.menu.is-showing {
  transform: translateX(0);
}

.menu-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__user {
    display: flex;
    align-items: center;
  }

  &__user-logo {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
    flex-shrink: 0;
    background-color: $gray-light;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  &__user-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__user-fio {
    @include h5-L();
    margin-bottom: 3px;
  }

  &__user-toprofile {
    color: $gray-dark-2;

    &:hover {
      color: $color-red-bottom-border;
    }
  }

  &__logout {
    svg {
      cursor: pointer;

      path {
        transition-duration: .3s;
      }

      &:hover path {
        stroke: $color-red-bottom-border;
      }
    }
  }

 }

.menu-client-mode {
  background-color: $gray-light;
  border-radius: 20px;
  padding: 14px;
  display: flex;
  justify-content: space-between;

  &__title {
    @include h6-L();
    margin-bottom: 3px;
  }

  &__text {
    color: $gray-dark-2;
  }
}

.menu-cards-grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 16px;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    background: rgba($color-white, 0.8);
    filter: blur(4px);
    z-index: 3;
  }

  & > .menu-cards-grid__item {
    margin: 0 14px 14px 0;
    width: calc(50% - 7px);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &__item {
    border-radius: 20px;
    padding: 16px 14px;
    background-color: $gray-light;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    transition-duration: .3s;
  }

  &__item-title {
    @include h6-L();
    margin-bottom: 3px;
    color: $color-brand-2;
  }

  &__item-text {
    color: $gray-dark-2;
  }

  &__item-icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}

.menu-cards-grid__item_motivation {
  background-color: $one-more-blue;

  &:hover {
    background-color: #E3E5F8;
  }
}
.menu-cards-grid__item_crm {
  background-color: $one-more-yellow;

  &:hover {
    background-color: #FFF1BD;
  }
}


.menu-nav {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }


  &__item {
    display: flex;
    height: 50px;
    border-bottom: 1px solid $gray-dark;
    justify-content: space-between;
    align-items: center;
    transition-duration: .3s;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      transition-duration: .3s;
      .menu-nav__item-text {

        svg {
          transition-duration: .3s;

          path {
            stroke: #FF2D23;
            transition-duration: .3s;
          }
        }
      }

      .menu-nav__item-arrow {

        svg {
          transition-duration: .3s;
          transform: translate(5px,0);
          -webkit-transform: translate(5px,0);
          -o-transform: translate(5px,0);
          -moz-transform: translate(5px,0);

          path {
            stroke: #FF2D23;
            transition-duration: .3s;
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
  &__item-new {
    font-size: 13px;
    color: $green;
    background-color: $deal-completed-bg-status;
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 20px;
    font-weight: normal;
  }

  &__item-text {
    display: flex;
    align-items: center;
    @include h6-L();
    color: $color-brand-2;

    svg {
      margin-right: 13px;
    }

  }

  &__item-arrow {
    margin-right: 5px;
  }

  .menu-services {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $color-white;
    padding: 0px 30px 26px 30px;
    overflow: auto;
    z-index: 99999;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &::after {
      content: '';
      width: 100%;
      height: 40px;
      display: block;
      position: fixed;
      bottom: 0px;
      left: 0;
      background: rgba($color-white, 0.8);
      filter: blur(3px);
      z-index: 3;
      //border: 1px solid red;
    }

    &__back {
      padding-top: 26px;
      padding-bottom: 20px;
      position: sticky;
      top: 0;
      background-color: $color-white;

      &::before {
        content: '';
        width: 100%;
        height: 20px;
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        background: rgba($color-white, 0.8);
        filter: blur(5px);
        z-index: 3;
      }

    }

    &__back-arrow {
      display: inline-block;
      cursor: pointer;
    }
  }

}

.menu-nav_menu {
  margin-top: -20px;
}














