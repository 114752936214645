@import '../../../styles/variables';
@import '../../../styles/mixins';

.section-search {
  position: relative;

  &__inp {
    height: 50px;
    background-color: $gray-light;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 14px;
    font-family: 'Formular';
    width: 100%;
    padding-right: 40px;

    &::placeholder {
      color: #3C2E3E;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;

  }
}

.section-search_icon {
  .section-search__inp {
    padding-left: 44px;
  }
}
.chat-search-icon {
  position: absolute;
  padding-top: 12px;
  padding-left: 12px;
}
