@import "../../styles/variables";
@import "../../styles/mixins";

.share-block {
  margin-top: 25px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    
  }

  .share__item {
    margin-bottom: 0;
  }

  .popover {
    position: static;
  }

  .share__list {
    display: grid;
    gap: 13px;
    grid-template-columns: repeat(4, 1fr);
    margin-left: 17px;

    @include break-max($breakpoint-xs) {
      margin-left: 13px;
    }
  }

  .share__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: $color-brand-opaque-8;
    border-radius: 50%;

    @include break-max($breakpoint-xs) {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 18px;
      height: 18px;
      &.icon_wa {
        fill: $color-wa;
      }
    }
  }
}

.share-block__copy {
  margin-left: 50px;
  border-radius: 8px;
  background-color: $color-brand-opaque-8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 44px;
  cursor: pointer;
  font-size: 14px;
  color: $color-brand-1;

  &_m_0 {
    margin-left: 0;
  }

  @include break-max($breakpoint-xs) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $color-brand-opaque-8;
    border-radius: 50%;
    margin-left: auto;

    span {
      display: none;
    }
  }

  svg {
    margin-right: 10px;

    @include break-max($breakpoint-xs) {
      margin-right: 0;
    }
  }
}

.share-block .text {
  @include break-max($breakpoint-xs) {
    margin-right: auto;
  }
}


.share-popover {
  .ant-popover-title {
    display: none;
  }
}