@import "../../../styles/variables";
@import "../../../styles/mixins";

.da {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;


  background-color: $color-brand-1;
  color: $color-white;

  @include break-max($breakpoint-xs) {
    display: flex;
  }

  &.hidden {
    display: none;
  }
}

.da__container {
  width: 290px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.da__title {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 35px;
  line-height: 35px;
  text-align: center;
}

.da__text {
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.da__smile {
  font-size: 40px;
  margin-bottom: 40px;
}

.da__item + .da__item {
  margin-top: 18px;
}

.da__thanks {
  margin-top: 65px;

  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  text-align: center;
  letter-spacing: 0.3px;

  color: $color-brand-3;
}