@import "../../styles/variables";
@import "../../styles/mixins";

$ele-purple: #A34DE3;
$ele-blue: #58B8FD;
$ele-pink: #C12E83;

.ele {
  padding-bottom: 100px;
  @include break-max($breakpoint-xs) {
    padding-bottom: 60px;
  }

  font-family: 'Montserrat', sans-serif;
  background: $color-brand-1;
  color: $color-white;
  *::selection {
    background: $ele-purple;
    color: $color-white;
  }

  @include break-max($breakpoint-sm) {
    overflow-x: hidden;
  }

  .wrapper__center {
    margin-left: auto;
    margin-right: auto;
    width: 1220px;
    @include break-max($breakpoint-md) {
      width: 1000px;
    }
  
    @include break-max($breakpoint-sm) {
      width: 600px;
    }
  
    @include break-max($breakpoint-xs) {
      width: 100%;
      padding: 0 20px;
    }
  }
}

.ele__purple {
  color: $ele-purple;
}

.ele__blue {
  color: $ele-blue;
}

.ele__pink {
  color: $ele-pink;
}


.ele__promo {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -70%;
    top: -20%;
    width: 100%;
    height: 100%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, rgba(91, 186, 255, 0.3) 0%, rgba(91, 186, 255, 0) 100%);
    transform: rotate(-19.75deg);
  }

  &::after {
    content: '';
    position: absolute;
    right: -20%;
    top: 0;
    width: 60%;
    height: 80%;
    background: radial-gradient(50% 50% at 50% 50%, #C73E88 0%, rgba(199, 62, 136, 0) 100%);
    transform: rotate(-19.75deg);
    opacity: 0.7;
  }
}


.ele__h1 {
  font-weight: 900;
  font-size: 120px;
  line-height: 146px;
  text-align: center;
  @include break-max($breakpoint-md) {
    font-size: 100px;
    line-height: 122px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 75px;
    line-height: 91px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 55px;
    line-height: 50px;
  }
}

.ele__subtitle {
  position: relative;
  z-index: 4;
  font-weight: 200;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  margin-bottom: 85px;
  @include break-max($breakpoint-md) {
    font-size: 50px;
    line-height: 70px;
    margin-bottom: 55px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 35px;
    line-height: 52px;
    margin-bottom: 40px;
  }

  @include break-max($breakpoint-xs) {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}

.ele__promo-content {
  position: relative;
  z-index: 2;
  padding-top: 200px;
  background-image:
    url('../../assets/images/eleven/icon_star-blue.svg'),
    url('../../assets/images/eleven/icon_star-pink.svg'),
    url('../../assets/images/eleven/icon_star-pink.svg');
  background-position: bottom left, top right, bottom right;
  background-size: 35px, 35px, 24px;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 20%;
    left: -20%;
    width: 50%;
    height: 50%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #AA4AE2 0%, rgba(170, 74, 226, 0) 100%);
    opacity: 0.3;
    transform: rotate(40deg);
  }

  @include break-max($breakpoint-md) {
    background-position: bottom left, top 15% right, bottom right;
  }

  @include break-max($breakpoint-sm) {

  }

  @include break-max($breakpoint-xs) {
    padding-top: 100px;
  }
}

.ele__promo-img {
  position: absolute;
}

.ele__promo-img_boy {
  top: 0;
  z-index: 3;
  @include break-max($breakpoint-md) {
    max-width: 345px;
    top: 30px;
    left: -25px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 260px;
    top: 70px;
    left: -95px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 186px;
    left: 0;
    top: 0;
  }
}

.ele__promo-img_girl {
  z-index: 3;
  top: 0;
  right: -90px;
  @include break-max($breakpoint-md) {
    max-width: 455px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 350px;
    top: 70px;
    right: -150px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 231px;
    top: 35px;
    right: -65px;
  }
}

.ele__info {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: 970px;
  max-width: 100%;
  margin: 0 auto;
  @include break-max($breakpoint-md) {
    width: 794px;
  }

  @include break-max($breakpoint-sm) {
    width: 604px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.ele__info-box {
  position: relative;
}

.ele__info-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 470px;
  max-width: 100%;
  padding: 40px;
  border-radius: 20px;
  background: $color-brand-2;
  margin-bottom: 28px;
  margin-right: 28px;
  font-weight: 400;

  &:nth-child(2) {
    position: relative;
    margin-right: 0;
    &::before {
      content: '';
      position: absolute;
      top: -70%;
      right: -10%;
      width: 100%;
      height: 100%;
      background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #AA4AE2 0%, rgba(170, 74, 226, 0) 100%);
      opacity: 0.4;
      transform: rotate(40deg);
    }
  }
  &:last-of-type {
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    margin-right: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: 120%;
      right: -40%;
      width: 100%;
      height: 150%;
      background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #AA4AE2 0%, rgba(170, 74, 226, 0) 100%);
      opacity: 0.4;
      transform: rotate(40deg);
    }
    @include break-max($breakpoint-sm) {
      padding: 20px;
    }
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  @include break-max($breakpoint-md) {
    width: 386px;
    margin-bottom: 22px;
    margin-right: 22px;
  }
  
  @include break-max($breakpoint-sm) {
    width: 294px;
    margin-bottom: 12px;
    margin-right: 12px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 20px 30px;
  }
}

.ele__info-sub {
  margin-top: 15px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: $color-white;
  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    font-size: 12px;
    line-height: 15px;
  }

  @include break-max($breakpoint-xs) {

  }
}

.ele__time {
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 50px;
  line-height: 61px;
  @include break-max($breakpoint-md) {
    font-size: 40px;
    line-height: 49px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 30px;
    line-height: 37px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 20px;
    line-height: 24px;
  }
}


.ele__info-about {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-sm) {
    font-size: 15px;
    line-height: 18px;
    margin-top: 5px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 12px;
    line-height: 14px;
  }
  span {
    margin-right: 35px;
    @include break-max($breakpoint-sm) {
      margin-right: 15px;
    }
  }

}

.ele__info-btn {
  margin-left: auto;
  margin-right: 0;
  position: relative;

  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.ele__btn {
  display: inline-flex;
  padding: 33px 66px;
  background: linear-gradient(60.95deg, #188FE5 17.57%, #AA4AE2 88.6%);
  border-radius: 100px;
  color: inherit;
  font: inherit;
  font-weight: 400;

  transition: all 0.3s;
  
  
  &:hover, &:focus {
    box-shadow: 0 0 20px 4px $ele-purple;
    background: linear-gradient(60.95deg, #AA4AE2 17.57%, #188FE5 88.6%);
  }

  &:active {
    box-shadow: 0 0 10px 4px $ele-purple;
  }

  &:disabled {
    opacity: 0.3;
    color: inherit;
  }

  @include break-max($breakpoint-md) {
    padding: 25px 50px;
  }

  @include break-max($breakpoint-sm) {
    padding: 16px 36px;
  }

  @include break-max($breakpoint-xs) {
    padding: 15px 47px;
  }
}

.ele__h2 {
  position: relative;
  font-weight: 900;
  font-size: 65px;
  line-height: 70px;
  @include break-max($breakpoint-md) {
    font-size: 50px;
    line-height: 60px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 45px;
    line-height: 50px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 25px;
    line-height: 30px;
  }
}

.ele__h3 {
  position: relative;
  font-weight: 800;
  font-size: 35px;
  line-height: 43px;
  @include break-max($breakpoint-md) {
    font-size: 25px;
    line-height: 30px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 20px;
    line-height: 25px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}

.ele__h4 {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  @include break-max($breakpoint-md) {
    font-size: 21px;
    line-height: 26px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 20px;
    line-height: 24px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}


.ele__map-opener {
  display: inline-flex;
  background: none;
  border: 1px solid $ele-purple;
  border-radius: 100px;

  font-family: inherit;
  padding: 3px 15px;
  margin-left: 7px;

  transition: all 0.3s;;

  @include break-max($breakpoint-sm) {
    font-size: 15px;
    line-height: 18px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 12px;
    line-height: 15px;
  }

  &:hover {
    background: $ele-purple;
    color: $color-white;
  }
}

.ele__whom {
  position: relative;
  display: flex;
  padding-top: 220px;
  padding-bottom: 70px;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #D387DD 0%, rgba(231, 150, 242, 0) 100%);
    opacity: 0.2;
    transform: rotate(-19.75deg);
    right: -20%;
  }

  @include break-max($breakpoint-md) {
    padding-top: 170px;
    padding-bottom: 0;
  }

  @include break-max($breakpoint-sm) {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
  }
}

.ele__whom-img {
  position: relative;
  top: -80px;
  max-width: 630px;
  @include break-max($breakpoint-md) {
    max-width: 550px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 330px;
    position: absolute;
    top: 130px;
    right: -70px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 100%;
    position: static;
    margin-top: 5px;
  }
}

.ele__whom-text {
  padding-top: 40px;
  font-weight: 200;
  font-size: 45px;
  line-height: 50px;
  background: linear-gradient(60.95deg, #35AAFF 0, #A919FF 88.6%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @include break-max($breakpoint-md) {
    font-size: 40px;
    line-height: 45px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 30px;
    line-height: 35px;
    width: 80%;
  }

  @include break-max($breakpoint-xs) {
    font-size: 18px;
    line-height: 22px;
    padding-top: 20px;
  }
}

.ele__whom-box {
  width: 595px;
  max-width: 100%;
  @include break-max($breakpoint-sm) {
    width: 410px;
  }
}

.ele__text {
  padding-top: 50px;
  font-weight: 300;
  font-size: 25px;
  line-height: 35px;
  @include break-max($breakpoint-md) {
    font-size: 20px;
    line-height: 30px;
    padding-top: 30px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 18px;
    line-height: 25px;
    padding-top: 20px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 22px;
  }
}

.ele__about {
  position: relative;
}

.ele__about-label {
  position: absolute;
  display: inline-flex;
  padding: 10px 25px;
  font-weight: 300;
  font-size: 30px;
  line-height: 37px;
  border-radius: 20px;
  white-space: nowrap;
  background: linear-gradient(96.52deg, #A34DE3 -29.17%, #188FE6 196.43%, #188FE6 196.43%);

  @include break-max($breakpoint-md) {
    font-size: 25px;
    line-height: 30px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 20px;
    line-height: 24px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}

.ele__about-item {
  position: relative;
  display: flex;
  margin-top: 100px;
  padding-bottom: 100px;
  
  @include break-max($breakpoint-md) {
    margin-top: 80px;
    padding-bottom: 80px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 65px;
    padding-bottom: 65px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-top: 45px;
    padding-bottom: 45px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(60.95deg, #AA4AE2 17.57%, #188FE5 88.6%);
  }
}

.ele__about-left {
  width: 500px;
  max-width: 100%;
  flex-shrink: 0;
  @include break-max($breakpoint-md) {
    width: 410px;
  }

  @include break-max($breakpoint-sm) {
    width: 300px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
  
}

.ele__about-timing {
  position: relative;
  width: max-content;
  z-index: 2;
}

.ele__img {
  position: absolute;
}

.ele__clock {
  @include break-max($breakpoint-md) {
    width: 230px;
  }
  @include break-max($breakpoint-sm) {
    width: 230px;
  }
  @include break-max($breakpoint-xs) {
    width: 230px;
  }
}

.ele__about-item_col {
  flex-direction: column;
}

.ele__title-svg {
  @include break-max($breakpoint-md) {
    width: 50%;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.ele__about-item_1 {

  .ele__about-content {
    width: 470px;
    max-width: 100%;
    @include break-max($breakpoint-md) {
      width: 270px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }
  .ele__about-label {
    top: -30px;
    right: -80px;
    transform: rotate(10.22deg);
    @include break-max($breakpoint-sm) {
      top: -10px;
      right: -50px;
    }

  }
}

.ele__about-item_2 {
  position: relative;
  padding-bottom: 220px;

  &::before {
    content: '';
    position: absolute;
    top: -80%;
    left: -30%;
    width: 80%;
    height: 130%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #AA4AE2 0%, rgba(170, 74, 226, 0) 100%);
    opacity: 0.2;
    transform: rotate(-19.75deg);
  }

  @include break-max($breakpoint-md) {
    padding-bottom: 160px;
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 60px;
  }

  .ele__about-content {
    padding-top: 20px;
    @include break-max($breakpoint-xs) {
      
    }
  }

  .ele__about-label {
    background: linear-gradient(91.04deg, #A84AEF 21.23%, #4D0087 112.31%);
    border-radius: 20px;
    transform: rotate(-12.92deg);
    right: -140px;
    bottom: -20px;
    @include break-max($breakpoint-sm) {
      right: -50px;
      bottom: -10px;
    }

  }

  .ele__img {
    left: -190px;
    top: 65px;

    @include break-max($breakpoint-md) {
      width: 470px;
      left: -100px;
      z-index: 2;
    }
    @include break-max($breakpoint-sm) {
      width: 340px;

    }
    @include break-max($breakpoint-xs) {
      width: 260px;
      position: static;
    }
  }


}

.ele__about-item_3 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 40%;
    height: 100%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, rgba(91, 186, 255, 0.3) 0%, rgba(91, 186, 255, 0) 100%);
    opacity: 0.8;
    transform: rotate(-40deg);
  }


  .ele__title-svg {
    @include break-max($breakpoint-md) {
      width: 80%;
    }
  }

  .ele__about-content {
    width: 555px;
    max-width: 100%;
  }

  .ele__about-container {
    @include break-max($breakpoint-xs) {
      margin-top: 35px;
    }
  }
  
  .ele__about-label {
    background: linear-gradient(96.91deg, #A34DE3 -28.98%, #188FE6 116.82%, #188FE6 116.82%);
    border-radius: 20px;
    transform: rotate(10.22deg);
    left: 120px;
    top: 80px;

    @include break-max($breakpoint-md) {
      left: 100px;
      top: 70px;
    }

    @include break-max($breakpoint-sm) {
      left: 60px;
      top: 50px;
    }
    
    @include break-max($breakpoint-xs) {
      left: 30px;
      top: 30px;
    }

  }

  .ele__img {
    position: relative;
    left: 0;
    top: 30px;

    @include break-max($breakpoint-md) {
      width: 280px;
      left: 40px;
    }

    @include break-max($breakpoint-sm) {
      width: 180px;
    }

    @include break-max($breakpoint-xs) {
      position: static;
    }
  }
  
  &::after {
    background: linear-gradient(60.95deg, #FF7B26 17.57%, #C858FD 88.6%);
  }
}

.ele__about-item_4 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -20%;
    left: -20%;
    width: 90%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #FF7B26 1.91%, rgba(255, 123, 38, 0) 100%);
    opacity: 0.2;
    transform: rotate(-40deg);
  }

  .ele__about-content {

  }
  .ele__about-label {
    background: linear-gradient(93.2deg, #FF7B26 -11.01%, #C858FD 82.03%);
    transform: rotate(-12.92deg);
    top: -25px;
    left: 320px;
    @include break-max($breakpoint-sm) {
      left: 220px;
    }

  }

  .ele__img {
    top: 20px;
    @include break-max($breakpoint-md) {
      width: 290px;
      left: 30px;
    }

    @include break-max($breakpoint-sm) {
      width: 220px;
      left: 0px;
      top: 0;
    }

    @include break-max($breakpoint-xs) {
      position: relative;
      margin-top: -90px;
    }

  }
  
  &::after {
    background: linear-gradient(91.04deg, #A84AEF 21.23%, #4D0087 112.31%);
  }
}

.ele__about-item_5 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 40%;
    height: 100%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, #AA4AE2 0%, rgba(170, 74, 226, 0) 100%);
    opacity: 0.2;
    transform: rotate(-19.75deg);
  }
  .ele__about-content {

  }
  .ele__about-label {
    background: linear-gradient(91.04deg, #A84AEF 21.23%, #4D0087 112.31%);
    transform: rotate(8.13deg);
    left: 100px;
    z-index: 2;
    @include break-max($breakpoint-sm) {
      left: 10px;
    }

  }

  .ele__img {
    top: -70px;
    left: 80px;
    @include break-max($breakpoint-md) {
      width: 396px;
      left: 50px;
      top: -50px;
    }

    @include break-max($breakpoint-sm) {
      width: 300px;
      left: 0;
      top: -40px;
    }

    @include break-max($breakpoint-xs) {
      position: relative;
      margin-top: -90px;
      margin-bottom: -90px;
    }

  }
  
  &::after {
    background: linear-gradient(92.85deg, #474DEA 24.01%, #A34DE3 127.26%);
  }
}

.ele__about-item_6 {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -70%;
    left: auto;
    right: -60%;
    width: 100%;
    height: 250%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, rgba(91, 186, 255, 0.3) 0%, rgba(91, 186, 255, 0) 100%);
    opacity: 0.6;
    transform: rotate(-40deg);
  }
  .ele__about-content {

  }
  .ele__about-label {
    background: linear-gradient(92.85deg, #474DEA 24.01%, #A34DE3 127.26%);
    transform: rotate(13.45deg);
    top: -15px;
    left: 370px;
    @include break-max($breakpoint-sm) {
      left: 210px;
    }
  }

  .ele__img {
    top: -10px;
    left: 0;
    @include break-max($breakpoint-md) {
      width: 386px;
    }
    @include break-max($breakpoint-sm) {
      width: 300px;
    }
    @include break-max($breakpoint-xs) {
      width: 252px;
      position: relative;
      margin-top: -90px;
      margin-left: 20px;
    }
  }
  
  &::after {
    background: linear-gradient(93.2deg, #FF7B26 -11.01%, #C858FD 82.03%);
  }
}

.ele__about-item_7 {
  padding-bottom: 150px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -30%;
    left: -15%;
    width: 60%;
    height: 140%;
    background: radial-gradient(50% 50% at 50% 50%, #FF7B26 1.91%, rgba(255, 123, 38, 0) 100%);
    opacity: 0.3;
    transform: rotate(-40deg);
  }
  @include break-max($breakpoint-sm) {
    padding-bottom: 65px;
  }
  @include break-max($breakpoint-xs) {
    padding-bottom: 45px;
  }
  .ele__about-content {

  }
  .ele__about-label {

  }

  .ele__img {
    top: 135px;
    left: -60px;
    @include break-max($breakpoint-md) {
      width: 300px;
      top: 95px;
      left: 0;
    }
    @include break-max($breakpoint-sm) {
      width: 200px;
      left: 0;
      top: 60px;
    }
    @include break-max($breakpoint-xs) {
      width: 220px;
      position: relative;
      top: 0;
      margin-top: -70px;
    }
  }
  
  &::after {
    background: linear-gradient(91.04deg, #A84AEF 21.23%, #4D0087 112.31%);
  }
}

.ele__about-item_8 {
  padding-bottom: 180px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -70%;
    left: auto;
    right: -60%;
    width: 100%;
    height: 250%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, rgba(91, 186, 255, 0.3) 0%, rgba(91, 186, 255, 0) 100%);
    opacity: 0.6;
    transform: rotate(-40deg);
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 140px;
  }
  @include break-max($breakpoint-xs) {
    padding-bottom: 45px;
  }
  .ele__about-content {
    padding-top: 80px;
    @include break-max($breakpoint-sm) {
      padding-top: 20px;
    }
  }
  .ele__about-label {
    background: linear-gradient(91.04deg, #A84AEF 21.23%, #4D0087 112.31%);
    transform: rotate(10.17deg);
  }

  .ele__img {
    left: 150px;
    top: 60px;
    z-index: 2;
    @include break-max($breakpoint-md) {
      width: 270px;
      top: 20px;
      left: 130px;
    }
    @include break-max($breakpoint-sm) {
      width: 200px;
      top: 60px;
      left: 90px;
    }
    @include break-max($breakpoint-xs) {
      width: 160px;
      position: relative;
      top: 0;
      margin-bottom: -50px;
    }
  }
  
  &::after {
    
  }
}

.ele__about-item_last {
  &::after {
    display: none;
  }

  .ele__btn {
    display: block;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ele__about-container {
  display: flex;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.ele__about-header {
  position: relative;
  padding-bottom: 80px;
  @include break-max($breakpoint-md) {
    padding-bottom: 40px;
  }

}

.ele__svg-right {
  position: absolute;
  top: -20px;
  right: 0;

  @include break-max($breakpoint-md) {
    top: -40px;
    right: 90px;
  }

  @include break-max($breakpoint-sm) {
    width: 100px;
  }

  @include break-max($breakpoint-xs) {
    width: 68px;
    right: 0;
    top: -25px;
  }

}

.ele__banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 244px;
  @include break-max($breakpoint-xs) {
    height: 280px;
  }
}

.ele__banner-inner {
  position: relative;
  width: 100%;
  height: 160px;
  background: $color-brand-2;
  border-radius: 20px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: -90%;
    left: auto;
    right: -21%;
    width: 80%;
    height: 250%;
    background: radial-gradient(46.18% 46.18% at 48.92% 53.01%, rgba(91, 186, 255, 0.6) 0%, rgba(91, 186, 255, 0) 100%);
    opacity: 1;
  }
  @include break-max($breakpoint-sm) {
    padding: 0 30px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 85px;
    height: 213px;
    padding-bottom: 30px;
  }
}

.ele__banner-text {
  position: relative;
  width: 840px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 666px;
  }
  @include break-max($breakpoint-sm) {
    width: 388px;
  }
  @include break-max($breakpoint-xs) {
    width: 240px;
  }
}

.ele__banner-img {
  max-height: 244px;
  position: absolute;
  top: 0;
  right: 5%;
  @include break-max($breakpoint-sm) {
    width: 170px;
    right: 10px;
  }
  @include break-max($breakpoint-xs) {
    width: 105px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: -10%;
  }
}

.modal_eleven {

  .modal__content {
    display: flex;
    align-items: center;
    padding: 0;
    background-image:
    url('../../assets/images/eleven/icon_star-blue.svg'),
    url('../../assets/images/eleven/icon_star-blue.svg');
    background-repeat: no-repeat;
    background-position: bottom 25% left 20%, top 25% right 20%;
    background-size: 45px, 40px;

    @include break-max($breakpoint-md) {
      background-position: bottom 15% left 20%, top 15% right 20%;
    }
  }

  &-nobg {
    .modal__content {
      background: none;
    }
  }

  .modal__shadow {
    background: $color-brand-1;
  
  }
  // .modal__content {
  //   background: $color-brand-1;
  //   // padding-bottom: 0;
  // }
  .modal__inner {
    background: $color-brand-1;
    box-shadow: none;
    width: 800px;
    padding-left: 0;
    padding-right: 0;
    // width: 100%;
    // height: 100%;
    // border-radius: none;
  }

  .zl-form__item + .zl-form__item {
    margin-top: 20px;
  }

  // .ele-form__container {
  //   margin-top: 60px;
  //   padding: 0 40px;
  // }

  .input__control {
    background: $color-brand-2;
    color: $ele-blue;

    height: 80px;
    box-shadow: none;
    padding: 0 30px;
    border-radius: 10px;
    &::placeholder {
      color: $ele-blue;
    }
  }
}

.ele.ele-form {
  padding-bottom: 0;
}

.ele-form__container {
  display: flex;
  flex-direction: column;
  width: 720px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
}

.ele__submit {
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px;
}

.ele-map {
  @include break-max($breakpoint-xs) {
    background: $color-brand-1;
  }
}

.ele-map__container {
  width: 100%;
  .complex__map-wrap {
    height: 600px;
    @include break-max($breakpoint-xs) {
      height: 550px;
    }
  }

}

.ele-map__title {
  text-transform: none;
  margin-bottom: 20px;
  color: $color-white;
}