.reviews {
  background: $color-brand-opaque-8;
  .constructor__box {
    @include break-max($breakpoint-xsm) {
      width: 100%;
    }
  }
}

.reviews {
  margin-bottom: 0;
}

.reviews ~ .banner {
  margin-top: 0 !important;
}

.reviews__item {
  display: flex;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xsm) {
    justify-content: center;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: center;
  }
}

.reviews__name {
  margin-bottom: 15px;
  color: $color-brand-3;
}

.reviews__avatar {
  width: 95px;
  height: 95px;
  margin-right: 60px;
  border-radius: 50%;

  @include break-max($breakpoint-xs) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.reviews__cloud {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 1095px;
  max-width: 100%;
  padding: 40px 75px;

  border-radius: 18px;
  background: $color-white;
  // box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);

  @include break-max($breakpoint-md) {
    width: 840px;
    padding: 40px 45px;
  }

  @include break-max($breakpoint-sm) {
    width: 670px;
  }

  @include break-max($breakpoint-xsm) {
    width: 570px;
  }

  @include break-max($breakpoint-xs) {
    width: 252px;
    padding: 20px 25px;

    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 31px;
    left: -31px;
    width: 0;
    height: 0;
    border-top: 62px solid $color-white;
    border-left: 62px solid transparent;

    @include break-max($breakpoint-sm) {
      // top: 35px;
      // left: -20px;
      // border-top: 40px solid $color-white;
      // border-left: 40px solid transparent;

      display: none;
    }
  }
}

.reviews__item:nth-child(even) {
  justify-content: flex-end;
  
  .reviews__avatar {
    order: 1;
    margin-right: 0;
    margin-left: 60px;
    @include break-max($breakpoint-xs) {
      margin-left: 0;
      order: 0;
    }
  }
  .reviews__cloud::after {
    left: auto;
    right: -31px;
    border-left: none;
    border-right: 62px solid transparent;

    @include break-max($breakpoint-xs) {
      right: -20px;
      border-right: 40px solid transparent;
    }
  }
}

.reviews__text {
  line-height: 24px;
  overflow-wrap: break-word;
}
