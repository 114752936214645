@import "../../styles/variables";
@import "../../styles/mixins";


.training {
  padding: 60px 0 100px;


  @include break-max($breakpoint-xs) {
    padding: 20px 10px 20px;
  }
}

.training__title {
  margin-bottom: 35px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.training__aside {
  width: 345px;
  max-width: 100%;
  flex-shrink: 0;
  height: 800px;
  background: $color-brand-opaque-8;
  margin-right: 30px;
  border-radius: 4px;

  padding: 30px 0;

  position: sticky;
  top: 100px;

  @include break-max($breakpoint-sm) {
    width: 230px;
    height: 700px;
    top: 25px;
  }

  @include break-max($breakpoint-xs) {
    position: static;
    width: 100%;
    height: auto;
  }
}

.training__aside-header,
.training__task-body {
  padding: 0 40px;
  @include break-max($breakpoint-sm) {
    padding: 0 15px;
  }
}

.training__container {
  display: flex;
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.training__block {
  width: 970px;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    min-width: 480px;
    flex-grow: 1;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    min-width: 100%;
  }
}

.training__aside-title {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
}


.training__progress {
  margin-bottom: 20px;
}

.training__progress-bar {
  position: relative;
  width: 100%;
  height: 8px;
  background: $color-brand-opaque-10;
  border-radius: 5px;
  margin-bottom: 5px;
}

.training__progress-filler {
  position: absolute;
  left: 0;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
  background: $green;
  transition: width 0.3s;
}

.training__progress-current {
  font-weight: bold;
}

.training__progress-total {
  color: $color-brand-3;
}

.training__place {
  margin-bottom: 35px;
  color: $color-brand-3;
}

.training__current-place {
  display: block;
  margin-top: 5px;
  color: $color-brand-1;
  font-size: 16px;
  font-weight: bold;
}

.training__task-list {
  height: 550px;

  @include break-max($breakpoint-xs) {
    height: 100px;

    .training__task-body {
      display: flex;
      overflow-x: scroll;
    }
  }
}

.training__tooltip {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  padding: 0 30px;
  color: $color-white;
  background: $color-brand-3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  border-radius: 4px;
}

.training__task {
  display: flex;
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  padding-right: 15px;
  margin-bottom: 5px;
  transition: all 0.3s;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &_current {
    background: $color-red;
    color: $color-white;
  }

  &_inactive {
    color: $color-brand-opaque-40;
  }

  &_not-available {
    position: relative;
    background: $color-brand-opaque-20;
    color: $color-brand-opaque-40;
    cursor: default;


    &:hover .training__tooltip {
      opacity: 1;
      visibility: visible;
    }

  }


  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @include break-max($breakpoint-xs) {
    width: 160px;
    height: 100px;
    margin-right: 5px;
    width: 160px;
    flex-shrink: 0;
    height: 100px;
    margin-right: 5px;
    margin-bottom: 0;
    padding: 5px;
    align-items: center;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.training__task-date {
  margin-right: 18px;
  color: $color-brand-3;
  @include break-max($breakpoint-xs) {
    margin-right: 0;
    font-size: 13px;
    margin-top: 3px;
  }
}

.training__task_current .training__task-date {
  color: $color-white;
}

.training__task-name {
  font-weight: bold;
  @include break-max($breakpoint-xs) {
    order: -1;
  }
}

.training__task-result {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-left: 20px;
  border-radius: 50%;
  background: $color-brand-opaque-10;

  svg.icon_x-mark {
    fill: $color-red;
  }
}

.training__task_current .training__task-result,
.training__task_inactive .training__task-result,
.training__task_not-available .training__task-result {
  display: none;
}

.training__banner {
  display: flex;
  align-items: center;
  height: 114px;
  padding: 0 45px;
  background: $color-brand-opaque-8 url('../../assets/images/training/banner.svg') no-repeat right;
  color: $color-brand-1;

  @include break-max($breakpoint-md) {
    background-image: url('../../assets/images/training/banner-sm.svg');
  }

  @include break-max($breakpoint-sm) {
    height: 100px;
    padding: 0 30px;

    .h4 {
      max-width: 265px;
    }
  }

  @include break-max($breakpoint-sm) {
    background-image: url('../../assets/images/training/banner-xs.svg');

    .h4 {
      max-width: 35%;
    }
  }

}

.training__poll {

  .poll {
    padding: 0;
    padding-left: 25px;
    margin-top: 0;
  }

  .poll__item:nth-child(odd) {
    background: none;
  }

  .poll__item:nth-child(even) {
    background: $color-brand-opaque-8;
  }

  .poll__item:last-of-type {
    background: none;
    justify-content: flex-start !important;
  }

  .checkbox {
    align-items: flex-start;
  }


  .poll__submit {
    margin-left: 0;
  }

  .poll__item {
    @include break-max($breakpoint-sm) {
      align-items: flex-start;
      flex-direction: column;
      padding: 30px 15px;

      .poll__q {
        width: 100%;
        margin-bottom: 25px;
        margin-right: 0;
      }

      .poll__a {
        width: 100%;
      }
    }
  }

  .poll__submit_big-mb {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    .poll {
      padding-left: 0;
    }

    .radio__label {
      font-size: 15px;
    }

    .poll__row {
      flex-direction: column;
    }

    .poll__elem {
      margin-right: 0;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .poll__q {
      margin-bottom: 15px;
    }

    .poll__elem {
      width: 100%;
    }

    .poll__box {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }

    .poll__half {
      width: 100%;
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .poll__row + .poll__row {
      margin-top: 15px;
    }

    .poll__item {
      padding: 30px;
    }

    .poll__submit {
      width: 100%;
      height: 44px;
    }

    .poll__social {
      order: -1;
      margin-right: 10px;
    }
  }
}

.training__poll-header {
  display: flex;
  margin-top: 50px;
  padding-bottom: 35px;
  padding-left: 45px;
  border-bottom: 1px solid $color-brand-opaque-20;

  &_column {
    flex-direction: column;
  }

  &_additional {
    margin-top: 0;
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 0;
    .training__poll-title {
      margin-bottom: 15px;
    }
  }

  &_done {
    justify-content: center;
    align-items: center;

    .h2 {
      margin-top: 50px;
    }
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    margin-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
  }

  @include break-max($breakpoint-xs) {
    padding: 0;
    border-bottom: none;
  }
}

.training__poll-title {
  padding-right: 20px;

  @include break-max($breakpoint-sm) {
    font-size: 21px;
    line-height: 24px;
  }

  @include break-max($breakpoint-xs) {
    text-align: center;
    padding: 0 10px;
  }
}

.training__poll-timer {
  margin-left: auto;
  display: flex;
  flex-shrink: 0;

  @include break-max($breakpoint-sm) {
    margin-left: 0;
    margin-top: 25px;
    align-items: center;
  }

  @include break-max($breakpoint-xs) {
    border-radius: 4px;
    background: $color-brand-opaque-8;
    height: 60px;

    div {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.training__poll-clock {
  margin-right: 12px;
  @include break-max($breakpoint-sm) {
    width: 30px;
    height: 30px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.training__poll-sign {
  color: $color-brand-3;
  @include break-max($breakpoint-xs) {
    font-size: 15px;
  }
}

.training__poll-time {
  display: block;
  padding-top: 3px;
  color: $color-red;
  font-size: 20px;
  font-weight: bold;

  @include break-max($breakpoint-sm) {
    font-size: 14px;
    padding-top: 0;
  }

  @include break-max($breakpoint-xs) {
    font-size: 18px;
  }
}

.training__question-box {
  padding: 45px;

  &:nth-of-type(even) {
    padding-top: 30px;
    background: $color-brand-opaque-8;
    border-radius: 4px;
  }

  @include break-max($breakpoint-sm) {
    padding: 30px;
    &:nth-of-type(even) {
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.training__q {
  display: flex;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  span {
    margin-right: 5px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 14px;
    line-height: 18px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}

.training__a {

  @include break-max($breakpoint-xs) {
    padding: 0 20px;
  }

  .radio {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }

    @include break-max($breakpoint-sm) {
      padding-bottom: 15px;
      border-bottom: 1px solid $color-brand-opaque-20;
      &:last-of-type {
        padding-bottom: 0;
        border: none;
      }
    }
  }

  .radio__box + .radio__label {
    padding-left: 15px;
  }

  .radio__box {
    @include break-max($breakpoint-sm) {
      margin-top: 2px;
    }
  }

}

.training__submit {
  width: 200px;
  margin-top: 20px;
  @include break-max($breakpoint-sm) {
    margin-top: 10px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.training__questions_results {
   .training__q {
     margin-bottom: 10px;
   }

  .training__result-text {
    margin-bottom: 8px;
  }
}

.training__result {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 465px;
  max-width: 100%;
}

.training__result-box {
  display: flex;
  flex-direction: column;
}

.training__result-text {
  display: block;
  margin-bottom: 7px;
  color: $color-brand-3;
}

.training__result-counter {
  font-size: 40px;
  font-weight: bold;
  color: $color-brand-3;

  &.positive {
    color: $green;
  }

  &.negative {
    color: $color-red;
  }
}

.training__result-a {
  margin-top: 15px;
  width: 465px;
  max-width: 100%;
}

.training__q-label {
  display: inline-flex;
  align-items: center;
  height: 28px;
  padding: 0 12px;
  border-radius: 4px;
  color: $color-white;
  font-weight: bold;
  line-height: 1;

  &.positive {
    background: $green;
  }

  &.negative {
    background: $color-red;
  }

  .icon {
    margin-right: 8px;
  }


  .icon_x-mark {
    fill: $color-white !important;
  }
}

.training__result-a-row {
  display: flex;

  .training__result-a {
    width: fit-content;
    max-width: 40%;
    margin-right: 100px;
  }
}

.training__additional {
  margin-top: 20px;
  padding: 30px 45px 45px;
  background: $color-brand-opaque-8;
  border-radius: 4px;

  @include break-max($breakpoint-sm) {
    margin-top: 0;
  }

  @include break-max($breakpoint-xs) {
    padding: 25px 5px;
  }

  .text-input {
    width: 540px;
    max-width: 100%;
    background: $color-white;
  }

  .h4 {
    margin-bottom: 30px;
  }
}

.training__additional-sign {

}

.training__additional-box {
  width: 640px;
  max-width: 100%;
}

.training__additional-counter {
  margin-right: 15px;
}

.training__additional-text {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.training__download {
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 25px;
  background: $color-white;
}

.training__additional-text:not(:first-of-type) {
  margin-bottom: 25px;
}

.training__additional-review {
  margin-top: 35px;
  padding-left: 45px;

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    padding: 25px 5px;
  }

  .h4 {
    margin-bottom: 22px;
  }
}

.training__review-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .training__result-text {
    margin-bottom: 0;
  }
  .info {
    margin-left: 10px;
  }
}
