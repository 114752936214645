@import "../../styles/variables";
@import "../../styles/mixins";

.special {

  .swiper-wrapper {
    display: flex;
    justify-content: center;
  }

  .swiper-container {
    width: 100%;
  }

  .complex__slider-thumbnails_item {
    max-width: 125px;
  }

  .pswp {
    margin-top: 0;
  }
}

.special__promo {

  @include break-max($breakpoint-xs) {

    .button {
      width: 100%;
    }
  }
}

.special__promo-container {
  display: flex;
  min-height: 387px;
  background-color: $color-brand-opaque-8;
  background-image: url(../../assets/images/special/promo-bg.png);
  background-position: right -100px center;
  background-repeat: no-repeat;
  padding: 60px 125px;
  margin-top: 60px;
  border-radius: 20px;

  @include break-max($breakpoint-md) {
    min-height: 305px;
    padding: 40px 100px;
    margin-top: 30px;
  }
  @include break-max($breakpoint-sm) {
    min-height: 378px;
    padding: 60px 120px;
    background-image: url(../../assets/images/special/promo-sm.png);
    background-position: right center;
  }
  @include break-max($breakpoint-xs) {
    padding: 22px 30px;
    margin-left: 10px;
    margin-right: 10px;
    background-image: url(../../assets/images/special/promo-xs.png);
  }
}

.special__promo-box {
  width: 550px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 388px;
  }
  @include break-max($breakpoint-sm) {
    width: 507px;
    text-align: center;
  }
  @include break-max($breakpoint-xs) {
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.special__promo-title {
  margin-bottom: 20px;

  @include break-max($breakpoint-md) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 15px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 28px;
    line-height: 33px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 10px;
  }

}

.special__promo-text {
  font-weight: 300;
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }
}

.special__cards {
  padding-top: 50px;
  padding-bottom: 75px;

  @include break-max($breakpoint-md) {
    padding-top: 40px;
    padding-bottom: 55px;
  }

  @include break-max($breakpoint-sm) {
    padding-left: 10px;
    padding-right: 10px;

    padding-top: 40px;
    padding-bottom: 50px;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 10px;
    padding-right: 10px;

    padding-top: 10px;
    padding-bottom: 40px;
  }
}

.special__mobile-sign {
  display: none;
  color: $color-brand-3;
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 4px;
  @include break-max($breakpoint-xs) {
    display: block;
    font-weight: normal;
  }

  &_pl {
    padding-left: 10px;
  }
}

.special__filter-section {
  display: flex;
  flex-direction: column;
}

.special__filter-title {
  margin-bottom: 25px;
}

.special__filter {
  // min-height: 92px;
  margin-bottom: 35px;
  @include break-max($breakpoint-md) {
    min-height: auto;
  }

  @include break-max($breakpoint-xs) {
    order: -1;
    min-height: 45px;
    margin-bottom: 25px;

    .button-mobile {
      background: $color-brand-opaque-8;
    }
  }


  &.mini-filter .form {
    background: none;
    padding: 0;

    .clients__filter-col .form__item .form__drop-btn {
      background-color: $color-brand-opaque-8;
      color: $color-brand-3;
    }
  }


  .form__drop-content {
    background-color: $gray-light;
    .checkbox__label {
      background-color: $gray-light;
    }
    .checkbox_type_btn .checkbox__box {
      width: 100%;
    }
    .checkbox-group__item:hover {
      background-color: #ecedf1;
      .checkbox__label {
        background-color: #ecedf1;
      }
    }

  }
}

.special__cards-list {
  display: grid;
  grid-template-columns: repeat(3, 470px);
  justify-content: space-between;
  row-gap: 30px;

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(3, 386px);
    row-gap: 22px;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(2, 356px);
    justify-content: center;
    column-gap: 16px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    justify-content: center;
    row-gap: 30px;
    column-gap: 0;
  }
}

.special__cards-aside {
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.special__cards-aside-ul {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 11px;
  padding-top: 100px;
  @include break-max($breakpoint-md) {
    padding-top: 80px;
  }
  @include break-max($breakpoint-sm) {
    padding-top: 65px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 80px;
  }
}

.special__cards-slider {
  position: relative;
  width: 1220px;
  max-width: 100%;
  margin-left: auto;
  @include break-max($breakpoint-md) {
    width: 960px;
  }
  @include break-max($breakpoint-sm) {
    width: 710px;
  }

  @include break-max($breakpoint-xsm) {
    width: 510px;
  }

}

.special__card-a {
  color: inherit;
}

.special__card {
  position: relative;
  max-width: 100%;

  @include break-max($breakpoint-md) {

  }

}

.special__slider-nav {
  position: absolute;
  top: 0;
  left: -55px;
  height: 100%;
  width: 44px;

  @include break-max($breakpoint-xs) {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background: $color-brand-opaque-20;
    z-index: 0;
  }

  &.next {
    left: auto;
    right: -55px;
    @include break-max($breakpoint-sm) {
      right: -15px;
      &:after {
        display: none;
      }
    }
  }
}

.special__nav-item {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 50%;
  cursor: pointer;
  background: $color-white;
  transition: all 0.3s;
  &:hover {
    background: lighten($color-brand-3, 30%);
  }
  &:active {
    background: lighten($color-brand-3, 30%);
  }

  svg {
    width: 12px;
    height: 10px;
  }
}

.special__card-header {
  background-color: $color-brand-opaque-8;
  border-radius: 20px 20px 0 0;
}

.special__card-header:hover .sf-card__btn-list {
  opacity: 1;
  transition-duration: .3s;
}

.special__card-body {
  padding: 0 25px 20px;
  margin-top: 0;
  background-color: $color-brand-opaque-8;

  border-radius: 0 0 20px 20px;
}

.special__card-row-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 15px;
  border-bottom: 1px solid $color-brand-opaque-20;
}

.special__card-title {
  margin-bottom: 10px;
}

.special__card-price {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
  @include break-max($breakpoint-sm) {
    font-size: 14px;
  }
}

.special__card-price-per {
  font-size: 14px;
  line-height: 18px;
  color: $color-brand-3;
  text-align: right;
}

.special__card-row-bottom {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.special__card-info {
  color: $color-brand-3;
  b {
    color: $color-brand-1;
  }
}

.special__card-params {
  margin: 0;
}

.special__card-param {
  margin-top: 30px;
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    font-weight: bold;
  }
}

.special__card-preview {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;

  @include break-max($breakpoint-md) {
    height: 272px;
  }

  @include break-max($breakpoint-sm) {
    height: 250px;
  }
}

.special__card-prev-box {
  cursor: pointer;
  position: relative;
}

.special__magnifier {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  background: $color-white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  svg {
    fill: $color-brand-2;
    transition: all 0.3s;
  }

  &:hover {
    background: $color-brand-2;
    svg {
      fill: $color-white;
    }
  }

  @include break-max($breakpoint-sm) {
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
  }
}

.special__label {
  display: inline-flex;
  padding: 0 13.5px;
  justify-content: center;
  background: $color-brand-3;
  color: $color-white;
  border-radius: 5px;
  font-size: 14px;
  line-height: 28px;

  &_absolute {
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 100px;
  }
}

.special__more-btn {
  display: inline-flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-red;
  border-radius: 4px;
  color: $color-red;
  transition: all 0.3s;
  &:hover, &:focus {
    background: $color-red;
    color: $color-white;
  }

  &:active {
    background: $color-red-active;
  }
}


.special__detail-title {
  display: flex;
  align-items: center;

  .icon_arrow_up {
    display: none;
  }

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
    min-height: 50px;
    border-bottom: 1px solid $color-brand-opaque-20;
    padding: 15px 25px;
    justify-content: center;
    text-align: center;
    .icon_back {
      display: none;
    }
    .icon_arrow_up {
      display: block;
      transform: rotate(-90deg);
    }
  }

}

.special__detail-return {
  display: flex;
  align-items: center;
  color: $color-brand-3;

  svg {
    margin-right: 5px;
  }

  @include break-max($breakpoint-xs) {
    position: absolute;
    top: 2px;
    left: -10px;
    border: none;
    box-shadow: none;
    font-size: 0;
    border-radius: 0;
    &:active {
      background: none;
    }
  }
  .icon {
    width: 12px;
    fill: $color-brand-2;
    @include break-max($breakpoint-xs) {
      width: 16px;
      fill: $color-brand-1;
    }
  }
}

.special__detail-h1 {
  margin-left: 40px;
  @include break-max($breakpoint-xs) {
    margin-left: 0;
    font-size: 18px;
    line-height: 22px;
  }
}

.special_detail {
  padding-top: 50px;
  padding-bottom: 50px;
  @include break-max($breakpoint-md) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  @include break-max($breakpoint-sm) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 0;
    padding-bottom: 40px;
  }
}

.special__detail-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 40px;
  @include break-max($breakpoint-sm) {
    margin-top: 25px;
    gap: 15px;
  }
  @include break-max($breakpoint-xs) {
    overflow-x: scroll;
    padding: 0 10px;
    gap: 10px;
    margin-right: 10px;
  }
}

.special__detail-tab {
  width: 470px;
  max-width: 100%;
  height: 50px;
  justify-content: center;
  background: $color-brand-opaque-10;
  color: $color-brand-3;
  font-weight: bold;
  border-radius: 4px;
  transition: all 0.3s;

  &.active {
    background: $color-brand-opaque-30;
    color: $color-brand-2;
  }

  @include break-max($breakpoint-md) {
    width: auto;
  }

  @include break-max($breakpoint-xs) {
    width: 140px;

    &:last-of-type {
      margin-right: 10px;
    }
  }
}

.special__detail-tab-label {
  display: inline-flex;
  width: 47px;
  color: $color-white;
  line-height: 28px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  justify-content: center;

  &.blue {
    background: $blue;
  }

  &.green {
    background: $green;
  }

  &.yellow {
    background: $yellow;
  }
}

.special__detail-wrapper {
  margin-top: 30px;
  padding-bottom: 70px;


  @include break-max($breakpoint-md) {

    padding-bottom: 55px;
  }

  @include break-max($breakpoint-sm) {

  }

  @include break-max($breakpoint-xs) {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.special__detail-card {
  display: flex;
  margin-top: 60px;
  padding-bottom: 50px;
  border-bottom: 1px solid $color-brand-opaque-20;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
    border: none;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }
}

.special .special__detail-slider {
  flex-grow: 1;
  max-width: 850px;
  margin-top: 0;
  flex-shrink: 0;


  @include break-max($breakpoint-md) {
    max-width: 692px;
    width: 692px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 726px;
    width: 726px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 100%;
    width: 100%;
  }

  .complex__images.assignation-photos.special__detail-photos .complex__slider-item img {
    height: 580px;

    @include break-max($breakpoint-md) {
      height: 490px;
    }

    @include break-max($breakpoint-xs) {
      height: 256px;
      object-fit: cover;
    }
  }

  .tns-ovh {
    border-radius: 20px;

    @include break-max($breakpoint-xs) {
      border-radius: 0;
    }

    .tns-inner {
      border: none;
    }
  }


  .special__detail-photos {
    position: relative;

    .complex__images-nav {
      padding: 16px 38px;
      @include break-max($breakpoint-md) {
        padding: 16px 20px;
      }
    }
    img {
      padding: 0;
      @include break-max($breakpoint-xs) {
        border-radius: 0;
      }
    }
  }

  .assignation-photos .complex__images-navs {
    @include break-max($breakpoint-sm) {
      justify-content: flex-start;
    }
  }

  .assignation-photos .complex__slider-controls li:hover {
    background: $color-bg-light;

  }

  .complex__slider-magnifier {
    transition: all 0.3s;
    width: 44px;
    height: 44px;
     svg {
       transition: all 0.3s;
     }
  }

  .complex__slider-controls li {
    box-shadow: none !important;
    border: 1px solid $color-brand-opaque-20;
  }

  .complex__slider-controls li.prev {
    .icon {
      margin-left: 0;
    }
  }

  .complex__slider-controls li.next {
    right: 22px;
    .icon {
      margin-right: 0;
    }
  }

  .complex__slider-magnifier:hover {
    background: $color-brand-2;
    svg {
      fill: $color-white;
    }
  }
}

.complex__slider-box {

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 0;
  }
}

.special__detail-h2 {
  display: none;
  @include break-max($breakpoint-sm) {
    display: block;
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 15px;
  }
}

.special__detail-info {
  width: 555px;
  flex-shrink: 0;
  max-width: 100%;
  margin-top: 0;
  margin-left: 70px;
  @include break-max($breakpoint-md) {
    width: 100%;
    margin-left: 45px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  @include break-max($breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.special__detail-pres {
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  max-width: 200px;
  padding: 17px 20px;
  @include break-max($breakpoint-sm) {
    width: 200px;
    justify-content: center;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 13px;
    text-transform: uppercase;
    margin-right: 0;
    max-width: 100%;
  }
}

.special__detail-grid {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $color-brand-opaque-15;
  @include break-max($breakpoint-sm) {
    border-bottom: none;
  }
  @include break-max($breakpoint-xs) {
    border-bottom: none;
  }

  &_no-border {
    border-bottom: none;
  }
}

.special__detail-col {
  width: 50%;
  padding-right: 25px;
  padding-bottom: 25px;
  @include break-max($breakpoint-sm) {
    width: 33%;
  }
  @include break-max($breakpoint-xs) {
    width: 50%;
  }
}

.special__detail-value {
  display: block;
  font-size: 16px;
  font-weight: bold;
  @include break-max($breakpoint-sm) {
    font-size: 14px;
  }

  &_lg {
    font-size: 25px;
    @include break-max($breakpoint-md) {
      font-size: 20px;
    }
    @include break-max($breakpoint-sm) {
      font-size: 18px;
    }
  }
}

.special__detail-sub {
  display: block;
  margin-top: 3px;
  color: $color-brand-3;
}

.special__manager {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: $color-brand-opaque-8;
  border-radius: 20px;
  padding: 33px 85px 33px 39px;

  @include break-max($breakpoint-sm) {
    width: 480px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @include break-max($breakpoint-xs) {
    position: relative;
    padding: 12px 20px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;

    .info {
      position: absolute;
      top: 20px;
      right: 35px;
    }
  }
}

.special__manager-avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.special__manager-info {
  max-width: 170px;
  @include break-max($breakpoint-xs) {
    margin-right: 20px;
  }
}

.special__manager-name {
  display: block;
  font-weight: bold;
  @include break-max($breakpoint-xs) {
    font-size: 15px;
  }
}

.special__manager-position {
  display: block;
}

.special__manager-contacts {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  a {
    margin-bottom: 4px;
  }
}

.special-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  max-width: 100%;
  padding: 43px;
  text-align: center;
  border: 1px solid $color-brand-opaque-20;
  background: $color-white;
  z-index: 10;
  border-radius: 5px;

  display: none;
  &.show {
    display: block;
  }

  @include break-max($breakpoint-xs) {
    top: auto;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    transform: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 65px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 25px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 22px;
      width: 40px;
      height: 3px;
      background: $color-brand-opaque-50;
      border-radius: 20px;
    }
  }
}

.special-modal__text {
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @include break-max($breakpoint-xs) {
    max-width: 292px;
    margin-bottom: 45px;
    font-size: 15px;
  }
}

.special-modal__btns {
  display: flex;
  width: 270px;
  margin-left: auto;
  margin-right: auto;
  .button:first-child {
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 15px;
  }
  .button:last-child {
    min-width: 120px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    justify-content: center;

    .button {
      width: 172px;
      font-weight: bold;
      &:last-child {
        background: #f4f4f7;
        box-shadow: none;
      }
    }
  }
}

.special-modal__body-blur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-brand-1, 0.5);
  z-index: 2;

  display: none;
  &.show {
    display: block;
  }
}

.special-form-modal {
  @include break-max($breakpoint-xs) {
    .h2 {
      display: none;
    }
  }
}

.special__showroom {
  width: 345px;
  height: 65px;
  display: inline-flex;
  align-items: center;
  padding: 0 18px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: $color-red;
  color: $color-white;
  margin-left: auto;
  border-radius: 4px;
  @include break-max($breakpoint-xs) {
    position: static;
    width: 100%;
    padding: 10px 15px;
    height: auto;
    min-height: 55px;
    text-align: left;
    margin-top: 15px;
  }

}

.special__showroom-icon {
  width: 38px;
  height: 34px;
  flex-shrink: 0;
  margin-right: 20px;
}

.special__showroom-anchor {
  background: none;
  text-decoration: underline;
  color: $color-white;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.special__about {
  margin-bottom: 10px;
  margin-top: 40px;

  .h3 {
    margin-bottom: 20px;
  }
}

.special-filter-drop {
  width: 330px;
  max-width: 100%;
  height: 50px;

  .custom-select {
    height: 50px;
  }

  .custom-select__selected {
    padding-left: 45px;
    height: 100%;

    background-image: url(../../assets/images/special/sort.svg);
    background-position: 15px center;
    background-repeat: no-repeat;
    background-color: $color-white;
    &::after {
      display: none;
    }
  }

  .custom-select.is-showing {
    box-shadow: none;
  }


  .custom-select.is-showing .custom-select__selected {
    background-color: $color-brand-opaque-8;
    box-shadow: none;
  }

  .custom-select__option {
    padding: 16px;
  }

  .custom-select.is-showing .custom-select__options {
    background: $gray-light;
    margin-top: 2px;
  }
}

.special-filter-drop_currencys {
  width: auto;
  display: inline-block;
  height: auto;
  background-color: transparent;

  .custom-select {
    min-width: auto;
    height: auto;
    display: inline-block;
    background-color: transparent;

    &__selected {
      padding: 0 20px 0 0;
      background-color: transparent;
      background-position: 30px center;
    }

    &__option {
      padding: 5px;
    }
  }

  .custom-select.is-showing {
    .custom-select__selected {
      background-color: transparent;
    }
  }
}

.slider-toggler {
  position: absolute;
  top: 30px;
  right: 45px;
  z-index: 2;
}

.special__detail-btns {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-brand-opaque-15;
  margin-bottom: 25px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.special__detail-lock {
  display: flex;
  align-items: center;
  @include break-max($breakpoint-xs) {
    width: 100%;
    justify-content: center;
  }

  img {
    margin-right: 8px;
  }
}

.special__detail-top {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.special__detail-name {
  margin-right: 40px;
}

.special__similar {
  margin-top: 95px;

  @include break-max($breakpoint-md) {
    margin-top: 60px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 25px;
  }
}

.special__similar .h2 {
  margin-bottom: 40px;

  @include break-max($breakpoint-md) {

  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {

  }
}


.special-filter-drop.special-filter-drop_white-select {

  .custom-select__selected {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjgyNSA5LjQwNDg0TDcuNjc1IDYuMzU3MjIiIHN0cm9rZT0iIzJDMkUzRSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik03LjMyNSA2LjM1NzEyTDQuMTc1IDkuNDA0NzQiIHN0cm9rZT0iIzJDMkUzRSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik03LjQ5OTk2IDYuMTY2N0w3LjQ5OTk2IDE3LjM4MSIgc3Ryb2tlPSIjMkMyRTNFIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTEzLjE3NSAxNC4zNTcxTDE2LjMyNSAxNy40MDQ3IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTYuNjc1IDE3LjQwNDhMMTkuODI1IDE0LjM1NzIiIHN0cm9rZT0iIzJDMkUzRSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNi41IDE3LjU5NTNMMTYuNSA2LjM4MDk4IiBzdHJva2U9IiMyQzJFM0UiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
  }

  .custom-select.is-showing {
    .custom-select__selected {
      background-color: white;
      box-shadow: 0px 2px 20px rgba(143, 149, 172, 0.1);
      border-radius: 15px 15px 0 0;
    }
  }
  .custom-select__options ul {
    background-color: white;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .custom-select__option {
    margin: 0 10px;
    padding-top: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 7px;
    border-radius: 5px;
    transition-duration: .3s;

    &:hover {
      background-color: #ECEDF0;
    }
  }
  .custom-select.is-showing {
    .custom-select__options {
      margin-top: -2px;
      border-radius: 0 0 20px 20px;
    }
  }
  .simplebar-mask {
    border-radius: 0 0 20px 20px;
    margin-top: -5px;
    background-color: white;
  }
}
