.promo {
  position: relative;

  &_no-mb {
    margin-bottom: 0 !important;
  }
}

.promo__wrapper {
  flex-direction: row;
  align-items: center;
  height: calc(100vh - 50px);

  @include break-max($breakpoint-md) {
    height: auto;
    padding: 110px 0 150px;
  }
  @include break-max($breakpoint-sm) {
    padding: 110px 0;
  }
  @include break-max($breakpoint-xs) {
    padding: 42px 0;
    flex-direction: column;
  }
}

.promo__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 570px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 500px;
  }
  @include break-max($breakpoint-sm) {
    width: 385px;
  }
  @include break-max($breakpoint-sm) {
    width: 310px;
  }
  @include break-max($breakpoint-xs) {
    order: 1;
    text-align: center;
    width: 100%;
  }
}

.promo__label {
  margin-bottom: 35px;
  color: $color-1;
  @include break-max($breakpoint-md) {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 18px;
  }

  @include break-max($breakpoint-xsm) {
    width: 225px;
    margin-bottom: 20px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 0 15px;
  }
}

.promo.for-theme-5 .promo__label,
.promo.for-theme-5 .promo__sign {
  color: $color-white;
}

.promo__text {
  margin-bottom: 20px;
  color: $color-brand-2;
  @include break-max($breakpoint-sm) {
    margin-bottom: 15px;
  }
  @include break-max($breakpoint-xs) {
    padding: 0 20px;
    width: 100%;
  }
}

.promo__sign {
  margin-bottom: 60px;
  color: $color-brand-3;
  @include break-max($breakpoint-md) {
    margin-bottom: 50px;
  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 30px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 0 15px;
  }
}

.promo__btn {
  position: relative;
  padding: 17px 42px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 700;
  @include break-max($breakpoint-md) {
    font-size: 14px;
  }
  @include break-max($breakpoint-sm) {
    font-size: 12px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 11px;
    line-height: 16px;
    margin: auto;
  }


//   &:after {
//   background: #fff;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: .2;
//   position: absolute;
//   top: -50px;
//   transform: rotate(35deg);
//   transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   width: 50px;
//   z-index: -10;
// }

}

.promo__author {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-left: 40px;

  @include break-max($breakpoint-xs) {
    padding-left: 0;
    margin-bottom: 25px;
  }
}

.promo__descr {
  padding: 24px 32px;
  box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  border-radius: 8px;
  background: $color-white;
  text-align: center;
  margin-top: -45px;

  @include break-max($breakpoint-md) {
    padding: 16px 32px;
  }
  @include break-max($breakpoint-sm) {
    padding: 20px 46px;
  }
  @include break-max($breakpoint-xsm) {
    padding: 10px 19px;
  }
}

.promo.for-theme-2 {
  @include break-max($breakpoint-xs) {
    .promo__author {
      margin-bottom: 40px;
    }
  }
}

.promo.for-theme-3 {
  @include break-max($breakpoint-xs) {
    .promo__descr {
      background: none;
      box-shadow: none;
      color: $color-white;
      margin-top: 18px;
      margin-bottom: 50px;
      padding: 0;

      .promo__position,
      .promo__name {
        color: $color-white;
      }
    }

    .promo__wrapper {
      padding-top: 55px;
    }
  }
}

.promo.for-theme-4 {
  margin-bottom: 75px;
  @include break-max($breakpoint-md) {
    margin-bottom: 50px;
  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 25px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  .promo__img {
    width: 445px;
    height: 445px;
    border-radius: 42px;
  }

  .promo__descr {
    border-radius: 20px;
  }
}


.promo.for-theme-5 {
  margin-bottom: 75px;
  @include break-max($breakpoint-md) {
    margin-bottom: 50px;
  }
  @include break-max($breakpoint-sm) {
    margin-bottom: 25px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  .promo__text {
    color: $color-white;
  }

  .promo__img {
    width: 595px;
    height: 550px;
    border-radius: 15px;

    @include break-max($breakpoint-md) {
      width: 480px;
      height: 440px;
    }

    @include break-max($breakpoint-sm) {
      width: 335px;
      height: 310px;
    }
    
    @include break-max($breakpoint-xs) {
      width: 250px;
      height: 230px;
    }

  }
}

.promo__name {
  color: $color-brand-2;
  @include break-max($breakpoint-md) {
    display: block;
    padding-bottom: 5px;
  }

}

.promo__position {
  color: $color-1;
}

.promo__img {
  width: 351px;
  height: 351px;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;

  @include break-max($breakpoint-sm) {
    max-width: 310px;
    max-height: 310px;
  }

  @include break-max($breakpoint-xsm) {
    max-width: 260px;
    max-height: 260px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 176px;
    max-height: 176px;
  }
}

.promo__bottom-info {
  display: flex;
  width: auto;
  align-items: center;

  .constructor__socials_promo:after {
    display: none;
  }

  @include break-max($breakpoint-md) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    max-width: 355px;
    margin-left: auto;
    margin-right: auto;
  }
  

  .promo__btn {
    margin-right: 15px;
    @include break-max($breakpoint-xs) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .constructor__socials {
    flex-shrink: 0;
    margin-top: 0;
    align-items: center;
    justify-content: flex-start;
    @include break-max($breakpoint-md) {
      margin-top: 15px;
    }
  }

  li.constructor__social-btn:nth-child(n) {
    margin-bottom: 0;
    width: 50px;
    height: 50px;
    margin-left: 0;
    margin-right: 10px;
    flex-shrink: 0;
    &:last-of-type {
      margin-right: 0 ;
    }

    @include break-max($breakpoint-xs) {
      width: calc((100% - 16px) / 3);
      height: 44px;
      margin-right: 0;
      margin-bottom: 7px;
      &.is-one-social {
        width: 100%;
      }

      &:only-child {
        width: 100%;
      }
    }
    
  }

  li.constructor__social-btn:nth-child(2), li.constructor__social-btn:nth-child(5) {
    @include break-max($breakpoint-xs) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.promo__paint-bg {
  position: absolute;
  top: -30%;
  right: -15%;
  z-index: -1;

  @include break-max($breakpoint-sm) {
    top: -85%;
    right: 0;
  }

  @include break-max($breakpoint-xsm) {
    width: 220px;

    svg {
      width: 100%;
    }
  }

  @include break-max($breakpoint-xs) {
    top: -100px;
    right: -40px;
    height: 260px;

    svg {
      height: 100%;
    }
  }


}
