@import "../../../styles/variables";
@import "../../../styles/mixins";

.redesign {

  .section__grid {
    margin-left: 0;
    margin-right: 0;
  }
  .section__grid-col.index-search__filter {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .section__grid-col.index-search__header.for-main {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;

    .h2 {
      @include h3();
    }

    .form__row {
      .form__row {
        margin-top: -23px;
      }
    }
  }

  .index-search__filter-body {
    border-radius: 0;
    background-color: transparent;

    .index-search__filter-top {
      background-color: $gray-light;
      border-radius: 20px;
      padding: 16px 20px;
    }

    .index-search__filter-main {
      padding: 30px 20px;
      .form__drop-btn {
        background-color: $gray-light;
      }
    }

  }

  .index-search__filter-applied.for-dragable {
    .index-search__filter-inc {
      border-radius: 20px;

      .filtered {
        box-shadow: none;
        border-radius: 20px;
        background-color: $gray-light;
      }
    }
    .index-search__filter-exc {
      border-radius: 20px;

      .filtered {
        box-shadow: none;
        border-radius: 20px;
      }
    }

    .filtered-clear__wrapper {
      .button_type_filtered {
        box-shadow: none;

        &:hover {
          background-color: transparent;
          color: $color-red-bottom-border;
        }
      }
    }


  }

  .section_view_gray {
    background-color: transparent;
    padding-top: 0;
  }

  .section__grid.cards__header {

    margin-bottom: 20px;
    .section__grid-col.section__heading {
      display: none;
    }

    .section__grid-col.cards__controls {
      width: 100%;
    }
  }
  .section__grid-col.cards__item.shadow {
    border-radius: 20px;
    overflow: hidden;
  }
  .card {
    background-color: $gray-light;

    .card__body {
      background-color: $gray-light;
      flex-direction: column;

      .card__image {
        border-radius: 20px;
      }
    }
  }

  .cards__item {
    width: calc(100% / 12 * 4 - 22px);
  }

  .card__header {
    height: 310px;
    width: 100%;
  }
  .card__image {
    height: 310px;
    width: 100%;

    img {
      height: auto;
    }
  }

}



























