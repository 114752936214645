@import '../../styles/variables';
@import '../../styles/mixins';

.apt-modal {

  &__map {
    height: 492px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid $gray-dark;

    @include break-max($breakpoint-sm) {
      height: 432px;
    }

  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &__status {
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
    margin-left: 10px;
    height: 28px;

    svg {
      margin-right: 2px;
    }

    @include break-max($breakpoint-xs) {
      margin-left: 0;
    }
  }

  &__status.label-item {
    padding: 5px 9px 6px;
  }

  &__header-second {
    display: flex;
    justify-content: space-between;
  }

  &__fullinfo-link {
    color: $color-red-bottom-border;
    transition-duration: .3s;
    margin-top: 19px;
    height: max-content;

    svg {
      margin-left: 7px;
      margin-bottom: -4px;
    }

    &:hover {
      color: $one-more-red;

      svg path {
        stroke: $one-more-red;
      }
    }
  }


  .label_green {
    svg circle {
      stroke: $green;
    }
    svg line {
      stroke: $green;
    }
  }

  .label_red {
    svg circle {
      stroke: $color-red;
    }
    svg line {
      stroke: $color-red;
    }
  }

  .label_yellow {
    svg circle {
      stroke: $yellow;
    }
    svg line {
      stroke: $yellow;
    }
  }

  .flat__images {
    margin-top: 0;
    margin-bottom: 0;
  }

  .flat__image {
    padding: 0;
  }

}


.sf__detail-contactSlider {
  position: relative;
  margin-bottom: 15px;
  .tns-outer {
    display: grid;
  }
  .tns-horizontal.tns-subpixel>.tns-item {
    display: inline-flex;
  }
  .slider__nav-wrap {
    border: none;
  }
  .tns-nav {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 5px;
    z-index: 1;

    button {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 0 5px;
      padding-right: 0;
    }
    button.tns-nav-active {
      background-color: #ff2d23;
    }
  }

}
