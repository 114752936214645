.video {
  position: relative;
}

.video__wrapper {

}

.video__box {
  width: 100%;
  display: flex;
  align-items: flex-start;

  &_single-video {
    justify-content: center;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.video__title {

}

.video__main {
  width: 845px;
  min-height: 560px;
  max-width: 100%;

  @include break-max($breakpoint-md) {
    width: 640px;
    min-height: 420px;
    height: 420px;
  }

  @include break-max($breakpoint-sm) {
    width: 845px;
    min-height: 560px;
    max-width: 100%;
    margin-bottom: 35px;
  }

  @include break-max($breakpoint-xs) {
    min-height: auto;
    height: auto;
    margin-bottom: 15px;
  }


  iframe {
    width: 845px;
    min-height: 560px;
    max-width: 100%;

    border-radius: 15px;
  
    @include break-max($breakpoint-md) {
      width: 640px;
      min-height: 420px;
      height: 420px;
    }
  
    @include break-max($breakpoint-sm) {
      width: 845px;
      min-height: 560px;
      max-width: 100%;
    }
  
    @include break-max($breakpoint-xs) {
      min-height: 240px;
      height: 240px;
      border-radius: 5px;
    }
  }
}

.video__prev {
  flex-shrink: 0;
  border-radius: 15px;
  object-fit: cover;

  width: 252px;
  max-width: 100%;
  height: 160px;

  @include break-max($breakpoint-xs) {
    border-radius: 5px;
    height: 85px;
  }

  &_side {

  }
}

.video__main-info {
  position: absolute;
  bottom: 0;
  width: 85%;

  padding: 40px 50px;
  color: $color-white;

  svg {
    display: inline-flex;
    margin-bottom: 30px;
  }
}

.video__h5 {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;

  @include break-max($breakpoint-xs) {
    font-size: 14px;
    line-height: 18px;
  }
}

.video__text {
  font-size: 14px;
  line-height: 16px;

  @include break-max($breakpoint-xs) {
    display: none;
  }

}

.video__sidebar {
  width: 580px;
  height: 560px;
  max-width: 100%;
  margin-left: auto;

  @include break-max($breakpoint-md) {
    width: 510px;
    height: 420px;
  }

  @include break-max($breakpoint-sm) {
    width: 845px;
    height: 355px;
    margin-left: 0;
  }

  @include break-max($breakpoint-sm) {
    height: 355px;
  }

  .video__item:not(:last-of-type) {
    margin-bottom: 35px;
    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
    }
  }

  svg {
    opacity: 0;
    transition: all 0.6s;
    position: absolute;
    bottom: 15px;
    left: 20px;
    @include break-max($breakpoint-xs) {
      bottom: 5px;
      left: 10px;
    }
  }

  .video__item:hover svg {
    opacity: 1;
  }

  .video__item.active svg {
    opacity: 1;
  }
}

.video__item {
  display: flex;
  position: relative;
  border-radius: 15px;
  min-height: 160px;
  
  padding: 0;
  font-family: inherit;
  text-align: left;
  background: none;

  cursor: pointer;

  @include break-max($breakpoint-xs) {
    width: 100%;
    height: 85px;
    min-height: 85px;
    overflow-y: hidden;
    border-radius: 5px;
  }
}

.video__sidebox {
  position: relative;
  flex-shrink: 0;
  @include break-max($breakpoint-xs) {
    width: 40%;
  }
}

.video__side-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 160px;

  @include break-max($breakpoint-md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    min-height: auto;
  }

  .video__h5 {
    margin-bottom: 0;
  }
}

.video__box_single-video {
  .video__main {
    @include break-max($breakpoint-xs) {
      margin-bottom: 0;
      iframe {
        margin-bottom: 0;
      }
    }
  }
}