.assignation {
  &-photos {
    @include break-max($breakpoint-sm) {
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-xs) {
      margin-bottom: 0;
    }
  }

  &-info {

    @include break-max($break-xs) {
      & .flat__col-row {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      }

      & .flat__complex {
        flex-basis: 100%;
      }

      & .flat__title {
        margin-right: 0;
        padding-top: 7px;
        width: 70%;
      }

      .manager {
        flex-wrap: wrap;
      }

      .manager-contacts_item {
        flex-basis: 100%;
      }

      .manager-avatar_wrapper {
        height: 75px;
        width: 75px;
      }

      .manager-avatar {
        flex-basis: auto;
      }

      .manager-action {
        flex-basis: 100%;

        .button {
          border-radius: 5px;
          width: 100%;
        }
      }
    }

    @include break-max($break-xxs - 1px) {
      & .flat__title {
        width: 64%;
      }
    }

    & &__client-type {
      cursor: text;
      margin-right: 0;
      margin-left: 15px;
      &:hover {
        background-color: $color-brand-3;
      }
    }

    .flat__col-row {
      display: flex;
      width: 100%;

      @include break-max($breakpoint-xs) {
        flex-direction: column;
      }

      .flat__title {
        min-width: 400px;
        max-width: 100%;
        margin-right: 35px;
        flex-shrink: 1;
        @include break-max($breakpoint-sm) {
          min-width: 300px;
        }

        &_small {
          min-width: 280px;
          @include break-max($breakpoint-sm) {
            min-width: 250px;
          }
          
        }
      }

      .flat__complex {
        margin-right: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .flat__complex-grid:first-child .flat__complex-col {
          padding-top: 0;
        }
      }
    }

    .flat__col-title {
      @include grid-size(7);
      flex-direction: column;
      align-items: flex-start;

      @include break-max($breakpoint-md) {
        @include grid-size(8, $grid-gutter-md);
      }

      @include break-max($breakpoint-xs) {
        flex-basis: 100%;
        width: 100%;
      }
    }

    .flat__col-download {
      @include break-max($breakpoint-sm) {
        @include grid-size(4, $grid-gutter-sm);
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;

        .flat__download {
          margin-bottom: 20px;
        }

        .button_presentation {
          max-width: initial;
        }
      }

      @include break-max($breakpoint-xs) {
        align-items: flex-start;
      }
    }

    .flat__complex {

      @include break-max($breakpoint-sm) {
        flex-direction: column;
      }

      &-grid {
        display: flex;
        flex-wrap: nowrap;

        &_wrap {
          flex-wrap: wrap;
        }

        @include break-max($breakpoint-sm) {
          flex-direction: column;
          height: auto;
        }
      }

      .complex__routes {
        @include break-max($breakpoint-sm) {
          align-items: flex-start;
        }
      }

      &-col {
        flex: 1 1 50%;

        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 15px;

        // &:first-child {
        //   margin-right: 60px;
        // }

        // @include break-max($breakpoint-md) {
        //   &:first-child {
        //     margin-right: 30px;
        //   }
        // }

        @include break-max($breakpoint-sm) {
          flex: 0 1 auto;
          padding-top: 10px;

          // &:first-child {
          //   margin-right: 15px;
          // }
        }
      }

      &-address {
        padding-top: 2px;
        line-height: 18px;
      }

      &-title {
        .h4 {

          font-size: 16px;
          line-height: 20px;
        }
      }

      &-link {
        margin-top: 4px;
      }

    }

    .flat {

      &__updated {
        color: $color-brand-3;
        font-style: italic;
        margin-top: 15px;
        white-space: nowrap;
      }

      &__info-btns {
        justify-content: flex-start;
        @include break-max($breakpoint-sm) {
          justify-content: center;
          padding-top: 20px;
          border-bottom: none;
        }
        @include break-max($breakpoint-xs) {
          padding: 0;
          margin: 0;
        }
      }
    }

    .complex__routes-item {
      color: $color-brand-3;
    }

    .complex__metro-station,
    .complex__route-time {
      color: inherit;
    }

  }

  &-about {
    position: relative;
    padding: 25px 65px 30px;
    color: $color-white;
    background: $color-brand-3;
    line-height: 18px;
    border-radius: 5px;

    @include break-max($break-xs) {
      padding: 20px 10px;
    }

    p:first-of-type {
      margin-top: 0;
    }

    &__info {
      position: absolute;
      top: 25px;
      left: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background: $color-brand-3-hover;
      border-radius: 50%;

      @include break-max($break-xs) {
        left: auto;
        right: 10px;
      }

      &::after {
        content: "Примечания"; /* Выводим текст */
        position: absolute;
        left: 25px;
        top: -100%;
        z-index: 1;
        background: white;
        font-size: 11px;
        padding: 5px 10px;
        box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
        border-radius: 4px;
        white-space: nowrap;
        color: $color-brand-2;
        font-weight: 700;
        display: none;
      }

      &:hover::after {
        display: block;
      }

      .icon {
        width: 15px;
        height: 15px;
        fill: $color-white;
      }

    }


    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      height: 75px;
      overflow: hidden;
      transition: height 0.3s;

      & + .assignation-about__toggle {
        padding-top: 15px;
      }
    }

    &__col {
      flex-grow: 1;

      &:first-child {
        margin-right: 100px;

        @include break-max($break-xs) {
          margin-right: 40px;
        }
      }
    }

    & p {
      font-size: 14px;
      line-height: 21px;
      margin: 15px 0;
      &:last-child, &:only-of-type {
        margin-bottom: 0;
      }
    }

    & br:last-child {
      display: none;
    }

    &__toggle {
      position: relative;
      width: 100%;
      text-align: left;
      color: $color-white;

      &::after {
        content: '';
        width: 100%;
        position: absolute;
        top: -10px;
        left: 0;
        height: 25px;
        background-image: linear-gradient(rgba(143,149,172,0.2) 0%, rgba(143,149,172, 1) 100%);
      }
    }


    .icon_chevron {
      stroke: #fff;
      fill: transparent;
      font-size: 12px;
      margin-left: 5px;
      transform: rotate(0);
      transition: transform 0.15s ease-in-out;
    }

    &.opened {
      .assignation-about__body {
        height: auto;
      }
      .icon_chevron {
        transform: rotate(180deg);
      }

      .assignation-about__toggle::after {
        display: none;
      }

    }
  }

  &-calc {
    margin: 90px 0;

    &__title {
      margin-bottom: 30px;
    }
  }

  &-reservation {
    position: relative;
    padding-top: 240px;

    @include break-max($break-xs) {
      padding-top: 0;
      max-width: 100%;

      & .react-datepicker-wrapper {
        width: 100%;
      }
    }

    &__header {
      position: absolute;
      top: -50px;
      left: -125px;
      right: -125px;
      height: 240px;
      padding: 55px 225px 0;

      @include break-max($break-xs) {
        left: -30px;
        right: -30px;
      }

      &-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-top-right-radius: 5px;
        overflow: hidden;

        @include break-max($break-xs) {
          text-align: center;
          width: 100%;
        }

        & .icon {
          width: 100%;
          height: 238px;
        }
      }
    }

    &__warning {
      color: $color-red;
      font-weight: bold;
      margin: 10px 0;
    }
  }
}

div.assignations__slider-item img {
  @include break-max($break-xs) {
    height: auto;
  }
}

.button.button_view_outline-with-icon-invert.mobile-map {
  border: none;
  box-shadow: none;
  font-size: 11px;
  font-weight: bold;
  height: auto;
  line-height: 16px;
  margin-bottom: 20px;
  padding: 0 10px;

  &.for-secondary {
    justify-content: flex-end;
  }
}

.button.button_view_outline-with-icon-invert.for-list-secondary {
  position: fixed;
  left: 10px;
  right: 0;
  bottom: 20px;
  z-index: 100;
}

.button.button_view_outline.for-reglament {
  @include break-max($breakpoint-xs) {
    border-radius: 5px;
    color: $color-brand-3;
    width: 100%;

    align-items: center;
    display: flex;
    justify-content: center;
    height: 44px;
  }
}

.mobile-filters {
  .assignation-reservation__header {
    background: none;
    left: 0;
    right: 0;
    top: -23px;
  }
}

.assignations-flats__row {
  @include break-max($break-xs) {
    margin-bottom: 10px;
  }
}


.flat__complex-title {
  margin-right: 15px;
}