@import '../../../styles/variables';
@import '../../../styles/mixins';

.uploadedr-docs {
  padding: 30px 30px 30px 30px;
  background-color: #f6f7f8;
  border-radius: 20px;
  margin-bottom: 30px;

  &__title {
    @include h6();
    color: $color-brand-2;
    margin: 0;
    margin-bottom: 20px;
  }
}
