.block {
  display: flex;
  flex-direction: column;

  width: 260px;
  padding: 35px 35px 35px 40px;

  background: $color-white;
  //box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  border: 1px solid rgba(148, 134, 204, 0.15);
  border-radius: 15px;

  @include break-max($breakpoint-md) {
    width: 230px;
    padding: 30px 30px 40px;
  }

}

.block__img {
  max-width: 67px;
  max-height: 67px;
  margin-bottom: 20px;

  svg {
    max-width: 67px;
    max-height: 67px;
    
  }

  .toggle-color {
    fill: $color-brand-2;
  }
}

.block__title {
  margin-bottom: 15px;
}

.block__title_underline {
  display: flex;
  flex-direction: column;
  &::after {
    content: '';
    width: 37px;
    height: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: $color-1;
  }
  @include break-max($breakpoint-md) {
    margin-top: 15px;
    margin-bottom: 0px;
  }

}
