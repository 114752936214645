@import '../../../styles/variables';
@import '../../../styles/mixins';

.new-tabs {
  margin-top: 10px;
  position: relative;
}

.common-link {
  position: absolute;
  right: 0;
  top: 15px;
  display: flex;
  align-items: center;
  color: $color-red-bottom-border;
  transition-duration: .3s;

  svg {
    margin-bottom: -3px;
    margin-right: 9px;
  }

  &:hover {
    color: $one-more-red;
    transition-duration: .3s;

    svg path {
      stroke: $one-more-red;
    }
  }
}

.common-link.common-link_dark {
  color: $color-brand-2;
  cursor: pointer;

  &:hover {
    color: $one-more-red;
  }
}

.tabs-nav {
  border-bottom: 1px solid $gray-dark;
  margin-bottom: 30px;
  display: flex;
  width: max-content;
}

.tabs-nav-item {

  &__button {
    background-color: transparent;
    min-width: 140px;
    text-align: center;
    padding: 15px 30px 15px 30px;
    position: relative;
    cursor: pointer;
    color: inherit;
    display: inline-block;
    font-family: inherit;
    @include h6();

    &:before {
      content: '';
      height: 3px;
      width: 100%;
      background-color: $gray-dark;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      opacity: 0;
      transition-duration: .3s;
    }

    &:hover {

      &:before {
        opacity: 1;
        transition-duration: .3s;
      }
    }

    &--active {

      &:before {
        opacity: 1;
        transition-duration: .3s;
        background-color: $color-red-bottom-border;
      }

      &:hover {
        cursor: default;

        &:before {
          background-color: $color-red-bottom-border;
        }
      }
    }
  }
}


.small-five-title {
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}

.deal-contacts-grid {
  display: flex;
  flex-wrap: wrap;

  & > .contact-card {
    margin: 0 20px 20px 0;
    width: calc(50% - 10px);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.deal-description-list {
  margin-bottom: 30px;

  &__item {
    list-style-type: none;
    font-weight: 400;
    color: $color-brand-2;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

