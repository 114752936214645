@import "../../../styles/variables";
@import "../../../styles/mixins";

.panmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  //background-color: $color-white;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  overflow: auto;


  background-color: rgba(0, 0, 0, 0.6);

  padding: 0 15px;
  overflow: auto;

  iframe {
    width: 510px;
    height: 276px;
    max-width: 100%;
    border-radius: 20px;
  }
  &__wrapper {
    background-color: $color-white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    //max-width: 970px;
    border-radius: 20px;
    margin: 100px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .sf__manager-block {
    text-align: left;
  }
  .new-alert {
    text-align: left;
  }
  &__content {
    text-align: left;
  }
}

.panmodal__header {
  position: relative;
  margin: 30px 0 0;
  //padding: 30px;
  display: flex;
  min-height: 70px;
  height: 70px;
  align-items: center;
  //background-color: $gray-light;
  border-radius: 20px;
  max-width: 100%;
  width: 1230px;
  justify-content: center;

  @include break-max($breakpoint-sm) {
    //margin: 15px 0;
    //padding: 18px;
    //border-radius: 10px;
  }
}

.panmodal__content {
  max-width: 100%;
  width: 768px;
  padding: 30px;
  @include break-max($breakpoint-xs) {
    padding: 0;
  }
}

.panmodal__content_sm {
  width: 570px;
}

.panmodal__content_lg {
  max-width: 1230px;
  width: 100%;
  padding: 0;
}

.panmodal__btn {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  border-radius: 50%;
  background-color: #f6f7f8;
  @include break-max($breakpoint-sm) {
    right: 25px;
  }

  svg {
    stroke: $color-brand-2;
    transition: all 0.3s;
  }

  &:hover svg {
    stroke: $color-brand-3;
  }
}

.panmodal_mobile {
  @include break-max($breakpoint-xs) {
    overflow-x: hidden;
    .panmodal__header {
      height: 50px;
      min-height: 50px;
      background-color: $color-white;
      border-radius: 0;
      margin: 0;
      border-bottom: 1px solid $gray-dark;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px);
      margin-bottom: 20px;

      .panmodal__h3 {
        font-size: 14px;
      }
    }
    .panmodal__close {
      position: absolute;
      left: 5px;
    }
    .panmodal__btn {
      display: none;
    }

    .sf-slider--with-border .swiper-container:not(.swiper-thumbs) {
      border: none;
    }
    .swiper-pan img:not(.pswp__img) {
      max-height: 280px;
    }
    .swiper-zoom {
      display: none;
    }
    .apt-modal__map {
      max-height: 280px;
      border: none;
    }
    .swiper-thumbs .swiper-slide {
      background-color: #424242;
    }
    .new-alert {
      padding-left: 20px;
      padding-right: 20px;
    }
    .new-alert__icon {
      display: none;
    }
    .sf__info-header {
      .sf__header-col {
        display: block;

        .h3-l.sf__price {
          font-size: 18px;
        }
      }
    }
    .fv-comment {
      margin-top: 20px!important;
      margin-bottom: -50px!important;
    }
  }
}
