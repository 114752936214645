@import "../../styles/variables";
@import "../../styles/mixins";


// #Рефакторить нахрен все страницы

.preview__header + .section .sor.sor_booking {
  padding-top: 85px;
  @include break-max($breakpoint-sm) {
    padding-top: 60px;
  }
}

.sor .wrapper__center {
  width: 1220px;
  max-width: 100%;
}

.sor__h3 {
  margin-bottom: 35px;
}

.sor__about-sign {
  margin-top: 10px;
}

.sor.sor_auto.booking {

  .sor__promo-pic {
    max-width: 600px;
    right: -100px;
    @include break-max($breakpoint-sm) {
      right: 0;
      top: 50px;
    }
  }

  .sor__submit {
    @include break-max($breakpoint-xs) {
      max-width: 100%;
    }
  }

  .booking__banner {
    .sor__promo-btn {
      @include break-max($breakpoint-sm) {
        width: 170px;
        &:first-of-type {
          margin-right: 0;
          margin-bottom: 0;
        }
      }

      @include break-max($breakpoint-xs) {
        width: 100%;
      }
    }
  }


  .sor__promo {
    min-height: 560px;
    @include break-max($breakpoint-md) {
      min-height: 520px;
    }
    @include break-max($breakpoint-sm) {
      min-height: 440px;
    }
    @include break-max($breakpoint-xs) {
      min-height: 550px;
    }
  }
}

.booking {

  .sor__step {
    display: block;
    margin-bottom: 10px;
  }

 .sor__prog-t {
   max-width: 945px;
 }

 .sor__grid_steps {

    &:not(.swiper-pan) {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, 386px);
      grid-template-rows: 1fr;
      row-gap: 20px;
    
      @include break-max($breakpoint-md) {
        grid-template-columns: repeat(3, 318px);
      }
    
      @include break-max($breakpoint-sm) {
        grid-template-columns: repeat(3, 232px);
      }
    
      @include break-max($breakpoint-xs) {
        grid-template-columns: repeat(1, 1fr);
      }

      
    }


    .sor__about-counter {
      color: $color-brand-3;
    }

  }

  .sor__steps_step-2 .sor__about-item:nth-child(4) {
    grid-column: 1 / 3;
  }
}

.booking__grid {
  display: grid;
  grid-template-columns: repeat(3, 386px);
  justify-content: space-between;
  margin-top: 35px;

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(3, 318px);
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 232px);
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    row-gap: 65px;
  }
}

.booking__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 135px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 60px;

  border: 1px solid $color-brand-opaque-40;
  border-radius: 25px;

  @include break-max($breakpoint-md) {
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 30px;

  }

  @include break-max($breakpoint-xs) {
    padding-top: 60px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
}

.booking__client-img {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 240px;
  background-color: $color-white;

  @include break-max($breakpoint-md) {
    width: 200px;
  }

  @include break-max($breakpoint-sm) {
    width: 140px;
  }

  @include break-max($breakpoint-xs) {
    width: 160px;
  }

  img {
    @include break-max($breakpoint-md) {
      max-height: 130px;
    }

    @include break-max($breakpoint-sm) {
      max-height: 85px;
    }
  }
}

.booking__client-title {
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 10px;
  }
}

.booking__last-step {
  margin-top: 40px;
}

.booking__cost {
  display: flex;
  align-items: center;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: flex-start;
  }

  .sor__h3 {
    margin-bottom: 0;
  }
}

.booking__badge {
  display: flex;
  align-items: center;
  border-radius: 25px;
  background-color: $color-red;
  color: $color-white;
  padding: 25px;
  margin-left: 25px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  @include break-max($breakpoint-sm) {
    padding-top: 19px;
    padding-bottom: 19px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }

}

.booking__badge-lg {
  font-weight: 900;
  font-size: 35px;
  line-height: 40px;
  margin-right: 12px;
  @include break-max($breakpoint-sm) {
    font-size: 25px;
    line-height: 25px;
  }
}

.booking__cost-title {
  position: relative;
  height: 90px;
  display: flex;
  align-items: center;

  @include break-max($breakpoint-sm) {
    height: 78px;
  }

  @include break-max($breakpoint-xs) {
    height: auto;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  }


  .sor__step {
    position: absolute;
    top: 0;
    @include break-max($breakpoint-xs) {
      position: static;
      margin-bottom: 5px;
    }
  }
}

.booking__banner-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 204px;
  background: url('../../assets/images/booking/banner-1.png') no-repeat center right;
  @include break-max($breakpoint-sm) {
    padding: 0 55px;
    height: 160px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    height: 260px;
    background-position: bottom center;
    background-image: url('../../assets/images/booking/banner-1_mob.png');
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }
}

.booking__banner {
  color: $color-white;
  background-color: $color-brand-2;
  &_second {
    color: $color-brand-1;
    background-color: $color-brand-opaque-8;

    .booking__banner-inner {
      background: url('../../assets/images/booking/banner-2.png') no-repeat center right;

      @include break-max($breakpoint-xs) {
        background-image: url('../../assets/images/booking/banner-2_mob.png');
        background-position: bottom center;
      }
    }
  }
  

}

.booking__banner-title {
  width: 760px;
  max-width: 100%;
  @include break-max($breakpoint-md) {
    width: 630px;
  }

  @include break-max($breakpoint-sm) {
    width: 295px;
  }
}