@import '../../styles/variables';
@import '../../styles/mixins';

.compare {
  margin-bottom: 50px;

  [data-rbd-drag-handle-context-id="0"] {
    @media screen and (min-width: 768px) {
      cursor: move;
      .compare-slider__item-plan .compare-slider__plan-image img {
        cursor: move;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 2;
    //top: 50%;
    transform: translateY(-50%);
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 50%;
    cursor: pointer;
    background: $color-white;
    transition: all 0.3s;

    margin-top: 50px;

    &:hover:not(.swiper-button-disabled) {
      background: $gray-light;
    }
    &:active:not(.swiper-button-disabled) {
      background: $gray-light;
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 1px solid $color-brand-2;
      border-right: 1px solid $color-brand-2;
      transform: rotate(-135deg);
      margin-left: 2px;
    }

    svg {
      width: 12px;
      height: 10px;
    }

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .swiper-button-next {
    left: auto;
    right: 0px;
    &::before {
      transform: rotate(45deg);
      margin-left: 0;
      margin-right: 2px;
    }
  }

  .swiper-button-disabled {
    opacity: 0.3;
    cursor: default;
  }



  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &-dropdowns {
      display: flex;
      gap: 20px;
      height: 50px;

      .custom-select__selected {
        height: 100%;
        min-width: 190px;
      }
    }

    &-buttons {
      display: flex;
      gap: 25px;
      .link {
        color: $color-brand-2;
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
        svg path {
          transition-duration: .3s;
        }
        &:hover {
          color: #cf2d23;
          svg path {
            stroke: #cf2d23;
          }
        }
      }
    }
  }
  .switch {
    margin-top: 20px;
    display: inline-flex;
  }
}

.swiper-overbtn .swiper-button-prev {
  left: 20px;
}
.swiper-overbtn .swiper-button-next {
  right: 20px;
}


.compare-slider {
  margin-top: 25px;


  &__item-plan {
    padding: 16px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    height: 205px;

    .compare-slider__plan-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-zoom {
        bottom: auto;
        right: auto;
        opacity: 0;
      }

      img {
        max-height: 173px;
        cursor: pointer;
      }
      .compare-table__zoom-btn {
        bottom: auto;
        right: auto;
        opacity: 0;
      }
      &:hover {
        .swiper-zoom {
          opacity: 1;
        }
      }
    }


    .compare-table__zoom-items {
      //opacity: 0;
      opacity: 1;
    }
    &:hover {
      .compare-table__zoom-btn {
        opacity: 1 !important;
      }
      .compare-table__zoom-items {
        opacity: 1;
      }
    }

  }

  .compare-slider__plan-zoom {
    position: absolute;
    opacity: 0;
    transition-duration: .3s;
  }

  &__item-actions {
    position: absolute;
    right: 0;
    top: 0;

    li {
      cursor: pointer;
      margin-bottom: 8px;

      svg path {
        transition-duration: .3s;
      }
      &:hover {
        svg path {
          stroke: #FF2D23;
        }
      }
    }
  }

  &__item-status {
    padding: 4px 10px;
    border-radius: 20px;
    display: inline-flex;
    margin-bottom: 10px;
    margin-top: 4px;
    margin-left: 10px;
  }
  .compare-slider__item-status_green {
    background-color: $deal-completed-bg-status;
    color: $green;
  }
  &__item-title {
    height: 38px;
    overflow: hidden;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &-item {
      padding: 12px;

      &:nth-child(even) {
        background-color: $gray-light;
      }
    }
    &-label {
      color: $gray-dark-2;
      margin-bottom: 4px;
      font-size: 13px;
    }
    &-value {
      @include h6-L();
    }
  }

  &__plan-title {
    //margin-top: 35px;
    //margin-bottom: 30px;
  }

  &__floor-plan {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 205px;
    padding: 16px 32px;

    .swiper-zoom {
      bottom: auto;
      right: auto;
      opacity: 0;
    }

    img {
      max-height: 173px;
      cursor: pointer;
    }

    &:hover {
      .swiper-zoom {
        opacity: 1;
      }
    }
  }

  &__object-photo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;

    .swiper-zoom {
      bottom: auto;
      right: auto;
      opacity: 0;
    }

    img {
      height: 190px;
      cursor: pointer;
      border-radius: 5px;
      object-fit: cover;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .compare-slider__object-description {
      text-align: left;
      width: 100%;
    }

    .compare-slider__object-description_address {
      color: #64687B;
    }



    .compare-slider__object-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      &:hover {
        .swiper-zoom {
          opacity: 1;
        }
      }
    }

    .sf-card__metro-list {
      width: 100%;
      margin-top: 10px;
    }
  }

}



.compare-view-settings {
  margin-top: 40px;

  li {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &__items-list {
    background-color: $gray-light;
    border-radius: 20px;
    padding: 27px 30px;
    // margin-top: 20px;

  }

  .switch {
    width: 100%;
    justify-content: space-between;
  }
}

.compare-sent-settings {
  margin-top: 30px;

  .h3 {
    margin-bottom: 20px;
  }
}



//
//.swiper-item {
//  width: 100%;
//  height: 100%;
//  background-color: #ccc;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  font-size: 20px;
//}
//
//.dragged {
//  background-color: #f0f0f0;
//  opacity: 0.7;
//}
//
//
//.mySwiper .swiper-wrapper {
//  height: 200px;
//}


.compare-table {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    display: none;
  }

  .ant-table-tbody > tr:hover {
    //background-color: transparent!important;
  }

  //.ant-table-tbody > tr:nth-child(odd) {
  //  background-color: #f6f7f8;
  //}
  .ant-table-tbody > tr:nth-child(odd):hover {

  }



  .ant-table-tbody > tr > td.compare-table__top-cell {
    vertical-align: top;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    .label-item {
      margin-top: 5px;
      margin-bottom: 10px;
    }


  }

  .compare-table__note {
    color: #777A89;
    margin-bottom: 40px;
  }
  .compare-table__note-text {
    border-radius: 5px;
    background-color: #F6F7F8;
    color: black;
    padding: 15px 10px;
    min-height: 87px;
  }

  .ant-table-tbody > tr > td.compare-table__white-color {
    background-color: white;
  }

  .ant-table-tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__photo-building {
    height: 212px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  &__plan {
    display: flex;
    justify-content: center;
    position: relative;
    height: 205px;
    margin-top: 20px;

    img {
      object-fit: contain;
      height: 100%;
    }


  }

  &__plan-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .compare-table__zoom-btn {
      bottom: auto;
      right: auto;
      opacity: 0;
    }

    &:hover {
      .compare-table__zoom-btn {
        opacity: 1 !important;
      }
      .compare-table__zoom-items {
        opacity: 1;
      }
    }

  }

  &__zoom-btn {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DFE1E9;
    background-color: #fff;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    cursor: pointer;
    transition: all 0.3s;


  }

  &__list-label {
    color: #777A89;
    margin-bottom: 4px;
    font-size: 13px;
  }

  &__list-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  &__subway {
    color: #8F95AC;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    span {
      background-color: #F6F7F8;
      border-radius: 20px;
      padding: 2px 6px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-size: 13px;

      svg {
        margin-right: 5px;
        flex-shrink: 0;
      }
    }
  }

  &__zoom-items {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    right: 0;
    opacity: 0;
    transition-duration: .3s;

    span {
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
  }

  &__drag {

  }

  &__header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__header-actions {
    span {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }

}


.compare-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #f6f7f8;
}
.ant-table-tbody > tr.compare-table__top-row:hover {
  background-color: white;
}
.ant-table-tbody > tr.compare-table__subway-row:hover {
  background-color: white;
}
.ant-table-tbody > tr.compare-table__note-row:hover {
  background-color: white;
}
.ant-table-tbody > tr.compare-table__floor:hover {
  background-color: white;
}

.compare-table .ant-table-tbody > tr:nth-child(odd):not(.compare-table__subway-row):hover {
  background-color: white;
}


.compare-table .ant-table table {
  min-width: auto!important;
}

.ant-table-tbody > tr.compare-table__subway-row td {
  vertical-align: top;
}

.drop-content {
  display: flex;
  overflow-x: scroll;
  flex: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none;
    }
}


.compare-table__note.compare-table__note_basic {
  width: 100%;
  margin-top: 30px;
}
.compare-table__note {
  color: #777A89;
  margin-bottom: 40px;
}
.compare-table__note-text {
  border-radius: 5px;
  background-color: #F6F7F8;
  color: black;
  padding: 15px 10px;
  min-height: 87px;
}
.compare-table__note.compare-table__note_basic .compare-table__note-text {
  height: 100%;
}

.compare-table__photo-building {
  width: calc(100% - 20px);
}

.compare .drop-content {
  overflow: hidden;
  @media screen and (max-width: 767px) {
    overflow: auto;
  }
}


.compare-table__drag:hover {
  svg {
    path {
      stroke: #EF372E;
    }
  }
}
.compare-table__drag.active {
  svg {
    path {
      stroke: #EF372E;
      fill: #EF372E;
    }
  }
}

.compare-public-footer {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #d2d4de;

  .compare-slider {
    margin-top: 5px;
  }

  .compare-slider .compare-slider__list {
    flex-direction: row;
  }
  .compare-slider__list-item:nth-child(even) {
    background-color: white;
  }
}

.crm-table__sf-controlsmodal.crm-table__sf-controlsmodal_compare .modal__inner {
  width: auto;
}


.compare-slider__list-item.compare-slider__list-item_stat {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
