.calculator-section {
  background: $color-brand-opaque-8;
  .calculators {
    margin-bottom: 0;
  }
  .button_view_default {
    @extend .constructor__btn;
    margin-top: 0;
    // padding: 27px 47px;
    // @include break-max($breakpoint-md) {
    //   padding: 22px 47px;
    // }
  }

  .section_view_white {
    background: none;
  }
  .calculator__item-wrapper {

  }

  .constructor__box {
    @include break-max($breakpoint-xsm) {
      width: 100%;
    }
  }

  .calculator__result {
    @include break-max($breakpoint-xs) {
      flex-direction: column;
      width: 100%;
    }
  }

  .calculator__result-btn {
    margin-top: 41px;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  .calculator__result-value {
    @include break-max($breakpoint-xs) {
      margin-bottom: 10px;
    }
  }

  .calculators__container {
    @include break-max($breakpoint-xs) {
      background: none;
      padding: 0px 10px;
      margin: 0 -10px;
    }

  }
}
