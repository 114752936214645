@import "../../../styles/variables";
@import "../../../styles/mixins";

.header.header_fixed .sub {
  opacity: 0;
}

.sub {
  display: none;
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  padding: 28px 30px;
  box-shadow: 0px 4px 25px rgba(44, 46, 62, 0.1);
  border-radius: 20px;
  background-color: $color-white;
  color: $color-brand-2;
  font-size: 14px;
  line-height: 18px;
  z-index: 2;

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {
    display: none;
  }

  
  &.show {
    display: block;
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }
}

.sub__grid {
  display: grid;
  grid-template-columns: repeat(4, 140px);
  gap: 30px 50px;
}

.sub__item {

  
}

.sub__link {
  cursor: pointer;
  color: inherit;
  font: inherit;
  background: none;
  text-align: left;
  padding: 0;
}

.sub__title {
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $color-red;
    margin-right: 19px;
  }
}

.sub__text {
  display: block;
  color: $color-brand-3;
}

.sub__link:hover .sub__title,
.sub__link:focus .sub__title {
  color: $color-red;
}

.sub__link:active .sub__title {
  color: $color-red-active;
}