@import "../../styles/variables";
@import "../../styles/mixins";

.wrapper__center_start {
  margin: 0 auto;
  width: 1220px;
  max-width: 100%;


  @include break-max($breakpoint-sm) {
    width: 730px;
  }

  @include break-max($breakpoint-xs) {
    width: 390px;
    padding: 0 10px;
  }
}

.start {

  .document__col_info {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  .pill {
    margin-right: 0;
    cursor: text;
    &:hover {
      background: $color-brand-3;
    }
  }

  .promo {
    padding-bottom: 110px;
    @include break-max($breakpoint-md) {
      padding-bottom: 95px;
    }
    @include break-max($breakpoint-sm) {
      padding-bottom: 70px;
    }
    @include break-max($breakpoint-xs) {
      padding-bottom: 45px;
    }
  }

  .promo__image {
    @include break-max($breakpoint-md) {
      max-width: 700px;
    }
    @include break-max($breakpoint-sm) {
      max-width: 480px;
    }
    @include break-max($breakpoint-xs) {
      order: -1;
      max-width: 320px;
      margin: 0 auto;
    }
  }

  .promo__title {
    margin-top: 75px;
    margin-bottom: 15px;
    text-align: center;

    @include break-max($breakpoint-md) {
      margin-top: 48px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 38px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 28px;
    }
  }

  .promo__container {
    display: flex;
    justify-content: space-between;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .promo__box {
    width: 390px;
    margin-top: 130px;
    padding-top: 65px;
    background: url('../../assets/images/start/cloud.svg') no-repeat top left;

    @include break-max($breakpoint-md) {
      width: 380px;
    }

    @include break-max($breakpoint-sm) {
      width: 230px;
    }
    @include break-max($breakpoint-xs) {
      width: 100%;
      margin-top: 20px;
      padding-top: 0;
      text-align: center;
      background: none;
    }
  }

  .promo__box-title {
    margin-bottom: 25px;
  }


  .benefits {
    display: flex;
    align-items: flex-start;

    margin-bottom: 170px;
    &:last-child {
      margin-bottom: 0;
    }

    @include break-max($breakpoint-md) {
      margin-bottom: 80px;
    }

    @include break-max($breakpoint-sm) {
      flex-direction: column;
      margin-bottom: 50px;

      &_02 .h3 {
        white-space: nowrap;
      }
    }

    .sticky {
      position: fixed;
      top: 113px;
    }
  }

  .benefits__header {
    position: sticky;
    top: 140px;

    width: 270px;
    max-width: 100%;
    margin-right: auto;

    @include break-max($breakpoint-sm) {
      position: static;
      top: 0;

      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
      padding-right: 30px;
    }

    @include break-max($breakpoint-xs) {
      position: relative;
      flex-direction: column;
      padding-left: 75px;
    }

    .h3 {
      @include break-max($breakpoint-sm) {
        margin-right: 30px;
      }
      @include break-max($breakpoint-xs) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .benefits__counter {
    display: flex;
    padding: 20px 0 30px;
    font-size: 48px;
    font-weight: 900;
    line-height: 55px;

    @include break-max($breakpoint-md) {
      font-size: 40px;
    }

    @include break-max($breakpoint-sm) {
      margin-right: 30px;
      font-size: 35px;
      order: -1;
    }
    @include break-max($breakpoint-xs) {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      padding: 0;
      font-size: 25px;
      line-height: 1;
    }

  }

  .benefits__info {
    color: $color-brand-3;
    line-height: 21px;
  }

  .benefits__list {
    width: 850px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    @include break-max($breakpoint-md) {
      width: 825px;
    }

    @include break-max($breakpoint-sm) {

    }

  }

  .benefits__item {
    position: relative;

    width: 400px;
    max-width: 100%;

    padding: 40px;
    padding-right: 45px;

    box-shadow: 0px 4px 60px rgba(143, 149, 172, 0.1);
    border-radius: 4px;

    &:nth-child(even) {
      margin-left: auto;
    }

    &:nth-child(n+3) {
      margin-top: 45px;
    }


    @include break-max($breakpoint-md) {
      width: 385px;
    }

    @include break-max($breakpoint-sm) {
      width: 340px;


      &:nth-child(n+3) {
        margin-top: 45px;
      }

    }
    @include break-max($breakpoint-xs) {
      &:nth-child(even) {
        margin-left: 0;
      }
    }
  }

  .benefits__item img {
    position: absolute;
    top: -15px;
    right: 40px;
    @include break-max($breakpoint-md) {
      width: 92px;
    }
    @include break-max($breakpoint-sm) {
      width: 82px;
    }
    @include break-max($breakpoint-xs) {
      width: 65px;
    }
  }

  .benefits__item p {
    line-height: 21px;
  }

  .benefits__title {
    margin-bottom: 28px;
  }


  $subHeight: 410px;
  $subItemHeight: 189px;

  $subHeight_md: 390px;
  $subItemHeight_md: 180px;

  $subHeight_sm: 516px;
  $subItemHeight_sm: 250px;

  .sub {
    padding-bottom: 100px;

    @include break-max($breakpoint-md) {
      padding-bottom: 90px;
    }
    @include break-max($breakpoint-sm) {
      padding-bottom: 85px;
    }
    @include break-max($breakpoint-xs) {
      padding-bottom: 65px;
    }
  }

  .sub__title {
    margin: 0 auto 75px;
    width: 920px;
    max-width: 100%;
    text-align: center;

    @include break-max($breakpoint-md) {
      margin-bottom: 45px;
    }
  }

  .sub__list {
    position: relative;
    display: flex;
    height: $subHeight;

    @include break-max($breakpoint-md) {
      height: $subHeight_md;
    }

    @include break-max($breakpoint-sm) {
      height: $subHeight_sm;
    }

    @include break-max($breakpoint-xs) {
      height: auto;
      flex-direction: column;
      align-items: center;
    }
  }

  .sub__hidden {
    opacity: 0;
    transition: opacity 1000ms;
    margin-top: 25px;
    @include break-max($breakpoint-sm) {
      opacity: 1;
      margin-top: 0;
      .sub__text {
        display: none;
      }
      .sub__btn {
        padding: 16px 23px;
        margin-top: 20px;
      }
    }
  }

  .sub__item {
    position: absolute;
    z-index: 2;
    overflow: hidden;

    display: flex;
    align-items: flex-start;
    width: 595px;
    max-width: 100%;
    padding: 45px;

    border-radius: 5px;
    background: #0088CC;
    color: $color-white;

    &_insta {
      background: linear-gradient(79.6deg, #FFD569 -13.95%, #F35633 30.07%, #C62992 69.49%, #585AD1 112.19%);
    }

    &_yt {
      background: #C4302B;
    }

    height: $subItemHeight;
    transition: height 1s;
    will-change: height;

    @include break-min($break-md) {
      &:hover {
        height: $subHeight_md;

        .sub__hidden {
          margin-top: 25px;
          position: static;
          opacity: 1;
          transition: opacity 2000ms;
        }
      }
    }

    @include break-min($break-lg) {
      &:hover {
        height: $subHeight;
      }
    }


    &_z-index {
      z-index: 1;
    }

    img {
      flex-shrink: 0;
    }

    @include break-max($breakpoint-md) {
      width: 570px;
      height: $subItemHeight_md;
    }

    @include break-max($breakpoint-sm) {
      width: 356px;
      height: $subItemHeight_sm;
      padding: 30px 38px 30px 25px;
    }

    @include break-max($breakpoint-xs) {
      position: static;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: auto;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

  .sub__item:nth-child(1) {

  }

  .sub__item:nth-child(2) {
    right: 0;
  }

  .sub__item:nth-child(3) {
    bottom: 0;

  }

  .sub__item:nth-child(4) {
    bottom: 0;
    right: 0;
  }

  .sub__item img {
    @include break-max($breakpoint-sm) {
      width: 50px;
      margin-top: 8px;
    }

    @include break-max($breakpoint-xs) {
      margin-top: 0;
    }
  }

  .sub__item_insta img {
    @include break-max($breakpoint-sm) {
      width: 39px;
    }
  }

  .sub__btn {
    display: inline-flex;
    margin-top: 25px;
    padding: 21px 37px;

    background: transparent;
    border: 1px solid $color-white;
    border-radius: 5px;

    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    transition: all 0.2s;
    &:hover {
      background: $color-white;
      color: #0088CC;
    }
    &:active {
      opacity: 0.9;
    }
  }

  .sub__item_yt .sub__btn:hover {
    color: #C4302B;
  }

  .sub__item_insta .sub__btn:hover {
    color: #e0435a;
  }


  .sub__header {
    margin-bottom: 15px;
  }

  .sub__text {
    line-height: 21px;

    &_margin {
      margin-bottom: 15px;
      margin-top: 30px;
    }
  }

  .sub__box {
    margin-left: 38px;
    @include break-max($breakpoint-sm) {
      margin-left: 25px;
      margin-top: 15px;
    }

    @include break-max($breakpoint-xs) {
      margin-left: 0;
      text-align: center;
    }
  }

  .sub__item_socials {
    flex-direction: column;
    padding-bottom: 26px;

    transition: height 1s, padding-top 1s;
    will-change: height, padding-top;

    color: $color-brand-2;
    border: 1px solid $color-brand-opaque-20;
    background: $color-white;

    @include break-max($breakpoint-md) {
      padding-top: 30px;
    }

    @include break-max($breakpoint-sm) {
      .sub__hidden {
        margin-top: 15px;
        .sub__big-text {
          font-size: 14px;
          line-height: 18px;
        }
        .sub__ul {
          display: none;
        }
      }
    }

    @include break-min($break-md) {
      &:hover {
        padding-top: 40px;
        .sub__socials {
          bottom: 55px;
        }
      }
    }

    @include break-min($break-lg) {
      &:hover {
        padding-top: 60px;
        .sub__socials {
          bottom: 45px;
        }
      }
    }


    .h4 {
      font-size: 20px;
      line-height: 26px;
      @include break-max($breakpoint-sm) {
        font-size: 18px;
        line-height: 22px;
      }

      @include break-max($breakpoint-xs) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .sub__big-text {
    font-size: 18px;
    line-height: 26px;
    transition: margin 0.3s;
  }

  .sub__socials {
    display: flex;
    position: absolute;
    background: $color-white;
    bottom: 25px;
    transition: all 0.3s;
  }

  .sub__social {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }

  }

  .sub__social .button.button_view_round-control {
    width: 50px;
    height: 50px;
  }

  .sub__social_wa:hover .button.button_view_round-control {
    background-color: #4AC959;
  }

  .sub__social_tg:hover .button.button_view_round-control {
    background-color: #0088CC;
  }

  .sub__social_vk:hover .button.button_view_round-control {
    background-color: #4D7198;
  }

  .sub__social_fb:hover .button.button_view_round-control {
    background-color: #4267B2;
  }

  .sub__social_viber:hover .button.button_view_round-control {
    background-color: #665CAC;
  }

  .sub__social .icon {
    width: 23px;
    height: 23px;
    fill: $color-brand-3;
  }

  .sub__social:hover .icon {
    fill: $color-white;
  }

  .sub__ul {
    margin-bottom: 20px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        width: 5px;
        height: 5px;
        margin-right: 20px;
        border-radius: 50%;
        background: $color-red;
      }
    }
  }

  .news {
    padding-bottom: 100px;
    @include break-max($breakpoint-md) {
      padding-bottom: 90px;
    }
    @include break-max($breakpoint-xs) {
      padding-bottom: 65px;
    }
  }

  .news__container {
    display: flex;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .news__header {
    width: 595px;
    max-width: 100%;
    flex-shrink: 0;
    padding: 55px 75px 65px;

    background: $color-brand-2;
    color: $color-white;

    @include break-max($breakpoint-sm) {
      width: 355px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      padding: 40px 30px;
      text-align: center;
    }
  }

  .news__title {
    margin-bottom: 30px;
  }

  .news__choices {
    flex-grow: 1;
    padding: 55px 75px 65px;
    background: $color-bg-light;

    @include break-max($breakpoint-md) {
      padding-left: 50px;
    }

    @include break-max($breakpoint-xs) {
      padding: 40px 30px;
      padding-left: 25px;
    }
  }

  .news__checkbox {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .checkbox_view_start-checkbox {
    align-items: flex-start;
    .checkbox__box {
      margin-top: 4px;
      width: 18px;
      height: 18px;
    }
    .checkbox__marker {
      background: $color-red;
    }
    .checkbox__marker::after {
      top: 4px;
      left: 3px;
      width: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10'%3E%3Cpath d='M0 4.765l.855-.8 4.19 2.272L12.267 0 13 .665 5.36 10 0 4.765z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
    .checkbox__label {
      font-size: 18px;
      line-height: 26px;
      @include break-max($breakpoint-xs) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .offers {
    padding-bottom: 115px;
    @include break-max($breakpoint-md) {
      padding-bottom: 90px;
    }
    @include break-max($breakpoint-sm) {
      padding-bottom: 85px;
    }
    @include break-max($breakpoint-xs) {
      padding-bottom: 60px;
    }
  }

  .offers__list {
    @include break-max($breakpoint-xs) {
      display: flex;
      flex-direction: column;
    }
  }

  .offers__container {
    display: flex;
    justify-content: space-between;

    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .offers__header {
    width: 420px;
    max-width: 100%;
    flex-shrink: 0;
    margin-right: 75px;

    @include break-max($breakpoint-md) {
      margin-right: 130px;
    }
    @include break-max($breakpoint-sm) {
      width: 265px;
      margin-right: 50px;
    }
    @include break-max($breakpoint-xs) {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  }

  .offers__title {
    margin-bottom: 45px;

    @include break-max($breakpoint-sm) {
      margin-bottom: 30px;
    }
  }

  .offers__text {
    margin-bottom: 30px;

    @include break-max($breakpoint-sm) {
      margin-bottom: 20px;
    }
  }

  .offers__warning {
    padding: 15px 44px;
    padding-left: 72px;

    background-image: url("data:image/svg+xml,%3Csvg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='31' height='30'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0.28125H31V29.2812H0V0.28125Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.306517 25.593L13.2866 1.59483C14.241 -0.15661 16.7568 -0.15661 17.7112 1.59483L30.6962 25.593C31.5967 27.2615 30.3878 29.2812 28.4839 29.2812H2.51391C0.609973 29.2812 -0.598956 27.2615 0.306517 25.593Z' fill='%238790E5'/%3E%3C/g%3E%3Cpath d='M14.428 12.2852H16.402V16.3452L16.038 19.2712H14.792L14.428 16.3452V12.2852ZM16.486 22.2812H14.344V20.1532H16.486V22.2812Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 20px center;

    border: 1px solid rgba(143, 149, 172, 0.2);
    border-radius: 4px;

    font-weight: 700;
    line-height: 21px;

    @include break-max($breakpoint-sm) {
      background-position-y: 20px;
    }
  }

  .offers {

    .checkbox_view_start-checkbox {
      align-items: center;
      margin-bottom: 35px;

      @include break-max($breakpoint-sm) {
        align-items: flex-start;
      }

      .checkbox__label {
        font-size: 14px;
        line-height: 17px;
      }

      .checkbox__box {
        margin-top: 0;
      }
    }

    .reglament__list {
      margin-bottom: 20px;
    }

    .button_view_default {

      @include break-max($breakpoint-xs) {
        margin: 0 auto;
      }
    }

  }

  .scroll-panel {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 87%;
    height: 60px;

    background: $color-brand-2;
    border-radius: 5px 5px 0px 0px;

    color: $color-white;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    z-index: 2;

    opacity: 1;
    transition: opacity 0.2s;


    @include break-max($breakpoint-sm) {
      font-size: 14px;
      line-height: 18px;
    }

    @include break-max($breakpoint-xs) {
      height: auto;
      transform: none;
      left: 0;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }

    .button_view_default {
      padding: 17px 28px;
      @include break-max($breakpoint-sm) {
        margin-left: 30px;
      }

      @include break-max($breakpoint-xs) {
        margin-left: 0;
        padding: 15px 30px;
      }
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  .scroll-panel__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 0 65px;

    @include break-max($breakpoint-sm) {
      padding: 0 10px;
    }

    @include break-max($breakpoint-xs) {
      flex-direction: column;
      padding: 15px;
      .h5 {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 15px;
      }
    }

  }

}
