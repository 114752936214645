@import "../../styles/variables";
@import "../../styles/mixins";

.tgBot {
  .h1 {
    padding-top: 0;
    padding-bottom: 35px;
  }

  .h2 {
    padding-bottom: 25px;
  }

  .h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  .tgBtn {
    display: inline-flex;
    margin-top: 35px;
    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }
  }

  .promo__btn {
    position: relative;
    z-index: 2;

    @include break-max($breakpoint-xs) {
      margin-top: 285px;
    }
  }

  .tgBot__title {
    @include break-max($breakpoint-xs) {
      text-align: center;
    }
  }


  .promo {
    position: relative;
    background-repeat: no-repeat;
    background-image:
      url('../../assets/images/tgBot/promo-bg.svg');
    background-size: cover;
    background-position: center bottom;
    color: $color-white;

    @include break-max($breakpoint-xs) {
      background-image:
        url('../../assets/images/tgBot/promo-bg-mob.svg');
    }

    .highlighted {
      text-transform: uppercase;
      margin-left: 5px;
    }

    .container {
      display: flex;

      min-height: 968px;
      @include break-max($breakpoint-md) {
        min-height: 774px;
      }
      @include break-max($breakpoint-sm) {
        min-height: 623px;
      }
      @include break-max($breakpoint-xs) {
        flex-direction: column;
        align-items: center;
        min-height: 560px;
      }
    }

    .link {
      border-bottom: 1px solid $color-red;
    }

    img {
      position: relative;
      top: -55px;
      left: 35px;
      @include break-max($breakpoint-md) {
        width: 50%;
        top: -40px;
      }

      @include break-max($breakpoint-sm) {
        position: absolute;
        top: 67px;
        bottom: 0;
        left: auto;
        right: 20px;
        width: 460px;
      }

      @include break-max($breakpoint-xs) {
        width: 375px;
        top: auto;
        bottom: 0;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
    }

  }

  .promo__block {
    padding-top: 180px;
    @include break-max($breakpoint-sm) {
      padding-top: 80px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 25px;
    }
  }

  .promo__text {
    width: 365px;
    max-width: 100%;
    font-weight: 300;

    @include break-max($breakpoint-sm) {
      width: 295px;
    }
    @include break-max($breakpoint-xs) {
      width: 320px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .block {
    width: 530px;
    max-width: 100%;

    @include break-max($breakpoint-sm) {
      width: 360px;
    }
    @include break-max($breakpoint-xs) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .about {
    position: relative;
    min-height: 590px;
    margin-top: -240px;
    padding-top: 220px;
    background: url('../../assets/images/tgBot/about.svg') no-repeat;
    background-position: top 0px right 15%;

    @include break-max($breakpoint-md) {
      margin-top: -60px;
      background-position: top 0px right 60px;
      background-size: 700px;
    }
    @include break-max($breakpoint-sm) {
      min-height: 430px;
      padding-top: 40px;
      background-size: 465px;
      background-position: top 0px right 10px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 70px;
      background-size: 320px;
      background-position: bottom 110px center;
    }
  }

  .about__text {
    max-width: 100%;
    font-weight: 300;
    @include break-max($breakpoint-md) {
      width: 365px;
    }
    @include break-max($breakpoint-sm) {
      width: 295px;
    }
    @include break-max($breakpoint-xs) {
      margin: 0 auto;
      margin-bottom: 260px;
    }
  }

  .else {
    padding-top: 155px;
    background: url('../../assets/images/tgBot/else.svg') no-repeat;
    background-size: cover;

    @include break-max($breakpoint-sm) {
      padding-top: 25px;
    }

    @include break-max($breakpoint-xs) {
      padding-top: 25px;
    }

    .tgBot__title {
      padding-bottom: 90px;
      @include break-max($breakpoint-xs) {
        padding-bottom: 40px;
      }
    }
  }

  .features__text {
    width: 540px;
    max-width: 100%;
    margin-bottom: 10px;
    @include break-max($breakpoint-xs) {

      margin-left: auto;
      margin-right: auto;
    }
  }

  .tgBtn_transparent {
    background: transparent;
    border: 1px solid $color-white;
    &:hover {
      border-color: transparent;
      background-color: $color-white;
      color: $color-brand-1;
    }
  }

  .features {
    position: relative;
    min-height: 528px;
    margin-bottom: 65px;
    padding: 80px 125px 85px;

    background: $color-brand-1;
    color: $color-white;
    box-shadow: 0px 4px 70px rgba(143, 149, 172, 0.1);
    border-radius: 20px;

    @include break-max($breakpoint-sm) {
      padding: 60px;
      min-height: 450px;
    }

    @include break-max($breakpoint-xs) {
      padding: 40px 25px;
      text-align: center;
    }

    img {
      position: absolute;
      @include break-max($breakpoint-xs) {
        position: static;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &:first-of-type {
      background: #3B4391;
      @include break-max($breakpoint-xs) {
        padding-bottom: 0;
      }

      .features__title {
        @include break-max($breakpoint-md) {
          width: 260px;
        }
        @include break-max($breakpoint-xs) {
          margin: 0 auto;
        }
      }

      .features__text {
        width: 555px;
        @include break-max($breakpoint-md) {
          width: 425px;
        }
        @include break-max($breakpoint-sm) {
          width: 330px;
        }
      }

      img:first-of-type {
        right: 80px;
        top: 125px;
        @include break-max($breakpoint-sm) {
          width: 295px;
          right: 15px;
          top: 65px;
        }

      }

      img:last-of-type {
        bottom: 0;
        right: 95px;
        @include break-max($breakpoint-sm) {
          width: 273px;
          right: 45px;
        }

      }
    }

    &:nth-of-type(2) {
      background: $color-brand-2;

      .features__text {
        width: 470px;
        @include break-max($breakpoint-md) {
          width: 385px;
        }
        @include break-max($breakpoint-sm) {
          width: 317px;
        }
      }

      img {
        top: 40px;
        right: 135px;
        @include break-max($breakpoint-sm) {
          width: 187px;
          right: 85px;
        }
        @include break-max($breakpoint-xs) {
          margin-top: 25px;
        }
      }
    }

    &:nth-of-type(3) {
      background: #573B91;

      .features__title {
        width: 518px;
        max-width: 100%;
        @include break-max($breakpoint-sm) {
          width: 400px;
        }
        @include break-max($breakpoint-xsm) {
          width: 300px;
        }
      }

      .features__text {
        width: 455px;
        @include break-max($breakpoint-md) {
          width: 375px;
        }
        @include break-max($breakpoint-sm) {
          width: 265px;
        }
      }

      img {
        margin-top: 0;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        @include break-max($breakpoint-md) {
          width: 445px;
          right: 30px;
        }

        @include break-max($breakpoint-sm) {
          width: 357px;
        }
        @include break-max($breakpoint-xs) {
          transform: none;
          margin-top: 25px;
        }
      }
    }

    &:nth-of-type(4) {
      background: #376B88;
      margin-bottom: 0;

      .features__text {
        width: 540px;
        @include break-max($breakpoint-sm) {
          width: 330px;
        }
      }

      img {
        top: 40px;
        right: 135px;
        @include break-max($breakpoint-sm) {
          width: 187px;
          right: 85px;
        }
        @include break-max($breakpoint-xs) {
          margin-top: 25px;
        }
      }
    }

  }


  .features__counter {
    display: flex;
    align-items: center;
    padding-bottom: 45px;
    &::after {
      content: '';
      width: 52px;
      height: 1px;
      margin-left: 25px;
      background: $color-white;
    }

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .features__title {
    padding-bottom: 0;
  }

  .connect {
    padding-top: 505px;
    margin-top: -315px;
    background: url('../../assets/images/tgBot/connect.svg') no-repeat;
    background-size: cover;

    color: $color-white;
    @include break-max($breakpoint-sm) {
      padding-top: 405px;
    }
  }

  .connect__title {
    padding-bottom: 55px;
  }

  .connect__item_last {
    padding-top: 90px;
    padding-bottom: 80px;
    background: url('../../assets/images/tgBot/connect-bg.svg') no-repeat;
    background-position: bottom 47px right 53%;

    @include break-max($breakpoint-md) {
      background: $color-brand-2;
    }

    @include break-max($breakpoint-sm) {
      padding-top: 60px;
    }

    .h3 {
      padding-bottom: 15px;
    }
  }

  .connect__subtitle {
    margin-bottom: 50px;
  }


  .option__counter {
    display: inline-flex;
    align-items: center;
    margin-bottom: 45px;
  }

  .option__counter-text {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3;
    text-transform: uppercase;
    margin-left: 25px;
  }

  .option__num {
    font-size: 100px;
    line-height: 100px;
    font-weight: 900;
    color: $color-brand-2;
    text-shadow: 0px 0px 3px $color-red;
  }

  .steps {
    display: flex;
    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
    &_column {
      flex-direction: column;

      .steps__item {
        width: 100%;
        &:first-of-type {
          padding-bottom: 50px;
        }
      }
    }
  }

  .steps__row {
    display: flex;
    align-items: flex-start;
    &_align_center {
      align-items: center;
    }

    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
  }

  .steps__div {
    margin-right: auto;
  }

  .steps__btn {
    margin-left: 110px;
    @include break-max($breakpoint-sm) {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
  }

  .steps + .steps {
    margin-top: 100px;
    @include break-max($breakpoint-sm) {
      margin-top: 40px;
    }
  }

  .steps__item {
    display: flex;
    flex-direction: column;
    width: 550px;
    max-width: 100%;


    &:first-of-type {
      margin-right: auto;
    }

    img {
      margin-top: auto;
    }

    @include break-max($breakpoint-md) {
      width: 47%;
    }

    @include break-max($breakpoint-sm) {
      width: 100%;
      margin: auto;
    }
  }

  .steps__item_arrow-bg {
    background: url('../../assets/images/tgBot/arrow.svg') no-repeat;
    background-position: 200px -86px;
    @include break-max($breakpoint-md) {
      background-position: 200px -96px;
    }
    @include break-max($breakpoint-sm) {
      background: none;
    }
  }

  .steps__text {
    width: 560px;
    max-width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;
    font-weight: 300;
    &_width {
      width: 100%;
      @include break-max($breakpoint-sm) {
        width: 90%;
      }
    }
  }

  .steps__header {
      &_bg-arrow {
        background: url('../../assets/images/tgBot/arrow-right.svg') no-repeat right;
        @include break-max($breakpoint-md) {
          background-position: 10px;
          background-size: 80%;
        }
        @include break-max($breakpoint-sm) {
          background: none;
        }
      }
  }

  .steps__step {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-red;
    background: $color-brand-2;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -65px;
      width: 70px;
      height: 70px;
      background: $color-brand-2;
    }
    &::after {
      content: '';
      width: 73px;
      height: 1px;
      background: $color-red;
      margin-left: 20px;
    }


  }

  .option {


    &:first-of-type {
      background: url('../../assets/images/tgBot/option-bg.svg') no-repeat;
      background-position: bottom 70px right 53%;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding-bottom: 90px;
      border-bottom: 1px solid $color-brand-opaque-20;
    }
  }

  .option__second {
    display: flex;
    justify-content: space-between;
    @include break-max($breakpoint-xs) {
      flex-direction: column;
    }
  }

  .option__second-img {
    padding-top: 135px;
    @include break-max($breakpoint-sm) {
      width: 350px;
      padding-top: 60px;
    }
  }

  .option__second-left {
    width: 555px;
    max-width: 100%;
    padding-top: 110px;
    @include break-max($breakpoint-sm) {
      width: 370px;
      padding-top: 60px;
    }
  }

  .option__app {
    width: 495px;
    max-width: 100%;

    p + p {
      padding-top: 30px;
    }


    .option__no-app {
      padding-top: 40px;
      padding-bottom: 25px;
      color: $color-brand-3;
      font-style: italic;
    }


  }

  .option__download {
    display: flex;
    @include break-max($breakpoint-sm) {
      flex-wrap: wrap;
    }
    li {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
        @include break-max($breakpoint-sm) {
          width: 171px;
          a {
            width: 100%;
          }
        }
        @include break-max($breakpoint-xs) {
          width: 45%;
        }
      }
      &:nth-child(2) {
        @include break-max($breakpoint-sm) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      @include break-max($breakpoint-xs) {
        width: 45%;
      }
    }
    a {
      display: inline-flex;
      height: 55px;
      padding: 8px 12px;
      border: 1px solid $color-brand-3;
      border-radius: 8px;
      background: $color-brand-2;
      transition: all 0.3s;
      &:hover {
        background: $color-brand-1;
        border-color: $color-brand-1;
      }
    }
  }

  .connect__btn {
    display: inline-flex;
    margin: 30px auto 0;
  }

  .banner {
    padding: 72px 0;
    background: #573B91 url('../../assets/images/tgBot/banner.svg') no-repeat;
    background-size: cover;
    background-position: center;
    color: $color-white;
    @include break-max($breakpoint-xs) {
      background-image: url('../../assets/images/tgBot/banner-mob.svg');
      background-position: bottom;
      text-align: center;
      padding-bottom: 170px;
    }
  }

  .banner__btn {
    margin-top: 10px;
  }

}
