@import '../../../styles/variables';
@import '../../../styles/mixins';

.swiper-pan {
  border-radius: 20px;

  @include break-max($breakpoint-xs) {
    border-radius: 0;
  }

  img:not(.pswp__img) {
    width: 100%;
    max-height: 490px;
    object-fit: cover;
    @include break-max($breakpoint-sm) {
      max-height: 480px;
    }
    @include break-max($breakpoint-xs) {
      max-height: 320px;
      height: 320px;
      border-radius: 0;
    }
  }

  .swiper-container:not(.swiper-thumbs) {
    border-radius: 20px;
    overflow: hidden;
    @include break-max($breakpoint-xs) {
      border-radius: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    margin: 0 -10px;
  }

  .swiper-pagination {
    position: absolute;
    display: flex;
    column-gap: 12px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    z-index: 2;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 50%;
    cursor: pointer;
    background: $color-white;
    transition: all 0.3s;
    &:hover:not(.swiper-button-disabled) {
      background: $gray-light;
    }
    &:active:not(.swiper-button-disabled) {
      background: $gray-light;
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 1px solid $color-brand-2;
      border-right: 1px solid $color-brand-2;
      transform: rotate(-135deg);
      margin-left: 2px;
    }

    svg {
      width: 12px;
      height: 10px;
    }

    @include break-max($breakpoint-xs) {
      display: none;
    }
  }

  .swiper-button-next {
    left: auto;
    right: 20px;
    &::before {
      transform: rotate(45deg);
      margin-left: 0;
      margin-right: 2px;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-flex;
    background-color: $color-brand-opaque-30;
    border-radius: 50%;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    background-color: $color-brand-3;
  }
}

.swiper-thumbs-block {
  margin-top: 15px;
  display: flex;

  @include break-max($breakpoint-xs) {
    padding: 0 10px;
  }

  button {
    flex-shrink: 0;
  }
}

.swiper-thumbs {
  margin-left: 0;
  margin-right: 0;

  .swiper-slide {
    width: 50px;
    height: 50px;

    div {
      height: 100%;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
  }

  .swiper-slide-thumb-active {
    img {
      border: 2px solid $color-brand-2;
    }
  }

  // @include break-max($breakpoint-xs) {
  //   display: none;
  // }
}

.swiper-counter {
  position: absolute;
  display: none;
  justify-content: center;
  background: $color-brand-opaque-10;
  font-size: 15px;
  border-radius: 15px;
  padding: 5px 10px;
  bottom: 0;
  right: 0;
  @include break-max($breakpoint-xs) {
    display: inline-flex;
  }
}


.btn.swiper-zoom {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray-dark;
  background-color: $color-white;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: $gray-dark;
  }
}

.active-one-thumb-custom img {
  border: 2px solid $color-brand-2;
}
