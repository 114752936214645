@import '../../styles/variables';
@import '../../styles/mixins';

.residence-hint {
  display: flex;
  margin-bottom: 20px;

  &__icon {
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__title {
    color: $green;
    margin-bottom: 5px;
    @include h6-L();
  }

  &__text {
    color: $gray-light-2;
  }
}

.residence-hint_bottom-border {
  border-bottom: 1px solid $gray-dark;
  padding-bottom: 20px;
}
