.pan-card {
  color: $color-brand-2;
  cursor: pointer;
  display: flex;
  background-color: $color-white;
  position: relative;
  text-decoration: none;
  height: 100%;
  width: 100%;

  @include break-max($breakpoint-sm) {
    border: 1px solid $color-brand-opaque-20;
    border-radius: 4px;
    height: 170px;
    margin-bottom: 24px;
  }

  @include break-max($breakpoint-xs) {
    border-radius: 4px;
    height: auto;
    margin: 0;
    overflow: hidden;
  }
}

.pan-card__controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 310px;

  @include break-max($breakpoint-md) {
    height: 250px;
  }

  @include break-max($breakpoint-sm) {
    @include grid-size(6, $grid-gutter-md);
    height: 170px;
    width: 260px;
  }

  @include break-max($break-xs) {
    height: 234px;
    width: 100%;
  }

  .card__fav {
    bottom: 16px;
    right: 21px;
  }
}

.pan-card__features {
  @include unlist();
  display: flex;
  position: absolute;
  bottom: 18px;
  left: 34px;

  @include break-max($break-xs) {
    position: relative;
    left: 0;
  }
}

.pan-card__feature {
  line-height: 30px;
  background-color: $color-white;
  padding: 0 12px;
  border-radius: 3px;
  margin-right: 5px;
  text-decoration: none;
  transition: background-color $transition-default;

  @include break-max($break-xs) {
    background: $color-brand-opaque-12;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.pan-card__body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  @include break-max($breakpoint-sm) {
    flex-direction: row;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.pan-card__image {
  border-radius: 5px 5px 0 0;
  border-top: 1px solid $color-brand-opaque-20;
  border-left: 1px solid $color-brand-opaque-20;
  border-right: 1px solid $color-brand-opaque-20;
  overflow: hidden;
  height: 310px;

  & img {
    min-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    max-height: 300px;
    // backface-visibility: hidden;
  }

  @include break-max($breakpoint-md) {
    height: 250px;

    & img {
      max-height: 250px;
    }
  }

  @include break-max($breakpoint-sm) {
    @include grid-size(6, $grid-gutter-md);
    border-radius: 4px 0px 0 4px;
    border: none;
    height: 168px;
    width: 260px;

    & img {
      height: 100%;
      max-height: 168px;
      object-fit: contain;
    }
  }

  @include break-max($breakpoint-xs) {
    flex-shrink: 0;
    height: 234px;
    width: 100%;

    & img {
      max-height: 234px;
    }
  }
}

.pan-card__content {
  border-left: 1px solid $color-brand-opaque-20;
  border-right: 1px solid $color-brand-opaque-20;
  border-bottom: 1px solid $color-brand-opaque-20;
  border-radius: 0 0 5px 5px;
  padding: 17px 15px 25px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    padding: 24px 15px 30px 15px;
  }

  @include break-max($breakpoint-sm) {
    border: none;
    border-radius: 0;
    padding: 34px 52px 34px 60px;
  }

  @include break-max($breakpoint-xs) {
    border-radius: 0 0 5px 5px;
    padding: 24px 24px 24px 16px;
  }
}

.pan-card__title {
  display: flex;
  flex-wrap: wrap;
}

.pan-card-title-text-wrap {
  align-items: flex-start;
  display: flex;
  padding-right: 15px;
}

.pan-card__title-text {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;

  @include break-max($breakpoint-sm) {
    font-size: 20px;
    line-height: 26px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
  }
}

.pan-card-title-content {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}

.pan-card__dates {
  align-self: center;
  color: $color-red;
  text-align: right;
  line-height: 18px;
  white-space: nowrap;

  @include break-max($break-xs) {
    align-items: center;
    background: $color-white;
    border-radius: 3px;
    color: $color-brand-2;
    display: flex;
    left: 14px;
    height: 30px;
    padding: 0px 12px;
    position: absolute;
    top: 177px;
  }
}

.pan-card__dates-to,
.pan-card__dates-from {
  white-space: nowrap;
}

.pan-card__title-details {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 14px;
  line-height: 18px;
  color: $color-brand-3;

  li {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    padding-right: 15px;
    white-space: nowrap;

    @include break-max($breakpoint-xs) {
      display: inline-block;
      max-width: 95%;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      width: 100%;

      .info {
        display: inline-block;
      }
    }
  }

  .info {
    margin-left: 5px;
  }
}

.pan-card__address {
  color: $color-brand-2;
  margin-top: 15px;

  @include break-max($breakpoint-sm) {
    margin-top: 10px;
  }
}

.pan-card__list {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  margin-top: 10px;

  // &:after {
  //   content: "";
  //   flex: auto;
  // }

  @include break-max($breakpoint-sm) {
    justify-content: flex-start;
    margin-top: 20px;
  }

  // @include break-max($breakpoint-sm) {
  //   margin-top: 22px;
  // }
}

.pan-card__item {
  color: $color-brand-3;
  flex-basis: 30%;
  margin-bottom: 15px;
  max-width: 160px;

  & + & {
    margin-left: 18px;
  }

  @include break-max($breakpoint-sm) {
    max-width: 136px;

    & + & {
      margin: 0;
    }

    &:first-child {
      margin-right: 28px;
      max-width: 155px;
    }
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
  }
}

.pan-card__item-title {
  font-size: 14px;
  line-height: 20px;
  color: $color-brand-3;
  margin-bottom: 8px;

  strong {
    color: $color-brand-2;
    font-size: 16px;
    line-height: 20px;

    @include break-max($breakpoint-sm) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.pan-card__item-value {
  font-size: 14px;
  line-height: 18px;
  color: $color-brand-2;
}

.pan-card__measurements {
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include break-max($breakpoint-sm) {
    justify-content: flex-start;
  }

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
  }
}

.pan-card__item-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: $color-brand-2;
  margin-top: 2px;
}

.pan-card__item-list-value {
  font-size: 14px;
  line-height: 18px;
}

.sot-icon,
.house-icon {
  margin-right: 10px;

  @include break-max($breakpoint-md) {
    margin-right: 7px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 1px;
  }
}

.pan-card__house-number {
  color: $color-brand-3;
  margin-bottom: 5px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 20px;
  }
}

.pan-card__price {
  font-size: 25px;
  line-height: 30px;
  white-space: nowrap;

  @include break-max($breakpoint-md) {
    font-size: 20px;
    line-height: 26px;
  }

  @include break-max($breakpoint-sm) {
    margin-right: 28px;
    width: 155px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 21px;
    line-height: 24px;
    flex-basis: 100%;
    margin-bottom: 15px;
    margin-top: 6px;
  }
}

.pan-card__square {
  @include break-max($breakpoint-sm) {
    width: 136px;
  }
}

.pan-card__square-parcel,
.pan-card__square {
  font-size: 20px;
  line-height: 26px;
  white-space: nowrap;
  flex-grow: 1;
  margin-left: 18px;

  @include break-max($breakpoint-md) {
    font-size: 16px;
    line-height: 20px;
    margin-left: 12px;
  }

  @include break-max($breakpoint-sm) {
    flex-grow: 0;
    margin-left: 0;
    font-size: 18px;
    line-height: 22px;
  }
}
