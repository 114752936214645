.sm-hide {
  display: block !important;
  @include break-max($breakpoint-sm) {
    display: none !important;
  }
}

.sm-show {
  display: none !important;
  @include break-max($breakpoint-sm) {
    display: block !important;
  }
}

.flat {

  // margin-top: 50px;
  // margin-bottom: 65px;
  margin-top: 0;
  margin-bottom: 0;

  &_margin_0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
  }

  & ~ .section .calculators__mobile-nav {

    @include break-max($breakpoint-xs) {
      align-items: flex-start;
      padding-left: 10px;
    }

  }

  & .flat__price {
    flex-wrap: wrap;
  }

  &__presentation {
    margin: 0 0 26px 10px;
  }

  &__title {
    margin-right: 65px;
  }

  &__mobile-presentation {
    margin: 15px 0 0 10px;

    & .button_view_outline {
      height: 50px;
      padding-top: 0;
      padding-bottom: 0;

      @include break-max($breakpoint-xs) {
        height: 44px;
      }
    }

    & .button_view_outline-invert {
      @include break-max($break-xs) {
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        display: flex;
        height: 44px;
        width: 100%;
      }
    }

    @include break-max($break-xs) {
      border-bottom: 1px solid $color-brand-opaque-20;
      display: flex;
      justify-content: center;
      margin: 10px auto 15px;
      padding: 0 0px 40px;
      width: calc(100% - 20px);
    }
  }

  &__header {
    padding: 30px 0 36px;
    // border-bottom: 1px solid $color-brand-opaque-20;

    @include break-max($breakpoint-md) {
      padding: 30px 0;
    }

    @include break-max($breakpoint-sm) {
      padding: 26px 0;
    }

    @include break-max($breakpoint-xs) {
      padding: 16px 0;
    }

    @include break-max($break-xs) {
      border-bottom: none;
      padding: 16px 0;
    }
  }

  &__complex {
    @include break-max($breakpoint-sm) {
      display: flex;
      margin-top: 5px;

      &-title {
        margin-right: 30px;
      }
    }

    @include break-max($breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__sticky-container {
    position: relative;
    align-items: flex-start;
    .flat__col-slider {
      position: sticky;
      top: 90px;
      @include break-max($breakpoint-sm) {
        position: static;
      }
    }
  }

  &__download {
    display: flex;
    margin-right: 20px;

    & button {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }

      &:hover, &:focus {
        background-color: rgba(143, 149, 172, 0.2);
      }

      &:active {
        background-color: rgba(143, 149, 172, 0.3);
      }


    }
  }

  &__col {
    @include grid-col();

    &_width_100 {
      position: relative;
      width: 100%;
    }

    &-title {
      display: flex;
      align-items: flex-end;

      @include grid-size(6);

      @include break-max($breakpoint-md) {
        @include grid-size(7, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(7, $grid-gutter-sm);
        flex-direction: column;
        align-items: flex-start;
      }

      @include break-max($breakpoint-xs) {
        @include grid-size(12, $grid-gutter-xs);
      }

      @include break-max($break-xs) {
        flex-basis: 60%;
        margin: 0 10px;
      }

      @include break-max($break-xxs) {
        flex-basis: 100%;
      }
    }

    &-download {
      display: flex;
      align-items: center;
      justify-content: flex-end;



      @include grid-size(5);

      @include break-max($breakpoint-md) {
        @include grid-size(4, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(5, $grid-gutter-sm);
      }

      @include break-max($breakpoint-xs) {
        @include grid-size(12, $grid-gutter-xs);
      }

      @include break-max($break-xs) {
        flex-direction: column;
        flex-basis: 30%;
      }

      @include break-max($break-xxs) {
        align-items: flex-end;
        flex-basis: 35%;
        position: absolute;
        right: 5px;
      }

      & .button_presentation {
        @include break-max($breakpoint-sm) {
          padding: 13px 18px;
          max-width: 148px;
          white-space: normal;
        }
      }

      & .complex__address-map {
        margin-right: auto;
        @include break-max($breakpoint-md) {
          margin-right: 10px;
          display: flex;
        }
        @include break-max($breakpoint-sm) {
          margin-right: auto;
          margin-bottom: 10px;
        }
        .show__map {
          background-image: url('../../../images/png/show-map.png');
          background-color: $color-brand-opaque-8;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transition: all 0.3s;
          &:hover {
            background-color: $color-brand-opaque-20;
          }
          @include break-max($breakpoint-md) {
            margin-left: 0;
          }
        }
      }
    }

    &-images {
      @include grid-size(6);

      @include break-max($breakpoint-md) {
        @include grid-size(6, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(12, $grid-gutter-sm);
        flex-direction: column;
        align-items: flex-start;
      }

      @include break-max($break-xs) {
        margin: 0 10px;
        width: 100%;
      }

      &_full {
        padding-top: 5px;
      }


    }

    &-slider {
      margin-left: 0;
      margin-right: 0;

      .complex__slider-box {
        padding: 0 22px;
        width: 100%;
      }

      .complex__slider-controls {
        left: 0;

        li {

          border: 1px solid $color-brand-opaque-20;
        }

        .prev {
          box-shadow: none;
          left: 0;
          .icon {
            margin: 0;
          }
        }

        .next {
          box-shadow: none;
          right: 0;
          .icon {
            margin: 0;
          }
        }
      }
    }

    &-info {
      @include grid-size(5);

      @include break-max($breakpoint-md) {
        @include grid-size(6, $grid-gutter-md);
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(12, $grid-gutter-sm);
      }

      @include break-max($break-xs) {
        margin: 0 10px;
        width: 100%;
      }
    }
  }

  &__nav {
    @include break-max($break-xs) {
      display: flex;
      justify-content: center;

      & .custom-select {
        width: 206px;
      }

    }

    &-list {
      @include unlist();
      margin: 0;
      display: flex;
      justify-content: center;
      &.flex-start {
        justify-content: flex-start;
      }
    }

    &-item {
      margin-right: 20px;
      display: flex;
      background: $color-brand-opaque-10;
      &:last-of-type {
        margin-right: 0;
      }
      .label-item {
        margin-top: 8px;
      }
    }
    &-link {
      padding: 14px 20px;
      border-radius: 5px;
      text-decoration: none;
      color: $color-brand-2;
      transition: all $transition-default;

      &:hover {
        background: $color-brand-opaque-8;
      }
      &.active {
        background: $color-brand-opaque-20;
      }

      @include break-max($breakpoint-sm) {
        padding: 14px 12px;
      }
    }
  }

  &__images {
    display: flex;
    position: relative;
    // padding: 35px 0;
    margin-top: 15px;
    margin-bottom: 10px;

    @include break-max($breakpoint-sm) {
      padding-bottom: 15px;
      margin-bottom: 35px;
    }

    @include break-max($break-xs) {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__image {
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 10px 40px 50px;
    height: 600px;
    display: none;
    border: 1px solid $color-brand-opaque-20;
    border-radius: 5px;

    cursor: pointer;

    &.active {
      display: flex;
    }
    & img {
      display: block;
      max-width: 100%;
      max-height: 500px;
      margin: 0 auto;
      @include break-max($breakpoint-md) {
        max-height: 400px;
      }
    }
    .pswp__img{
      max-height: 100%;
    }
    &-wrapper {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &-expand {
      position: absolute;
      bottom: 20px;
      right: 20px;

      & .icon {
        fill: $color-brand-3;
        cursor: pointer;
        &:hover {
          fill: $color-red;
        }
      }
    }

    @include break-max($breakpoint-md) {
      height: 500px;
    }

    @include break-max($break-xs) {
      height: auto;
      margin: 34px 0;
    }

    &_full {
      padding: 0;

      img {
        max-height: 650px;
      }

      .assignations__slider-wrapper {
        width: 100%;
      }

    }
  }

  &__grid {
    @include grid();
    justify-content: space-between;
    &-col {
      @include grid-col();
      display: flex;
    }
  }

  &__content {
    padding-top: 0;
    padding-bottom: 20px;

    @include break-max($break-xs) {
      border-bottom: none;
      padding-bottom: 0;
      padding-top: 15px;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;

    .flat__price-item_flex {
      display: flex;
    }

    .flat__price-item_padding_top {
      padding-top: 8px;
    }


    @include break-max($break-xs) {
      &-item {
        order: 4;
        flex-basis: 100%;

        &:first-child {
          order: 2;
          flex-basis: auto;
        }
      }

      &-actions {
        order: 3;
      }
    }

    &-actions {
      display: flex;
      align-items: start;
    }

    &-action {
      margin-right: 50px;

      &:last-of-type {
        margin-right: 0;
      }

      @include break-max($breakpoint-md) {
        margin-right: 30px;
      }

      &:active {
        & button {
          box-shadow: none;
          background-color: $color-red-active;

          .icon {
            stroke: $color-white;
            fill: $color-red-active;
          }
        }
      }

      .icon {
        stroke: $color-brand-3;
        fill: $color-bg-light;
        transition: all $transition-default;
      }

      button {
        margin-right: 10px;
      }
    }
  }

  &__period {
    &-grid {
      @include grid();


      @include break-max($break-xs) {
        justify-content: space-between;
      }
    }
    &-col {
      @include grid-col();
      @include grid-size(7);
      &:nth-child(odd) {
        @include grid-size(5);
      }

      @include break-max($breakpoint-md) {
        @include grid-size(6, $grid-gutter-md);
        &:nth-child(odd) {
          @include grid-size(6, $grid-gutter-md);
        }
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(7, $grid-gutter-sm);
        &:nth-child(odd) {
          @include grid-size(5, $grid-gutter-md);
        }
      }

      @include break-max($break-xs) {
        margin: 0;
      }
    }
  }

  &__info {
    &-grid {
      @include grid();

      @include break-max($break-xs) {
        justify-content: space-between;
      }

      .flat__info-item_value {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        @include break-max($break-xs) {
          font-size: 14px;
          line-height: 18px;
        }
      }

    }

    &-col {
      @include grid-col();
      @include grid-size(7);
      &:nth-child(odd) {
        @include grid-size(5);
      }
      @include break-max($breakpoint-md) {
        @include grid-size(6, $grid-gutter-md);
        &:nth-child(odd) {
          @include grid-size(6, $grid-gutter-md);
        }
      }

      @include break-max($breakpoint-sm) {
        @include grid-size(3, $grid-gutter-sm);
        &:nth-child(odd) {
          @include grid-size(3, $grid-gutter-sm);
        }
      }
      @include break-max($breakpoint-xs) {
        @include grid-size(6, $grid-gutter-xs);
        &:nth-child(odd) {
          @include grid-size(6, $grid-gutter-xs);
        }
      }

      @include break-max($break-xs) {
        margin-left: 0;
      }
    }
    &-item {
      margin-bottom: 30px;
      margin-right: 10px;

      @include break-max($break-xs) {
        margin-right: 0;
      }

      &_value {
        font-size: 25px;
        line-height: 31px;
        font-weight: bold;
        @include break-max($breakpoint-md) {
          font-size: 20px;
          line-height: 24px;
        }

        @include break-max($break-xs) {
          font-size: 18px;
          line-height: 20px;
        }

        sup {
          font-size: 12px;
          vertical-align: text-top;
        }
      }
      &_desc {
        font-size: 14px;
        line-height: 21px;
        color: $color-brand-3;
      }
      &_price {
        font-size: 25px;
        line-height: 31px;
        font-weight: bold;

        @include break-max($breakpoint-md) {
          font-size: 21px;
          line-height: 25px;
        }
      }

      @include break-max($break-xs) {
        margin-bottom: 23px;
      }
    }
    &-btns {
      padding: 35px 0;
      border-top: 0.5px solid $color-brand-opaque-30;
      border-bottom: 0.5px solid $color-brand-opaque-30;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .button {
        display: inline-flex;
        margin-right: 15px;
      }

      @include break-max($break-xs) {
        flex-direction: row;
        flex-wrap: wrap;

        .js-modal-btn {
          flex-basis: 100%;
          width: 100%;

          .button_view_default {
            margin-right: 0;
            margin-bottom: 14px;
            height: 44px;
            width: 100%;
          }
        }

        &-sticky {
          border: none;
          margin: 0;
          padding: 0;
        }

        &-sticky div {
          background: $color-white;
          position: fixed;
          bottom: 0;
          left: 0;
          padding: 10px;
          z-index: 11;
        }
      }

      @include break-max($breakpoint-sm) {
        justify-content: center;
        padding-top: 0;
      }

      @include break-max($break-xs) {
        border-top: none;
        justify-content: center;
      }
    }
  }

  &__manager {
     padding-top: 25px;
    @include grid-shift-left(2);

    @include break-max($breakpoint-md) {
      @include grid-shift-left(1);
    }

    @include break-max($breakpoint-sm) {
      @include grid-shift(2);
    }

    @include break-max($break-xs) {
      margin: 0 10px;

      & .flat__manager-collapse {
        margin-left: 12px;
      }

      & .collapse__title {
        border-bottom: 1px solid $color-brand-opaque-40;
      }

      & .collapse__content {
        padding-bottom: 0;
      }

      & .manager {
        flex-direction: row;
        flex-wrap: wrap;

        .manager-content {
          flex-basis: 65%;
          margin-top: 0;
          padding-left: 15px;

          
          &_managerIsAgent {
            @include break-max($breakpoint-xs) {
              padding-left: 0;

              .manager-contacts {
                margin-top: 10px;
              }
            }
          }
        }

        .manager-action {
          flex-basis: 100%;
        }

        .button_view_outline {
          border-radius: 5px;
          width: 100%;
        }
      }

      & .manager-title {
        margin-top: 0;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.modal-form {
  .form {
    &__item {
      height: auto;
      margin: 0;
    }

    &__label {
      color: $color-brand-3;
      line-height: 25px;
      margin-bottom: 4px;
      white-space: nowrap;
    }

    &__drop-btn {
      height: 50px;
      background-color: $color-white;
      padding: 0 16px 0 16px;
      box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
      border-radius: 4px;
      color: $color-brand-2;
    }
  }

  .add-client {
    display: none;

    &.active {
      display: inline-flex;
    }
  }

  & &__new-client {
    display: none;

    &.active {
      display: block;
    }
  }
}

.add-client {
  display: inline-flex;
  align-items: center;
  color: $color-brand-3;
  cursor: pointer;
  margin-top: 10px;
  white-space: nowrap;


  .button_type_add {
    margin-right: 10px;
  }

  &:hover {
    .button_type_add {
      background-color: $color-brand-opaque-20;
    }
  }
}

.modal-form__new-client {
  position: relative;
  .close-button {
    align-items: center;
    background: $color-brand-opaque-10;
    border-radius: 50em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 16px;
    right: 16px;
    height: 28px;
    width: 28px;

    .icon_x {
      margin-left: 3px;
      stroke: $color-brand-3;
      width: 10px;
    }
  }
}

.new-client {

  @include break-max($breakpoint-xs) {
    margin: 0 10px;
  }

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    @include break-max($breakpoint-xs) {
      .button {
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.flat__white-btn {
  @include break-max($break-xs) {
    width: 48%;

    &:first-child {
      margin-right: 12px;
    }

    button {
      align-items: center;
      height: 50px;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}

.flat__white-btn.flat__white-btn-is-one {
  @include break-max($break-xs) {
    margin-bottom: 20px;
    margin-left: 0;
    width: 100%;
  }
}

.wrapper__center {
  .banks__slider {
    @include break-max($break-xs) {
      margin-top: -20px;
      padding-top: 0;
    }
  }
}

.flats__card_compact {
  @include break-max($break-xs) {
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    border: 1px solid $color-brand-opaque-20;
    height: 420px;
    padding: 17px 25px;
    position: relative;
    width: 90%;
  }

  @include break-max($break-xxs - 1px) {
    padding: 10px 20px;
  }
}

.assignations-top__slider.slider {
  @include break-max($break-xs) {
    border: none;
    margin: 0 10px;

    .complex__slider-thumbnails {
      justify-content: center;
      margin-top: 30px;
    }

    .complex__slider-info {
      bottom: 10px;
    }

    .slider__item.tns-slide-active {
      margin-right: -20px;
    }

    .tns-ovh {
      margin-right: -10px;
    }

    .card__pill {
      align-self: flex-start;
    }

    .card__pill,
    .card__title,
    .card__address,
    .card__company {
      font-size: 15px;
      // line-height: 20px;
    }

    .flats__card_compact .flats__card-image {
      height: 160px;
      margin: 0 auto;
    }

    .flats__card .card__row {
      margin-top: 15px;
    }

    .flats__card-info {
      padding: 0 10px;
      width: 100%;
    }

    .card__dates {
      color: $color-red;
      margin-top: 5px;
      padding: 0;
      position: static;
    }
  }

  @include break-max($break-xxs - 1px) {
    .card__pill,
    .card__title,
    .card__address,
    .card__company {
      font-size: 14px;
      // line-height: 20px;
    }
  }
}

div.assignations-top__title {
  @include break-max($break-xs) {
    margin-bottom: 10px;
  }
}

div.assignations-top {
  @include break-max($break-xs) {
    padding-bottom: 25px;
    padding-top: 0;
  }
}

.assignations-flats.for-commercial {
  @include break-max($break-xxs - 1px) {
    .flats__row-col:first-child {
      overflow: hidden;
      min-width: 58px;
      text-overflow: ellipsis ;
    }

    .flats__row-concat {
      margin-left: 14px;
    }
  }

  @include break-max($break-xs) {
    background: transparent;

    .section__map {
      margin-top: 50px;
    }

    .flats__row-table-expanded {
      padding-left: 45px;
    }

    .button.button_view_outline-with-icon-invert {
      background: white;
      bottom: 20px;
      right: 0;
      position: fixed;
      z-index: 11;
      left: 10px;
      padding: 0 20px;

      &.mobile-map {
        background: none;
        color: $color-red;
        justify-content: flex-end;
        position: static;

        .icon {
          fill: $color-red;
        }
      }
    }

    .presentation-filters-btn {
      margin: 0 0 10px auto;
      bottom: 10px;
      position: sticky;
      z-index: 10;
    }

    .flats__row-table {
      background: none;
      border-radius: 0 0 5px 5px;
    }

    .assignations-flats__tables {
      padding-bottom: 60px;
    }

    .table__show-more {
      background: $color-white;
      border-radius: 0 0 5px 5px;
      padding: 0;
    }

    .table__show-more_btn {
      background: none;
      border-radius: 0;
      box-shadow: none;
      color: $color-brand-3;
      font-size: 15px;
      font-weight: normal;
      height: 65px;
      line-height: 20px;
      margin-top: -20px;
      margin-bottom: 10px;
      text-transform: none;
      position: relative;
      width: 100%;
    }

    .icon.icon_arrow-bottom {
      display: block;
      margin-left: 5px;
    }
  }
}

.mobile-complex-details  {
  @include break-max($break-xs) {
    margin: 0 10px 20px;
  }
}

.flat__info-item.for-mobile {
  margin: 16px 0 0 0px;

  .pill {
    margin-top: 10px;
    display: block;
    width: fit-content;
  }
}

div.flats__row-table-expanded {
  @include break-max($break-xxs - 1px) {
    font-size: 13px;
  }
}

.assignation-info .flat__col-row.is-row {
  @include break-max($break-xs) {
    flex-direction: row;

    .flat__title {
      margin-right: 0;
    }

    .flat__download {
      width: 30%;
      margin-right: 0;
      margin-top: 20px;
    }
  }
}

.assignations-flats .flats__row-table.for-secondary {
  table {
    padding: 0;
  }

  .ant-table-tbody {
    .ant-table-row {
      background: $color-white;
      transition: none;

      &:hover {
        background: #e9eaee;
      }
    }

    .ant-table-second-color {
      background: $color-bg-light;
      transition: none;

      &:hover {
        background: #e9eaee;
      }
    }
  }

  @media only screen and (max-width: 1330px) {
    table {
      table-layout: fixed !important;
    }

    .ant-table-column-sorters,
    .ant-table-tbody .ant-table-cell {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.flat__col-to-presentation {
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.flat__price-actions.for-fav {
  @include break-max($breakpoint-xs) {
    flex-grow: 1;
  }
}

.flat__price {
  @include break-max($breakpoint-xs) {
    .flat__info-item {
      min-width: 160px;
      width: 100%;
    }
  }
}

.flat__image {
  @include break-max($breakpoint-xs) {
    .complex__slider-thumbnails_item {
      height: auto;
      width: auto;
    }
  }
}

.status-sold {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 2%;
    bottom: 2%;
    left: 2%;
    right: 2%;
    background-image: url('../../../assets/images/status-sold.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2;
  }
}

.flat__slider-title {
  margin-left: 15px;
  &_absolute {
    position: absolute;
    top: 2px;
    left: 0;
    @include break-max($breakpoint-sm) {
      position: static;
    }
  }

}


.assignation-info .flat__complex-col.flat__col-with-map-mobile {
  @include break-max($breakpoint-xs) {
    display: flex;
    flex-direction: row;

    .flat__complex-title {
      margin-right: 0;
      width: 70%;
    }

    .complex__address-map {
        width: 30%;
      .show__map {
        width: 44px;
        height: 44px;
        background-image: url('../../../images/png/show-map.png');
        background-color: $color-brand-opaque-8;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: all 0.3s;
        &:hover {
          background-color: $color-brand-opaque-20;
        }
        margin-left: 0;
      }
    }
  }
}

.lawyers-checked {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 23px;
  padding-right: 10px;
  width: 380px;
  max-width: 100%;
  height: 65px;
  background: $color-brand-opaque-8;
  border-radius: 4px;
  font-size: 14px;
  color: $color-brand-3;

  @include break-max($breakpoint-xs) {
    width: 100%;
  }

  span {
    margin-left: 10px;
  }
}

.lawyers-checked__block {
  display: flex;
}

.lawyers-checked__background {
  position: absolute;
  right: 10px;
  top: 0;
}

.pswp {
          
  .pswp__bg {
    background: $color-white;
  }

  .pswp__ui--fit .pswp__top-bar {
    background-color: $color-brand-1;
  }
}

.pswp__img--placeholder--blank {
  background: $color-white;
}
