.consultation {
  &_single {
    margin-top: 100px;
    @include break-max($breakpoint-md) {
      margin-top: 60px;
    }
    @include break-max($breakpoint-xsm) {
      margin-top: 50px;
    }
  }
}

.consultation__form {

  input {
    height: 70px;
    padding-left: 32px;
    padding-right: 32px;
    background: $color-brand-opaque-10;
    color: $color-brand-2;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: none;

    @include break-max($breakpoint-sm) {
      height: 50px;
      @include break-max($breakpoint-sm) {
        font-size: 15px;
      }
    }

    &::placeholder {
      color: $color-brand-2;
    }
  }

  .checkbox {
    align-items: flex-start;
  }

  .checkbox__input {
    background: $color-brand-opaque-10;
  }

  .checkbox__box {
    box-shadow: none;
  }

  .checkbox__label {
    text-align: left;
    font-size: 16px;
    line-height: 22px;

    @include break-max($breakpoint-sm) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}


.consultation_dark {
  position: relative;
  color: $color-white;
  background: $color-2;
  padding-bottom: 50px;
  margin-bottom: 0;

  &::after {
    @extend .constructor__container;
    content: '';
    display: block;
    height: 1px;
    margin-top: 85px;
    background-color: $color-brand-opaque-30;
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

  .consultation__form {
    input {
      color: $color-white;
      &::placeholder {
        color: $color-white;
      }
    }
  }
}

.consultation__form_half {
  width: 50%;
  max-width: 470px;
  justify-content: center;

  .constructor__title {
    margin-bottom: 50px;
    @include break-max($breakpoint-sm) {
      margin-bottom: 25px;
    }
    &::before, &::after {
      display: none;
    }
    @include break-max($breakpoint-xs) {
      justify-content: center;
    }
  }

  .form__submit {
    margin-left: 0;
  }

  @include break-max($breakpoint-xsm) {
    width: 100%;
    max-width: 400px;
    padding: 50px 10px;
    .form__submit {
      width: 100%;
    }
  }
}

.consultation__row {
  width: 100%;
  display: flex;
  @include break-max($breakpoint-xsm) {
    flex-direction: column;
    padding: 0 10px;
  }
}

.consultation__map {
  position: relative;
  width: 50%;
  height: 695px;

  @include break-max($breakpoint-xsm) {
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    height: 500px;
  }
}

.consultation__map-placeholder {
  background: pink;
}

.consultation_colored {
  margin-bottom: 0;
  border-bottom: 1px solid;
  @include break-max($breakpoint-xs) {
    border-bottom: 0;
  }
}

.consultation__map-info {
  position: absolute;
  top: 7%;
  left: 7%;
  display: inline-flex;
  flex-direction: column;
  padding: 35px;
  padding-right: 30px;
  border-radius: 15px;
  max-width: 80%;
  color: $color-white;
  @include break-max($breakpoint-sm) {
    padding: 25px;
  }

  @include break-max($breakpoint-xs) {
    max-width: 100%;
    margin: 15px;
    top: 0;
    left: 0;
  }

}

.consultation__map-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
}

.consultation__map-adress {
  width: 100%;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  @include break-max($breakpoint-sm) {
    width: 100%;
  }
}

.consultation__map-blocks {
  display: flex;
  margin-top: 15px;
  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }
}

.consultation__map-block {
  display: flex;
  &:first-of-type {
    margin-right: 70px;
    @include break-max($breakpoint-md) {
      margin-right: 25px;
    }

    @include break-max($breakpoint-sm) {
      margin-right: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  a {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    &:last-child {
      margin-bottom: 0;
    }

    @include break-max($breakpoint-md) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.consultation__map-icon {
  margin-right: 15px;
}

.consultation__map-anchors {
  display: flex;
  flex-direction: column;
}

.banner.constructor__section + .consultation.constructor__section.constructor__section_no-margin {
  border: none;
  // margin-top: -100px;
  // @include break-max($breakpoint-md) {
  //   margin-top: -60px;
  // }
  // @include break-max($breakpoint-xsm) {
  //   margin-top: -50px;
  // }
}
