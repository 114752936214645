.modal-form {
  $c: &;

  &_width_470 {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &_mt_50 {
    margin-top: 50px;
  }

  &_mt_25 {
    margin-top: 25px;
  }

  &__radio-row  {
    margin-top: 10px;
    @include break-max($breakpoint-xs) {
      display: flex;
      flex-direction: row !important;
    }
    .radio + .radio {
      margin-left: 60px;
      @include break-max($breakpoint-xs) {
        margin-left: 25px;
      }
    }

  }

  &__col {
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__filters {
    width: 250px;
    margin-right: 30px;
    padding-left: 30px;
    flex-shrink: 0;
  }

  &__divider {
    background: none;
    border: 0;
    border-top: 1px solid $color-brand-opaque-20;
    margin-bottom: 0;
  }

  &__row {
    display: flex;

    @include break-max($break-xs) {
      flex-direction: column;
    }

    & + & {
      margin-top: 18px;
    }

    & + .modal-form__row_mt_32 {
      margin-top: 32px;
    }

    & + .modal-form__row_mt_30 {
      margin-top: 30px;
    }

    &_align {
      &_bottom {
        align-items: flex-end;

        & .modal-form__add {
          margin-bottom: 10px;
        }

        & .checkbox {
          align-items: flex-start;
        }
      }
    }
  }


  &__row-block {
    display: flex;

    & + & {
      margin-top: 12px;
    }

    & .modal-form__item {
      flex: 1;

      & + & {
        margin-left: 12px;
      }

      &_2x {
        flex: 2;
      }
    }

    &:first-of-type {
      margin-top: 24px;
    }

  }

  &__gist {
    padding: 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
    border-radius: 4px;
    background-color: $color-brand-opaque-8;
    flex-grow: 1;
  }

  &__wrap {
    background-color: $color-brand-opaque-8;
    padding: 26px 32px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > * {
      width: 100%;
    }

    & > * + * {
      margin-top: 18px;
    }
  }

  &__item {
    flex: 1;

    & + & {
      margin-left: 30px;

      @include break-max($break-xs) {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    &_2x {
      flex: 2;
    }

    &_sm {
      flex-basis: 200px;
      flex-grow: 0;
    }

    &_half {
      flex-basis: calc(50% - 15px);
      flex-grow: 0;
    }
    &_single-wide {
      flex-basis: calc(75% - 7.5px);
      flex-grow: 0;
    }

    &_145 {
      flex-basis: 145px;
      flex-grow: 0;
      .custom-select {
        min-width: 145px;
        width: 145px;
      }
    }

    &_flex {
      display: flex;
      align-items: center;
    }

  }

  &__files {
    border-bottom: 1px solid $color-border;
    padding-bottom: 30px;

    &-notEditable {
      border-bottom: none;

      & .file-up__name {
        width: auto;
        margin-right: 10px;
      }

      & .file-up__edit {
        display: none;
      }
      & .file-uploader__name {
        width: auto;
        margin-right: 10px;
      }

      & .file-uploader__edit {
        display: none;
      }
    }
  }

  &__submit {
    margin-top: 25px;
    display: flex;
    justify-content: center;

    & > * + * {
      margin-left: 30px;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    & span {
      margin-left: 8px;
      color: $color-brand-3;
    }

    &.is-hidden {
      display: none;
    }
  }

  &__block {
    margin-bottom: 34px;

    & h4 {
      margin-bottom: 20px;
    }
  }

  &__filter {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    &-name {
      color: $color-brand-3;
      margin-right: 0.5em;
      white-space: nowrap;
    }

  }

  &__additional {
    display: none;

    &.is-active {
      display: block;
    }
  }

  .modal-form__item {
    &--client-docs {
      display: flex;
      flex-direction: column;

      .input_type_upload-btn {
        border: 1px dashed $gray-light-2;
        border-radius: 4px;
        background-color: $gray-light-3;
        height: 80px;
        line-height: normal;
      }
    }
  }

  &__add-docs {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100%;
  }

  &__add-docs-overtext {
    text-align: left;
    margin-left: 10px;
    margin-top: -3px;
  }

  &__add-docs-text {
    @include h6();
    color: $color-brand-2;
  }

  &__add-docs-hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $gray-dark-2;
  }

}

.modal-form_row {
  @include break-max($break-xs) {
    padding: 0;
  }
}

.modal-form__send {
  @include break-max($break-xs) {
    .button {
      width: 100%;
    }
  }
}

.send-filter {
  @include break-max($break-xs) {
    align-items: center;
  }
}

.modal-form__item.modal-form__full-width .form__item {
  flex-basis: 100%;
  width: 100%;
}

.modal-form__grid.for-saved-filter {
  @include break-max($break-xs) {
    flex-direction: column;

    .modal-form__wrap {
      background: none;
      padding: 0;
    }

    .checkbox {
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding-left: 0;
    }
  }
}

.modal-form__submit {
  @include break-max($break-xs) {
    justify-content: space-between;

    .button {
      margin: 0;
      //width: calc(50% - 5px);
      width: 100%;
    }
  }
}

.numeric-form-item {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;

  &.modal-form__row {
    margin-top: 0;
  }

  .checkbox_view_invert {
    margin-top: 10px;
  }

  .select.select_type_form.for-clients {
    width: 345px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  &.is-disabled {
    margin-top: 0;
    margin-bottom: 0;

    &.for-table {
      padding-bottom: 15px;

      @include break-max($breakpoint-xs) {
        padding-bottom: 10px;
      }
    }

    *,
    .textarea__control::placeholder,
    .textarea__control::-webkit-input-placeholder,
    .checkbox_view_invert .checkbox__label,
    .select_type_form .custom-select-label {
      border-color: $color-brand-opaque-20;
      color: $color-brand-opaque-50;
      pointer-events: none;
    }

    .button_view_default,
    .numeric-form-item__radio.is-active {
      background: $color-brand-opaque-10;
      color: $color-brand-opaque-50;
      pointer-events: none;
    }

    .numeric-form-item__number::after {
      border-color: $color-brand-opaque-50;
      pointer-events: none;
    }
  }
}

.numeric-form-item + .numeric-form-item {
  border-top: 1px solid $color-brand-opaque-20;
  padding-top: 32px;

  @include break-max($breakpoint-xs) {
    border-top: none;
    padding-top: 0;
  }

  &.no-border,
  &:last-child {
    border-top: none;
    padding-top: 0;
  }
}

.numeric-form-item__label {
  color: $color-brand-1;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  flex-basis: 100%;
  line-height: 20px;
  margin-bottom: 20px;
}

.numeric-form-item__number {
  align-items: center;
  color: $color-red;
  display: flex;
  margin-right: 10px;

  &::after {
    border-top: 1px solid $color-brand-3;
    content: "";
    display: flex;
    margin-left: 10px;
    height: 1px;
    width: 25px;
  }
}

.numeric-form-item__list {
  display: flex;
  // flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: 100%;
}

.numeric-form-item__radio {
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 4px;
  display: flex;
  height: 44px;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  padding: 0 17px;
  justify-content: center;
  margin-right: 30px;
  cursor: pointer;
  input {
    display: none;
  }

  &.is-active {
    background: $color-red;
    color: $color-white;
  }

  &.is-disabled {
    color: $color-brand-opaque-50;
  }
}

.meeting-room-types {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &.for-less {
    justify-content: space-around;
  }

  @include break-max($breakpoint-sm) {
    align-items: center;
    flex-direction: column;
  }
}

.meeting-room__plan {
  align-items: center;
  background: $color-brand-opaque-10;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  height: 150px;
  width: 220px;

  @include break-max($breakpoint-xs) {
    height: 110px;
    width: 205px;
  }

  @include break-max($breakpoint-sm) {
    svg {
      height: 80%;
    }
  }
}

.meeting-room__title {
  display: flex;
  margin: 12px 0 0 5px;
}

.meeting-room {
  cursor: pointer;

  @include break-max($breakpoint-sm) {
    margin-bottom: 35px;
  }
}

.meeting-room.is-active {
  .meeting-room__plan {
    background: $color-red;

    & path {
      stroke: $color-white;
    }
  }
}

.meeting-room__input {
  display: none;
}

.meeting-room__photo {
  align-items: center;
  background: $color-brand-opaque-15;
  border-radius: 50em;
  display: inline-flex;
  justify-content: center;
  margin-top: 8px;
  position: relative;
  height: 28px;
  width: 28px;
}

.meeting-room__description {
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(44, 46, 62, 0.2);
  font-size: 15px;
  line-height: 20px;
  padding: 24px;
  position: absolute;
  left: calc(100% + 30px);
  top: 0;
  max-width: 390px;
  width: 390px;
  z-index: 10;

  @include break-max($breakpoint-xs) {
    left: -30px;
    top: 40px;
    width: 265px;
  }

  img {
    width: 100%;
  }
}

.modal-form__row.for-builders {
  margin-top: 25px;
  width: 345px;
}

.modal-form__item.for-clients {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .add-client {
    flex-basis: 100%;
    margin-right: 50%;
  }

  .modal-form__new-client {
    margin-top: 25px;
  }

}

.modal-form__row.fixation-badges-container  {
  margin-top: 0;
}

.modal-form__submit_is {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .new-alert {
    width: auto;
  }

  .ant-back-top {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    width: auto;
    height: auto;
  }

  .more-object-link {
    color: $color-red-bottom-border;
    transition-duration: .3s;
    margin-top: -10px;

    &:hover {
      color: $one-more-red;
    }
  }

}


