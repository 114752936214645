.form {
  $c: &;

  &__row {
    display: flex;
    align-items: center;
    margin-left: -6px;
    margin-right: -6px;
    flex-wrap: wrap;
    // flex-wrap: wrap;

    &:last-child {
      & #{$c}__item {
        margin-bottom: 0;
      }
    }

    & > * {
      margin-left: 6px;
      margin-right: 6px;
    }

    & .mobile-filters {
      margin: 0;
    }
  }

  &__item {
    align-self: stretch;
    background-color: #fff;
    flex: 0 1 calc(100% / 6 - 12px);
    margin-bottom: 12px;
    margin-right: 6px;
    margin-left: 6px;
    border-radius: 4px;
    height: 50px;

    @include break-max($breakpoint-sm) {
      flex: 0 1 calc(100% / 4 - 12px);
    }

    @include break-max($break-xs) {
      flex-basis: 50%;
      width: 50%;
    }

    // @include break-max($break-sm) {
    //   flex: 0 1 calc(100% / 4 - 12px);
    //   width: calc(100% / 4 - 12px);
    // }

    &:last-of-type {
      margin-right: 0;
    }

    &_2x {
      flex: 0 1 calc(100% / 6 * 2 - 12px);

      @include break-max($breakpoint-sm) {
        flex: 0 0 calc(100% / 4 * 3 - 12px);
      }

      @include break-max($break-xs) {
        background-color: $color-brand-opaque-10;
      }
    }
  }
  .form__item_cyp-top-type {
    .form__drop-name {
      padding-right: 7px;

      span {
        flex-shrink: 0;
        margin-left: 5px;
      }
    }
  }
  .form__item_bedroom-top {
    .form__drop-name {
      padding-right: 7px;

      span {
        flex-shrink: 0;
        margin-left: 5px;
      }
    }
  }
  &__drop {
    &.is-showing {
      & .icon {
        transform: rotate(180deg);
      }

      & #{$c}__drop-btn {
        border-radius: 4px 4px 0 0;
        box-shadow: #{$shadow};
      }
    }

    & .dropdown__block {
      left: 0;
      transform: translate(0);
      min-width: 100%;
      border-radius: 0 4px 4px 4px;
      overflow: hidden;
      box-shadow: $shadow;
      z-index: 1;
    }
  }

  &__drop-btn {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    transition: box-shadow 1s ease-in-out;
    box-shadow: 0 0 0 $color-brand-opaque-20;

    &:hover {
      // background-color: $color-brand-opaque-20;
      box-shadow: #{$shadow};
    }

    & > .icon {
      width: 9px;
      stroke: $color-brand-1;
      fill: none;
      transform: rotate(0);
      transition: transform $transition-default;
    }
  }

  &__drop-name {
    max-width: calc(100% - 16px);
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon_chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;

      @include break-max($breakpoint-sm) {
        width: 9px;
        height: 9px;
      }
    }
  }

  &__drop-content {
    background-color: $color-white;
    padding: 24px 22px 24px 16px;
    max-height: 370px;
    min-width: 350px;

    &.centered {
      display: flex;
      justify-content: center;
    }

    &.no-max-height {
      max-height: initial;
    }

    @include break-max($break-xxs) {
      min-width: 300px;
      max-width: 300px;
    }
  }
}

.form__item_suburbans-region {
  .form__drop-content {
    min-width: 375px;
  }
}
