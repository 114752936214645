.section__another {
  margin-top: 80px;
  padding: 60px 0 70px;

  @include break-max($breakpoint-md) {
    padding: 50px 0 70px;
  }
  @include break-max($breakpoint-md) {
    padding: 40px 0 70px;
  }

  @include break-max($break-xs) {
    padding-top: 0;
    margin: 0 10px;
  }

  & .block__title-nav {
    margin-left: 20px;
  }

  & .block__title-navs {
    @include break-max($breakpoint-md) {
      height: 45px;
    }
  }
}

.complex__blocks {
  margin-top: 55px;
  @include break-max($breakpoint-md) {
    margin-top: 40px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 35px;
  }

  @include break-max($break-xs) {
    & .section__grid-col {
      margin-right: 0;
    }

    & .section__grid-col.tns-slide-active {
      visibility: visible;
      margin-left: -20px;
      padding-left: 20px;

      &:last-child {
        padding-left: 0;
      }

      & ~ .section__grid-col {
        visibility: visible;
      }
    }

    .tns-inner {
      margin-right: -10px !important;
      overflow: hidden;
      padding-right: 10px;
    }
  }
}
