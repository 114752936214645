@import "../../../styles/variables";
@import "../../../styles/mixins";

.toggler {
  display: none;

  @include break-max($breakpoint-sm) {
    position: relative;
    display: inline-flex;
    font-size: 0;
    width: 50px;
    height: 50px;
    justify-content: center;
    cursor: pointer;
    margin-top: 22px;

    &::before {
      content: '';
      width: 18px;
      height: 2px;
      background-color: $color-brand-1;
      box-shadow: 0 7px 0 0 $color-brand-1, 0 14px 0 0 $color-brand-1;
    }

    &.opened {
      margin-left: auto;
      margin-bottom: 75px;
      margin-top: 28px;
      margin-right: 8px;
    }

    &.opened::before {
      box-shadow: none;
      transform: rotate(45deg);
      margin-top: 8px;
      width: 28px;
    }

    &.opened::after {
      content: '';
      position: absolute;
      top: 8px;
      width: 28px;
      height: 2px;
      background-color: $color-brand-1;
      transform: rotate(-45deg);
    }
  }

  @include break-max($breakpoint-xs) {
    width: 40px;
    height: 40px;
    margin-top: 18px;
  }
}

.mobile-slide-menu:not(.mobile-only) {
  @include break-max($breakpoint-sm) {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 57%;
    max-width: 300px;
    height: 100vh;
    right: -100%;
    top: 0;
    transition: right 0.5s ease-in-out;
    background: $color-white;
    margin-left: 0;
    &.opened {
      right: 0;
    }
  }

  @include break-max($breakpoint-xs) {
    position: absolute;
  }
}

.mobile-slide-menu.mobile-only {
  @include break-max($breakpoint-xs) {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 57%;
    max-width: 300px;
    height: 100vh;
    right: -100%;
    top: 0;
    transition: right 0.5s ease-in-out;
    background: $color-white;
    margin-left: 0;
    &.opened {
      right: 0;
    }
  }
}


.mobile-slide-menu-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color-brand-1, 0.5);
  animation: opacity 0.8s ease-in-out;
}