.label {
  $c: &;

  &-item {
    display: inline-block;
    padding: 5px 9px 6px;
    border-radius: 4px;

    @include break-max($breakpoint-md) {
      white-space: normal;
    }

    &.label_id {
      padding-left: 12px;
      padding-right: 12px;

      @include break-max($breakpoint-xs) {
        margin-right: 10px;
      }
    }
  }

  &_plain-text {
    background-color: transparent;
  }

  &_orange {
    color: $orange;
    background-color: $orange-30;
  }

  &_purple {
    color: $purple;
    background-color: $purple-20;
  }

  &_green {
    color: $green;
    background-color: $green-20;
  }

  &_red {
    color: $color-red;
    background-color: $color-red-10;
  }

  &_yellow {
    color: $yellow;
    background-color: $yellow-20;
  }

  &_violet {
    color: $violet;
    background-color: $violet-20;
  }
  &_blue {
    color: $blue;
    background-color: $blue-20;
  }
  &_gray {
    background-color: $color-brand-opaque-10;
  }
  &_is-fav {

    & .icon {
      fill: $color-red;
      margin-right: 9px;
      font-size: 17px;
    }
  }

  &_brand {
    background-color: $color-brand-3;
    color: #fff;
  }

  &_with-icon {

    &_prefix {
      display: flex;
      align-items: center;
      #{$c}__icon {
        margin-right: 5px;
      }
    }

    &_postfix {
      display: flex;
      align-items: center;
      #{$c}__icon {
        margin-left: 5px;
      }
    }
  }

  &_id {
    color: $color-white;
    background: $blue;
  }
}
