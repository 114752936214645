.typical__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.typical__btn {
  background: none;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  color: $color-brand-2;
  text-transform: none;

  align-items: center;
  width: 100%;
}

.typical__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 468px;
  max-width: 100%;

  padding: 32px;
  padding-top: 70px;

  // box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  border: 1px solid $color-brand-opaque-10;
  border-radius: 15px;
  margin-right: 30px;
  margin-bottom: 30px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 2px 15px rgba(143, 149, 172, 0.5);
  }

  &:last-child,
  &:nth-child(3n) {
    margin-right: 0;
  }


  @include break-max($breakpoint-md) {
    width: 380px;
  }

  @include break-max($breakpoint-sm) {
    width: 305px;
  }

  @include break-max($breakpoint-xsm) {
    width: 350px;
    margin-right: 0;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 27px;
    padding-right: 27px;
    width: 330px;
  }
}

.typical__label {
  position: absolute;
  top: 17px;
  right: 37px;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: $color-white;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.typical__image {
  margin: 0 auto;
  // max-width: 280px;
  max-height: 240px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 24px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 30px;
  }
}

.typical__info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 16px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
  }
}

.typical__info-item {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.typical__header {
  border-top: 1px solid $color-brand-opaque-10;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  padding-top: 24px;
}

.typical__header-item {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
}

.typical__info-title {
  font-size: 18px;
  line-height: 18px;
}

.typical__info-value {
  padding-left: 15px;
  text-align: right;
}
