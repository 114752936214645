.mobile-filters {
  background: $color-white;
  bottom: 0;
  left: 0;
  overflow: scroll;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 9999;

  .icon.icon_chevron {
    transform: rotate(-90deg);
  }

  &.with-padding {
    padding-top: 50px;
  }

  .switch__input {
    @include break-max($break-xs) {
      background: $color-brand-opaque-30;
    }
  }

  .filter__filter .input__control {
    @include break-max($break-xs) {
      height: 50px;
    }

    &.is-thin {
      @include break-max($break-xs) {
        background: $color-brand-opaque-10;
      }
    }
  }
  label.is-thin {
    @include break-max($break-xs) {
      background: $color-brand-opaque-10;
    }
  }
  & .index-search__filter-main {
    margin-top: 12px;
  }

  & .index-search__filter-main,
  & .index-search__filter-top {
    padding: 0;

    & .form__item {
      flex-basis: 100%;

      &:nth-child(2n) {
        margin-left: 0;
      }
    }
  }

  & .form__row > * {
    margin: 0;
  }

  & .index-search__filter-applied {
    padding: 0;
  }

  & .form__drop .dropdown__block,
  & .index-search__filter-main .form__drop .dropdown__block,
  & .index-search__filter-top .form__drop .dropdown__block {
    background: $color-white;
    bottom: 0;
    left: 0;
    overflow: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
  }

  & .form__drop-content,
  & .index-search__filter-main .form__drop-content,
  & .index-search__filter-top .form__drop-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & .filter__checks {
    max-height: none;
    margin-bottom: 40px;
  }

  & .checkbox-group {
    padding-right: 0;
  }

  & .checkbox__box {
    order: 3;
  }

  & .first-order {
    order: 1;
  }

  & .second-order {
    order: 2;
    margin-left: 14px;
  }

  & .checkbox_view_red {
    margin-top: 10px;
    padding: 0 10px 20px;
  }

  & .checkbox-group__item {
    background: none;
    border-bottom: 1px solid $color-brand-opaque-20;
    border-radius: 0;
    margin: 0;
    min-height: 47px;
    padding-left: 0;
    padding-right: 0;
  }

  & .filter__exclude-btn {
    background: $color-brand-opaque-10;
    border-radius: 3px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    order: 2;
    margin-right: 14px;
  }

  & .form__drop-content > .filter {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: scroll;
    padding: 17px 10px 90px;
  }

  & .filter__cols {
    max-height: none;
    margin-bottom: 90px;
  }

  & .filter__cols,
  & .filter__checks,
  & .filter__filter {
    width: 100%;
  }

  & .checkbox__box + .checkbox__label {
    padding: 0;
  }
}

.mobile-filters.for-motivation,
.complex__filter-row .mobile-filters {
  .form__item {
    flex-basis: 100%;
    margin: 12px 0 0 0;
    max-width: 100%;
    width: 100%;
  }

  .complex__filter-switch {
    margin-left: 0;
    justify-content: flex-start;
  }
}

.modal.for-districts {
  @include break-max($break-xs) {
    & .modal__inner {
      padding: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      & .mobile-filters__submit {
        bottom: 40px;
        padding: 0 10px;
        position: absolute;
      }

      & .button__mobile-filters {
        background: $color-border;
        color: $color-brand-2;
      }
    }

    & #dist-map-container {
      height: 100%;
      max-height: 100%;
    }

    & .modal__title {
      margin-bottom: 0;
    }
  }
}

.mobile-filters__title {
  align-items: center;
  border-bottom: 1px solid $color-brand-opaque-20;
  min-height: 50px;
  display: flex;
  justify-content: center;
  padding: 15px 25px;
  text-align: center;
  max-height: 50px;

  & > svg.icon.icon_arrow_up {
    top: 13px;
    left: -4px;
    position: absolute;
    transform: rotate(-90deg);
    height: 30px;
    width: 34px;
    padding: 9px;
  }

  &.is-fixed {
    background: $color-white;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 1;
  }
}

.mobile-filters__content {
  padding: 23px 10px 115px;
}

.mobile-filters.sf-mobile {
  .checkbox_view_red {
    padding: 20px 10px 0;
    margin: 0 !important;
  }
    .mobile-filters__content {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

.mobile-filters__submit {
  background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 10%, $color-white 70%);
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  left: 10px;
  padding: 30px 0 20px;
  position: fixed;
  right: 0;
  width: calc(100% - 20px);
  z-index: 10;

  & .button:first-child {
    margin-right: 14px;
  }
}

.dist-map .mobile-filters__submit {
  background: $color-white;
  bottom: 0;
  left: 0;
  padding: 12px 10px;
  right: 0;
  width: 100%;
}

.mobile-filters__show-all {
  background: linear-gradient(rgba($color-white, 0.8), $color-white);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 12px 0 15px;
  position: fixed;
  right: 0;
  z-index: 10;

  .button {
    font-size: 15px;
    width: calc(100% - 20px);
  }
}

.mobile-filters.for-motivation,
.section.complex__section-filter,
.section__personal-header + .section .mini-filter,
.index-search__filter.for-secondary,
.section.index-search.index-search_dark.for-main {
  & .form__item_2x.form__item_main {
    @include break-max($break-xs) {
      flex-basis: 100%;
    }
  }

  @include break-max($break-xs) {
    & .form__item:nth-child(2n) {
      margin-left: 0px;
    }

    & .form__drop-btn > .icon {
      transform: rotate(-90deg);
    }
  }
}

.section.cards.for-main {
  @include break-max($break-xs) {
    & .card__header {
      width: 100%;
    }

    & .card__pills {
      left: 14px;
      width: calc(100% - 14px);
    }

    & .card__pill {
      margin-right: 0;
      max-width: 95%;
    }

    & .card__fav {
      bottom: auto;
      right: 16px;
      top: 175px;
    }

    & .pagination {
      margin-bottom: 70px;
    }

    .map-list__list {
      .card__pills {
        margin-top: 7px;
        position: static;
        width: 100%;
      }
    }
  }
}

.complex-filter_wrapper > .filtered {
  @include break-max($break-xs) {
    max-width: 100%;
  }
}

.mobile-filters.for-commercial {
  @include break-max($break-xs) {
    padding-top: 50px;

    .mobile-filters__title {
      z-index: 11;
    }

    .mobile-filters__content {
      padding-top: 5px;
    }

    .mobile-filters__show-all {
      padding-bottom: 10px;
    }

    .index-search__filter-options {
      justify-content: center;
    }
  }
}

.mobile-filters__show-all.for-agents {
  flex-direction: column;
  padding: 0 10px;

  .button.button__mobile-filters {
    border-radius: 5px;
    font-size: 15px;
    line-height: 20px;
    min-height: 44px;
    margin-top: 7px;
    margin-bottom: 20px;
    text-transform: none;
    width: 100%;
  }
}

.mobile-filters__gutter {
  flex-grow: 1;
  flex-basis: 100%;
}
