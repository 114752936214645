.h5 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: none;

  @include break-max($breakpoint-sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

.link {
  color: $color-1;

  transition: all 0.3s;
  &:hover {
    color: rgba($color-1, 0.6);
  }

  &:active {
    color: rgba($color-1, 0.5);
  }

}

.text {
  font-size: 16px;
  line-height: 24px;
  @include break-max($breakpoint-md) {
    font-size: 14px;
    line-height: 18px;
  }
}

.button {
  display: inline-flex;
  align-items: center;
}

.big-text {
  font-weight: 300;
  @include break-max($breakpoint-md) {
    line-height: 21px;
  }
}

.constructor__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  
  &_admin {
    margin-top: 35px;
    flex-direction: row;
    align-items: flex-start;
  }
}



.constructor__main {
  padding-top: 60px;
  flex: 1 0 auto;

  @include break-max($breakpoint-md) {
    padding-top: 50px;
  }

  @include break-max($breakpoint-xs) {
    &::before {
      content: "";
      background: $color-brand-1;
      height: 50px;
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
    }

    &.for-admin {
      padding-top: 20px;
    }
  }
}

.constructor__main_100vh {
  max-height: 100vh;
  overflow-y: hidden;
  @include break-max($breakpoint-sm) {
    min-height: 100vh;
  }
  @include break-max($breakpoint-xs) {
    max-height: 100vh;
    overflow-y: auto;
  }

  .promo {
    min-height: calc(100vh - 50px);
  }

  .background__pyramid {
    @include break-max($breakpoint-md) {
      min-height: 100%;
    }
  }
}

.constructor__main_full-page {
  padding: 0;

  &::before {
    display: none;
  }
}

.constructor__socials {
  display: flex;

  @include break-max($breakpoint-xs) {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 355px;
  }
}


.constructor__header {
  background: $color-2;
  color: $color-white;
}

.constructor__background {
  position: relative;

  @include break-max($breakpoint-xs) {
    overflow: hidden;
  }
}

.constructor__container {
  width: 1500px;
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @include break-max($breakpoint-md) {
    width: 1230px;
  }
  @include break-max($breakpoint-sm) {
    width: 1110px;
  }

  @include break-max($breakpoint-xs) {
    padding: 0 10px;
    width: 100%;
  }

}

.constructor__box {
  position: relative;
  z-index: 2;
  width: 1220px;
  max-width: 100%;
  margin: 0 auto;

  @include break-max($breakpoint-md) {
    width: 996px;
  }

  @include break-max($breakpoint-sm) {
    width: 810px;
  }
  @include break-max($breakpoint-xsm) {
    width: 605px;
  }
  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.constructor__section {
  margin-bottom: 100px;
  @include break-max($breakpoint-md) {
    margin-bottom: 60px;
  }
  @include break-max($breakpoint-xsm) {
    margin-bottom: 50px;
  }
}

.constructor__section_gray {
  padding-top: 90px;
  padding-bottom: 100px;
  @include break-max($breakpoint-md) {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  @include break-max($breakpoint-xsm) {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 50px;
  }
}

.constructor__section_no-margin {
  margin-bottom: 0;
  border-top: 1px solid $color-brand-opaque-20;
}


.constructor__section-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 65px;
  text-align: center;

  @include break-max($breakpoint-xs) {
    margin-bottom: 35px;
  }
}

.constructor__title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  &::before, &::after {
    content: '';
    width: 315px;
    height: 1px;
    background-color: $color-brand-opaque-30;
    @include break-max($breakpoint-md) {
      width: 305px;
    }
    @include break-max($breakpoint-sm) {
      width: 165px;
    }
    @include break-max($breakpoint-xsm) {
      width: 125px;
    }
    @include break-max($breakpoint-xs) {
      width: 57px;
    }
  }
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }

  &.typical__title {
    @include break-max($breakpoint-xs) {
      justify-content: center;
      &::after, &::before {
        display: none;
      }
    }
  }
}

.constructor__subtitle {
  width: 830px;
  max-width: 100%;

  margin-top: 50px;

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.constructor__socials {
  .icon {
    fill: $color-white;
    width: 22px;
    height: 22px;
    transition: all 0.3s;
    &:hover {
      fill: rgba($color-1, 0.9);
    }

    &:active {
      fill: rgba($color-1, 0.8);
    }


    @include break-max($breakpoint-xsm) {
      width: 18px;
      height: 18px;
    }

    @include break-max($breakpoint-xs) {
      width: 22px;
      height: 22px;
    }
  }

  li {
    margin-right: 20px;
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-2px);
    }
    &:last-child {
      margin-right: 0;
    }
  }

  // a:hover .icon {
  //   fill: $color-white;
  // }
}

.constructor__socials_banner {

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    width: 32px;
    height: 32px;
    fill: $color-white;
    &:hover,
    &:active {
      fill: $color-white;
    }
    @include break-max($breakpoint-sm) {
      width: 24px;
      height: 24px;
    }
    @include break-max($breakpoint-xs) {
      width: 18px;
      height: 18px;
    }
  }
}

.constructor__socials_promo {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  order: 2;
  width: 100%;

  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
  }

  &::after {
    content: "";
    flex: 0 1 31%;
  }
}

li.constructor__social-btn {
  border-radius: 5px;
  position: relative;
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 0;
  margin-bottom: 7px;
  width: calc((100% - 16px) / 3);

  &.is-one-social {
    width: 100%;
  }

  a {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  &:nth-child(2),
  &:nth-child(5) {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover {
    transform: none;
  }

  svg.icon {
    fill: $color-white;
    position: absolute;
  }

  .icon_social-tg {
    height: 18px;
    width: 22px;
  }

  .icon_social-wa {
    height: 26px;
    width: 26px;
  }

  .icon_social-ig {
    height: 20px;
    width: 20px;
  }

  .icon_social-vk {
    height: 14px;
    width: 22px;
  }

  .icon_social-fb {
    height: 20px;
    width: 8px;
  }

  .icon_social-vb {
    height: 18px;
    width: 18px;
  }
  .icon_site {
    height: 30px;
    width: 30px;
  }

  &_ig {
    background-image: $color-ig;
  }
  &_vk {
    background: $color-vk;
  }
  &_fb {
    background: $color-fb;
  }
  &_tg {
    background: $color-tg;
  }
  &_wa {
    background: $color-wa;
  }
  &_vb {
    background: $color-viber;
  }

}

.constructor__social-circle {
  position: relative;
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;

  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

 object {
   width: 70px;
   height: 70px;
   cursor: pointer;
   @include break-max($breakpoint-sm) {
     width: 60px;
     height: 60px;
   }
   @include break-max($breakpoint-xs) {
     width: 40px;
     height: 40px;
   }
 }

 @include break-max($breakpoint-sm) {
   width: 60px;
   height: 60px;
 }
 @include break-max($breakpoint-xs) {
   width: 40px;
   height: 40px;
 }

 &_ig {
   background-image: $color-ig;
 }
 &_vk {
   background: $color-vk;
 }
 &_fb {
   background: $color-fb;
 }
 &_tg {
   background: $color-tg;
 }
 &_wa {
   background: $color-wa;
 }
 &_viber {
   background: $color-viber;
 }
}

.constructor__btn {
  text-align: center;
  justify-content: center;
  padding: 17px 42px;
  margin-top: 50px;

  background: $color-1;
  color: $color-white;
  border-radius: 5px;

  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;

  transition: all 0.3s;


  @include break-max($breakpoint-md) {
    margin-top: 40px;
    font-size: 13px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 30px;
    font-size: 12px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 11px;
    border-radius: 5px;
    width: 100%;
    max-width: 355px;
  }


  &:hover {
    background: rgba($color-1, 0.9);
  }

  &:active {
    background: rgba($color-1, 0.8);
  }

  &_transparent {
    background: transparent;
    color: $color-2;
    border: 1px solid $color-1;

    &:hover {
      color: $color-white;
      background: $color-1;
      border-color: transparent;
    }
  }

}

.slider-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 9;
}

.slider-nav__wrapper {
  display: flex;
  justify-content: space-between;

}

.slider-nav__item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
  border-radius: 50%;
  background: $color-white;
  outline: none;

  @include break-max($breakpoint-xs) {
    box-shadow: none;
    background: none;
  }

  &:hover {
    background: $color-brand-opaque-20;
    @include break-max($breakpoint-xs) {
      box-shadow: none;
      background: none;
    }
  }

  &:active {
    background: $color-brand-opaque-30;
    @include break-max($breakpoint-xs) {
      box-shadow: none;
      background: none;
    }
  }
}

.color-test {
  position: fixed;
  bottom: 0;
  z-index: 99;
  button {
    padding: 10px;
    cursor: pointer;
  }
}


.theme-test {
  position: fixed;
  bottom: 35px;
  z-index: 99;
  button {
    padding: 10px;
    cursor: pointer;
  }
}

// constructor_color-yellow;
// black text color for yellow themes
&_color-yellow {
  .background_rect ~ .promo,
  .background_oval ~ .promo {
    .promo__btn {
      color: $color-2;
    }
  }

  .constructor__btn,
  .constructor__btn_transparent:hover,
  .header__callback-btn,
  .types-tabs__item.is-active
   {
    color: $color-2 !important;
  }
  
  .cards__item_highlight .obj-card__title {
    color: $color-2 !important;
  }

  .co__manager-btn,
  .co__video-panel {
    color: $color-2 !important;
    font-weight: bold;
  }

  .co__video-icon path {
    fill: $color-2 !important;;
   }

  .background_sphere ~ .promo {
    .promo__label,
    .promo__text,
    .promo__sign {
      color: $color-2 !important;
    }

    .promo__btn {
      color: $color-white !important;
    }
  }

  .public__map-button {
    .button {
      color: $color-2 !important;
    }

    .icon {
      fill: $color-2 !important;
    }
  }

  #myMap, #dist-map-container {
    & [class*="-hint__text"] {
      color: $color-2 !important;
    }

  }

  .inner-form__submit {
    color: $color-white !important;
  }

  .inner-form__title {
    color: $color-2 !important;
  }

  .inner-form__checkbox .checkbox__label {
    color: $color-2 !important;
  }

  .inner-form__agreement:hover {
    color: $color-2 !important;
  }

  .inner-form__input .input__control {
    color: $color-2 !important;
  }

  .inner-form__input .input__control::placeholder {
    color: $color-2 !important;
  }

  .link.inner-form__agreement {
    color: $color-brand-1 !important;
  }

  .inner-form__checkbox-box .checkbox__marker-icon {
    fill: $color-2 !important;
  }

  .contacts-socials__icon {
    svg {
      fill: $color-2 !important;
    }
  }
}

&_color-red .constructor__socials .icon {
  fill: $color-white !important;
}

// Slider styles

.constructor__slider {
  @include break-max($breakpoint-xs) {
    margin-bottom: 25px;
    position: relative;
    width: 100%;
  }
}

.constructor__slider-wrap {
  @include break-max($breakpoint-xs) {
    margin-right: -10px;
  }
}

.complex__slider-thumbnails.for-constructor {
  @include break-max($breakpoint-xs) {
    margin-top: 0px;
  }
}

.complex__slider-info.for-contstructor {
  @include break-max($breakpoint-xs) {
    right: 0;
    bottom: -35px;
  }
}

.constructor__slider .tns-carousel {
  display: flex;
  padding: 20px;

  @include break-max($breakpoint-xs) {
    padding: 20px 0 35px;
  }
}

.constructor__slider  .tns-ovh {
  @include break-max($breakpoint-xs) {
    margin-top: -20px;
    margin-left: -10px;
    padding-left: 10px;
  }
}

.constructor__slider .tns-carousel .tns-item {
  display: flex;
  flex-grow: 1;
  margin-right: 25px;

  @include break-max($breakpoint-xs) {
    border: none;
    display: inline-block;
    margin-right: 0;
    padding: 0;
    vertical-align: top;
    white-space: normal;
  }
}

.constructor__slider .tns-item.tns-slide-active {
  @include break-max($breakpoint-xs) {
    margin-right: -20px;
  }
}

.block.tns-item {
  background-color: $c-gray_m;
  border: none;
}

.advantages__item-inner,
.services__item-inner {
  @include break-max($breakpoint-xs) {
    // box-shadow: 0px 10px 40px rgba(148, 134, 204, 0.15);
    background-color: $c-gray_m;
    border-radius: 15px;
    height: 100%;
    padding: 30px;
    width: 90%;
  }
}

.banks__slider {
  @include break-max($breakpoint-xs) {
    margin-bottom: 50px;
  }
}

.public__map-button {
  .button {
    align-items: center;
    display: flex;
    height: 50px;
    padding: 0 20px;
  }

  @include break-max($breakpoint-sm) {
    margin-left: 14px;

    .button {
      height: 52px;
      width: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    .icon {
      margin: 0;
    }
  }

  &.for-laptop {
    position: static;
  }


  @include break-max($breakpoint-xs) {
    box-shadow: none;
    margin-left: 14px;
    position: static;
    flex-shrink: 0;
    // width: 125px;

    &.for-list {
      position: fixed;
    }

    .button {
      box-shadow: none;

      height: 52px;
      width: 52px;
    }
  }
}

.complex__main {
  @include break-max($breakpoint-xs) {
    position: relative;
  }
}

.complex__address-map {
  @include break-max($breakpoint-xs) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.complex__title {
  @include break-max($breakpoint-xs) {
    max-width: 80%;
  }
}

.modal-form__submit {
  @include break-max($breakpoint-xs) {
    bottom: 20px;
    position: fixed;
    left: 10px;
    right: 10px;
  }
}

.promo__position {
  @include break-max($breakpoint-xs) {
    width: 230px;
  }
}

.background_rect {
  .background__right {
    @include break-max($breakpoint-md) {
      width: 43%;
    }
    @include break-max($breakpoint-xsm) {
      width: 50%;
    }
    @include break-max($breakpoint-xs) {
      right: -40px;
    }
  }
}

.info__popup {
  @include break-max($breakpoint-xs) {
    right: -115px;
    top: 30px;

    &::after, &::before {
      display: none;
    }
  }
}

.admin.constructor__section {
  @include break-max($breakpoint-xs) {
    overflow: hidden;
  }
}

.admin__menu.is-mobile .constructor__container {
  @include break-max($breakpoint-xs) {
    padding-right: 0;
  }
}

.constructor__btn.map-card__details-button {
  margin-left: 0 !important;
  margin-top: 20px;
}

.calculator__result-btn .constructor__btn {
  margin-top: 0;
}