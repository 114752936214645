@import '../../styles/variables';
@import '../../styles/mixins';

.common-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &__title {
    display: flex;
  }

  &__chevron {
    margin-right: 20px;
    padding: 5px 10px;
    margin-left: -10px;
    height: max-content;
    cursor: pointer;
  }

}

.common-title {
  max-width: 728px;
}

.deal-content {
  max-width: 728px;
}

.deal-wrapper {
  position: relative;
}

.app-tabs {
  border-bottom: 1px solid $gray-dark;
  margin-top: 21px;
}

.app-tabs-list {
  display: flex;

  &__item {
    min-width: 140px;
    text-align: center;
    padding: 10px 30px;
    position: relative;
    cursor: pointer;

    &--active {
      &:before {
        content: '';
        height: 3px;
        width: 100%;
        background-color: $color-red-bottom-border;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
      }

    }
  }

}

.sf__detail-col_for-object {
 .contact-card {
   border: none;
   padding: 0;
 }
}























