@import "../../../styles/variables";
@import "../../../styles/mixins";

.nottifications-container {

  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  width: 406px;
  background-color: $color-white;
  z-index: 9999;
  padding: 26px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: $color-brand-2;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);

  transform: translateX(200%);
  transition: all 0.3s;

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.nottifications-container.is-showing {
  transform: translateX(0);
}

.nottifications {

  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
  width: 376px;
  padding-right: 30px;
  background-color: $color-white;
  z-index: 9999;
  padding-top: 26px;
  padding-bottom: 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: $color-brand-2;

  transform: translateX(200%);
  transition: all 0.3s;

  @include break-max($breakpoint-xs) {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;


    svg {
      cursor: pointer;

      path {
        transition-duration: .3s;
      }

      &:hover path {
        stroke: #FF2D23;
      }
    }
  }

  &__header-h {
    @include h5-L();
  }

  &__close-nottifications {
    display: flex;
    align-items: center;

    span.link {
      margin-right: 15px;
    }
  }

  &__stub {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-title {
      @include h6-L();
      color: $gray-dark-2;
      margin-bottom: 12px;
    }

    &-icon {
      flex-shrink: 0;
      margin-bottom: 12px;
      margin-top: -30px;
    }

    &-text {
      text-align: center;
      max-width: 240px;
      color: $gray-dark-2;
    }
  }

  .tabs {
    height: 100%;
    .tab-btn::after {
      left: 0;
      bottom: 0;
    }
    .tab-bar {
      margin-bottom: 0;
      overflow: auto;
    }

    .tab-bar::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    .tab-content {
      height: calc(100% - 60px);
      margin-top: 50px;
    }
    .tab-list {
      border-bottom: none;
      min-height: 50px;
      width: 100%;
      overflow: auto;
      position: absolute;
      padding-right: 30px;
    }
    .tab-list:after {
      content: '';
      display: block;
      height: 50px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.3) -100%, rgba(255, 255, 255, 0.99) 100%);
      width: 30px;
      position: fixed;
      right: 0;
    }
    .tab-list::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    .tab-btn {
      min-width: auto;
      font-weight: normal;
      padding-bottom: 7px;
      border-bottom: 1px solid #DFE1E9;
    }
  }

}

.nottifications.is-showing {
  transform: translateX(0);
}

.nottifications-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition-duration: .3s;

  &__item {
    padding: 12px;
    cursor: pointer;
    border-radius: 5px;

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      @include h6-L();
      display: flex;
      align-items: center;
    }

    &-text {
      color: $gray-dark-2;
      margin-top: 5px;
    }

    &-status {
      width: 8px;
      height: 8px;
      border-radius: 50px;
      background-color: $color-red-bottom-border;
      margin-right: 14px;
    }

    &:hover {
      background-color: $gray-light-3;
    }
  }
}
.nottifications-list__item_new {
  background-color: $gray-light;
  margin-bottom: 5px;
  margin-top: 5px;
}

.nottifications-aside.presentation__aside {
  max-height: calc(100vh - 100px);
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  height: 100%;

  .simplebar-content {
    padding: 0!important;
  }

  .simplebar-track.simplebar-vertical {
    margin-right: -27px;
  }

  .simplebar-mask {
    margin-bottom: 40px;
    margin-top: 10px;
  }
}

.nottifications-skeleton {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  .skeleton-box {
    border-radius: 5px!important;
    height: 58px!important;
    overflow: hidden;

    .sf-card__pic {
      height: 58px!important;
      border-radius: 0;
      background-color: transparent;
    }
  }
}
