.event {

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 130px;

    &-col {
      flex-basis: 100%;
    }
  }

  &__flex-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__title {
    @include grid-size-p(7);
    @include grid-col-p;
    margin-top: 60px;
    margin-bottom: 50px;

    @include break-max($breakpoint-md) {
      @include grid-size-p(9);
    }

    @include break-max($breakpoint-sm) {
      @include grid-size-p(12);
    }
  }

  &__info {
    @include grid-size-p(10);
    @include grid-shift-left-p(1);
    margin-bottom: 50px;

    border-radius: 5px;
    background-color: $color-brand-2;
    padding: 40px 60px 15px;

    @include break-max($breakpoint-md) {
      @include grid-size-p(12);
      margin-left: 0;
    }

    &-grid {
      @include grid;
      justify-content: space-between;
    }

    &-col {
      @include grid-col-p;
      color: $color-white;
      max-width: 330px;
      margin-bottom: 25px;
    }
  }

  &__label {
    color: $color-brand-3;
    margin-top: 15px;
  }

  &__speaker {
    display: flex;

    &-tag {
      margin-left: 20px;
      background-color: $color-red;
      border-radius: 100px;
      padding: 6px 12px;
      height: 29px;
    }
  }

  &__show-map {
    @include link;
  }

  &__details {
    @include grid-size-p(6);
    @include grid-shift-left-p(1);
    padding-left: 60px;
    padding-right: 60px;

    @include break-max($breakpoint-md) {
      @include grid-size-p(7);
      margin-left: 0;
    }

    &-text {
      padding-bottom: 40px;

      & ul {
        margin-bottom: 20px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 25px;
    }

    & &-list {
      list-style: disc;
      padding-left: 16px;
      padding-bottom: 40px;
    }

    & &-item {
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }

  &__contacts {
    display: flex;
    border-top: 1px solid $color-brand-opaque-20;
    padding-top: 30px;

    @include break-max($breakpoint-sm) {
      display: none;
    }

    &-name {
      font-weight: bold;
    }

    &-phone, &-email {
      margin-left: 25px;

      & a {
        color: $color-brand-2;
      }

      & .icon {
        font-size: 20px;
      }
    }
  }

  &__registration {
    @include grid-size-p(4);
    padding-left: 60px;
    padding-right: 60px;

    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include break-max($breakpoint-md) {
      @include grid-size-p(5);
      margin-left: 0;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 0;
    }


    &-info {
      display: none;
      margin-bottom: 20px;
    }

    &.registered {
      .event__registration {
        &-info {
          display: block;
        }
        &-submit {
          display: none;
        }
      }
    }

    &-done {
      color: $color-red;
      margin-bottom: 20px;
    }

    &-cancel {
      display: inline-block;
    }

    &-availability {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-brand-opaque-20;
      margin-bottom: 25px;
    }

    &-date {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-brand-opaque-20;
    }

    &-btns {
      display: flex;
      margin-top: 30px;

      @include break-max($breakpoint-sm) {
        display: block;
      }
    }

    &-submit {

      & .button {
        padding: 22px 28px;
      }
    }

    &-add {
      display: flex;
      align-items: center;
      margin-left: 25px;
      color: $color-brand-3;
      cursor: pointer;
      white-space: nowrap;

      @include break-max($breakpoint-sm) {
        margin-top: 15px;
        margin-left: 0;
      }

      & .button {
        margin-right: 10px;
      }

      &:hover {
        & .button {
          background-color: $color-brand-opaque-20;
        }
      }
    }

    &-label {
      color: $color-brand-3;
      margin-top: 15px;
    }
  }

  &__form {
    display: none;
    width: 100%;
    margin-top: 20px;
    padding: 25px 20px;
    border-radius: 5px;
    background-color: $color-brand-opaque-8;
    text-align: left;

    &-row {
      margin-bottom: 15px;
    }

    &-submit {
      margin-top: 25px;
      display: flex;
      justify-content: center;

      & .button {
        padding: 22px 28px;
      }
    }
  }

  &__records {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 35px;

    &-label {
      font-weight: bold;
      line-height: 28px;
      margin-right: 25px;
    }
  }

  &__recorded {
    &-label {
      color: $color-brand-3;
    }

    &-theme {
      font-weight: bold;
    }
  }
}

.events__event-info {
  @include break-max($break-xs) {
    flex-direction: column;
  }
}
