@import '../../../styles/variables';
@import '../../../styles/mixins';

.action-hint {
  border-radius: 20px;
  background-color: $gray-light;
  padding: 16px 20px;
  display: flex;

  svg {
    flex-shrink: 0;
    margin-right: 15px;
  }
}

.action-big-hint {
  display: flex;
  padding: 30px;
  border-radius: 20px;
  background-color: $gray-light;
  justify-content: space-between;
  align-items: center;

  &__main {
    display: flex;

    svg {
      flex-shrink: 0;
      margin-right: 20px;
    }
  }
  &__interval {
    margin-top: 4px;
    color: $gray-dark-2;
    b {
      margin-left: 6px;
      color: black;
    }
  }
  &__more {
    cursor: pointer;
    span {
      color: $gray-dark-2;
    }
  }
}

.error-report-link {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-top: 15px;

  svg {
    margin-right: 5px;
  }
}

.error-report {
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .h3 {
    text-align: center;
    margin-bottom: 25px;
  }

  &__area {
    width: 100%;
    margin-bottom: 20px;

    .textarea {
      textarea {
        background-color: $gray-light;
        box-shadow: none;
      }
    }
  }

  .new-alert {
    .new-alert__title {
      margin-bottom: 0;
      margin-top: 3px;
    }
  }
}


.stick {
  //width: 470px;
}
