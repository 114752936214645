.cards {
  padding-top: 50px;
  padding-bottom: 60px;

    &__header {
      align-items: center;
      margin-bottom: 36px;
    }

  @include break-max($break-xs) {
    padding-bottom: 0;
    padding-top: 20px;
  }

  &__controls {
    @include grid-size(5);
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include break-max($break-lg) {
      @include grid-size(6, $grid-gutter-md);
    }
    @include break-max($break-md) {
      @include grid-size(12, $grid-gutter-sm);
    }

    .public__map-button .button_view_outline-with-icon-invert {
      box-shadow: none;
      &:hover {
        background: none;
        color: $color-red;

        .icon {
          fill: $color-red;
        }
      }
    }
  }

  &__sort {
    width: 310px;
    display: flex;
    align-items: center;
  }
  &__sort_icon {
    position: relative;
    width: 12px;
    height: 12px;
    margin-right: 11px;
  }
  &__sort_icon_desc {
    svg {
      transform: rotate(180deg);
    }
  } 
  &__item {
    @include grid-size(4);
    margin-bottom: #{$grid-gutter};

    @include break-max($break-lg) {
      @include grid-size(4, $grid-gutter-md);
      margin-bottom: #{$grid-gutter-md};
    }

    @include break-max($breakpoint-md) {
      @include grid-size(12, $grid-gutter-md);
      margin-bottom: #{$grid-gutter-sm};
    }

    @include break-max($break-sm) {
      @include grid-size(12, $grid-gutter-sm);
      margin-bottom: #{$grid-gutter-sm};
    }

    &_no-border {
      border: none;
    }
  }

  &__pagination {
    @include grid-size(12);
    padding-top: 20px;
  }

  &__map-cards {
    // width: calc(100% / 12 * 3);
    width: 376px;
    background-color: $color-white;
    align-self: stretch;
    box-shadow: 10px 0 10px $color-brand-opaque-10;
    margin-right: 0;
    position: relative;
    z-index: 1;
    max-height: 900px;
    padding-right: $grid-gutter / 2;
    overflow: hidden;

    @include break-max($break-lg) {
      padding-right: $grid-gutter-md / 2;
    }

    @include break-max($break-md) {
      padding-right: $grid-gutter-sm / 2;
    }

    @include break-max($break-xs) {
      padding-right: 0;
      width: 100%;
    }

    &-list {
      overflow-y: auto;
    }
  }

  // &__map {
  //   margin-left: 0;
  //   @include grid-size(8);
  //   align-self: stretch;
  // }
}
