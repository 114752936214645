.no-border {
  border: none;
}

.complex__info-item-value {
  font-size: 20px;
  line-height: 26px;
}

.filtered {
  background-color: transparent;
}

.index-search__filter-applied.readonly .index-search__filter-inc:after {
  display: none;
}

.public__title {
  margin-bottom: 25px;

  @include break-max($break-sm) {
    margin: 30px 20px;
  }

  @include break-max($break-xs) {
    margin: 20px 10px 18px;
  }
}

.public__params {
  background-color: rgba(143, 149, 172, 0.08);
  padding: 25px 45px;
  border-radius: 5px;
  margin-bottom: 20px;

  @include break-max($break-sm) {
    padding: 26px 20px;
  }

  @include break-max($break-xs) {
    padding: 16px 10px;
    border-radius: 0;
  }
}

.public__map-button {
  margin-left: auto;
  z-index: 2;

  @include break-max($break-xs) {
    bottom: 10px;
    margin: 0;
    position: fixed;
    z-index: 11;
  }
}

.assignations-flats__header,
.public__map-button {
  @include break-max($break-xs) {
    .button_view_outline-with-icon-invert {
      align-items: center;
      background: $color-white;
      border: none;
      box-shadow: 0px 4px 20px $color-brand-opaque-30;
      color: $color-brand-2;
      display: flex;
      height: 44px;
      line-height: 44px;
      justify-content: center;
      text-align: center;
      width: 170px;

      & .icon {
        fill: $color-brand-2;
      }
    }
  }
}

.controls-wrapper {
  background: linear-gradient(rgba($color-white, 0), rgba($color-white, 0.5) 20%, $color-white 70%);
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 11;

  .public__map-button {
    position: static;
    width: calc(50% - 6px);
  }

  .button.button_view_outline-with-icon-invert,
  .button.button_view_ghost {
    justify-content: center;
    width: 100%;
  }
}

.public-header {
  align-items: center;
  background-color: $color-brand-2;
  color: $color-white;
  display: flex;
  height: 80px;
  padding: 0 65px;

  @include break-max($break-md) {
    height: 70px;
    padding: 0 40px;
  }

  @include break-max($break-sm) {
    height: 60px;
    padding: 0 20px;
  }

  @include break-max($break-xs) {
    height: 50px;
    padding: 0 15px 0 10px;
    justify-content: space-between;
  }

  .presentation__footer-item_value a {
    color: $color-white;
  }
}

.public-header__contacts {
  color: $color-white;
  font-weight: bold;
}

.public-header__wrapper {
  margin: 0 auto;
  width: 1600px;

  @include break-max($break-xs) {
    width: 100%;
  }
}

.public-header__menu {
  display: flex;
  flex-grow: 1;

  @include break-max($break-xs) {
    background-color: $color-white;
    flex-direction: column;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    padding: 32px 50px 0;
    transform: translateX(100%);
    transition: 0.2s;
    z-index: 11;

    & .public-header__contacts {
      color: $color-brand-2;
      display: block;
      margin-bottom: 20px;
    }

    & .presentation__footer-item {
      flex-grow: 0;
    }
  }

  &.public-header-is-open {
    transform: translateX(0);
  }
}

.public-header__cross {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 16px;
  stroke: #2A2A2A;
}

.public-header__menu-button {
  cursor: pointer;
  stroke: $color-white;
  height: 17px;
  width: 19px;
}

.public-header__overlay {
  background-color: $color-brand-2;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11;
}
