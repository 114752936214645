@import "../../styles/variables";
@import "../../styles/mixins";

.common-filters {
  display: flex;
  margin-top: 30px;
  gap: 20px;

  &__dropdowns {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    height: 50px;

    .custom-select__selected {
      height: 100%;
      min-width: 190px;
    }
  }

  &__search {
    width: 400px;
  }
}

.items-filters {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    display: flex;
    gap: 25px;
    .link {
      color: $color-brand-2;
      display: flex;
      align-items: center;
      white-space: nowrap;
      svg {
        margin-right: 8px;
      }
      svg path {
        transition-duration: .3s;
      }
      &:hover {
        color: #cf2d23;
        svg path {
          stroke: #cf2d23;
        }
      }
    }
  }

  &__left {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__addcompilation-btn {
    display: flex;
    background-color: #8790E5;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: .3s;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    padding-right: 18px;

    &:hover {
      background-color: #6f76bb;
    }
    &.primary {
      background-color: #EF372E;
      border: 1px solid #EF372E;
      &:hover {
        background-color: #e6473f;
      }
    }
    &.secondary {
      background-color: white;
      border: 1px solid #EF372E;
      color: #EF372E;
      * {
        stroke:  #EF372E;
      }
      &:hover {
        background-color: #e6473f;
        color: white;
        * {
          stroke:  white;
        }
      }
    }
    &.blank {
      background-color: white;
      border: 1px solid white;
      color: #2C2E3E;
      * {
        stroke:  #2C2E3E;
      }
    }
  }

}
.items-filters__addcompilation-btn_link:hover {
  color: #EF372E;
}

.favorites-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  justify-content: flex-start;
  margin-bottom: 55px;

  &__over-item {
    display: flex;
    gap: 20px;
    width: 100%;
    cursor: pointer;
    align-items: flex-start;
  }

  &__item {
    background-color: $gray-light;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    min-height: 220px;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
  }

  &__item-left {
    display: flex;
    gap: 30px;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-img {
    border-radius: 5px;
    width: 260px;
    height: 160px;
    object-fit: cover;
  }

  &__item-price {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 3px;
  }

  &__item-address {
    color: $gray-dark-2;
    margin-bottom: 3px;
  }

  &__item-remove {
    z-index: 3;
    span {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }

    svg {
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  &__item-tags {
    display: flex;
    gap: 10px;

    li {
      padding: 4px 11px 5px 11px;
      color: $gray-dark-2;
      cursor: default;
      font-size: 13px;
      background-color: $gray-light-3;
      border-radius: 50px;
    }
    li.label_green {
      color:#2cba2e;
      background-color:rgba(44,186,46,0.2);
    }
    li.label_red {
      color:#FF2D23;
      background-color:rgba(255,45,35,0.1);
    }
  }

  .favorites-list__over-item_nopointer {
    cursor: default;
  }

  .favorites-list__item-addcompilation {
    display: inline-flex;
    background-color: transparent;
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid $blue;
    color: $blue;
    align-items: center;
    height: 32px;
    padding-left: 8px;
    padding-right: 18px;
    cursor: pointer;
    padding-bottom: 1px;
    white-space: nowrap;

    svg {
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &__item-action {
    display: flex;
    gap: 20px;
  }

  &__item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-dynamic {
    display: inline-flex;
    margin-left: 10px;
    position: relative;
    .sf__block-hint {
      width: 400px;
      pointer-events: auto;
      right: auto;
      bottom: 15px;
      left: -180px;
      opacity: 1;
      display: none;
      z-index: 1;
    }
    &:hover {
      .sf__block-hint {
        display: block;
        z-index: 3;
      }
    }
  }


}

.favorite-note {
  flex-basis: 100%;
  margin-left: 290px;

  &__create-note {
    margin-top: 20px;
  }
  &__ready-note {
    margin-top: 20px;
  }

  .textarea__control {
    border-radius: 5px;
    background-color: white;
  }

  &__label {
    color: $gray-dark-2;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
  }

  &__actions {
    display: flex;
    gap: 20px;

    button {
      color: #8790E5;
      box-shadow: none;
      background-color: transparent;
      padding: 10px 0px 0px 0px;
      cursor: pointer;
      transition-duration: .3s;

      &:hover {
        color: #7179c7;
      }
    }
  }

  &__ready-note {
    padding: 16px 10px;
    border-radius: 5px;
    background-color: $gray-light-3;
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;
  }

  &__ready-actions {
    display: flex;
    gap: 15px;
    margin-left: 60px;

    span {
      cursor: pointer;
    }
  }
}




.favorites-list__item-dynamic_hint .sf__block-hint {


  .sf__info-key {
    font-size: 14px;
    font-weight: normal;
  }
  .sf__info-value {
    font-size: 14px;
  }
  .sf__info-row {
    margin-bottom: 12px;
  }
}

.favorites-list__item_sold {
  position: absolute;
  background-color: rgba(255,255,255,0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.checkbox_favorite {
  align-items: flex-start;
}

.favorites-list__item-img_apartments {
  object-fit: contain;
  background-color: white;
}

.favorites-list__item-img_apartments-public {
  cursor: pointer;
}

.compare-settings-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;
}

.pdf-modal {

  &__grid {
    display: flex;
    gap: 20px;
  }

  &__grid-item {
    width: 100%;

    .h3 {
      margin-bottom: 20px;
    }
  }
  &__setting-block {
    background-color: $gray-light;
    border-radius: 20px;
    padding: 30px;
  }
  .compare-view-settings {
    margin-top: 0;
  }

  &__share {
    max-width: 396px;
    margin: 30px auto;
  }
}



.crm-table__sf-controlsmodal.crm-table__sf-controlsmodal_compilation .modal__inner {
  padding: 30px;
  width: 420px;
}

.compilation-modal {

  &__create {
    margin-top: 5px;
  }
  &__create-link {
    color: #8790E5;
    transition-duration: .3s;
    cursor: pointer;

    &:hover {
      color: #7179c7;
    }
  }

  &__create-input {
    margin-top: 20px;
    .input_type_form .input__control {
      box-shadow: none;
      background-color: #F6F7F8;
    }
  }

  &__action-btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .btn_full + .btn_full {
      margin-top: 0;
    }
  }

}


.compilation-list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-img {
    border-radius: 5px;
    border: 1px solid #DFE1E9;
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }

  &__item-overcont {
    display: flex;
  }

  &__item-advcount {
   font-size: 13px;
    color: #777A89;
  }

  &__item-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__item-title {
    font-weight: 700;
  }

}


.hint-label {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .input__label_hint {
    cursor: pointer;
    color: #8790E5;
    transition-duration: .3s;

    &:hover {
      color: #7179c7;
    }
  }
}

.compilations-stub {

  &__icon {
    margin-top: 70px;
    text-align: center;
  }

  &__text {
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 15px;
  }

  &__btn {
    text-align: center;

    button {
      width: 230px;
    }
  }
}


.special-filter-drop.special-filter-drop_favorites {
  width: 370px;
}

.favorite-note__ready-note_hide {
  display: none;
}




.favorite-note__actions button.favorite-note__actions_disable-btn {
  color: #DFE1E9;
  pointer-events: none;
}


.calculator-commercial_favorite {
  padding: 0;
  .calculator__form {
    .calculator__item:nth-of-type(2n + 1) {
      margin-right: 0;
    }
    .calculator__item {
      width: 100%;
      flex-basis: auto;
    }
    .calculator__item#slideId {
      margin-bottom: 10px;
    }
  }

  .calculator-commercial__bottom {
    display: none;
  }
  .calculator__result-list {
    flex-direction: column;
    gap: 0px;
    grid-gap: 0px;
  }
  .calculator__result-list-item {
    margin-bottom: 0;
  }
  .calculator__result-list {
    margin-top: 0;
    margin-bottom: 0;
  }
  .calculator__result-title {
    padding-bottom: 10px;
  }
  .calculator__form {
    border-bottom: none;
  }
  .calculator__result {
    margin-bottom: 0;
    padding-top: 0;
  }
  .h3.calculator__result-list-value {
    margin-bottom: 0;
  }
  .mortgage-percents ul {
    margin-top: -6px;
  }
}

.calculator-commercial.calculator-commercial_favorite .calculator__crumbs {
  display: inline-block;
}

.favorite-note__actions button.favorite-note__actions_btn-disabled {
  pointer-events: none;
  color: #DFE1E9;
}

.common-filters_compilations {
  justify-content: space-between;
}

.common-title {
  &__blue-link {
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    color: #8790E5;
    margin-top: -7px;
    display: block;
    transition-duration: .3s;

    &:hover {
      color: #636dbd;
    }
  }
}

.compilation-public-header {
  background-color: $gray-light;
  padding: 55px 0px;
  text-align: center;
  margin-bottom: 40px;

  &__title {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__info-text {

  }
}

.compilation-public-form {
  background-color: #f6f7f8;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  margin-top: 85px;

  &__firm {
    color: #777A89;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  &__user {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__phone {
    margin-bottom: 30px;
  }
}

.crm-table__sf-controls.crm-table__sf-controls_shareOn {
  display: block;
}



.custom-radio {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.custom-radio label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.custom-radio input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #F6F7F8;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
}

.custom-radio input[type="radio"]:checked::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #8F95AC;
  border-radius: 50%;
  display: block;
  margin: 4px;
}

.compilation-public-top {
  padding: 7px 16px;
  border-radius: 10px;
  background-color: #ECEDF0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: -15px;
  justify-content: space-between;
  width: calc(100% - 20px);
  margin-left: 10px;

  &__left {
    display: flex;
    text-align: left;

    svg {
      margin-right: 12px;
    }
  }
}

.crm-table__sf-controlsmodal_mobilecompilationsmodal {
  .modal__inner {
    //display: none;
    background-color: transparent;
  }
}
.compilation-public-mobile-form {
  position: fixed;
  z-index: 1234;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F6F7F8;
  padding: 20px 10px;
  text-align: left;
  border-radius: 10px 10px 0 0;

  .h4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .compilation-public-header {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 0px;
  }
  .compilation-public-header__title {
    margin-top: 5px;
    margin-bottom: 15px;
  }



  .compilation-public-items {
    .favorites-list {

      .favorites-list__item {
        display: block;
        padding: 20px;
      }
      .favorites-list__item-left {
        display: block;
      }
      .favorites-list__item-img {
        width: 100%;
        height: 190px;
        cursor: pointer;
      }
      .h4.favorites-list__item-title {
        font-size: 18px;
      }
    }
    .common-title__blue-link {
      margin-top: 10px;
      width: 100%;
      background-color: #8790E5;
      color: white;
      border-radius: 5px;
      text-align: center;
      height: 44px;
      line-height: 44px;
      position: relative;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi40OTk4IDIyLjU5QzEyLjQ5OTggMjIuNTkgMjAuNTY1IDE3LjIxMzIgMjAuNTY1IDEwLjA0NDFDMjAuNTY1IDUuNTg5OCAxNi45NTQxIDEuOTc4ODggMTIuNDk5OCAxLjk3ODg4QzguMDQ1NDkgMS45Nzg4OCA0LjQzNDU3IDUuNTg5OCA0LjQzNDU3IDEwLjA0NDFDNC40MzQ1NyAxNy4yMTMyIDEyLjQ5OTggMjIuNTkgMTIuNDk5OCAyMi41OVpNMTIuNDk5OCAxMi43MzI2QzEzLjk4NDYgMTIuNzMyNiAxNS4xODgyIDExLjUyODkgMTUuMTg4MiAxMC4wNDQxQzE1LjE4ODIgOC41NTkzOCAxMy45ODQ2IDcuMzU1NzQgMTIuNDk5OCA3LjM1NTc0QzExLjAxNSA3LjM1NTc0IDkuODExMzkgOC41NTkzOCA5LjgxMTM5IDEwLjA0NDFDOS44MTEzOSAxMS41Mjg5IDExLjAxNSAxMi43MzI2IDEyLjQ5OTggMTIuNzMyNloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
        margin-right: 10px;
        display: inline-block;
        margin-bottom: -6px;
      }
    }
    .common-header__title {
      width: 100%;
      display: block;
    }

    .favorite-note {
      margin-left: 0;
    }

    .items-filters__buttons {
      margin-top: 10px;
    }

  }


  .pdf-modal__grid {
    display: block;
  }
  .pdf-modal__grid-item {
    margin-top: 30px;
  }
}

.compilation-list-simplebar .simplebar-track.simplebar-vertical {
  margin-right: -10px;
}


.favorites-list_public {
  .favorite-list_public__img-items {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    div {
      background-color: white;
      width: calc(100% / 3);
      border-radius: 5px;
      cursor: pointer;

      img {
        height: 50px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}



.favorites-list__item-dynamic.favorites-list__item-dynamic_mobile {

    .favorites-list__item-dynamic_hint {
      display: none;
    }
    .favorites-list__item-dynamic_hint-mobilebg {
      height: 100vh;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.2);
      display: block;

      .sf__block-hint {
        border-radius: 20px 20px 0 0;
      }
    }


  .sf__block-hint {
    position: fixed;
    left: 0;
    right: 0;
    width: auto;
    bottom: 0;
    padding: 20px 10px;


    .sf__info-row {
      border-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0;

      .sf__info-key {
        font-size: 14px;
        font-weight: normal;
      }
      .sf__info-value {
        font-size: 14px;
      }
    }
  }
}


.custom-radio.custom-radio_compare input[type="radio"] {
  background-color: white;
}
.custom-radio.custom-radio_compare input[type="radio"]:checked::before {
  background-color: #FF2D23;
}











