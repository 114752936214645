.add-fav-btn,
.add-compare-btn {
  display: flex;
  align-items: center;
  color: $color-brand-3;
  cursor: pointer;

  &.hollow {
    &.is-fav, &.is-compare {
      & .button_type_settings {
        background-color: $color-red;

        .icon {
          stroke: $color-white;
          fill: $color-red;
        }
      }
    }

    &:hover {
      & .button_type_settings {
        background-color: $color-red;

        .icon {
          stroke: $color-white;
          fill: $color-red;
        }
      }
    }
  }

  &.is-fav {
    .fav-attach {
      opacity: 1;
      visibility: visible;
      margin-left: 12px;
    }
  }

  & .button_type_settings {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $color-brand-opaque-10;
    transition: background-color $transition-default;
    margin-right: 15px;
    @include break-max($breakpoint-md) {
      margin-right: 10px;
    }

    & .icon {
      padding-top: 2px;
      fill: $color-brand-3;
      fill-opacity: 1;
      stroke: none;
      font-size: 20px;
      transition: fill $transition-default;
    }
  }


  &:hover {
    & .button_type_settings {
      background-color: $color-brand-opaque-10;
      & .icon {
        fill: $color-brand-3-hover;
      }
    }
  }

  &:active {
    & .button_type_settings {
      & .icon {
        fill: $color-red-active;
      }
    }
  }

  &.is-fav, &.is-compare {
    & .button_type_settings {
      & .icon {
        fill: $color-red;
      }
    }

    &:hover {
      & .button_type_settings {
        & .icon {
          fill: $color-red-hover;
        }
      }
    }

    &:active {
      & .button_type_settings {
        & .icon {
          fill: $color-brand-3-hover;
        }
      }
    }
  }
}

.add-fav-btn .card__fav-attachments {
  margin-left: 12px;

  .fav-attach {
    margin-left: 0;
  }
}
