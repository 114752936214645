.services {

}

.services__list {
  display: flex;
  justify-content: center;
}

.services__item {
  margin-right: 55px;
  &:last-child {
    margin-right: 0;
  }
  @include break-max($breakpoint-md) {
    margin-right: 0;
  }
}
