.footer {
  font-size: 16px;
  background: $color-2;
  padding: 54px 0 0;

  @include break-max($breakpoint-xs) {
    padding: 30px 0;
  }
}

.footer__title {
  font-weight: 700;
}

.footer__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include break-max($breakpoint-md) {
    align-items: flex-start;
  }
}

.footer__logo {
  margin-bottom: 0;
  margin-right: 45px;
  height: auto;
  object-fit: contain;

  @include break-max($breakpoint-xsm) {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

.footer__contacts {
  width: auto;
  margin: 0;
  margin-right: auto;
  @include break-max($breakpoint-xsm) {
    margin-bottom: 15px;
    width: 100%;
  }
}

.footer__contact-label {
  font-weight: bold;
  margin-bottom: 20px;
  @include break-max($breakpoint-xsm) {
    margin-bottom: 5px;
  }
}

.footer__adress {
  max-width: 280px;
  margin-left: auto;
  margin-right: 40px;
  @include break-max($breakpoint-sm) {
    margin-left: 0;
  }

  @include break-max($breakpoint-xs) {
    margin-right: auto;
  }
}

.footer__socials {
  margin-left: auto;

  @include break-max($breakpoint-md) {
    margin-left: 25px;
  }

  @include break-max($breakpoint-sm) {
    margin-left: auto;
  }

  @include break-max($breakpoint-xsm) {
    justify-content: flex-start;
    margin-left: 0;

    li {
      margin-bottom: 10px;
    }
  }

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.footer__socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background: transparentize($color-1, 0.8);
  border-radius: 50%;
  transition: all 0.3s;
  &:hover {
    background: transparentize($color-1, 0.9);
  }
}

.footer__phone {
  margin-bottom: 0;
  @include break-max($breakpoint-xsm) {
    font-size: 15px;
    line-height: 18px;
  }

}

.footer__box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include break-max($breakpoint-md) {
    width: 280px;
    max-width: 100%;
  }
}

.footer__left {
  display: flex;
  max-width: 280px;
  align-items: center;
  max-height: 70px;
  padding-top: 3px;

  @include break-max($breakpoint-xsm) {
    flex-direction: column;
    align-items: flex-start;
    max-height: none;
  }
}

.footer__right {
  display: flex;
  margin-left: auto;
  flex-grow: 1;

  @include break-max($breakpoint-sm) {
    width: 100%;
    margin-left: 0;
    margin-top: 25px;
  }

  @include break-max($breakpoint-xsm) {
    flex-direction: column;
    margin-top: 0;
    padding-top: 25px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 0;
    padding-top: 15px;
  }
}

.footer__bottom {
  flex-basis: 100%;
  margin-top: 40px;
  width: 100%;

  @include break-max($breakpoint-xsm) {
    margin-top: 35px;
  }
}

.footer__note {
  color: $color-brand-3;
  font-size: 13px;
  line-height: 16px;
  padding-bottom: 28px;

  @include break-max($breakpoint-xs) {
    font-size: 12px;
    line-height: 14px;
  }
}

.footer__toprealtors {
  align-items: center;
  // border-top: 1px solid rgba($color-1, 0.2);
  display: flex;
  height: 70px;
  flex-basis: 100%;
  justify-content: center;
  width: 100%;
}

.footer__contacts-flex {
  display: flex;
  @include break-max($breakpoint-md) {
    flex-direction: column;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
  }

  & > div:first-of-type {
    margin-right: 55px;
    @include break-max($breakpoint-md) {
      margin-right: 0;
      margin-bottom: 10px;
    }
    @include break-max($breakpoint-sm) {
      margin-bottom: 0;
    }
    @include break-max($breakpoint-xs) {
      margin-right: auto;
    }
  }
}

.footer__address {
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 22px;
  }
}
