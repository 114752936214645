@import "../../styles/variables";
@import "../../styles/mixins";

.payment {
  .custom-select__selected {
    background: $color-white;
    height: 50px;
    box-shadow: inset 0 0 0 1px $color-brand-opaque-20;
    }

  .custom-select.is-showing .custom-select__selected {
   background: none;
  }

  padding-top: 50px;
  padding-bottom: 70px;
  @include break-max($breakpoint-xs) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  &__content {
    padding-left: 80px;
    padding-right: 80px;
    max-width: 1200px;
    margin: 0 auto;

    @include break-max($breakpoint-sm) {
      max-width: 1000px;
    }

    @include break-max($breakpoint-xsm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include break-max($breakpoint-xs) {


    }



  }
}

.payment__h1 {
  font-size: 42px;

  @include break-max($breakpoint-sm) {
    font-size: 32px;
  }
  @include break-max($breakpoint-xs) {
    padding: 0 10px;
    font-size: 20px;
    line-height: 10px;
  }
}

.payment__container {
  display: flex;
  //padding-top: 50px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    padding: 0 10px;
  }
}

.payment__left {
  width: 400px;
  max-width: 100%;
  margin-right: auto;
  padding-top: 25px;

  @include break-max($breakpoint-sm) {
    //width: 250px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding-top: 15px;
  }
}

.payment__img {
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.payment__form {
  flex-shrink: 0;
  width: 505px;
  max-width: 100%;
  margin-top: -40px;

  @include break-max($breakpoint-sm) {
    width: 420px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 0;
  }

  .modal__grey-wrapper {
    margin-top: 0;
    border-radius: 20px;
    @include break-max($breakpoint-xs) {
      padding-top: 0px;
      padding-bottom: 25px;
      background-color: transparent;
    }
  }

  .modal-form {
    padding: 0 35px;

    @include break-max($breakpoint-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.payment__h3 {
  padding-bottom: 25px;
  @include break-max($breakpoint-xs) {
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}

.payment__text {
  padding-bottom: 25px;
  font-size: 15px;
  @include break-max($breakpoint-xs) {
    //padding-bottom: 35px;
  }
}

.payment__price-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: $color-brand-3;
}

.payment__sign {
  margin-top: 30px;
  padding-top: 12px;
  border-top: 1px solid $color-brand-opaque-20;
  font-size: 13px;
  line-height: 16px;
  color: $color-brand-3;
}

.modal-form__item.payment__submit {
  margin-left: 0;
  display: flex;
  .button {
    margin-left: auto;
    @include break-max($breakpoint-xs) {
      width: 100%;
    }
  }
}

.payment__price {
  @include break-max($breakpoint-xs) {
    font-size: 25px;
    line-height: 30px;
  }
}
