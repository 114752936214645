@import '../../styles/variables';
@import '../../styles/mixins';

.sms-hint {
  border-radius: 20px;
  margin-bottom: 20px;
  @include h6();
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 34px;
  padding-right: 34px;
  display: flex;
  justify-content: center;
  background-color: #F6F7F8;

  & svg {
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -3px;
  }

  &--sucess {
    background-color: $deal-completed-bg-status;
  }

  &--error {
    background-color: $deal-error-bg-status;
  }
}
