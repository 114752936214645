.checkbox-group {
  padding-right: 20px;

  &__item {
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox__box {
      background: none;
    }

    .checkbox.checkbox_type_btn .checkbox__box {
      width: 100%;
    }

    &:hover {
      background-color: $color-brand-opaque-10;

      & .filter__exclude-btn {
        display: block;
      }
    }

    &_single {
      margin-bottom: 14px!important;
    }

    &.is-hidden {
      display: none;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
