@import "../../styles/variables";
@import "../../styles/mixins";
.preview__header + .section .sor.sor_cashback {
  padding-top: 85px;
  @include break-max($breakpoint-sm) {
    padding-top: 60px;
  }
}

.sor.sor_cashback {
  color: $color-brand-1;
  .sor__sup {
    display: block;
    border-bottom: 1px solid $color-brand-opaque-20;
    color: $color-red;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    padding-bottom: 10px;

    @include break-max($breakpoint-xs) {
      text-align: center;
    }
  }

  .sor__box {
    padding-top: 0;
  }

  .sor__content {
    padding-top: 70px;
    width: 535px;
    max-width: 100%;

    @include break-max($breakpoint-sm) {
      width: 355px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 15px;
      width: 100%;
    }
  }

  .sor__promo {
    padding-bottom: 40px;

    @include break-max($breakpoint-xs) {
      min-height: auto;
    }
  }

  .sor__h1 {
    padding-top: 100px;
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 35px;
    @include break-max($breakpoint-md) {
      padding-top: 70px;
      font-size: 48px;
      line-height: 50px;
    }
    @include break-max($breakpoint-sm) {
      padding-top: 50px;
      font-size: 36px;
      line-height: 38px;
    }
    @include break-max($breakpoint-xs) {
      padding-top: 330px;
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 15px;
    }
  }
  
  .sor__sign {
    @include break-max($breakpoint-xs) {
      margin-bottom: 15px;
    }
  }

  .sor__promo-btns {
    @include break-max($breakpoint-xs) {
      margin-top: 15px;
      min-height: auto;
    }
  }

  .sor__faq {
    max-width: 940px;
    @include break-max($breakpoint-sm) {
      max-width: 620px;
    }

    @include break-max($breakpoint-xs) {
      max-width: 100%;
      margin-top: 30px;
    }
  }

  .sor__promo-btn {
    font-weight: bold;
    max-width: 100%;
  }

  .sor__promo-pic {
    top: 30px;
    width: 710px;
    @include break-max($breakpoint-md) {
      width: 575px;
      top: 70px;
      right: -100px;
    }
    @include break-max($breakpoint-sm) {
      width: 480px;
      right: 0;
      top: 110px;
    }
    @include break-max($breakpoint-xs) {
      position: absolute;
      width: 360px;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .sor__promo-img-lg {
    position: static;
    max-width: 100%;
  }

  .sor__faq-item {
    width: fit-content;
  }

  .sor__faq-label {
    display: flex;
    width: fit-content;
    margin-top: 7px;
    padding: 5px 10px;
    background: $color-brand-1;
    color: $color-white;
    border-radius: 4px;
  }
  
  .sor__section {
    padding: 90px 0;
    @include break-max($breakpoint-sm) {
      padding: 45px 0;
    }
    @include break-max($breakpoint-xs) {
      padding-bottom: 30px;
    }
  }

  .calculators {
    margin-bottom: 0;

    @include break-max($breakpoint-xs) {
      padding-bottom: 0;
    }
  }

  .sor__h2 {
    padding-bottom: 50px;
    @include break-max($breakpoint-xs) {
      padding-bottom: 15px;
    }
  }

  .sor__table-item {
    padding: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    @include break-max($breakpoint-xs) {
      padding: 25px 20px;
      display: grid;
      grid-template-columns: repeat(2, auto);
    }
  }

  .sor__table-item:nth-child(even) {
    background: $color-brand-opaque-8;
  }

  .sor__table-percent {
    border-radius: 4px;
    background: $color-brand-3;
    color: $color-white;
    padding: 5.5px 12px;
    display: flex;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;

    @include break-max($breakpoint-xs) {
      grid-column: 3 / 4;
    }

  }

  .sor__table-name {
    width: 180px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  .sor__table-info {
    width: 640px;
    max-width: 100%;

    @include break-max($breakpoint-sm) {
      width: 320px;
    }

    @include break-max($breakpoint-xs) {
      width: 100%;
      grid-column: 1 / 4;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $color-brand-opaque-20;
    }

    .bold {
      display: block;
      margin-bottom: 15px;
    }
  }

  .sor__how {
    background: $color-brand-opaque-8;
  }

  .sor__how-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(282px, 1fr));
    gap: 22px;

    @include break-max($breakpoint-md) {
      grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
    }
    @include break-max($breakpoint-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include break-max($breakpoint-xs) {
      grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    }
  }

  .sor__how-item {
    position: relative;
    border-radius: 4px;
    padding: 0 25px;
    padding-top: 225px;
    padding-bottom: 45px;
    background-color: $color-white;
    background-image: url('../../assets/images/cashback/01.png');
    background-repeat: no-repeat;
    background-position: center 30px;

    @include break-max($breakpoint-md) {
      padding-bottom: 25px;
    }

    &:nth-child(2) {
      background-image: url('../../assets/images/cashback/02.png');
    }
    &:nth-child(3) {
      background-image: url('../../assets/images/cashback/03.png');
    }
    &:nth-child(4) {
      background-image: url('../../assets/images/cashback/04.png');
    }

    
    @include break-max($breakpoint-xs) {
      padding: 45px 15px 45px 175px;
      background-position: left center;
      background-size: 190px;
    }

  }

  .sor__how-counter {
    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 1;

    @include break-max($breakpoint-xs) {
      top: 20px;
      left: 20px;
    }

  }

  .sor__how-sign {
    margin-top: 35px;
    font-size: 14px;
    line-height: 21px;
    color: $color-red;

    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }
  }

  .sor__contacts {
    padding-top: 35px;
    border-top: 1px solid $color-brand-opaque-20;

    p:first-of-type {
      margin-bottom: 20px;
    }
    p:last-of-type {
      margin-bottom: 15px;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 32px;
    }

    @include break-max($breakpoint-sm) {
      padding-left: 32px;
    }
  }

  .sor__contacts-block {
    display: flex;
  }

  .sor__contacts-link {
    display: flex;
    align-items: center;
    margin-right: 55px;

    @include break-max($breakpoint-sm) {
      margin-right: 25px;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      stroke: $color-brand-1;
      fill: $color-white;
      width: 20px;
      height: 18px;
      margin-right: 5px;
      transition: color $transition-default;
    }

    &:hover svg {
      stroke: $color-red-hover;
    }
  }

  .wrapper__center {
        
    @include break-max($breakpoint-md) {
      margin: 0 auto;
      width: 997px;
      max-width: 100%;
    }
  }
}