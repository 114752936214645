.inner {
  margin-bottom: 0;
}

.inner__header {
  padding-bottom: 45px;
  border-bottom: 3px solid #000;

  &_no-border {
    border: none;
  }

  @include break-max($breakpoint-sm) {
    padding-bottom: 30px;
  }

}

.inner__section {
  padding-top: 65px;
  padding-bottom: 45px;
  border-bottom: 3px solid #000;

  &_no-border {
    border: none;
  }

  @include break-max($breakpoint-sm) {
    padding-top: 45px;
    padding-bottom: 30px;
  }
}

.inner__title {
  text-align: center;
  padding-top: 50px;

  @include break-max($breakpoint-sm) {
    padding-top: 30px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 20px;
  }
}

.inner__text {
  padding-top: 50px;

  @include break-max($breakpoint-sm) {
    padding-top: 30px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 20px;
  }
}

.inner__h2 {
  margin-bottom: 15px;
}

.inner__p {
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.inner__list {
  margin-top: 40px;
  counter-reset: section;
  list-style-type: none;
  @include break-max($breakpoint-xs) {
    margin-top: 20px;
  }
}

.inner__list-item {
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 25px;
    counter-increment: section;
    content: counters(section,".") " ";
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #4545C2;
  }
}

.inner__list-title {
  margin-bottom: 20px;
}

.inner__calc-title {
  margin-top: 40px;
  margin-bottom: 15px;

  @include break-max($breakpoint-sm) {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.inner__big-pic {
  border-radius: 8px;
  margin: 65px 0 0;

  @include break-max($breakpoint-md) {
    margin: 50px 0 0;
  }

  @include break-max($breakpoint-sm) {
    margin: 35px 0 0;
  }

  @include break-max($breakpoint-xs) {
    margin: 25px 0 0;
  }
}

.inner .calculators {
  @include break-max($breakpoint-xs) {
    padding-bottom: 0;
  }
}