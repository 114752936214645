.custom-table__card {
  padding-bottom: 0;
}

.custom-table__empty {
  text-align: center;
  margin: 20px 0;
}

.motivation__table_fee {
  align-items: flex-start;
}

.motivation__object-header.motivation__table_object {
  align-items: flex-start;
  padding-top: 12px;
}

.motivation__object-item, .motivation__fee-item {
  align-items: center;
  height: auto;
}

.motivation__object-header, .motivation__fee-header {
  padding-top: 6px;
  padding-bottom: 20px;
}

.motivation__table-conditions {
  position: relative;
  padding-top: 8px;
}

.motivation__table-conditions .info {
  position: absolute;
  left: 0;
}

.motivation__table-conditions .label__text {
  padding-left: 35px;
}

.motivation__builder {
  flex-direction: column;
}

.motivation__builder-logo {
  max-width: 100%;
  margin-top: 10px;
}

.motivation__builder-logo img {
  width: auto;
  object-fit: contain;
}

.motivation__fee-item .label-item {
  max-width: 100%;
  white-space: pre-line;
}

.label-item {
  padding: 6px 9px;
}

.motivation__sticky {
  position: sticky;
}

.motivation__period {
  display: flex;
  gap: 40px;
  margin-top: -15px;
}
.motivation__period span {
  display: flex;
  gap: 10px;
  align-items: center;
}







