@import "../../styles/variables";
@import "../../styles/mixins";

.delete-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;

  &__title {
    margin-top: 90px;
    font-size: 55px;
    margin-bottom: 30px;
    text-align: center;
  }

  &__content {
    max-width: 800px;
    margin-bottom: 40px;
  }

  &__hint {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
  }

  &__rules {
    //display: flex;
    li {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-bottom: 25px;

      span {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #ED2324;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        flex-shrink: 0;
      }

      a {
        color: #1e2030;
        font-weight: 500;
        text-decoration: underline;
        display: inline;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  &__initem {
    //display: flex;
  }


  &__form {
    background-color: #F6F7FB;
    //background-color: pink;
    width: 465px;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 60px;
  }

  .custom-radio input[type="radio"] {
    background-color: white;
  }

  &__code-area {
    margin-top: 30px;
  }

  &__code {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #D9D9D9;
    height: 50px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

}

@include break-max($breakpoint-md) {
  .delete-user {
    &__title {
      font-size: 42px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }
}

@include break-max($breakpoint-xsm) {
  .delete-user {
    &__title {
      font-size: 34px;
      line-height: 34px;
      margin-top: 40px;
      //margin-bottom: 5px;
    }

    &__hint {
      font-size: 16px;
    }
  }
}

@include break-max($breakpoint-xs) {
  .delete-user {
    padding-left: 10px;
    padding-right: 10px;

    &__title {
      font-size: 20px;
      line-height: 20px;
    }
    &__hint {
      font-size: 14px;
      font-weight: 400;
    }

    &__rules {
      li {
        flex-direction: column;
        margin-bottom: 20px;

        span {
          margin-right: 0;
          margin-bottom: 7px;
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
      }
    }

    &__initem {
      font-size: 14px;
      text-align: center;
    }

    &__form {
      width: 100%;
      padding: 25px;
    }
  }
}

















