@import "../../styles/variables";
@import "../../styles/mixins";

$gradient: linear-gradient(178.9deg, rgba(243, 244, 252, 0.58) 30.12%, #F4F4F7 99.06%);

.hp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .wrapper {
    width: 1600px;
  }

  .wrapper__center {
    position: relative;
    max-width: 100%;
    @include break-max($breakpoint-md) {
      width: 1200px;
      margin: 0;
      padding: 0 10px;
    }
  }

  .h1 {
    @include h1-L();
  }

  .h2 {
    @include h2-L();
  }

  .h3 {
    @include h3-L();
  }

  .h4 {
    @include h4-L();
  }

  .h5 {
    @include h5-L();
  }

  .big-text {
    @include big-text-L();
  }

  .italic {
    font-style: italic;
  }

  .medium-text {
    @include medium-text-L();
  }

  .caps {
    @include caps-L();
  }

  .text {
    @include text-L();
  }

  .text-xs {
    @include text-xs-L();
  }
}

.hp__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-white;
  z-index: 2;
}

.hp__slider {
  margin-top: 40px;
}

.hp__header-content {
  display: flex;
  align-items: center;

  @include break-max($breakpoint-sm) {
    height: 60px;
  }

  @include break-max($breakpoint-xs) {
    height: 50px;
  }

}

.hp__header-logo {

  @include break-max($breakpoint-sm) {
    height: 40px;
  }

  @include break-max($breakpoint-xs) {
    height: 28px;
  }
}

.hp__nav {
  @include break-max($breakpoint-sm) {
    order: 1;
    align-self: start;
    margin-left: 30px;
  }
}

.hp__nav-list {
  display: flex;
  margin-left: 180px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    margin-left: 0;
  }

}

@keyframes opacity {
  from { opacity: 0; }
  to { opacity: 1; }
 }

.hp__header-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color-brand-1, 0.5);
  animation: opacity 0.8s ease-in-out;
}

.hp__nav-item {
  margin-right: 30px;

  @include break-max($breakpoint-sm) {
    border-bottom: 1px solid $color-brand-opaque-20;
    margin-left: 35px;
    margin-right: 75px;
  }

  &:last-child {
    margin-right: 0;

    @include break-max($breakpoint-sm) {
      margin-right: 75px;
      border-bottom: none;
    }
  }
}

.hp__nav-anchor {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $color-brand-3;
  transition: all 0.3s;
  border-bottom: 4px solid transparent;

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }

  @include break-max($breakpoint-sm) {
    height: 50px;
    width: 100%;
    color: $color-brand-1;
    padding: 0;
  }

  &.active {
    border-color: $color-red;
    @include break-max($breakpoint-sm) {
      color: $color-red;
      border-color: transparent;
    }
  }

  &:hover:not(.active) {
    border-color: $color-brand-3;
    @include break-max($breakpoint-sm) {
      border-color: transparent;
    }
  }
}

.hp__nav-enter {
  display: none;
  @include break-max($breakpoint-sm) {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 100px;
  }
}

.hp__btns {
  display: flex;
  margin-left: auto;
}

.hp__header-anchor {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: $color-brand-3;
}

.hp__header-btn {
  margin-left: 50px;
  padding: 16px 30px;
  background: $color-brand-2;
  border-radius: 4px;
  transition: all 0.3s;
  color: $color-white;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  @include break-max($breakpoint-sm) {
    margin-left: 30px;
  }

  @include break-max($breakpoint-xs) {
    margin-left: 15px;
    padding: 8px 15px;
  }

  &:hover {
    background: $color-brand-3;
  }
}

.hp__promo {
  padding-bottom: 25px;

}

.hp:not(.hp_dashboard) .hp__promo {
  padding-top: 80px;

  @include break-max($breakpoint-sm) {
    padding-top: 60px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 50px;
  }

}

.hp__promo-img {
  position: absolute;
  width: 56.5%;
  right: 0;
  max-height: 100%;

  @include break-max($breakpoint-md) {
    width: 780px;
  }

  @include break-max($breakpoint-sm) {
    width: 460px;
  }

  @include break-max($breakpoint-xs) {
    width: 320px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
}

.hp__promo-img_self {
  width: 50.6%;
  @include break-max($breakpoint-md) {
    width: 780px;
  }

  @include break-max($breakpoint-sm) {
    width: 460px;
  }

  @include break-max($breakpoint-xs) {
    width: 320px;
  }
}

.hp__promo-content {
  width: 460px;
  max-width: 100%;
  height: 685px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-height: calc(100vh - 80px);

  @include break-max($breakpoint-md) {
    height: 635px;
  }

  @include break-max($breakpoint-sm) {
    height: 380px;
    width: 290px;
    max-height: calc(100vh - 60px);
  }

  @include break-max($breakpoint-xs) {
    height: 510px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 40px;
  }

  &_self {
    // height: 765px;

    @include break-max($breakpoint-sm) {
      height: 420px;
      width: 290px;
      max-height: calc(100vh - 60px);
    }

    @include break-max($breakpoint-xs) {
      height: 530px;
      width: 100%;
    }
  }
}

.hp__promo-btn {
  @include break-max($breakpoint-xs) {
    width: 100%;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
}

.hp__subtitle {
  margin-top: 30px;
  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.hp__promo-btn_second {
  margin-left: 20px;
  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
}

.hp__promo-btn_bold {
  font-weight: bold;
}

.hp__promo-btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include break-max($breakpoint-xs) {
    width: 100%;
    gap: 10px;
    grid-template-columns: 1fr;
  }
}

.hp__h1 {
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    width: 255px;
    margin-bottom: 10px;
  }
}

.hp__page {
  padding-bottom: 25px;
}

.hp__page .hp__h1 {
  @include break-max($breakpoint-xs) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}


.hp__h2 {
  margin-bottom: 10px;
}

.hp__label {
  display: block;
  margin-bottom: 20px;
  @include break-max($breakpoint-xs) {
    margin-bottom: 10px;
  }
}

.hp__sign {
  margin-bottom: 40px;
  @include break-max($breakpoint-sm) {
    margin-bottom: 30px;
  }
  @include break-max($breakpoint-xs) {
    margin-bottom: 15px;
  }

  &_sm {
    font-size: 18px;
    line-height: 24px;
    @include break-max($breakpoint-xs) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.hp__title {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-brand-opaque-20;
  @include break-max($breakpoint-xs) {
    padding-bottom: 15px;
  }
}

.hp__about-list {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;

  @include break-max($breakpoint-sm) {
    overflow-x: auto;
  }
}

.hp__about-item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 261px;
  height: 360px;
  align-items: center;
  text-align: center;
  background: linear-gradient(187.57deg, #FCFCFF 7.98%, #F1F1F8 94.14%);
  padding-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 35px;

  border-radius: 20px;

  transition: all ease 0.3s;

  @include break-max($breakpoint-sm) {
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
    min-width: 200px;
    margin-right: 15px;
  }

  @include break-max($breakpoint-xs) {
    height: 290px;
  }
}

.hp__about-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 260px;
  max-width: 100%;

  @include break-max($breakpoint-xs) {
    height: 150px;
  }
}

.hp__about-title {
  margin-bottom: 5px;
  width: 181px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 15px;
  }
}

.hp__about-descr {
  width: 260px;
  min-width: 260px;
  max-width: 100%;
  margin: 0 auto;
  display: none;
  @include break-max($breakpoint-sm) {
    width: 240px;
    min-width: 240px;
  }
  @include break-max($breakpoint-xs) {
    width: 215px;
    min-width: 215px;
  }
}

.hp__about-btn {
  margin-top: auto;
  display: none;
  position: relative;

  @include break-max($breakpoint-xs) {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
}

.hp__about-item.active {
  width: 345px;
  max-width: 100%;
  background: $color-brand-2;
  color: $color-white;

  padding-left: 18px;
  padding-right: 18px;

  @include break-max($breakpoint-sm) {
    width: 248px;
    min-width: 248px;
  }

  .hp__about-btn {
    display: inline-flex;
  }

  .hp__about-descr {
    // transition: opacity 1s ease-out;
    // visibility: visible;
    // opacity: 1;
    display: flex;
  }

  // &:nth-child(even) {
  //   background: linear-gradient(187.57deg, #FCFCFF 7.98%, #F1F1F8 94.14%);
  //   color: inherit;
  // }

}

.hp__develop-anchor {
  position: absolute;
  top: 8px;
  right: 0;
  display: inline-flex;
  align-items: baseline;
  color: $color-red;

  .hp__develop-icon {
    position: relative;
    margin-left: 10px;
    transition: all 0.3s;
    left: 0;
  }

  &:hover .hp__develop-icon {
    left: 5px;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }

}

.hp__develop-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: linear-gradient(187.57deg, #FCFCFF 7.98%, #F1F1F8 94.14%);
  border-radius: 20px;
  margin-bottom: 30px;

  @include break-max($breakpoint-md) {
    width: 78px;
    height: 78px;
  }

  @include break-max($breakpoint-xs) {
    margin-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
}

.hp__develop-grid {
  margin-top: 40px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 248px);
  row-gap: 74px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 200px);
    row-gap: 43px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: repeat(1, 100%);
    row-gap: 32px;
  }
}

.hp__develop-grid_furnish {
  margin-top: 40px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: repeat(1, 100%);

  }
}

.hp .hp__develop-h4 {
  width: 90%;
  color: $color-brand-1;
  margin-bottom: 8px;

  @include break-max($breakpoint-md) {
    width: 80%;
  }

}

.hp .hp__develop-grid_self .hp__develop-h4 {
  @include break-max($breakpoint-md) {
    width: 90%;
  }
}

.hp__develop-descr {
  margin-bottom: 10px;

  @include break-max($breakpoint-xs) {
    margin-bottom: 7px;
  }
}

.hp__develop-item-anchor {
  display: inline-flex;
  align-items: center;
  color: $color-red;

  &::after {
    content: '';
    width: 13px;
    height: 8px;
    display: flex;
    background: url('../../assets/images/hp/arrow.svg') no-repeat right;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    margin-left: 10px;
  }
}

.hp__develop-pic-revert {
  display: none;
}

.hp__develop-item:not(.hp__develop-item_no-hover):hover {

  .hp__develop-square {
    background: rgba($color-red, 0.1);
  }

  .hp__develop-item-anchor::after {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.6s;
  }

  .hp__develop-pic {
    display: none;
  }

  .hp__develop-pic-revert {
    display: block;
  }
}

.hp__section-margin {
  margin-bottom: 85px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 70px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 50px;
  }
}

.hp__news.hp__section-margin {
  margin-bottom: 48px;
}

.hp__develop-descr {
  color: $color-brand-3;
}

.hp__develop-item {
  @include break-max($breakpoint-xs) {
    display: flex;
  }
}


.hp__develop-item_flex {
  display: flex;
  align-items: center;

  .hp__develop-square {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .hp__develop-content {
    padding-right: 15%;
  }
}

.hp__develop-all {
  margin-top: 25px;
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: inherit;
  border-radius: 4px;
  border: 1px solid $color-brand-opaque-20;
}

.hp__descr {
  color: $color-brand-3;
}

.hp__socials {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  width: 90%;

  @include break-max($breakpoint-sm) {
    width: 100%;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.hp__socials-item {
  color: inherit;
  display: flex;
  align-items: center;
  width: 410px;
  max-width: 100%;
  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.hp__socials-img {
  width: 95px;
  height: 95px;
  border-radius: 20px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  @include break-max($breakpoint-md) {
    width: 78px;
    height: 78px;
  }

  &_tg {
    background: linear-gradient(180deg, #41BCE7 0%, #22A6DC 99.63%);

  }

  &_vk {
    background: linear-gradient(180deg, #548ABB 0%, #3473AC 100%);
  }
}

.hp__socials-item:hover .hp__socials-img_tg {
  background: linear-gradient(180deg, #20ADDF 0%, #108FC3 99.63%);
}

.hp__socials-item:hover .hp__socials-img_vk {
  background: linear-gradient(180deg, #407CB1 0%, #1C5C96 100%);
}

.hp__socials-content {
  @include break-max($breakpoint-sm) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .big-text-sm {
    @include break-max($breakpoint-sm) {
      font-size: 17px;
      line-height: 22px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.hp__socials-h3 {
  margin-bottom: 5px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
  }
}

.hp__mobile {
  display: flex;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 20px;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-left: 125px;
  background: url('../../assets/images/hp/phone-bg.png') no-repeat right;

  @include break-max($breakpoint-md) {
    padding-left: 100px;
    background-size: contain;
  }

  @include break-max($breakpoint-sm) {
    padding-left: 82px;
    padding-right: 82px;
    padding-top: 40px;
    padding-bottom: 50px;
  }

  @include break-max($breakpoint-xs) {
    padding-top: 33px;
    padding-left: 21px;
    padding-right: 21px;
    text-align: center;
    height: 432px;
    background-position: bottom center;
  }
}

.hp__mobile-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    width: 315px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    justify-content: flex-start;
  }
}

.hp__mobile-code {
  margin-left: 28px;
  @include break-max($breakpoint-sm) {
    display: none;
  }
}

.hp__mobile-apps {
  display: flex;
  justify-content: space-between;

  @include break-max($breakpoint-xs) {
    width: 315px;
    margin: 0 auto;
  }

}

.hp__mobile-h3 {
  @include break-max($breakpoint-sm) {
    margin-bottom: 16px;
  }
}

.hp__mobile-text {
  @include break-max($breakpoint-sm) {
    margin-bottom: 16px;
  }
}

.hp__mobile-img {
  rect {
    fill: $color-brand-2;
    transition: all 0.3s;
  }
  @include break-max($breakpoint-sm) {
    height: 42px;
    width: 150px;
  }
}

.hp__mobile-app:hover .hp__mobile-img rect {
  fill: #151722;
}

.hp__banner {
  display: flex;
  justify-content: space-between;
  background: rgba($color-red, 0.1) url('../../assets/images/hp/banner-plant.svg') no-repeat right 340px bottom 0;
  border-radius: 20px;
  padding-left: 125px;
  padding-right: 125px;
  padding-top: 59px;
  padding-bottom: 48px;

  margin-bottom: 100px;

  &:last-child:not(.hp__banner_main) {
    margin-bottom: 0;
  }

  @include break-max($breakpoint-md) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;

    margin-bottom: 65px;
    text-align: center;

    background-position: right -40px bottom;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 17px;
    padding-right: 17px;
    background-position: right -40px bottom -40px;
    margin-bottom: 45px;
  }

  .checkbox__label {
    color: $color-white;
  }

}


.hp__banner_type_2,
.hp__banner_type_3 {

  margin-bottom: 60px;

  @include break-min($break-md) {
    padding: 60px;
  }


  background-position: right 250px bottom 0;

  @include break-max($breakpoint-md) {
    margin-bottom: 50px;
  }

  @include break-max($breakpoint-sm) {
    margin-bottom: 45px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 30px;
  }

  .hp__banner-h2 {
    margin-bottom: 0;
  }

  .hp__banner-btn {
    margin-bottom: 0;
  }
}

.hp__banner_type_2, .hp__banner_plant_2 {
  background-image: url('../../assets/images/hp/banner-plant-2.svg');
  @include break-max($breakpoint-sm) {
    background-position: center bottom -70px;
  }

  @include break-max($breakpoint-xs) {
    background-position: center bottom -80px;
  }

}

.hp__banner_type_3, .hp__banner_black {
  background-color: $color-brand-2;
  background-image: url('../../assets/images/hp/banner-plant-3.svg');
  color: $color-white;

  @include break-max($breakpoint-xs) {
    background-position: center bottom -70px;
  }
}

.hp__banner-h2 {
  margin-bottom: 15px;
}

.hp__banner-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include break-max($breakpoint-sm) {
    margin-top: 29px;
    flex-direction: row;
    align-items: center;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.hp__banner-btn {
  margin-bottom: 22px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 0;
    margin-right: 34px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 20px;
    margin-right: 0;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
}

.hp__banner-content {
  width: 595px;
  max-width: 100%;

  @include break-max($breakpoint-xs) {
    width: 225px;
    margin: 0 auto;
  }
}

.hp__accord {
  padding-top: 45px;
  padding-bottom: 45px;
  border-bottom: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-xs) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &:last-of-type {
    border-bottom: 0;
    @include break-max($breakpoint-xs) {
      padding-bottom: 0;
    }
  }

  &.accordeon .accordeon__title {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 26px;
    cursor: pointer;

    @include break-max($breakpoint-xs) {
      align-items: flex-start;
    }
  }

  .accordeon__content {
    padding-right: 60px;
  }

  .accordeon__btn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gradient;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 50px;

    svg {
      stroke: $color-brand-2;
      font-size: 12px;
    }
  }
}

.hp__faq {
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
  @include break-max($breakpoint-xs) {
    margin-top: 10px;
  }
}

.hp__faq-pic {
  margin-right: 45px;
  @include break-max($breakpoint-sm) {
    max-width: 235px;
    margin-right: 15px;
  }
  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.hp__footer-content {
  display: flex;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }
}

.hp__footer {
  margin-top: auto;
  border-top: 1px solid $color-brand-opaque-20;
  padding-top: 24px;
  padding-bottom: 32px;

  .wrapper__center {
    position: relative;
    max-width: 100%;
    @include break-max($breakpoint-md) {
      width: 1200px;
      margin: 0;
      padding: 0 10px;
    }
  }
}

.hp__footer-item {
  display: flex;
  align-items: flex-start;
  @include break-max($breakpoint-xs) {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.hp__footer-img {
  @include break-max($breakpoint-xs) {
    width: 52px;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
  }
}

.hp__footer-info {
  display: flex;
  flex-direction: column;
  width: 250px;
  max-width: 100%;
  margin-left: 15px;

  @include break-max($breakpoint-sm) {
    width: 140px;
  }

  @include break-max($breakpoint-xs) {
    width: 220px;
  }
}

.hp__footer-title {
  margin-bottom: 6px;
}

.hp__footer-item:first-child {
  margin-right: auto;
}

.hp__footer-item:last-child {
  margin-left: 90px;
  width: 225px;

  @include break-max($breakpoint-sm) {
    margin-left: 45px;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 0;
  }
}

.hp__footer-logo {
  @include break-max($breakpoint-sm) {
    height: 40px;
  }

  @include break-max($breakpoint-xs) {
    height: 28px;
  }
}

.hp__become-p {
  margin-top: 70px;
  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }
}

.hp__become-grid {
  width: 970px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 65px;
  margin-bottom: 65px;
  display: grid;
  grid-template-columns: repeat(2, 470px);
  gap: 30px;

  @include break-max($breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: 100%;
    margin-top: 25px;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 10px;
    padding-right: 10px;
    gap: 14px;
  }

}

.hp__become-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  padding-left: 60px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 20px;
  color: inherit;
  transition: all 0.3s;

  @include break-max($breakpoint-xs) {
    padding: 22px;
  }
}

.hp__become-content {
  width: 280px;
  max-width: 100%;
}

.hp__become-text {
  margin-top: 8px;
}

.hp__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-brand-1;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: all 0.3s;
  flex-shrink: 0;
  @include break-max($breakpoint-xs) {
    width: 34px;
    height: 34px;
  }
}

.hp__become-item:hover {
  border-color: transparent;
  background: linear-gradient(187.57deg, #FCFCFF 7.98%, #F1F1F8 94.14%);
  .hp__arrow {
    margin-right: -14px;

    @include break-max($breakpoint-sm) {
      margin-right: 0;
    }
  }
}

.hp__page-form {
  width: 530px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include break-max($breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include break-max($breakpoint-xs) {
    padding-left: 10px;
    padding-right: 10px;
  }


}

.hp__back-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  margin-bottom: 30px;

  svg {
    margin-right: 10px;
  }
}

.hp__form-content {
  background: $gradient;
  padding: 55px 85px;
  border-radius: 20px;

  @include break-max($breakpoint-xs) {
    padding: 35px 45px;
  }

  &_no-bg {
    background: none;
  }
}

.hp__form-label {
  display: block;
  margin-bottom: 5px;
  color: $color-brand-3;
}

.hp__form-item {

  & + & {
    margin-top: 20px;
  }
}

.hp__form-item_space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hp__submit {
  width: 100%;
}

.hp__contacts-grid {
  display: grid;
  grid-template-columns: repeat(2, 590px);
  grid-template-rows: 240px;
  justify-content: space-between;

  @include break-max($breakpoint-md) {
    width: 998px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 488px);
    grid-template-rows: 207px;
  }

  @include break-max($breakpoint-sm) {
    width: 604px;

    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.hp__contacts-item {
  padding-top: 55px;
  padding-left: 52px;
  padding-bottom: 44px;
  border-radius: 20px;

  background-image: url('../../assets/images/hp/contacts/spb.svg'), $gradient;
  background-position: center right;
  background-repeat: no-repeat;


  @include break-max($breakpoint-md) {
    padding-top: 40px;
    padding-left: 36px;
    padding-bottom: 34px;
    background-size: contain, cover;
  }

  @include break-max($breakpoint-sm) {
    height: 207px;
  }

  @include break-max($breakpoint-xs) {
    height: 186px;
    background-size: 180px, cover;
    background-position: bottom right;
  }

  &_msk {
    background-image: url('../../assets/images/hp/contacts/msk.svg'), $gradient;
    background-position: top 0 right 60px, center;

    @include break-max($breakpoint-md) {
      background-position: top 0 right 22px, center;
    }

    @include break-max($breakpoint-xs) {
      background-size: 120px, cover;
      background-position: bottom 0 right 22px, center;
    }
  }

}

.hp__contacts-content {
  width: 290px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include break-max($breakpoint-xs) {
    width: 200px;
  }
}

.hp .hp__contacts-title {
  font-weight: 900;

  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 22px;
  }
}

.hp .hp__contacts-phone {
  color: inherit;
  display: flex;
  align-items: center;

  @include break-max($breakpoint-md) {
    font-size: 18px;
    line-height: 22px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 22px;
  }

  span {
    margin-right: 5px;
  }

  svg {
    margin-right: 15px;
  }
}

.hp__question {
  position: sticky;
  bottom: 20px;

  margin-top: 145px;
  margin-bottom: 35px;
  margin-left: auto;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 345px;
  padding: 0 25px;
  height: 100px;
  max-width: 100%;
  background: #F4F4F7;
  border-radius: 20px;

  @include break-max($breakpoint-md) {
    margin-top: 45px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 85px;
  }

  @include break-max($breakpoint-xs) {
    padding: 0;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    margin-top: 45px;
    margin-right: 10px;
  }
}

.hp__question-content {
  display: flex;
  flex-direction: column;
  b {
    margin-bottom: 5px;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.hp__tabs {
  display: flex;
  justify-content: center;

  margin-bottom: 50px;

  @include break-max($breakpoint-xs) {
    overflow: auto;
    margin-bottom: 15px;
    justify-content: flex-start;
  }
}

.hp__tabs-list {
  display: flex;
}

.hp__tabs-item {
  margin-right: 30px;
  &:last-of-type {
    margin-right: 0;
  }

  @include break-max($breakpoint-xs) {
    margin-right: 0;
  }
}

.hp__tabs-btn {
  font-weight: bold;

  @include break-max($breakpoint-xs) {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    border-radius: 0;
    border-bottom: 1px solid $color-brand-opaque-20;
    box-shadow: none;

    &:hover {
      background-color: $color-white;
    }
  }

  &_current {
    color: $color-white;
    background-color: $color-brand-3;

    &:hover {
      color: $color-brand-1;
    }

    &:disabled {
      color: $color-white;
    }

    @include break-max($breakpoint-xs) {
      color: $color-brand-1;
      background-color: $color-white;
      border-color: $color-red;
    }
  }


}

.hp__events-grid {
  position: relative;
  display: grid;
  grid-template-columns: 623px 494px;
  justify-content: space-between;
  align-items: start;

  &_align-center {
    align-items: center;
  }

  @include break-max($breakpoint-md) {
    grid-template-columns: 613px 486px;
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: 1fr 362px;
    gap: 35px;
  }


  @include break-max($breakpoint-xs) {
    grid-template-columns: 1fr;
    row-gap: 25px;
  }
}

.hp__events-item {
  display: flex;
  flex-direction: column;
  &_empty {
    justify-content: center;
  }
}

.hp__events-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
}

.hp__events-day {
  display: block;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.hp__events-day:first-of-type .hp__events-header {
  margin-top: 0;
}

.hp__events-label {
  display: inline-flex;
  align-items: center;
  padding: 0 17px;
  height: 40px;
  background: $gradient;
  border-radius: 20px;
  font-weight: 700;

  span {
    color: $color-brand-3;
    margin-left: 5px;
  }
}

.hp__event {
  position: relative;
  padding: 30px 45px;
  border: 1px solid $color-brand-opaque-20;
  border-radius: 20px;

  margin-bottom: 20px;

  text-align: left;
  cursor: pointer;
  margin-bottom: 20px;
  white-space: unset;
  width: 100%;
  color: inherit;
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: transparent;
    background-image: $gradient;
  }

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 20px;
    width: 3px;
    border-radius: 1px;
  }
}

.hp__event_webinar {
  &::before {
    background-color: $green;
  }
}

.hp__event_learning {
  &::before {
    background-color: $yellow;
  }
}

.hp__event_excursion {
  &::before {
    background-color: $blue;
  }
}

.hp__event-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.hp__event-status {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 1px;
  }
}



.hp__event_webinar .hp__event-status,
.hp__event-status_webinar {
  color: $green;
  &::before {
    background-color: $green;
  }
}

.hp__event_learning .hp__event-status,
.hp__event-status_learning {
  color: $yellow;
  &::before {
    background-color: $yellow;
  }
}

.hp__event_excursion .hp__event-status,
.hp__event-status_excursion {
  color: $blue;
  &::before {
    background-color: $blue;
  }
}

.hp__event_vip .hp__event-status,
.hp__event-status_vip {
  color: #EF372E;
  &::before {
    background-color: #EF372E;
  }
}

.hp__event-name {
  width: 470px;
  max-width: 100%;
  margin-bottom: 12px;

  @include break-max($breakpoint-md) {
    width: 100%;
  }
}

.hp__event-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $color-brand-3;
}

.hp__event-showmore {
  display: flex;
  align-items: baseline;
  color: $color-brand-3;

  svg {
    margin-left: 8px;
  }
}

.hp__e-socials {
  display: flex;
  align-items: flex-start;
  padding-top: 34px;
  padding-left: 27px;
  padding-right: 25px;
  padding-bottom: 35px;
  background-image: url('../../assets/images/hp/events/social-bg.svg'), $gradient;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 20px;

  @include break-max($breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 0;
  }
}

.hp__e-socials-img {
  margin-right: 28px;

  @include break-max($breakpoint-sm) {
    margin-bottom: 20px;
    margin-right: 0;
    margin-top: -100px;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.hp__e-socials-content {
  margin-top: 0;

  @include break-max($breakpoint-sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.hp__e-socials-title {
  margin-bottom: 15px;
}

.hp__e-socials-text {
  color: $color-brand-3;
  margin-bottom: 24px;
}

.hp__e-socials-list {
  display: flex;
}

.hp__e-socials-item {
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
}

.hp__events-empty {
  display: flex;
  align-items: center;
  svg {
    margin-right: 30px;
  }
}

.hp-modal {
  .c-modal__icon {
    width: 40px;
    height: 40px;
  }
  .modal__inner {
    width: 970px;
    max-width: 100%;
    border-radius: 20px;
    padding: 85px 125px;
  }

  .h3 {
    font-weight: 900;
    font-size: 28px;
    line-height: 30px;

    @include break-max($breakpoint-md) {
      font-size: 24px;
      line-height: 26px;
    }

    @include break-max($breakpoint-sm) {
      font-size: 20px;
      line-height: 26px;
    }

    @include break-max($breakpoint-xs) {
      font-size: 16px;
      line-height: 26px;
    }

  }

}

.hp-modal__title {
  width: 540px;
  max-width: 100%;
}

.hp-modal__status {
  display: flex;
  align-items: center;
  margin-top: 8px;
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 1px;
  }

  &_webinar {
    color: $green;
    &::before {
      background-color: $green;
    }
  }

  &_learning {
    color: $yellow;
    &::before {
      background-color: $yellow;
    }
  }

  &_excursion {
    color: $blue;
    &::before {
      background-color: $blue;
    }
  }
}

.hp-modal__h4 {
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;

  @include break-max($breakpoint-md) {
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-sm) {
    font-size: 17px;
    line-height: 22px;
  }

  @include break-max($breakpoint-xs) {
    font-size: 16px;
    line-height: 20px;
  }
}

.hp-modal__dates-title {
  margin-bottom: 5px;
  color: $color-brand-3;
}

.hp-modal__title {
  margin-bottom: 30px;
}

.hp-modal__container {
  display: flex;
  margin-bottom: 40px;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    margin-bottom: 25px;
  }
}

.hp-modal__info {
  width: 220px;
  border-right: 1px solid $color-brand-opaque-20;
  padding-right: 15px;

  @include break-max($breakpoint-xs) {
    width: 100%;
    padding: 0;
    border: none;
    padding-bottom: 15px;
  }

}

.hp-modal__descr {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.hp-modal__descr.hp-modal__right-box {
  font-size: 16px;
  line-height: 21px;

  a {
    display: block;
    color: $color-red;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.hp-modal__right-box {
  width: 446px;
  max-width: 100%;
  margin-left: 53px;

  p + p {
    margin-top: 25px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
    margin-left: 0;
  }
}

.hp-modal__info-item {
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 50px;
    @include break-max($breakpoint-md) {
      margin-top: 30px;
    }

    @include break-max($breakpoint-sm) {
      margin-top: 25px;
    }

    @include break-max($breakpoint-xs) {
      margin-top: 15px;
    }
  }
}

.hp-modal__text {
  color: $color-brand-3;
}

.hp-modal__dates-text {
  margin-top: 8px;
}

.hp-modal__block {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-image: $gradient;
  padding-top: 27px;
  padding-bottom: 27px;
}

.hp-modal__block-inner {
  width: 570px;
  max-width: 100%;
  padding-left: 40px;
}

.hp__calendar {
  padding-bottom: 15px;
  border-bottom: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-xs) {
    margin-top: 15px;
  }

  .react-calendar__tile {
    max-width: 100% !important;
    width: 100%;
    height: 46px;
    justify-content: center;
    padding-top: 0;
  }

  .react-calendar__month-view__weekdays {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 13px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    max-width: 100% !important;
    width: 100%;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    font-size: 12px;
  }

  .react-calendar__month-view__days {
    display: grid !important;
    align-items: flex-start;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px 10px;
    @include break-max($breakpoint-md) {
      gap: 5px;
    }
  }

  .react-calendar__navigation__label {
    padding-left: 15px;
    text-align: left;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 0;
  }

  .react-calendar__navigation {
    border-bottom: 1px solid $color-brand-opaque-20;

  }

  .react-calendar__decade-view, .react-calendar__tile {
    @include break-max($breakpoint-xs) {
      padding: 1.2em 0.5em;
    }

  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.4;
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile--active:enabled:focus {
    opacity: 1;
    background-image: $gradient;
    background-color: unset;
    color: $color-brand-2;
  }

  .react-calendar__month-view__days__day--weekend {
    color: $color-brand-2;
    background: $gradient;
  }
}

.hp__calendar-title {
  padding-bottom: 20px;
  margin-left: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-brand-opaque-20;
}

.hp__calendar-legend {
  display: grid;
  margin-bottom: 40px;
  padding-top: 15px;
  grid-template-columns: repeat(4, max-content);
  column-gap: 30px;

  @include break-max($breakpoint-sm) {
    column-gap: 25px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 25px;
    column-gap: 20px;
  }
}

.hp__calendar-events {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hp__calendar-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 4.5px;
  background-color: $gray;
  &:last-of-type {
    margin-right: 0;
  }

  &_webinar {
    background-color: $green;
  }

  &_learning {
    background-color: $yellow;
  }

  &_excursion {
    background-color: $blue;
  }

  &_vip {
    background-color: #EF372E;
  }

  &_show {
    background-color: #EF372E;
  }
}

.hp .react-calendar__tile--hasActive {
  background-color: $color-red;
  color: $color-white;
}

.hp__error-btns {
  display: flex;

  & + & {
    margin-top: 15px;
  }
}

.hp__error-btn {
  font-weight: bold;
  width: 170px;
  padding: 17px;
  & + & {
    margin-left: 20px;
  }
}

.hp__error-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 20px;
  }
}

.hp__error-title {
  font-weight: bold;
  margin-bottom: 25px;
}

.hp__submit_mb-10 {
  margin-bottom: 10px;
}

.hp__form-link {
  display: flex;
  justify-content: center;
}

.hp__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  margin-top: 40px;

  @include break-max($breakpoint-sm) {
    padding: 0 20px;
    margin-top: 20px;
  }
}

.hp__success-img {
  margin-bottom: 20px;

  @include break-max($breakpoint-sm) {
    width: 60px;
    margin-bottom: 10px;
  }
}

.hp__events-sticky {
  position: sticky;
  top: 100px;
}

.hp__promo_calendar {
  @include break-max($breakpoint-xs) {
    padding-bottom: 0;
  }
}

.hp__capa-header {
  margin-bottom: 25px;
}

.hp__capa-item {
  width: 600px;
  max-width: 100%;

  @include break-max($breakpoint-xs) {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.hp__capa-item + .hp__capa-item {
  margin-top: 100px;

  @include break-max($breakpoint-sm) {
    margin-top: 60px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 0;
  }
}

.hp__capa-list-item + .hp__capa-list-item {
  margin-top: 25px;
}

.hp .hp__capa-title {
  font-weight: 400;
  padding-top: 5px;
}

.hp__capa-text {
  margin-top: 12px;
}

.hp__capa-list-item {
  position: relative;
  padding-left: 60px;
  &::before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &_01::before {
    background-image: url(../../../src/assets/images/hp/self/icons/01.svg);
  }
  &_02::before {
    background-image: url(../../../src/assets/images/hp/self/icons/02.svg);
  }
  &_03::before {
    background-image: url(../../../src/assets/images/hp/self/icons/03.svg);
  }
  &_04::before {
    background-image: url(../../../src/assets/images/hp/self/icons/04.svg);
  }
  &_05::before {
    background-image: url(../../../src/assets/images/hp/self/icons/05.svg);
  }
  &_06::before {
    background-image: url(../../../src/assets/images/hp/self/icons/06.svg);
  }
  &_07::before {
    background-image: url(../../../src/assets/images/hp/self/icons/07.svg);
  }
  &_08::before {
    background-image: url(../../../src/assets/images/hp/self/icons/08.svg);
  }
  &_09::before {
    background-image: url(../../../src/assets/images/hp/self/icons/09.svg);
  }
  &_10::before {
    background-image: url(../../../src/assets/images/hp/self/icons/10.svg);
  }
  &_11::before {
    background-image: url(../../../src/assets/images/hp/self/icons/11.svg);
  }
  &_12::before {
    background-image: url(../../../src/assets/images/hp/self/icons/12.svg);
  }
  &_13::before {
    background-image: url(../../../src/assets/images/hp/self/icons/13.svg);
  }
  &_14::before {
    background-image: url(../../../src/assets/images/hp/self/icons/14.svg);
  }
}

.hp__scrolling-btn {
  display: flex;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 220px;
}

.hp__scrolling {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 55px;

  @include break-max($breakpoint-sm) {
    gap: 45px;
  }

  @include break-max($breakpoint-xs) {
    flex-direction: column;
    gap: 0;
  }
}

.hp__scrolling-info {
  @include break-max($breakpoint-md) {
    width: 540px;
    max-width: 100%;
  }

  @include break-max($breakpoint-sm) {
    width: 310px;
  }

  @include break-max($breakpoint-xs) {
    width: 100%;
  }
}

.hp__scrolling-pic {
  position: sticky;
  top: 100px;

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.hp__scrolling-img {
  width: 530px;
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.3s;
  &_hidden {
    opacity: 0;
    position: absolute;
    top: 0;
  }

  @include break-max($breakpoint-sm) {
    width: 340px;
  }

  @include break-max($breakpoint-xs) {
    margin-bottom: 25px;
  }
}


.blog {

  padding-bottom: 0;

  @include medium-text-L();

  h1 {
    @include h1-L();

    margin-top: 60px;
    margin-bottom: 30px;

    @include break-max($breakpoint-md) {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 45px;
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .blog__header + h1 {
    margin-top: 25px;
    @include break-max($breakpoint-md) {
      margin-top: 20px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 0;
    }
  }

  h2:not([class]) {
    @include h2-L();
    margin-top: 60px;
    margin-bottom: 20px;

    @include break-max($breakpoint-md) {
      margin-top: 50px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 50px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 30px;
    }
  }

  h3 {
    @include h3-L();
    margin-top: 40px;
    margin-bottom: 30px;

    @include break-max($breakpoint-md) {
      margin-top: 40px;
      margin-bottom: 25px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 30px;
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }

  h4 {
    @include h5-L();
    margin-top: 30px;
    margin-bottom: 20px;

    @include break-max($breakpoint-md) {
      margin-top: 25px;
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  h5 {
    @include h5-L();
    margin-top: 30px;
    margin-bottom: 20px;

    @include break-max($breakpoint-md) {
      margin-top: 25px;
      margin-bottom: 20px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }


  .blog__content img {
    width: 100%;
    height: 540px;
    object-fit: contain;
    border-radius: 20px;
    @include break-max($breakpoint-md) {
      height: 450px;
    }
    @include break-max($breakpoint-sm) {
      height: 245px;
    }
  }

  ol:not([class]) {
    list-style: decimal;
    margin-left: 20px;

    margin-top: 20px;
    margin-bottom: 30px;
    @include break-max($breakpoint-md) {
      margin-top: 20px;
      margin-bottom: 25px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    li {
      margin-bottom: 20px;
      @include break-max($breakpoint-md) {
        margin-bottom: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    li::marker {
      color: $color-red;
      font-weight: 700;
    }
  }

  ul:not([class]) {

    margin-left: 20px;

    margin-top: 20px;
    margin-bottom: 30px;

    @include break-max($breakpoint-md) {
      margin-top: 20px;
      margin-bottom: 25px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    li {
      margin-bottom: 10px;
      @include break-max($breakpoint-md) {
        margin-bottom: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      li::marker {
        color: $color-brand-3;
        font-weight: 700;
      }
    }
  }


  blockquote {
    margin-top: 60px;
    margin-bottom: 60px;

    position: relative;
    padding-top: 40px;
    border-top: 1px solid $color-brand-opaque-30;

    @include break-max($breakpoint-md) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &::before {
      content: '«';
      position: absolute;
      top: -15px;
      left: 0;

      display: flex;
      align-items: center;
      width: 30px;
      height: 30px;

      background-color: $color-white;

      font-weight: 300;
      font-size: 21px;
      line-height: 30px;
      color: $color-brand-3;
    }
  }

  a[href]:not(.hp__banner-btn) {
    font: inherit;
    color: inherit;
    transition: all 0.3s;
    &:hover {
      color: $color-red;
    }
  }


  table {
    border: none;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;

    @include break-max($breakpoint-md) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    @include break-max($breakpoint-sm) {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    @include break-max($breakpoint-xs) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .blog__table-wrapper {
    @include break-max($breakpoint-xs) {
      overflow-x: scroll;
    }
  }

  table tr {
    display: flex;
    align-items: center;

    &:first-of-type {
      border-bottom: 1px solid $color-brand-opaque-20;

      td {
        font-weight: 700;
      }
    }
  }

  table thead {
    display: flex;
    border-bottom: 1px solid $color-brand-opaque-20;
  }

  table th,
  table td {
    width: 200px;
    padding: 19px;
    border: none;

    text-align: left;
  }

  table td:first-of-type {
    font-weight: 700;
    padding-left: 43px;
  }

  table tr:nth-child(odd):not(:first-child) {
    background: #F8F8FD;
    border-radius: 20px;
  }

}
.blog__img-container {
  position: relative;
  display: flex;
  justify-content: center;
  background: #F8F8FD;

  margin-top: 60px;
  margin-bottom: 60px;

  border-radius: 20px;

  @include break-max($breakpoint-md) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  img {
    position: relative;
  }

}

// .blog__img-blur {
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;

//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   filter: blur(8px);
//   z-index: -1;
// }

.blog__main-pic {
  position: relative;

  margin-top: 40px;
  margin-bottom: 60px;

  margin-left: -125px;
  margin-right: -125px;

  @include break-max($breakpoint-md) {

    margin-bottom: 50px;
    margin-left: -100px;
    margin-right: -100px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: 20px;
    margin-bottom: 45px;

    margin-left: -20px;
    margin-right: -20px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: 15px;
    margin-bottom: 30px;

    margin-left: -10px;
    margin-right: -10px;
  }

  img {
    border-radius: 0;
    width: 100%;
    height: 500px;
    object-fit: cover;

    @include break-max($breakpoint-md) {
      height: 410px;
    }

    @include break-max($breakpoint-sm) {
      height: 400px;
    }

    @include break-max($breakpoint-xs) {
      height: 244px;
    }
  }
}

.blog__socials {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  display: flex;
  flex-direction: column;
}

.blog__socials-item {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-white, 0.8);
  border-radius: 50%;
  margin-bottom: 18px;
  transition: all 0.3s;
  &:hover {
    background-color: $color-white;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.blog__text {
  margin-bottom: 39px;
}

.blog__h2 {
  margin-bottom: 26px;
}

.blog__h3 {
  margin-bottom: 34px;
}

.blog__h4 {
  margin-bottom: 13px;
}

ol.blog__ol {
  list-style: decimal;
  margin-left: 20px;
  margin-bottom: 60px;

  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li::marker {
    color: $color-red;
    font-weight: 700;
  }
}

.blog__quote {
  margin-top: 35px;

  position: relative;
  padding-top: 40px;
  border-top: 1px solid $color-brand-opaque-30;
  &::before {
    content: '«';
    position: absolute;
    top: -15px;
    left: 0;

    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;

    background-color: $color-white;

    font-weight: 300;
    font-size: 21px;
    line-height: 30px;
    color: $color-brand-3;
  }
}

.blog__qoute-autor {
  margin-top: 25px;
}

.blog__ul {

  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.blog__block {
  margin-top: 80px;
}

.blog__block-content {
  display: flex;
  align-items: center;

  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  img {
    margin-right: 78px;
  }
}

.blog__block-text {
  @include break-max($breakpoint-xs) {
    margin-top: 25px;
  }
}

.blog__block-anchors {
  margin-top: 30px;
}

.blog__anchor {
  font-weight: 700;
  color: $color-brand-3;

  &_highlighted {
    color: $color-red;
  }

  & + & {
    margin-left: 50px;
  }
}

.blog__block-sign {
  margin-top: -40px;
  color: $color-brand-3;
  @include break-max($breakpoint-md) {
    margin-top: -30px;
  }
  @include break-max($breakpoint-sm) {
    margin-top: -25px;
  }
  @include break-max($breakpoint-xs) {
    margin-top: -10px;
  }
}

.blog__img-block {
  margin-top: 95px;
  margin-bottom: 75px;
}

.blog__table {
  width: 100%;
  margin-bottom: 100px;
}

.blog__table tr {
  display: flex;
  align-items: center;
}

.blog__table thead {
  display: flex;
  border-bottom: 1px solid $color-brand-opaque-20;
}

.blog__table th,
.blog__table td {
  width: 200px;
  padding: 19px;

  text-align: left;
}

.blog__table td:first-of-type {
  font-weight: 700;
  padding-left: 43px;
}

.blog__table tr:nth-child(even) {
  background: #F8F8FD;
  border-radius: 20px;
}

.blog__banner {
  background-position: right 170px bottom 0;
  .hp__banner-content {
    width: 640px;
  }
  @include break-max($breakpoint-md) {
    background-position: center bottom -20px;
    &.hp__banner_type_2 {
      background-position: center bottom -100px;
    }
  }

}

.blog__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @include break-max($breakpoint-md) {
    gap: 22px;
  }
  @include break-max($breakpoint-sm) {
    gap: 16px;
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: repeat(3, 232px);

    &_scroll {
      overflow-x: scroll;
    }
  }



}

.blog__card {
  display: flex;
  flex-direction: column;
  color: inherit;
}

.blog__card-info {
  background-color: $color-white;
  padding: 0 46px 46px;
  border-radius: 0 0 20px 20px;
  @include break-max($breakpoint-md) {
    padding: 0 31px 31px;
  }
  @include break-max($breakpoint-sm) {
    padding: 0 28px 28px;
  }
}

.blog__card-descr {
  margin-top: 17px;
  @include break-max($breakpoint-md) {
    margin-top: 14px;
  }
}

.blog__card-date {
  margin-top: 30px;
  color: #9AA9B8;
  @include break-max($breakpoint-md) {
    margin-top: 22px;
  }
}

.blog__card-pic {
  background-color: $color-white;
  border-radius: 20px 20px 0 0;

  img {
    margin: 0;
    border-radius: 20px;
    height: 226px;
    object-fit: cover;
    @include break-max($breakpoint-md) {
      height: 186px;
    }
    @include break-max($breakpoint-sm) {
      height: 186px;
    }
    @include break-max($breakpoint-xs) {
      height: 186px;
    }
  }
}

.blog-cards {
  padding-bottom: 60px;
}

.blog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include break-max($breakpoint-xs) {
    display: none;
  }
}

.blog__breadcrumbs {
  display: flex;
  align-items: center;
  color: $color-brand-3;

  li {
    display: flex;
    &::after {
      content: '>';
      margin-left: 5px;
      margin-right: 5px;
    }

    &:last-child::after {
      display: none;
    }
  }

  a {
    color: inherit;
  }
}

.blog__date {
  color: $color-brand-3;
}

.hp .blog .blog__wrapper {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 125px;
  background-color: $color-white;
  border-radius: 20px;

  padding-top: 50px;
  padding-bottom: 80px;

  @include break-max($breakpoint-md) {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 100px;
    padding-top: 50px;
    padding-bottom: 60px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 45px;
    margin-bottom: 45px;
    padding: 0 20px;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 0;
    margin-bottom: 30px;
    padding: 0 10px;
    padding-top: 30px;
    padding-bottom: 40px;
    border-radius: 0 0 20px 20px;
  }

}

.bg-gray {
  background-color: #F8F8FD;
}

.hp__banner_form {
  flex-direction: column;
  background-color: $color-brand-2;

  .hp__banner-content {
    width: 615px;
  }

  &:not(.hp__banner_pink) .hp__banner-h3 {
    color: $color-white;
  }

  .sor__form-item {
    width: 345px;
    max-width: 100%;
    input {
      width: 100%;
    }
  }

  .sor__submit {
    @include break-max($breakpoint-sm) {
      margin-top: 15px;
      position: static;
      transform: none;
      width: 345px;
      max-width: 100%;
    }
  }

}

.hp__banner_plant_1 {
  background-image: url('../../assets/images/hp/furnish/plant-1.svg');
  background-position: right 200px bottom 0;
}

.hp__banner_plant_2 {
  background-image: url('../../assets/images/hp/furnish/plant-2.svg');
  background-position: right 200px bottom 0;
}

.hp__banner_pink {
  background-color: #FFE6E5;

  .checkbox__label {
    color: $color-brand-2;
  }
}

.hp__star {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  border-radius: 50%;
  margin-left: 5px;
  background-color: $color-red;
  padding-top: 5px;
}

.hp__banner_gray {
  background-color: $gray-light;
  background-image: url('../../assets/images/hp/banner-plant-4.svg');

}

.hp__banner-h3 {
  display: flex;
  align-items: baseline;
}

.hp__banner-descr {
  margin-top: 5px;
}

.hp__youtube {
  margin-top: 40px;
  max-height: 640px;
  cursor: pointer;
}

.hp__blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;

  @include break-max($breakpoint-md) {
    margin-top: 30px;
    gap: 22px;
  }

  @include break-max($breakpoint-sm) {
    margin-top: 20px;
    gap: 16px;
  }

  @include break-max($breakpoint-xs) {
    margin-top: 20px;
    gap: 10px;
    grid-template-columns: 1fr;
  }
}

.hp__block {
  padding: 40px;
  border-radius: 20px;
  background-color: $gray-light;

  @include break-max($breakpoint-md) {
    padding: 30px;
  }
  @include break-max($breakpoint-xs) {
    padding: 24px 30px;
  }
}

.hp__table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 125px;
  padding-right: 125px;
  @include break-max($breakpoint-md) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @include break-max($breakpoint-sm) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @include break-max($breakpoint-xs) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.hp__table-header {
  margin-left: 125px;
  margin-right: 125px;
  @include break-max($breakpoint-md) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @include break-max($breakpoint-sm) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include break-max($breakpoint-xs) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.hp__table-row {
  &:nth-child(even) {
    background-color: $gray-light;
    border-radius: 20px;

    @include break-max($breakpoint-xs) {
      margin-left: -10px;
      margin-right: -10px;
      border-radius: 0;

      .hp__table-header {
        margin-left: 20px;
        margin-right: 20px;
      }

      .hp__table-grid {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.hp__table-grid {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.hp__table-item li {
  display: flex;
  margin-bottom: 6px;
  @include break-max($breakpoint-xs) {
    padding-right: 20px;
  }

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $gray-dark-2;
    margin-right: 28px;
    margin-top: 6.5px;
    @include break-max($breakpoint-xs) {
      display: none;
    }
  }
}

.hp__table-header {
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-dark;

  @include break-max($breakpoint-xs) {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.hp__promo-content_furnish {
  @include break-max($breakpoint-xs) {
    height: 530px;
    padding-bottom: 15px;
  }
}

.hp__action {
  display: flex;
  background: linear-gradient(360deg, #ECEDF0 0%, #F6F7F8 95%);
  border-radius: 5px;
  padding-right: 20px;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;

  &:before {
    content: "";
    background: linear-gradient(360deg, #D8DAE1 0%, #F6F7F8 100%, #ECEDF0 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition-duration: .3s;
  }
  &:after {
    content: "";
    background: linear-gradient(360deg, #ECEDF0 0%, #F6F7F8 95%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition-duration: .3s;
  }

  &:hover {
    background: linear-gradient(360deg, #D8DAE1 0%, #F6F7F8 100%, #ECEDF0 100%);
  }

  &:hover:after {
    opacity: 0;
  }

  svg {
    margin-right: 15px;
    flex-shrink: 0;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .hp__action-text {
    z-index: 1;
    color: $gray-dark-2;
    @include h6-L();
    padding-left: 85px;
    text-align: left;
  }

}


.grecaptcha-badge {
  z-index: 123;
}
