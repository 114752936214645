.clients {
  padding-bottom: 80px;

  &__download {
    display: flex;
    align-items: center;
    margin-right: 38px;
    color: $color-brand-3;
    transition: all 0.3s;
    &:hover, &:focus {
      color: $color-red;
    }

    &:active {
      color: $color-red-active;
    }

    &-icon {
      margin-right: 5px;
    }


  }

  &__lid {
    margin-left: 3px;
    height: 24px;
    cursor: default;
    font-weight: normal;
    &.with-tooltip:hover .tooltip {
      display: table;
    }
  }

  &__search-input {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 10px 0 40px;
    background-color: #fff;
    height: 50px;

    & .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
      stroke: $color-brand-3;
    }
  }

  &__phone {
    display: block;
    white-space: nowrap;
  }

  &__fav-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;

    & .icon {
      font-size: 17px;
      fill: $color-brand-opaque-20;
      transition: fill $transition-default;
    }

    &:hover {

      & .icon {
        fill: $color-brand-opaque-50;
      }
    }

    &.is-fav {

      & .icon {
        fill: #91A3E9;
      }
    }
  }

  .is-hidden-md {
    @include break-max($breakpoint-md) {
      display: none;
    }
  }

  .is-hidden-sm {
    @include break-max($breakpoint-sm) {
      display: none;
    }
  }

  &__header {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    @include break-max($breakpoint-xs) {
      margin: 20px 10px;
      flex-direction: column;
    }
  }

  &__header-box {
    display: flex;
    align-items: center;
    @include break-max($breakpoint-xs) {
      margin-top: 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__info {
    display: inline-block;
    margin-left: 4px;

    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-block {
      margin-bottom: 16px;
      flex: 1 0 100%;

      &_auto {
        flex: 1 1 auto;
      }
    }

    &-label {
      color: $color-brand-3;
      margin-bottom: 3px;
    }

    &-val {
      color: $color-brand-2;
      font-weight: 700;
    }
  }

  &__switcher {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;


    &-btn {
      padding: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color $transition-default, color $transition-default;
      text-decoration: none;
      color: $color-brand-2;
      & + & {
        margin-left: 15px;
      }

      @include break-max($break-md) {
        padding: 8px 10px;
      }

      &.active {
        background-color: $color-brand-3;
        color: $color-white;
        pointer-events: none;
      }

      &:hover {
        background-color: $color-brand-opaque-10;
      }
    }
  }

  &__add-new {
    position: relative;
    z-index: 3;

    @include break-max($break-xs) {
      align-items: center;
      display: flex;
      height: 32px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__filter {
    padding: 30px 20px;
    background: $color-brand-opaque-8;
    border-radius: 5px;
    display: flex;
    position: relative;
    margin-bottom: 25px;

    @include break-max($breakpoint-sm) {
      margin-left: -20px;
      margin-right: -20px;
    }

    &-grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;

      @include break-max($breakpoint-sm) {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    &-col {
      flex: 1 1 150px;
      margin-right: 12px;

      @include break-max($breakpoint-sm) {
        flex-basis: 130px;
      }

      &:last-of-type {
        margin-right: 0;
      }


      & .form__item {
        margin-bottom: 0;
        margin-left: 0;
        flex: 1 1 auto;
        @include break-max($breakpoint-xs) {
          width: 100%;
        }
      }

      &_2x {
        flex: 2 1 300px;
      }

      &_search {
        flex: 1 1 250px;

        & .input_type_search .input__control {
          border-radius: 4px;
          background-color: $color-white;
          height: 50px;
        }
      }
    }

    &-addit {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include break-min($break-lg) {
        margin-left: 18px;
      }

      @include break-max($breakpoint-sm) {
        flex: 1 0 100%;
        margin-left: 0;
      }
    }

    &-button {
      margin-left: 30px;
      border-radius: 5px;
      padding: 17px 24px;
      border: 1px solid $color-border;
    }

    &-mobile {
      display: none;
      justify-content: center;
      margin-bottom: 40px;

      @include break-max($breakpoint-sm) {
        display: flex;
      }
    }
  }

  &__table {
    &-client {
      font-weight: bold;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    &-link {
      color: $color-brand-2;
      text-decoration: none;
      transition: color 0.15s ease;
      cursor: pointer;

      .icon {
        display: inline-block;
        stroke: $color-red;
        opacity: 0;
        width: 12px;
        height: 12px;
        transition: opacity 0.15s ease;
        margin-left: 6px;
      }

      &:hover {
        color: $color-red;

        .icon {
          opacity: 1;
        }
      }

      @include break-max($breakpoint-sm) {
        .icon {
          opacity: 1;
          stroke: $color-brand-3;
        }
      }
    }

    &-object {
      margin-bottom: 10px;
    }

    &-label {
      color: $color-brand-3;
      text-decoration: none;
      transition: color 0.15s ease;

      &:hover {
        color: $color-red;
      }
    }
  }

  &__back {
    color: $color-brand-2;
  }

  &__grid {
    @include grid();
    
    position: relative;
    align-items: flex-start;
  }

  &__grid-col {
    @include grid-col();
  }

  &__back {
    @include grid-size(2);
    position: sticky;
    top: 100px;
    @include break-max($break-md) {
      @include grid-size(12, $grid-gutter-sm);
      margin-bottom: 22px;
    }
  }

  &__new {
    @include grid-size(8);

    @include break-max($break-lg) {
      @include grid-size(9, $grid-gutter-md);
    }

    @include break-max($break-md) {
      @include grid-size(12, $grid-gutter-sm);
    }
  }

  &__back-btn {

    & .icon {
      margin-right: 11px;
      fill: $color-brand-2;
      font-size: 14px!important;
    }
  }

  &__view {

    &-row {

      &-title {
        white-space: nowrap;

        & .icon {
          font-size: 20px;
          margin-right: 13px;
        }
      }
    }

    &-strong {
      font-weight: 700;
    }

    &-secondary {
      color: $color-brand-3;
      margin-top: 4px;
    }
  }
}

.table {
  width: 100%;
  & th.nowrap {
    white-space: nowrap;
  }
  & td.nowrap {
    white-space: nowrap;
  }

  tbody tr {
    border-bottom: 1px solid $color-brand-opaque-30;
  }
}

table.dataTable.dtr-column {
  border-bottom: none;

  & > thead {
    .sorting {
      background: none;

      &-th {
        display: flex;
        align-items: center;
      }

      &-arrows {
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='20' viewBox='0 0 8 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 0L0.000719547 7.36842H7.36914L3.68493 0Z' fill='%238F95AC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 20L0.000719547 12.6316H7.36914L3.68493 20Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 8px;
        height: 20px;
        margin-left: 10px;
      }

      &_asc {
        background: none;

        .sorting-arrows {
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 8L0.000719547 0.631579H7.36914L3.68493 8Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
          background-position: center bottom;
        }
      }

      &_desc {
        background: none;

        .sorting-arrows {
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.68493 0L0.000719547 7.36842H7.36914L3.68493 0Z' fill='%238F95AC'/%3E%3C/svg%3E%0A");
        }
      }
    }

    & > tr {
      & > th {
        text-align: left;

        @include break-max($breakpoint-sm) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      & > th.control {
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
      }
    }
  }

  & > tbody {
    & > tr {
      & > td {
        @include break-max($breakpoint-sm) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      & > td.control:before {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25px;
        width: 25px;
        margin: auto;
        background-color: $color-brand-opaque-10 !important;
        border: none;
        box-shadow: none;
        font-size: 20px;
        line-height: 22px;
        font-weight: 300;
        font-family: "Formular", sans-serif;
        color: $color-brand-1;
      }
    }
  }
}

.sorting_1 {
  background-color: transparent!important;
}

.pan-table.for-saved-filters {
  @include break-max($break-xs) {
    .clients__table-client {
      margin-left: 10%;
    }

    .control_more {
      display: flex;
      justify-content: flex-end;
    }

    span.flats__row-table-expanded-title {
      margin-right: 16px;
    }

    .filter-row__block-name {
      flex-basis: 100%;
    }

    .filter-row__block {
      margin-bottom: 24px;
    }

    .filter-row {
      max-width: 255px;
    }

    .filter-row__value {
      white-space: normal;
    }
  }
}

.saved-filters-popup {
  .subscribe__socials {
    padding: 10px 0 20px 30px;
  }
}

.edit-saved-filters {
  .modal-form__row_align_bottom {
    align-items: flex-start;
  }

  .form__item {
    flex-basis: 100%;
    width: 100%;
  }

  .checkbox.for-saved-filters {
    align-items: center;
    flex-direction: row-reverse;

    .checkbox__box {
      margin-right: 8px;
    }
  }

  .modal-form__item.modal-form__item_2x.for-saved-filters {
    width: 100%;
  }

  .mobile-filters__show-all {
    flex-direction: column;
    margin: 0 10px;

    .button_view_default {
      margin: 0;
      width: 100%;
    }
  }
}

.clients__sell-btns {
  display: flex;
  margin-top: 10px;
}

.clients__sell-btn {
  display: flex;
  align-items: center;
  transition: all 0.3s;
  color: inherit;
  svg {
    margin-right: 5px;
  }
  span {
    margin-top: 5px;
  }

  &:first-of-type {
    margin-right: 35px;
  }

  &:hover {
    color: $color-brand-3;
  }
}
