@import "../../styles/variables";

.showroom-tooltip {
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 2;

  width: 129px;
  height: 65px;

  padding: 5px;
  padding-left: 25px;

  color: $color-white;
  background: $color-red;
  border-radius: 9px;
  font-size: 14px;
  line-height: 18px;
}

.showroom-tooltip__decor {
  position: absolute;
  top: 0;
  left: -11px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  background: $color-red;
  border-radius: 50%;
}

.showroom-tooltip__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;
  background: rgba($color-white, 0.3);
  border-radius: 50%;

  .icon {
    fill: $color-white;
  }

}
