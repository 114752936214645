@import "../../styles/variables";
@import "../../styles/mixins";

.office-training {
  @include break-max(950px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .panschool-sec1 {
    background-image: url("../../assets/images/OfficeTraining/office-training-bg1.png");
    background-color: #EF372E;
    color: white;
    overflow: hidden;

    @include break-max($breakpoint-md) {
      height: 550px;
    }

    @include break-max($breakpoint-sm) {
      height: 420px;
      min-height: 200px;
    }

    @include break-max($breakpoint-xsm) {
      height: 370px;
      min-height: 370px;
    }
    @include break-max(950px) {
      background: none;
    }

    @include break-max($breakpoint-xs) {
      height: 330px;
      min-height: 330px;
    }

  }

  .panschool-sec1__leveltext {
    margin-bottom: 130px;

    @include break-max($breakpoint-md) {
      margin-bottom: 90px;
    }

    @include break-max($breakpoint-sm) {
      margin-bottom: 50px;
    }
    @include break-max($breakpoint-xs) {
      margin-bottom: 70px;
    }
  }

  .panschool-sec1__toplogo {

    @include break-max($breakpoint-md) {
      width: auto;
      height: 100%;
    }

    @include break-max(950px) {
      width: 100%;
    }
  }

  .panschool-sec2 {
    background-image: url("../../assets/images/OfficeTraining/ofice-training-sec2bg.png");
    background-size: cover;
  }

  .office-training__sec3 {

    .panschool-sec3__left {
      background-image: url("../../assets/images/OfficeTraining/office-training-sec3bg.png");
      background-size: cover;
      width: 500px;
      display: flex;
      justify-content: flex-end;
      padding: 70px;

      @include break-max($breakpoint-md) {
        width: 420px;
        padding: 60px;
      }

      @include break-max(950px) {
        width: 100%;
        order: 0;
        padding: 30px;
        height: 200px;
        align-items: flex-end;
        background-size: 50%;
        background-repeat: no-repeat;
      }
    }

    .panschool-sec3__title {
      font-size: 48px;
      margin: 0;

      @include break-max($breakpoint-md) {
        font-size: 30px;
      }

      @include break-max(950px) {
        text-align: left;
        font-size: 24px;
        width: 60%;
      }
    }

    .panschool-sec3__title.panschool-sec3__title_mark {
      position: relative;
      height: 60px;

      @include break-max($breakpoint-md) {
        height: 45px;
      }

      @include break-max(950px) {
        height: 35px;
      }

      span {
        background-color: #2C2E3E;
        border-radius: 50px;
        padding: 0px 20px 10px 20px;
        margin-left: -20px;
        position: absolute;
        transform: rotate(-3deg);
      }
      div {
        transform: rotate(3deg);
      }
    }

    .panschool-sec3__right {
      padding: 70px 60px;

      @include break-max($breakpoint-md) {
        padding: 70px 40px;
      }

      @include break-max($breakpoint-sm) {
        padding: 40px 40px;
      }
    }

    .office-training__sec3-list {
      display: flex;
      flex-wrap: wrap;
      gap: 40px 50px;

      @include break-max($breakpoint-md) {
        gap: 35px;
      }

      @include break-max(950px) {
        gap: 15px;
      }

      li {
        flex-basis: calc(50% - 30px);

        @include break-max(950px) {
          flex-basis: 100%;
        }

        .office-training__sec3-listTitle {
          margin-top: 20px;
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: 500;

          @include break-max($breakpoint-md) {
            font-size: 18px;
          }

          @include break-max($breakpoint-sm) {
            font-size: 16px;
          }

          @include break-max(950px) {
            margin-top: 10px;
          }
        }
        .office-training__sec3-listText {
          font-size: 22px;
          margin-bottom: 5px;
          padding-left: 20px;
          position: relative;

          @include break-max($breakpoint-md) {
            font-size: 18px;
          }

          @include break-max($breakpoint-sm) {
            font-size: 14px;
          }

          @include break-max(950px) {
            margin-bottom: 10px;
          }

          &:before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: black;
            position: absolute;
            left: 5px;
            top: 10px;

            @include break-max(950px) {
              margin-left: -10px;
              margin-top: 6px;
              left: auto;
              top: auto;
            }
          }
        }
      }
    }

  }

  .office-training__sec4 {
    background-color: #E3F0F7;
    padding: 60px 90px;
    border-radius: 60px;
    margin-top: 10px;

    @include break-max($breakpoint-md) {
      border-radius: 40px;
    }
    @include break-max($breakpoint-sm) {
      border-radius: 30px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include break-max(1120px) {
      border-radius: 20px;
    }
    @include break-max($breakpoint-xsm) {
      border-radius: 15px;
      padding: 40px 30px;
    }
    @include break-max(950px) {
      margin-top: 5px;
    }
    @include break-max($breakpoint-xs) {
      padding: 30px;
    }

    .panschool-sec1__maintitle {
      text-align: center;

      @include break-max(950px) {
        color: black;
        font-size: 28px;
      }

    }

    .office-training__overtabs {
      display: flex;
      gap: 95px;
      margin-top: 60px;

      @include break-max($breakpoint-sm) {
        gap: 60px;
      }

      @include break-max(950px) {
        margin-top: 20px;
      }
    }



    .office-training__tabs {
      width: 535px;

      @include break-max($breakpoint-md) {
        width: 420px;
      }

      @include break-max($breakpoint-sm) {
        width: 340px;
      }

      @include break-max(950px) {
        width: 100%;
      }
    }

    .office-training__tab {
      border-top: 1px solid #2C2E3E;
      padding-top: 20px;
      padding-bottom: 30px;
      cursor: pointer;

      @include break-max($breakpoint-sm) {
        padding-top: 10px;
        padding-bottom: 15px
      }

      @include break-max(950px) {
        padding-top: 5px;
        padding-bottom: 7px;
        position: relative;

        &:before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjcwOTkgOC40NjAwM0MxMC4wMDI4IDguMTY3MTMgMTAuNDc3NyA4LjE2NzEzIDEwLjc3MDYgOC40NjAwM0wxNC4yOTAzIDExLjk3OThDMTQuNTgzMiAxMi4yNzI3IDE0LjU4MzIgMTIuNzQ3NSAxNC4yOTAzIDEzLjA0MDRMMTAuNzcwNiAxNi41NjAxQzEwLjQ3NzcgMTYuODUzIDEwLjAwMjkgMTYuODUzIDkuNzA5OTcgMTYuNTYwMUM5LjQxNzA3IDE2LjI2NzIgOS40MTcwNyAxNS43OTIzIDkuNzA5OTcgMTUuNDk5NUwxMi42OTkzIDEyLjUxMDFMOS43MDk5IDkuNTIwNjlDOS40MTcwMSA5LjIyNzc5IDkuNDE3MDEgOC43NTI5MiA5LjcwOTkgOC40NjAwM1oiIGZpbGw9IiM2NDY4N0IiLz4KPC9zdmc+Cg==");
          display: inline-block;
          position: absolute;
          right: 0;
          top: 13px;
        }
      }
    }



    .office-training__tab-number {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 15px;

      @include break-max($breakpoint-sm) {
        font-size: 16px;
        margin-bottom: 10px;
      }

      @include break-max(950px) {
        margin-bottom: 5px;
      }
    }

    .office-training__tab-text {
      font-size: 18px;

      @include break-max($breakpoint-sm) {
        font-size: 14px;
      }
    }

    .office-training__tab.active {
      .office-training__tab-number {
        color: #EF372E;
      }
      .office-training__tab-text {
        color: #EF372E;
        font-weight: 700;
        font-size: 24px;

        @include break-max($breakpoint-sm) {
          font-size: 14px;
        }

        @include break-max(950px) {
          font-weight: 400;
        }
      }
    }

  }

  .autopan-sec6.panschool-sec6 {
    background-color: #EF372E;
    overflow: hidden;

    .panschool-sec1__maintitle {
      color: white;
    }

    .input_type_form .input__label {
      color: white;
    }

    .checkbox_view_invert .checkbox__label {
      color: white;
    }
    .checkbox_view_invert .checkbox__box {
      background-color: white;
    }
  }

  @include break-max($breakpoint-md) {

  }

  @include break-max($breakpoint-sm) {

  }
  @include break-max($breakpoint-xsm) {

  }

  @include break-max($breakpoint-xs) {

  }
}


.office-training__tab-content {
  width: calc(100% - 630px);
  border-radius: 30px;
  background-color: #EF372E;
  padding: 70px 150px 0 40px;
  position: relative;
  background-image: url("../../assets/images/OfficeTraining/tabbg.png");
  background-size: cover;

  @include break-max($breakpoint-md) {
    width: calc(100% - 515px);
    padding-top: 50px;
    padding-right: 50px;
  }

  @include break-max($breakpoint-sm) {
    width: calc(100% - 400px);
  }

}

.office-training__tabcontent-listitem {
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
  margin-left: 16px;
  position: relative;
  z-index: 1;

  @include break-max($breakpoint-sm) {
    font-size: 14px;
  }

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    margin-left: -16px;
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    margin-top: 8px;
  }
}

.office-training__tabcontent-listimage {
  position: absolute;
  bottom: 0;
  left: 0;
}

.office-training__tab-btn {
  min-width: 220px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}


.panmodal.mobile-office-trainingModal {
  h3.panmodal__h3 {
    display: none;
  }

  svg.panmodal__close {
    display: none;
  }

  .panmodal__content_sm {
    width: 100%;
    max-width: 400px;
    padding: 0;
  }
  .panmodal__header {
    width: 100%;
    margin: 0;
    min-height: auto;
    height: auto;
    z-index: 1;
    position: absolute;
    //top: 30px;

    .panmodal__btn {
      margin-top: 0px;
      background-color: transparent;


      top: 20px;
      right: 40px;
      width: 0;
      height: 0;

      svg {
        stroke: white;
      }
    }
  }
  .panmodal__wrapper {
    margin: 100px 10px;
    padding: 0;
    background-color: transparent;
    position: relative;
  }

  .office-training__tab-content {
    width: 100%;
    min-height: 600px;
    border-radius: 20px;
  }
}
