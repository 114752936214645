.obj-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  transition: all .3s;
  cursor: pointer;

  @include break-max($breakpoint-md) {
    flex-direction: row;
  }
  @include break-max($breakpoint-xs) {
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0 2px 15px rgba(143, 149, 172, 0.5);
  }

  &__suburban {
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  button.button {
    @include break-max($breakpoint-md) {
      margin-left: 0;
    }
  }

  &__suburban-block {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__suburban-info {
    display: flex;
    gap: 5px;

    div:first-child {
      width: 170px;
    }
  }
}

.obj-card__img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @include break-max($breakpoint-sm) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  @include break-max($breakpoint-xs) {
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.obj-card__slider {
  flex-shrink: 0;
  max-width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  img {
    max-width: 100%;
    width: 470px;
    height: 320px;
    object-fit: cover;
  }

  @include break-max($breakpoint-md) {
    width: 470px;
    height: 320px;
    flex-shrink: 0;

  }

  @include break-max($breakpoint-sm) {
    width: 360px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include break-max($breakpoint-xs) {
    height: 220px;
    min-height: auto;
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    img {
      height: 220px;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

}

.obj-card__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include break-max($breakpoint-md) {
    padding-left: 30px;
  }
  @include break-max($breakpoint-sm) {
    padding-left: 0;
  }
}

.obj-card__body {
  padding: 35px 22px 15px;
  @include break-max($breakpoint-md) {
    padding-top: 30px;
  }
  @include break-max($breakpoint-xs) {
    padding-top: 20px;
    padding-left: 18px;
    padding-right: 18px;
  }
}


.obj-card__footer {
  margin-top: auto;
  margin-left: 22px;
  margin-right: 22px;
  padding-top: 0;
  padding-bottom: 25px;

  min-height: 144px;

  @include break-max($breakpoint-md) {
    padding-top: 0;
    min-height: auto;
  }

}

.obj-card__title {
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 2px;
  @include break-max($breakpoint-md) {
    font-size: 25px;
    line-height: 30px;
  }
  @include break-max($breakpoint-sm) {
    font-size: 25px;
    line-height: 30px;
  }
  @include break-max($breakpoint-xs) {
    font-size: 18px;
    line-height: 22px;
  }

}



.obj-card__subtitle {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  @include break-max($breakpoint-xs) {
    font-size: 16px;
  }
}

.obj-card__row {
  display: flex;
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.obj-card__col {
  &:first-child {
    width: 140px;
    padding-right: 15px;

  }
}

.obj-card__price {
  font-size: 25px;
  line-height: 30px;
  @include break-max($breakpoint-xs) {
    font-size: 18px;
    line-height: 22px;
  }
}

.obj-card__price-per-m {
  margin-top: 2px;
  font-size: 14px;
  line-height: 18px;
}


.obj-card__body-descr {
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;

  @include break-max($breakpoint-xs) {
    font-size: 16px;
  }
}


.obj-card__descr {
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;

  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include break-max($breakpoint-md) {
    height: auto;
  }

  @include break-max($breakpoint-xs) {
    display: none;
  }
}


.cards__item_highlight {
  .obj-card__title {
    color: #fff;
    position: relative;
    margin-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -10px;
      right: -10px;
      z-index: 0;
      border-radius: 4px;
    }
    span {
      position: relative;
    }
  }
}

.obj-card-grid {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: repeat(4, 345px);

  @include break-max($breakpoint-md) {
    grid-template-columns: repeat(4, 280px);
  }

  @include break-max($breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;

    .obj-card:nth-child(n+4) {
      display: none;
    }
  }

  @include break-max($breakpoint-xs) {
    grid-template-columns: repeat(1, 320px);
    justify-content: center;
    row-gap: 25px;
  }



}

.obj-card_for-grid {
  border: 1px solid $color-brand-opaque-20;

  @include break-max($breakpoint-md) {
    flex-direction: column;
  }

  .obj-card__slider {
    @include break-max($breakpoint-md) {
      width: 100%;
      height: auto;
      border-radius: 15px 15px 0 0;
    }
  }

  .obj-card__slider img {
    max-height: 228px;
  }

  .obj-card__title,
  .obj-card__price {
    font-size: 20px;
    line-height: 26px;
  }

  .obj-card__content {
    @include break-max($breakpoint-md) {
      padding-left: 0;
    }
  }

  .obj-card__footer {
    min-height: auto;
  }
}
